<template>
  <div class="main-footer">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-2 content-img">
          <router-link :to="{ name: 'Login' }">
            <div style="overflow: hidden; height: auto; width: 120px;margin: auto">
              <img src="@/assets/logo/logo.png" alt="juris-logo" style="height: 100%; width: 100%;" />
            </div>
            <!-- <img src="@/assets/logo/logo-navbar.svg" alt="logo" /> -->
          </router-link>
        </div>
        <div class="col-lg-7">
          <div class="main-link footer-text">
            <div class="content-links" href="#">
              <router-link :to="{ name: 'Help-admin' }">Ayuda</router-link>
            </div>
            <div class="content-links" href="#">
              <router-link :to="{ name: 'Questions-admin' }">Preguntas Frecuentes
              </router-link>
            </div>
            <div class="content-links" href="#">
              <router-link :to="{ name: 'About-admin' }"> Nosotros</router-link>
            </div>
            <div class="content-links" href="#">
              <router-link :to="{ name: 'CookiePolicy-admin' }">Políticas de Cookies</router-link>
            </div>
            <div class="content-links" href="#">
              <router-link :to="{ name: 'Policy-admin' }">Políticas de Privacidad
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="content-copy">
            <span class="copyright">
              &copy; Juris Search {{ new Date().getFullYear() }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminFooter",
  data() {
    const isLogged = this.$store.getters;
    return {
      isLogged,
    };
  },
};
</script>

<style lang="scss" scoped>
$color-light-blue: #0673f2;
$color-dark: #00171f;
$color-white: #f4f4f4;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;

.main-footer {
  background: $color-white;

  .main-link {
    display: flex;
  }

  .footer-text {
    .content-links {
      margin-right: 16px;

      a {
        font-size: 13px;
        color: $color-gray;
        text-decoration: none;
        line-height: 16px;
        font-family: Inter-Regular;
      }
    }
  }

  .content-copy {
    .copyright {
      font-size: 13px;
      color: $color-gray;
      line-height: 16px;
      font-family: Inter-Regular;
    }
  }

}

@media (max-width: 768px) {
  .main-footer {
    padding: 20px 0;

    .content-img {
      text-align: center;
      margin-bottom: 20px;
    }

    .main-link {
      display: inline;
      text-align: center;

      .content-links {
        margin-bottom: 20px;
        margin-right: 0px;
      }
    }

    .content-copy {
      text-align: center;
    }
  }
}
</style>