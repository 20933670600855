<template>
  <div>
    <div class="main-legislation-detail">
      <div class="content-return">
        <div class="container">
          <a class="back-text" @click="$router.go(-1)">
            <img src="@/assets/icons/back-arrow.svg" class="mr-2" />
            Regresar a resultados
          </a>
        </div>
      </div>
      <div class="container">
        <div class="content-legislation-detail">
          <div>
            <h5 class="main-title">
              {{ document.name }}
            </h5>
            <div class="row">
              <div class="col-lg-4">
                <h5 class="title-blue pb-4">IDENTIFICACIÓN</h5>

                <div class="row main-table">
                  <div class="col-lg-5">
                    <span class="title">Tipo</span>
                  </div>
                  <div class="col-lg-7">
                    <span class="sub-title">Decreto Legislativo</span>
                  </div>
                </div>
                <div class="row main-table">
                  <div class="col-lg-5">
                    <span class="title">Numeración</span>
                  </div>
                  <div class="col-lg-7">
                    <span class="sub-title">{{ document.numeration }}</span>
                  </div>
                </div>
                <div class="row main-table">
                  <div class="col-lg-5">
                    <span class="title">Fecha de publicación</span>
                  </div>
                  <div class="col-lg-7">
                    <span class="sub-title">{{
                      document.publication_date | moment("DD MMMM YYYY")
                    }}</span>
                  </div>
                </div>
                <div class="row main-table estado">
                  <div class="col-lg-5">
                    <span class="title">Estado</span>
                  </div>
                  <div class="col-lg-7 right">
                    <span class="sub-title-state">
                      {{ document.status === 1 ? "Vigente" : "Expirado" }}</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-8">
                <h5 class="title-blue pb-4">CONTENIDO</h5>
                <iframe :src="documentFile" style="width: 100%; height: 500px" frameborder="0"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="content-legislation-detail">
          <div>
            <h5 class="main-title">
              Relacionados
            </h5>
            <div class="related-items">
              <div class="col-3" v-for="(item, index) in relateds" :key="index">
                <legislation-admin-related-item :legislation="item" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import documentProxy from "@/proxies/DocumentsProxy.js";
import LegislationAdminRelatedItem from "./LegislationAdminRelatedItem.vue";

export default {
  name: "DocumentsAdminLegislationDetail",
  components: { LegislationAdminRelatedItem },
  data() {
    return {
      documentModal: false,
      document: {},
      relateds: [],
      documentFile: null,
      documentId: this.$route.params.id,
      isLoading: false,
    };
  },
  watch: {
    "$route.params.id"(val) {
      this.documentId = val;
    },
    async documentId(val) {
      await this.reload();
    },
  },
  methods: {
    async getDocumentById() {
      let loader = this.$loading.show({
        canCancel: false,
        color: "#007bff",
      });
      try {
        this.isLoading = true;

        const { legislation } = await documentProxy.getByIdLegislation(
          this.documentId
        );
        const { relateds } = await documentProxy.getRelatedByIdLegislation(this.documentId);

        this.document = legislation;
        this.relateds = relateds;

      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
        loader.hide();
      }
    },
    async getDocumentLegislation() {
      try {
        this.documentFile = await documentProxy.getDocumentLegislation(
          this.documentId
        );
      } catch (e) {
        console.log(e);
      }
    },
    async reload() {
      await this.getDocumentById();
      await this.getDocumentLegislation();
    }
  },
  async mounted() {
    await this.reload();
  },
};
</script>

<style lang="scss" scoped>
$color-light-blue: #0673f2;
$color-darker-blue: #486aa3;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #dde3eb;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;
$color-weight-gray: #486169;

.main-footer {
  margin-bottom: 50px !important;
}

.main-legislation-detail {
  min-height: 100vh;
  width: 100%;
  padding-top: 69px;
  padding-bottom: 100px;
  background: $color-white-gray;

  .content-return {
    background: white;
    padding: 17px 0;

    .back-text {
      font-family: Inter-Medium;
      font-weight: 500;
      color: $color-weight-gray;

      &:hover {
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  .content-legislation-detail {
    background: white;
    padding: 32px;
    margin-top: 24px;

    .main-title {
      font-family: Inter-Medium;
      font-weight: 700;
      color: $color-dark;
      font-size: 32px;
      line-height: 39px;
      margin-bottom: 40px;
    }

    .title-blue {
      color: $color-darker-blue;
      font-family: Inter-Medium;
      font-weight: 600;
      line-height: 19px;
      font-size: 16px;
    }

    .main-table {
      border-bottom: 1px solid $color-light-gray;
      padding: 12px 0;

      &:last-child {
        border-bottom: 0px;
        margin-top: 12px;
      }

      .title {
        font-family: Inter-Medium;
        font-weight: 600;
        color: $color-dark;
        font-size: 15px;
        line-height: 140%;
      }

      .sub-title {
        font-family: Inter-Medium;
        font-weight: 400;
        color: $color-dark;
        font-size: 15px;
        line-height: 140%;
      }

      .sub-title-state {
        color: $color-dark;
        font-size: 15px;
        padding: 10px;
        background: #00e879;
        border-radius: 24px;
      }
    }
  }
}

.related-items {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 600px) {
  .main-legislation-detail {
    .main-table {
      &.estado {
        .right {
          padding: 15px 10px 15px;
        }
      }
    }
  }
}
</style>