<template>
    <div class="content-filter">
        <p class="text-sm font-semibold text-gray-800">
            {{
                (
                    data?.["NIVEL1"]?.LABEL + ' > ' + data?.["NIVEL2"]?.LABEL + ' > ' + data?.["NIVEL3"]?.LABEL + ' > ' + data?.["NIVEL4"]?.LABEL + ' > ' + data?.["NIVEL5"]?.LABEL
                )
                || 'Seleccione un filtro' }}
        </p>



        <div class="content-filter">
            <b-form class="d-flex flex-column flex-md-row align-items-end justify-content-between"
                @submit.prevent="search">
                <b-form-input placeholder="Buscar por descripción" v-model="filter.name"
                    class="mb-2 mr-sm-2 mb-sm-0 w-full md:w-2/3"></b-form-input>
                <b-form-select v-model="filter.option" :options="options"
                    class="mb-2 mr-sm-2 mb-sm-0 w-full md:w-1/3"></b-form-select>

                <div class="contenedor-buttons">
                    <button type="button" class="btn btn-blue-primary" @click="search">Buscar</button>
                    <button type="button" class="btn btn-blue-secondary" @click="openModal = true">Nuevo</button>
                </div>
            </b-form>

            <button type="button" class="mt-2 btn btn-dark" @click="regresar(data, 'nivel4')">
                <svg height="16" width="16" xmlns="http://www.w3.org/2000/svg" class="h-1 w-1" fill="none"
                    viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M10 19l-7-7m0 0l7-7m-7 7h18">
                    </path>
                </svg>
                Regresar</button>

        </div>
        
        <dataTable :role="role" :loading="pag.loading" :search="search" :fieldsComponent="fields" :actions="actions"
            :rowData="rowData" />

        <ModalEliminar :action="onDeleted" :openDelete="openDelete" :closeHandler="closeHandler"
            :message="'¿Está seguro de eliminar este registro, esta acción no se puede deshacer?'"
            :buttonOk="'Si, eliminar'" />
        <ModalAgregarFiltro :openModal="openModal" :toggleModal="toggleModal" :search="search"
            :objectValidator="objectValidator" :NIVEL="'NIVEL 6'" :IDPARENT="data.NIVEL5.VALUE"
            :TIPO="data.NIVEL1.TIPO == 'JURISPRUDENCIA' ? 1 : 2" />

        <ModalEditarFiltro :openModalEditar="openModalEditar" :toggleModalEditar="toggleModalEditar" :search="search"
            :data="dataEditar" :objectValidator="objectValidator" />
    </div>
</template>

<script>
import { inject } from 'vue'
import DataTable from "../../../components/admin/DataTable.vue";
import FilterProxy from '../../../proxies/FilterProxy';

import ModalEliminar from "../../../components/Modal/ModalEliminar.vue";
import ModalAgregarFiltro from "./Modales/ModalAgregarFiltro.vue";
import ModalEditarFiltro from "./Modales/ModalEditarFiltro.vue";

export default {
    name: "Nivel5",
    components: {
        DataTable,
        ModalEliminar,
        ModalAgregarFiltro,
        ModalEditarFiltro
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        setData: {
            type: Function,
            required: true,
        },
        regresar: {
            type: Function,
            required: true,
        },
        role: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            selected: "Noticias",
            filter: {
                name: null,
                option: 0,
            },
            options: [
                { value: 0, text: "-- Seleccione" },
                { value: 1, text: "JURISPRUCENCIA" },
                { value: 2, text: "LEGISLACIÓN" },
            ],
            pag: {
                loading: false,
                perPage: 10,
                init: 0,
            },
            fields: [
                { key: "RN", label: "" },
                { key: "VALUE", label: "", class: "d-none" },
                { key: "LABEL", label: "Descripción", width: "30%" },
                { key: "TIPO", label: "Tipo", class: "text-left" },
                { key: "ACCIONES", label: "Acciones", class: "text-center" },
            ],
            actions:
            {
                edit: (data) => this.onEdited(data),
                delete: (data) => this.onDeleted(data),
            },
            rowData: [],
            // AGREGAR
            openModal: false,
            // ELIMINAR
            openDelete: false,
            dataDelete: {},
            // EDITAR
            openModalEditar: false,
            dataEditar: {}
        };
    },
    setup() {
        const objectValidator = inject('objectValidator');

        return {
            objectValidator,
        }
    },
    methods: {
        async search(pag = {}) {
            if (Object.keys(pag).length === 0) {
                pag = this.pag
            }
            this.pag = { ...this.pag, ...pag }
            this.pag.loading = true;
            await FilterProxy.list({
                ROWS: pag?.perPage || 10,
                INIT: pag?.init || 0,
                DESC: this.filter.name,
                NIVEL: 7,
                ID: this.data?.["NIVEL5"]?.VALUE || 0
            }, this.filter.option)
                .then((response) => this.rowData = response)
                .catch((error) => this.rowData = [])
                .finally(() => this.pag.loading = false);
        },
        toggleModal() {
            this.openModal = !this.openModal;
        },
        // ELIMINAR
        async onDeleted(data = {}, isDelete = false) {
            if (!isDelete) {
                this.dataDelete = data;
                this.openDelete = true;
            } else {
                this.openDelete = false;
                this.$bvToast.hide();
                await FilterProxy.delete(this.dataDelete.VALUE)
                    .then((response) => {
                        this.$bvToast.toast(response.MESSAGE, {
                            title: response.STATUS ? "Exito" : "Error",
                            variant: response.STATUS ? "primary" : "danger",
                            solid: true,
                        });

                        if (response.STATUS) this.search(this.pag);
                    })
                    .catch((err) => {
                        this.$bvToast.toast(`Ocurrió un error al eliminar el registro`, {
                            title: "Error",
                            variant: "danger",
                            solid: true,
                        });
                    })
                    .finally(() => this.isLoading = false);
            }
        },
        closeHandler() {
            this.openDelete = false;
        },
        // EDITAR
        async onEdited(data) {
            this.dataEditar = {
                ...data,
                NIVEL: "NIVEL 6"
            };
            this.openModalEditar = true;
        },
        toggleModalEditar() {
            this.openModalEditar = !this.openModalEditar;
        },
    },
};

</script>


<style>

@media (max-width: 768px) {
    .content-filter form {
        gap: 0.5rem;
    }

    .content-filter form .contenedor-buttons{
        padding-right: .5rem;
    }
}

</style>