function transalateEntries(type){
    const typeEntrie = {
        0 : "Caso Común",
        1:  "Caso Emblemático",
        2 : "Resumen Ejecutivo"

    };
    return typeEntrie[type];
}
function transalateEntriesLegislation(type){
    const typeEntrie = {
        0 : "Expirado",
        1:  "Vigente",

    };
    return typeEntrie[type];
}

export { transalateEntries , transalateEntriesLegislation};