<template>
  <div>
    <div class="container">
      <div class="results-search">
        <div class="documents-list-cards" v-for="jurisprudence in jurisprudences" v-bind:key="jurisprudence.id">
          <div class="d-flex justify-content-between align-items-center">
            <router-link class="card-title" :to="{
              name: 'DocumentsJuristictionDetail',
              params: { id: jurisprudence.id },
            }">
              {{ jurisprudence.title }}
            </router-link>
            <div class="card-detail">
              <div class="to-detail">
                <div class="actions">
                  <a class="content-action" @click="downloadFile(jurisprudence.id)" v-b-tooltip.hover title="Descargar">
                    <img class="ml-2" src="@/assets/icons/download-paper.svg" alt="Descargar" />
                  </a>
                  <a class="content-action" @click="openDocumentModal(jurisprudence.id)" v-b-tooltip.hover
                    title="Previsualizar">
                    <img class="ml-2" src="@/assets/icons/search-paper.svg" alt="Previsualizar" />
                  </a>
                  <a class="content-action" @click="handleUserDocument(jurisprudence)" v-b-tooltip.hover
                    title="Agregar a mis documentos">
                    <img class="ml-2" :src="
                      jurisprudence.isFavorite
                        ? favoriteBluePicture
                        : favoritePicture
                    " alt="Agregar a mis documentos" />
                  </a>
                </div>
                <router-link class="btn-to-detail" :to="{
                  name: 'DocumentsJuristictionDetail',
                  params: { id: jurisprudence.id },
                }">
                  Ver Detalle
                  <img class="ml-2" src="@/assets/icons/arrow-right-dark-blue.svg" />
                </router-link>
              </div>
            </div>
          </div>
          <div class="d-flex card-content-detail">
            <div class="d-flex flex-row">
              <div class="card-tema">
                <div class="d-flex align-items-center">
                  <img src="@/assets/icons/paper-full.svg" />
                  <p class="tema-title">Tema</p>
                </div>
                <p class="tema-content" v-html="jurisprudence.theme"></p>
              </div>
              <div class="card-tema pl-5">
                <div class="d-flex align-items-center">
                  <img src="@/assets/icons/paper-full.svg" />
                  <p class="tema-title">Ambito</p>
                </div>
                <p class="tema-content" v-html="jurisprudence.ambit"></p>
              </div>
            </div>
            <div class="card-sintesis">
              <div class="d-flex align-items-center">
                <img src="@/assets/icons/paper-empty.svg" />
                <p class="sintesis-title">Fundamentos jurídicos relevantes</p>
              </div>
              <p class="sub-title pl-4" v-html="jurisprudence.resumen" v-if="jurisprudence.type === 0"></p>
              <p class="sub-title pl-4" v-html="jurisprudence.relevant_fundamentals" v-if="jurisprudence.type === 1">
              </p>
            </div>
            <div class="card-sintesis">
              <div class="d-flex align-items-center">
                <img src="@/assets/icons/paper-empty.svg" />
                <p class="sintesis-title">Resumen</p>
              </div>
              <p class="sub-title pl-4 pl-5" v-if="jurisprudence.type === 0">-</p>
              <p class="sub-title pl-4" v-html="jurisprudence.resumen" v-if="jurisprudence.type === 1"></p>
            </div>
          </div>
          <!-- MODAL -->
          <b-modal :ref="`modal-${jurisprudence.id}`" centered modal-class="b-jurisdiction" :hide-footer="true"
            :hide-header="true" ok-only no-stacking>
            <document-modal @closeDocumentModal="closeDocumentModal(jurisprudence.id)" :documentFile="documentFile"
              :title="jurisprudence.title" :documentId="jurisprudence.id" :documentType="0" :userId="getProfile.id"
              :isFavorite="jurisprudence.isFavorite" :reload="reload" />
          </b-modal>
        </div>
        <!-- PAGINATOR -->
        <div class="d-flex" v-if="jurisprudences.length > 0">
          <b-pagination v-model="currentPagination" size="lg" :total-rows="getFilterList.total"
            :per-page="getFilterList.perPage" first-text="Primero" prev-text="Anterior" next-text="Siguiente"
            last-text="Ultimo" align="fill" class="w-100 my-0">
          </b-pagination>
        </div>
        <div v-if="jurisprudences.length <= 0">
          <h4 class="text-center">No se encontraron resultados</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import documentProxy from "@/proxies/DocumentsProxy.js";
import DocumentModal from "@/components/subscriber/modal/DocumentModal.vue";
import favoritePicture from "@/assets/icons/favorite.svg";
import favoriteBluePicture from "@/assets/icons/favorite-blue.svg";
import filterProxy from "@/proxies/FilterProxy.js";

export default {
  name: "ResultsJurisdiction",
  components: { DocumentModal },
  data() {
    return {
      favoritePicture,
      favoriteBluePicture,
      jurisprudences: [],
      documentFile: null,
      currentUserDocuments: [],
      currenModalId: null,
      currentPagination: 1,
    };
  },
  computed: {
    ...mapGetters(["getProfile", "getFilterList", "getJurisprudenceSearchResult"]),
  },
  watch: {
    currentUserDocuments: function (val) {
      let onlyDocumentsIds = [];
      if (val.length) {
        val.map((document) => {
          if (document !== null) {
            onlyDocumentsIds.push(document.id);
          }
        });
      }
      if (onlyDocumentsIds.length) {
        let newsJurisprudences = [];
        this.jurisprudences.map((jurisprudence) => {
          let newJurisprudence = jurisprudence;
          if (onlyDocumentsIds.includes(jurisprudence.id)) {
            newJurisprudence = { ...jurisprudence, ...{ isFavorite: true } };
          } else {
            newJurisprudence = { ...jurisprudence, ...{ isFavorite: false } };
          }
          newsJurisprudences.push(newJurisprudence);
        });

        this.jurisprudences = newsJurisprudences;
      }
    },
    async currentPagination(value) {
      const filterVuex = this.$store.state.filter;
      const { filter } = filterVuex;
      const filters = { ...filter, ...{ currentPage: value } };
      await this.$store.dispatch("setFilterList", filters);
      await this.searchDocuments();
      await this.getUserDocuments();
    },
    getFilterList() {
      if (this.jurisprudences.length < 0) {
        this.setDataAndPagination();
      }
    },
    async getJurisprudenceSearchResult() {
      this.setDataAndPagination();
      await this.getUserDocuments();
    }
  },
  methods: {
    async searchDocuments() {
      let loader = this.$loading.show({
        canCancel: false,
        color: "#007bff",
      });
      const filterVuex = this.$store.state.filter;
      const { filter } = filterVuex;
      const { jurisprudences } = await filterProxy.jurisprudenceSearch(
        filter
      );
      await this.$store.dispatch("setJurisprudenceSearchResult", jurisprudences);
      loader.hide();
    },
    async downloadFile(id) {
      let loader = this.$loading.show({
        canCancel: false,
        color: "#007bff",
      });
      try {
        const downloadUrl = await this.getDocumentById(id);
        if (downloadUrl) {
          window.open(downloadUrl, "_blank");
        }
      } catch (e) {
        console.error(e);
      } finally {
        loader.hide();
      }
    },
    async getDocumentById(id) {
      let fileURL = null;
      try {
        fileURL = await documentProxy.getDocumentJurisprudence(id);
      } catch (e) {
        console.log(e);
      } finally {
        return fileURL;
      }
    },
    async openDocumentModal(id) {
      let loader = this.$loading.show({
        canCancel: false,
        color: "#007bff",
      });
      try {
        this.currenModalId = id;
        this.documentFile = await this.getDocumentById(id);
        const reference = `modal-${id}`;
        const modal = this.$refs[reference][0];
        modal.show();
      } catch (e) {
        console.log(e);
      } finally {
        loader.hide();
      }
    },
    closeDocumentModal(id) {
      this.documentFile = null;
      const reference = `modal-${id}`;
      const modal = this.$refs[reference][0];
      modal.hide();
    },
    async handleUserDocument(jurisprudence) {
      try {
        const { id } = this.getProfile;
        const documentData = {
          document: {
            document_id: jurisprudence.id,
            document_type: 0,
          },
        };
        if (!jurisprudence.isFavorite) {
          await documentProxy.postUserDocument(id, documentData);
        } else {
          await documentProxy.deleteUserDocument(id, jurisprudence.id, 0);
        }
      } catch (error) {
        console.log("ERROR on saving user document");
        console.log(error);
      } finally {
        await this.getUserDocuments();
      }
    },
    async getUserDocuments() {
      let loader = this.$loading.show({
        canCancel: false,
        color: "#007bff",
      });
      try {
        const { id } = this.getProfile;
        const { user: { documents = [] } } = await documentProxy.getUserDocuments(id, 0);
        this.currentUserDocuments = documents;
      } catch (error) {
        console.log("ERROR on gettings user document");
        console.log(error);
      } finally {
        loader.hide();
      }
    },
    async reload() {
      this.closeDocumentModal(this.currenModalId);
      this.currenModalId = null;
      await this.getUserDocuments();
    },
    async setDataAndPagination() {
      const { data = [], pagination = {} } = this.$store.getters.getJurisprudenceSearchResult;
      if (pagination.hasOwnProperty("currentPage") && pagination.currentPage === 1) {
        const filterVuex = this.$store.state.filter;
        const { filter } = filterVuex;
        const filters = { ...filter, ...{ total: pagination.total } };
        await this.$store.dispatch("setFilterList", filters);
      }
      this.jurisprudences = data;
    }
  },
  async mounted() {
    this.setDataAndPagination();
    if (this.jurisprudences.length) {
      await this.getUserDocuments();
    }
  },
};
</script>

<style lang="scss" scoped>
$color-light-blue: #0673f2;
$color-darker-blue: #486aa3;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #dde3eb;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;
$color-gray-main: #486169;

.container {
  .results-search {
    .documents-list-cards {
      margin-bottom: 10px;
      width: 100%;
      border-radius: 8px;
      padding: 30px 16px;
      background: $color-white;

      &:hover {
        // cursor: pointer;
        box-shadow: -7px -8px 6px -7px rgba(194, 194, 194, 0.75);
        -webkit-box-shadow: -7px -8px 6px -7px rgba(194, 194, 194, 0.75);
        -moz-box-shadow: -7px -8px 6px -7px rgba(194, 194, 194, 0.75);

        .card-detail {
          .actions {
            cursor: pointer;
            // visibility: visible;
            // opacity: 1;
            // transition: opacity 0.5s ease-in-out;
          }
        }
      }

      .card-title {
        font-family: Inter-Regular;
        font-weight: 600;
        font-size: 18px;
        padding: 10px;
        margin-bottom: 0;
        color: $color-gray-main;

        &:hover {
          text-decoration: none;
        }
      }

      .card-detail {

        // font-family: Inter-Regular;
        // font-size: 15px;
        // font-weight: 500;
        // color: $color-light-blue;
        .to-detail {
          //padding: 15px;
          position: relative;

          &:hover {
            text-decoration: none;
            color: $color-light-blue;
          }

          .btn-to-detail {

            padding: 10px;
            border-radius: 48px;
            position: relative;
            color: $color-darker-blue;

            &:hover {
              text-decoration: none;
              background: $color-white-gray;
              color: color-darker-blue;
              text-decoration: none;
            }
          }
        }
      }

      .actions {
        position: absolute;
        visibility: visible;
        // transition: opacity 500ms, visibility 500ms;
        // opacity: 0;
        left: -150px;
        top: 10px;

        .content-action {
          img {
            padding: 5px;
            border: 1px solid $color-light-gray;
            border-radius: 2px;
          }
        }
      }

      .card-content-detail {
        display: flex;
        flex-direction: column;
        margin-top: 30px;

        .card-tema {
          min-width: 400px;

          .tema-title {
            margin-bottom: 0;
            margin-left: 10px;
            font-family: Inter-Regular;
            color: $color-gray-main;
            font-weight: 600;
            font-size: 15px;
          }

          .tema-content {
            margin-left: 26px;
            color: $color-dark;
            font-family: Inter-Regular;
            font-weight: 400;
            font-size: 15px;
          }
        }

        .card-sintesis {
          .sintesis-title {
            margin-bottom: 0;
            margin-left: 10px;
            font-family: Inter-Regular;
            color: $color-gray-main;
            font-weight: 600;
            font-size: 15px;
          }

          .sintesis-content {
            margin-left: 28px;
          }
        }
      }
    }
  }
}
</style>