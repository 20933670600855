<template>
  <div class="main-policy-cookie fade-in">
    <div class="container mt-5">
      <div class="row">
        <div class="col-md-4 col-12">
          <h5 class="policy-card-title">Secciones</h5>
          <div class="policy-card">
            <div class="policy-redirect">
              <a
                href="#why"
                class="d-flex align-items-center justify-content-between"
              >
                <span>¿Qué son las cookies y para que las usamos?</span>
                <img src="@/assets/icons/arrow-card.svg" />
              </a>
            </div>
            <div class="policy-redirect">
              <a
                href="#types"
                class="d-flex align-items-center justify-content-between"
              >
                <span>¿Qué tipo de cookies utilizamos?</span>
                <img src="@/assets/icons/arrow-card.svg" />
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-8 col-12">
          <div class="content-policy">
            <h1 class="title">Política de Cookies</h1>
            <p class="policy-text">
              A continuación, se informa sobre la Política de Cookies utilizada
              por parte de JURIS SEARCH PENAL & COMPLIANCE (en adelante “Juris
              Search”), con domicilio legal ubicado en la Avenida Víctor Andrés
              Belaunde Nro. 370, San Isidro.
            </p>
            <p class="policy-text">
              Al acceder a nuestro sitio web o suscribirse a nuestros servicios
              online acepta haber leído y prestado su consentimiento de forma
              informada, libre, previa, expresa e inequívoca. Asimismo, la
              Política de Cookies de Juris Search puede actualizarse en
              cualquier momento en cuyo caso se le informará y se publicará la
              versión actualizada en el presente sitio web.
            </p>

            <p class="policy-sub-title" id="why">
              ¿Qué son las cookies y para que las usamos?
            </p>

            <p class="policy-text">
              Una cookie es un archivo que envía su ordenador, celular o tablet
              al navegador web para almacenar información sobre su navegación.
              Nuestro sitio web usa Cookies para recolectar información sobre su
              interacción con el sitio web a fin de mejorar su experiencia de
              navegación y poder realizar analítica sobre las visitas a la web.
            </p>

            <p class="policy-sub-title" id="types">
              ¿Qué tipo de cookies utilizamos?
            </p>
            <p class="policy-text">
              Usamos cookies propias y de terceros. A continuación, se detalla
              el tipo de cookie utilizadas, así como la función que cumple:
            </p>
            <p class="policy-text">
              Juris Search recolecta y trata sus datos personales a través de la
              información suministrada en nuestro sitio web. Dichos datos pueden
              incluir su nombre y apellidos, correo electrónico, dirección de
              domicilio, teléfono, negocio u ocupación, fecha de nacimiento,
              dirección IP, identificador único de dispositivo (UDID), sus
              opiniones en encuestas y las estadísticas que genera por el uso de
              nuestro buscador de jurisprudencia.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Policy",
};
</script>

<style lang="scss" scoped>
$color-light-blue: #0673f2;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #DDE3EB;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;
$color-gray-main: #486169;

.main-policy-cookie {
  min-height: 100vh;
  display: flex;
  background: $color-white;
  padding-top: 100px;
  .policy-card-title {
    font-family: Inter-Medium;
    font-weight: 600;
    font-size: 15px;
  }
  .policy-card {
    border-radius: 5px;
    border: 1px solid $color-light-gray;
    .policy-redirect {
      padding: 12px 15px;
      border-bottom: 1px solid $color-light-gray;
      cursor: pointer;

      a {
        font-family: Inter-Medium;
        color: $color-dark;
        text-decoration: none;

        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }
  .content-policy {
    max-width: 800px;
    margin: auto;
    padding-left: 80px;
    .title {
      font-family: Inter-Bold;
      line-height: 39px;
      font-size: 40px;
      color: $color-dark;
      max-width: 680px;
      margin: 0 auto;
      margin-bottom: 24px;
    }
    .policy-sub-title {
      color: $color-light-blue;
      font-size: 15px;
      font-family: Inter-SemiBold;
      margin-top: 32px;
    }
    .policy-text {
      color: $color-dark;
      font-size: 15px;
      font-family: Inter-Regular;
    }

    @media (max-width: 768px) {
      margin-top: 30px;
      padding-left: 0;
      .title {
        font-size: 15px;
        margin-bottom: 0;
      }

      p{
        font-size: 12px!important;
      }
    }
  }
}
</style>