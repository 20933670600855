<template>
  <b-modal id="modal-filtro-crear" v-model="show" title="Recuperar contraseña"
    size="lg" ok-title="Recuperar" cancel-title="Cancelar" @ok="register" :ok-disabled="isAction">
    <b-form ref="form" @submit.stop.prevent="register">
      <div class="row">

        <b-form-group class="col-12" :state="getValidationState('CORREO')">
          <label class="label-control">Correo </label><span class="text-danger">&nbsp;*</span>
          <b-form-input v-model="model.CORREO" :state="getValidationState('CORREO')"
            @input="validateOnInput('CORREO')"></b-form-input>
          <b-form-invalid-feedback :state="getValidationState('CORREO')">
            {{ getValidationMessage('CORREO') }}
          </b-form-invalid-feedback>
        </b-form-group>


      </div>
    </b-form>
  </b-modal>
</template>

<script>
import newUserProxy from "../../proxies/NewUserProxy";

export default {
  name: "Recover",
  props: {
    openModal: {
      type: Boolean,
      default: false
    },
    toggleModal: {
      type: Function,
      required: false,
      default: () => { }
    },
    search: {
      type: Function,
      required: false,
      default: () => { }
    },
  },
  data() {
    return {
      isLoading: false,
      model: {
        CORREO: null,
      },
      show: false,
      fieldTouched: {},
      validators: {
        CORREO: {
          REQUIRED: true,
          REGEX: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/i,
        },
      },
      isAction: false,
    };
  },
  methods: {
    async register(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.onForceInput();
      if (!this.checkFormValidity()) return;

      this.$bvToast.hide();
      this.isAction = true;
      await newUserProxy.recovery({
        ...this.model,
        EMAIL: this.model.CORREO,
      })
        .then((response) => {
          const toastMessage = response.MESSAGE;

          this.$bvToast.toast(toastMessage, {
            title: response.STATUS ? "Éxito" : "Error",
            variant: response.STATUS ? "primary" : "danger",
            solid: true,
          });
          
          if (response.STATUS) {
            this.show = false;
          }
        })
        .catch((error) => {
          this.$bvToast.toast(error?.MESSAGE || "Ocurrió un error al recuperar la contraseña", {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.isAction = false;
        });
    },
    dateDisabled(ymd, date) {
      const today = new Date();
      const minDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
      return date > minDate;
    },
    getValidationState(field) {
      if (!this.fieldTouched[field]) return null;
      return this.getValidationMessage(field) ? false : true;

    },
    getValidationMessage(field) {
      if (this.validators[field].REQUIRED && !this.model[field]) {
        return "Este campo es requerido";
      }

      if (this.validators[field].MAXLENGTH && this.model[field] && this.model[field].length > this.validators[field].MAXLENGTH) {
        return `Este campo no debe exceder los ${this.validators[field].MAXLENGTH} caracteres`;
      }

      if (this.validators[field].REGEX && this.model[field] && !this.validators[field].REGEX.test(this.model[field])) {
        return "El campo no cumple con el formato requerido";
      }

      if (this.validators[field].MINLENGTH && this.model[field] && this.model[field].length < this.validators[field].MINLENGTH) {
        return `Este campo debe tener al menos ${this.validators[field].MINLENGTH} caracteres`;
      }

      return null;
    },
    checkFormValidity() {
      let isValid = true;
      for (const field in this.validators) {
        if (this.getValidationMessage(field)) {
          isValid = false;
        }
      }
      return isValid;
    },
    clearErrors() {
      for (const field in this.validators) {
        this.model[field] = null;
      }

      this.fieldTouched = {};
    },
    validateOnInput(field) {
      this.fieldTouched = {
        ...this.fieldTouched,
        [field]: true,
      };
    },
    onForceInput() {
      const fields = Object.keys(this.model);
      fields.forEach((field) => {
        this.fieldTouched = {
          ...this.fieldTouched,
          [field]: true,
        };
      });
    },
  },
  watch: {
    openModal() {
      this.show = this.openModal
    },
    show(val) {
      if (!val) {
        this.toggleModal();
        this.clearErrors();
        this.model = {
          CORREO: null,
        };
        this.isAction = false;
      } else {

      }
    },
  },
};
</script>

<style lang="scss" scoped>
$color-light-blue: #0673f2;
$color-dark: #00171f;
$color-white: #ffffff;
$color-light-gray: #dfdfdf;

.content-register {
  h1 {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: $color-dark;
  }

  .card-register {
    background: white;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 15px;

    .form-group {
      margin-bottom: 30px;

      .label-register {
        margin-bottom: 2px;
        font-family: Inter-SemiBold;
      }

      .form-control {
        font-size: 15px;
        height: 52px;
        color: $color-dark;
        font-family: Inter-Regular;
      }
    }

    button {
      background: $color-light-blue;
      color: $color-white;
      font-size: 15px;
      border-radius: 36px;
      height: 44px;
      padding: 8px 0;
      font-family: Inter-Medium;
    }

    .forgot-pass {
      background: $color-white;
      margin-top: 15px;
      font-size: 14px;
      text-align: center;
      border-radius: 36px;
      height: 44px;
      width: 100%;
      border: 1px solid $color-light-gray;
      padding: 8px 0;
      font-family: Inter-Medium;

      a {
        color: $color-light-blue;
        text-decoration: none;
        font-size: 15px;
      }
    }
  }
}
</style>