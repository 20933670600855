<template>
  <div class="pt-100 fade-in">
    <div class="main-common-entrie">
      <div class="container p-4">
        <h3 class="text-center mb-2">Editar Entrada</h3>

        <div class="row">
          <div class="col-12 col-md-6">
            <b-form-group :state="getValidationState('TITLE')">
              <label for="TITLE" class="label-login">Nombre de la Norma<span class="text-danger">*</span></label>
              <b-form-input v-model="modelo.TITLE" @input="validateOnInput('TITLE')">
              </b-form-input>
              <b-form-invalid-feedback :state="getValidationState('TITLE')">
                {{ getValidationMessage('TITLE') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group >
              <label for="RTITLE" class="label-login">Título Descriptivo </label>
              <b-form-input v-model="modelo.RTITLE">
              </b-form-input>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group>
              <label class="">Estado</label>
              <b-form-checkbox name="check-button" switch size="lg" v-model="modelo.ISBINDING">
                <span class="switch-text"> Vigente</span>
              </b-form-checkbox>
            </b-form-group>
          </div>
        </div>

        <h6 class="col-12 p-0">CONTENIDO</h6>
        <hr>

        <div class="row">
          <div class="col-12 col-md-6">
            <b-form-group :state="getValidationState('TPONRMA')">
              <label for="TPONRMA" class="label-ambito">Tipo de Norma <span class="text-danger">*</span></label>
              <treeselect :multiple="true" :options="selects.norma" placeholder="Seleccione una opción"
                v-model="modelo.TPONRMA" no-children-text="No hay opciones disponibles"
                no-results-text="No hay resultados" clear-on-select :limit="1" :max-height="200"
                :limitText="count => `Solo puede seleccionar ${count} opción`"
                :class="{ 'border-danger': getValidationState('TPONRMA') === false }">
              </treeselect>

              <b-form-invalid-feedback :state="getValidationState('TPONRMA')">
                {{ getValidationMessage('TPONRMA') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group :state="getValidationState('OEMISOR')">
              <label for="OEMISOR" class="label-ambito">Órgano emisor <span class="text-danger">*</span></label>
              <treeselect :multiple="true" :options="selects.oemisor" placeholder="Seleccione una opción"
                v-model="modelo.OEMISOR" no-children-text="No hay opciones disponibles"
                no-results-text="No hay resultados" clear-on-select :max-height="200"
                :class="{ 'border-danger': getValidationState('OEMISOR') === false }">
              </treeselect>

              <b-form-invalid-feedback :state="getValidationState('OEMISOR')">
                {{ getValidationMessage('OEMISOR') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group :state="getValidationState('NMRCN')">
              <label for="NMRCN" class="label-login">Numeración<span class="text-danger">*</span></label>
              <b-form-input type="text" v-model="modelo.NMRCN" @input="validateOnInput('NMRCN')">
              </b-form-input>
              <b-form-invalid-feedback :state="getValidationState('NMRCN')">
                {{ getValidationMessage('NMRCN') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group>
              <label class="label-login">Fecha de resolución <span class="text-danger">*</span></label>
              <!-- <b-form-datepicker :date-disabled-fn="dateDisabled" @input="validateOnInput('FRESOLUTION')" placeholder=""
                locale="es" class="custom" v-model="modelo.FRESOLUTION"></b-form-datepicker> -->
                <date-picker v-model="modelo.FRESOLUTION" valueType="format" 
                :disabledDate="time => time.getTime() > Date.now()"
                ></date-picker>

              <b-form-invalid-feedback :state="getValidationState('FRESOLUTION')">
                {{ getValidationMessage('FRESOLUTION') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group>
              <label for="file"
                class="rs-form-control-label block mb-2 text-sm font-normal text-gray-500 dark:text-gray-500">Documento
                Principal <span class="text-danger">*</span>
              </label>
              <div class="relative">
                <label for="file_input" class="text-sm choose"></label>
                <input value="modelo.NENTRIEFILE"
                  class="w-100 text-sm choose text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  type="file" id="file_input" accept=".pdf" name="file_input"
                  @change="modelo.NENTRIEFILE = $event.target.files[0]" />
                <a v-if="modelo.ENTRIEFILE" download @click="downloadFile(modelo)"
                  class="text-blue-500 text-sm cursor-pointer flex items-center">
                  <small>Descargar archivo
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-cloud-download"
                      width="16" height="16" viewBox="0 0 24 24" stroke-width="2" stroke="#2c3e50" fill="none"
                      stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M19 18a3.5 3.5 0 0 0 0 -7h-1a5 4.5 0 0 0 -11 -2a4.6 4.4 0 0 0 -2.1 8.4" />
                      <path d="M12 13l0 9" />
                      <path d="M9 19l3 3l3 -3" />
                    </svg>
                  </small>
                </a>
              </div>
            </b-form-group>
          </div>


        </div>

        <div class="row justify-content-center"
          style="border-top: 1px solid #dfdfdf; padding-top: 20px; margin-top: 20px;gap: 20px;">
          <b-button variant="outline-primary" @click="$router.go(-1)">
            Cancelar
          </b-button>
          <b-button variant="primary" @click="create" :disabled="isLoading">
            <span>Guardar</span>
          </b-button>
          <b-button variant="danger" class="text-white" @click="localStorageSave">
            <span>Guardar Copia</span>
          </b-button>
          <b-button variant="success" class="text-white" @click="UpdateLocaleStorage" :disabled="isLoading">
            <span>Actualizar</span>
          </b-button>
        </div>
      </div>
    </div>

    <div v-if="isLoading" class="spinner-overlay">
      <b-spinner label="Cargando..." variant="primary"></b-spinner>
    </div>
  </div>

</template>

<script>
import adminEntriesProxy from "../../../../proxies/AdminEntriesProxy.js";
import DynamicAsyncSelect from "@/components/select/DynamicAsyncSelect.vue";
import SelectTags from "@/components/select/SelectTags.vue";
import Editor from "@tinymce/tinymce-vue";
import SelectGlobal from "@/components/select/SelectGlobal.vue";
import filterProxy from "../../../../proxies/FilterProxy.js";
import Treeselect from '@riophae/vue-treeselect'

export default {
  name: "LegislationEntriesEdit",
  components: { DynamicAsyncSelect, SelectTags, Editor, SelectGlobal },
  data() {
    return {
      isLoading: false,
      typeEntrie: "executive",
      entrieFile: null,
      entrieFileResumen: null,
      entrieFilters: [],
      menu: [],
      model: {
        title: null,
        type: 0,
        identification: {
          ambit: null,
          theme: null,
          sub_theme: null,
          record: "",
          resolution_date: null,
          jurisdictional_organ: null,
          magistrates: [],
          dissenting_vote: null,
          concurring_vote: null,
        },
        content: {
          short_summary: "",
          resumen: null,
          keywords: [],
        },
        organization: {
          binding: 0,
          filters: [],
        },
      },
      modelo: {
        ID: null,
        TITLE: null,
        ISBINDING: false,
        TPONRMA: [],
        NMRCN: null,
        FRESOLUTION: null,
        ENTRIEFILE: null,
        NENTRIEFILE: null,
        RTITLE: null,
        OEMISOR: []
      },
      validators: {
        TITLE: {
          REQUIRED: true
        },
        NMRCN: {
          REQUIRED: true,
        },
        TPONRMA: {
          REQUIRED: true,
          REGEX: /[^[]/
        },
        FRESOLUTION: {
          REQUIRED: true
        },
        ENTRIEFILE: {
          REQUIRED: true
        },
        OEMISOR: {
          REQUIRED: true,
          REGEX: /[^[]/
        }
      },
      fieldTouched: {},
      selects: {
        norma: [],
        oemisor: []
      },
    };
  },
  watch: {
    "model.organization.binding"(newValue, oldValue) {
      if (newValue) {
        return this.model.organization.binding = 1;
      } else {
        return this.model.organization.binding = 0;
      }
    }
  },
  methods: {
    localStorageSave() {
      localStorage.setItem("legislationEntrieEdit", JSON.stringify(this.modelo));
    },
    UpdateLocaleStorage() {
      let data = JSON.parse(localStorage.getItem("legislationEntrieEdit"));
      this.modelo = data;
    },
    async downloadFile(modelo) {
      let path = modelo.ENTRIEFILE;
      this.isLoading = true;
      await adminEntriesProxy.downloadFile({
        PATH: path
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          let extension = path.split('.').pop();
          link.href = url;
          link.setAttribute('download', (modelo.TITLE + "- " + "ENTRADA PRINCIPAL").toUpperCase() + '.' + extension);
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async getFilters() {
      await filterProxy.list({ NIVEL: 5 }, "2")
        .then((res) => {
          if (res && res.length > 0) {
            let norma = this.configFilter(res, "TIPO DE NORMA");
            this.selects.norma = norma?.NIVEL_2?.map((item) => {
              return {
                id: item.VALUE,
                label: item.LABEL,
              };
            });

            let oemisor = this.configFilter(res, "ÓRGANO EMISOR");
            this.selects.oemisor = oemisor?.NIVEL_2?.map((item) => {
              return {
                id: item.VALUE,
                label: item.LABEL,
                children: item.NIVEL_3?.map((subItem) => {
                  return {
                    id: subItem.VALUE,
                    label: subItem.LABEL,
                    children: subItem.NIVEL_4?.map((subSubItem) => {
                      return {
                        id: subSubItem.VALUE,
                        label: subSubItem.LABEL,
                        children: subSubItem.NIVEL_5?.map((subSubSubItem) => {
                          return {
                            id: subSubSubItem.VALUE,
                            label: subSubSubItem.LABEL,
                          };
                        }),
                      };
                    }),
                  };
                }),
              };
            });
          }
        })
        .catch((err) => {
          this.$bvToast.toast(`Ocurrió un error al obtener los filtros`, {
            title: "Ocurrió un error al obtener los filtros",
            variant: "danger",
            solid: true,
          });
        })
    },
    configFilter(data, label) {
      return data.filter((item) => item.LABEL.toUpperCase() === label.toUpperCase())
        .map((item) => {
          return {
            ...item,
            NIVEL_2: JSON.parse(item.NIVEL_2)
          };
        })?.[0] || {};
    },
    dateDisabled(ymd, date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today;
    },
    async onChangeKeywords(newKeywords) {
      this.modelo.KEYWORDS = newKeywords;
    },
    async onChangeVDESIDENTE(newVDESIDENTE) {
      this.modelo.VDESIDENTE = newVDESIDENTE;
    },
    async onChangeCVOTE(newCVOTE) {
      this.modelo.CVOTE = newCVOTE;
    },
    async getAllFilters() {
      await this.getFilters();
    },
    getValidationState(field) {
      if (!this.fieldTouched[field]) return null;
      return this.getValidationMessage(field) ? false : true;

    },
    getValidationMessage(field) {
      if (this.validators[field].REQUIRED && !this.modelo[field]) {
        return "Este campo es requerido";
      }

      if (this.validators[field].MAXLENGTH && this.modelo[field] && this.modelo[field].length > this.validators[field].MAXLENGTH) {
        return `Este campo no debe exceder los ${this.validators[field].MAXLENGTH} caracteres`;
      }

      if (this.validators[field].REGEX && this.modelo[field] && !this.validators[field].REGEX.test(this.modelo[field])) {
        return "El campo no cumple con el formato requerido";
      }

      if (this.validators[field].MINLENGTH && this.modelo[field] && this.modelo[field].length < this.validators[field].MINLENGTH) {
        return `Este campo debe tener al menos ${this.validators[field].MINLENGTH} caracteres`;
      }

      return null;
    },
    checkFormValidity() {
      let isValid = true;
      for (const field in this.validators) {
        if (this.getValidationMessage(field)) {
          isValid = false;
        }
      }
      return isValid;
    },
    clearErrors() {
      for (const field in this.validators) {
        this.modelo[field] = null;
      }

      this.fieldTouched = {};
    },
    validateOnInput(field) {
      this.fieldTouched = {
        ...this.fieldTouched,
        [field]: true,
      };
    },
    onForceInput() {
      const fields = Object.keys(this.modelo);
      fields.forEach((field) => {
        this.fieldTouched = {
          ...this.fieldTouched,
          [field]: true,
        };
      });
    },
    handleOk(bvModalEvent) {
      this.create();
    },
    async create() {
      this.onForceInput();
      if (!this.checkFormValidity()) return;

      this.isLoading = true;
      const formData = new FormData();
      formData.append("ID", this.modelo.ID);
      formData.append("ENTRIEFILE", this.modelo.ENTRIEFILE);
      formData.append("files", this.modelo.NENTRIEFILE);
      formData.append("TITLE", this.modelo.TITLE);
      formData.append("RTITLE", this.modelo.RTITLE);
      formData.append("ISBINDING", this.modelo.ISBINDING);
      formData.append("TPONRMA", this.modelo.TPONRMA.join(","));
      formData.append("NMRCN", this.modelo.NMRCN);
      formData.append("FRESOLUTION", this.modelo.FRESOLUTION);
      formData.append("TYPE", "legislations")
      formData.append("TIPO", this.typeEntrie);
      formData.append("OEMISOR", this.modelo.OEMISOR.join(","));

      await adminEntriesProxy.editSingle(formData)
        .then(response => {
          const toastMessage = response.MESSAGE;

          this.$bvToast.toast(toastMessage, {
            title: response.STATUS ? "Exito" : "Error",
            variant: response.STATUS ? "primary" : "danger",
            solid: true,
          });

          if (response.STATUS) {
            localStorage.removeItem("legislationEntrieEdit");
            setTimeout(() => {
              this.$router.push({ name: "EntriesJurisdiction" });
            }, 1500);
          }

        })
        .catch(err => {
          this.$bvToast.toast(`Ocurrió un error al editar la entrada`, {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.isLoading = false;
        })
    },
    async obtenerDatos(id) {
      this.isLoading = true;
      await adminEntriesProxy.get({
        ID: id
      })
        .then((response) => {
          let norma = response.TPONRMA?.split(",")?.map((item) => parseInt(item)) || [];
          let oemisor = response.OEMISOR?.split(",")?.map((item) => parseInt(item)) || [];

          this.modelo = {
            ID: response.ID,
            TITLE: response.TITLE,
            RTITLE: response.RTITLE,
            ISBINDING: response.ISBINDING,
            TPONRMA: norma,
            NMRCN: response.NMRCN,
            FRESOLUTION: response.FRESOLUTION,
            ENTRIEFILE: response.ENTRIEFILE,
            NENTRIEFILE: null,
            OEMISOR: oemisor
          }

        })
        .catch((error) => {
          this.$bvToast.toast(`Ocurrió un error al obtener la entrada`, {
            title: "Ocurrió un error al obtener la entrada",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {
    'modelo.TPONRMA'(newValue, oldValue) {
      if (newValue.length > 1) {
        this.modelo.TPONRMA = [newValue[newValue.length - 1]];
      }
    }
  },
  async mounted() {
    if (isNaN(this.$route.params.id) || this.$route.params.id <= 0) {
      this.$router.go(-1);
    }
    this.obtenerDatos(this.$route.params.id);
    this.getAllFilters();
  },
};
</script>

<style lang="scss">
// this can cover all elements in all components
@media (max-width: 575.98px) {
  .v-dropdown-container {
    width: 100% !important;

    .sm-parent {
      display: flex;
      flex-direction: row;
      margin: 0px !important;
      padding: 0px !important;
      width: 100% !important;
    }
  }
}
</style>

<style lang="scss" scoped>
$color-light-blue: #1864FF;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #DDE3EB;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;
$color-weight-gray: #486169;

a {
  color: $color-light-blue;

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
}

.convertir_file {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: #374151;
}

.pt-100 {
  padding-top: 61px;
}

.main-back-navbar {
  padding: 10px 0;
  border-bottom: 1px solid $color-light-gray;

  .back-text {
    color: $color-weight-gray;
    font-family: Inter-Regular;
    cursor: pointer;

    :hover {
      text-decoration: none;
    }
  }

  .btn-blue {
    background: $color-light-blue;
    border-radius: 50px !important;
    color: white;
    min-width: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    font-family: Inter-Medium;
  }
}

.main-common-entrie {
  padding-top: 50px;
  padding-bottom: 50px;
  background: $color-white;
  min-height: 100vh;

  h6 {
    font-family: Inter-bold;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: $color-light-blue;
  }

  .container {
    background-color: $color-white;
    border-radius: 8px;
    box-shadow: 0px 9px 36px rgba(0, 0, 0, 0.14),
      0px 2.71324px 10.8529px rgba(0, 0, 0, 0.0134174),
      0px 0.407592px 1.63037px rgba(0, 0, 0, 0.0106725);



    .row {
      .card {
        padding: 12px;
        margin-bottom: 20px;

        .title-blue {
          color: $color-light-blue;
          font-size: 16px;
          font-weight: 600;
          font-family: Inter-Regular;
          margin-bottom: 30px;
          text-transform: uppercase;
        }

        .switch-title {
          font-family: Inter-Bold;
          font-size: 15px;
        }

        .switch-text {
          font-size: 12px;
          font-weight: 500;
        }

        .form-group {
          margin-bottom: 20px;


          input {
            font-size: 15px;
            height: 38px;
            color: $color-dark;
            font-family: Inter-Regular;
          }

          select {
            font-size: 15px;
            height: 38px;
            color: $color-dark;
            font-family: Inter-Regular;
          }
        }
      }
    }
  }
}

.fade-in {
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>