<template>
    <b-modal id="modal-pregunta-editar" v-model="show" title="Editar Pregunta frecuente" size="lg" ok-title="Guardar"
        cancel-title="Cancelar" @ok="edit"
        :ok-disabled="isAction"
        >
        <b-form ref="form" @submit.stop.prevent="edit">
            <div class="row">
                <b-form-group class="col-12" :state="getValidationState('DESCRIPCION')">
                    <label class="label-control">Descripción</label>
                    <b-form-input v-model="model.DESCRIPCION" :state="getValidationState('DESCRIPCION')"
                        @input="validateOnInput('DESCRIPCION')"></b-form-input>
                    <b-form-invalid-feedback :state="getValidationState('DESCRIPCION')">
                        {{ getValidationMessage('DESCRIPCION') }}
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group class="col-12" :state="getValidationState('ORDEN')">
                    <label class="label-control">Orden <span class="text-danger">*</span></label>
                    <b-form-input v-model="model.ORDEN" :state="getValidationState('ORDEN')"
                        @input="validateOnInput('ORDEN')"></b-form-input>
                    <b-form-invalid-feedback :state="getValidationState('ORDEN')">
                        {{ getValidationMessage('ORDEN') }}
                    </b-form-invalid-feedback>
                </b-form-group>

                <!-- <b-form-group class="col-12">
                    <label for="HTMLTEXT" class="label-login">
                        Respuesta <span class="text-danger">*</span>
                    </label>
                    <editor v-if="editorVisible" :key="editorKey" class="pt-4" v-model="model.HTMLTEXT"
                        api-key="jt0hy8kriofp4c567mldynsfevzb2rcrfbyyeqxt8q6swatl" :init="{
                            height: 300,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar:
                                'undo redo | formatselect | bold italic backcolor | \
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             alignleft aligncenter alignright alignjustify | \
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             bullist numlist outdent indent | removeformat | help'
                        }" />
                </b-form-group> -->

                <div class="col-12">
                    <b-form-group :state="getValidationState('HTMLTEXT')">
                        <label for="HTMLTEXT" class="label-login">
                            Respuesta <span class="text-danger">*</span>
                        </label>
                        <vue-editor v-model="model.HTMLTEXT" class="mb-0 pb-0"></vue-editor>
                        <b-form-invalid-feedback :state="getValidationState('HTMLTEXT')">
                            {{ getValidationMessage('HTMLTEXT') }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </div>
            </div>
        </b-form>
    </b-modal>

</template>

<script>
import MantenimientoProxy from "../../../../proxies/MantenimientoProxy";
import { VueEditor } from "vue2-editor";

export default {
    name: "ModalEditarPregunta",
    components: {
        VueEditor
    },
    props: {
        openModalEditar: {
            type: Boolean,
            default: false
        },
        toggleModalEditar: {
            type: Function,
            required: true
        },
        search: {
            type: Function,
            required: true
        },
        data: {
            type: Object,
            required: true
        },
        objectValidator: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            show: false,
            model: {
                ID: null,
                DESCRIPCION: null,
                HTMLTEXT: null,
                ORDEN: null
            },
            validators: {
                DESCRIPCION: {
                    REQUIRED: true
                },
                HTMLTEXT: {
                    REQUIRED: true
                },
                ORDEN: {
                    REQUIRED: true
                }
            },
            fieldTouched: {},
            editorKey: 0,
            editorVisible: false,
            isAction: false
        }
    },
    methods: {
        getValidationState(field) {
            return this.objectValidator.getValidationState(field, this.fieldTouched, this.validators, this.model);
        },
        getValidationMessage(field) {
            return this.objectValidator.getValidationMessage(field, this.validators, this.model);
        },
        checkFormValidity() {
            return this.objectValidator.checkFormValidity(this.validators, this.model);
        },
        clearErrors() {
            this.objectValidator.clearErrors(this.validators, this.model, this.fieldTouched);
        },
        validateOnInput(field) {
            this.objectValidator.validateOnInput(field, this.fieldTouched);
        },
        onForceInput() {
            const fields = Object.keys(this.model);
            fields.forEach((field) => {
                this.fieldTouched = {
                    ...this.fieldTouched,
                    [field]: true,
                };
            });
        },
        async edit(bvModalEvent) {
            bvModalEvent.preventDefault();
            this.onForceInput();
            if (!this.checkFormValidity()) return;

            this.$bvToast.hide();
            this.isAction = true;
            await MantenimientoProxy.editPregunta(this.model)
                .then((response) => {
                    const toastMessage = response.MESSAGE;
                    this.$bvToast.toast(toastMessage, {
                        title: response.STATUS ? "Éxito" : "Error",
                        variant: response.STATUS ? "primary" : "danger",
                        solid: true,
                    });

                    if (response.STATUS) {
                        this.show = false;
                        this.search();
                    }
                })
                .catch((error) => {
                    this.$bvToast.toast(`Ocurrió un error al editar la pregunta frecuente`, {
                        title: "Error",
                        variant: "danger",
                        solid: true,
                    });
                })
                .finally(() => {
                    this.isAction = false;
                });
        }
    },
    watch: {
        openModalEditar() {
            this.show = this.openModalEditar
        },
        show(val) {
            if (!val) {
                this.toggleModalEditar();
                this.clearErrors();
                this.editorVisible = false;
                this.isAction = false;

            } else {
                this.$nextTick(() => {
                    this.editorVisible = true;
                });
                this.model = {
                    ID: this.data.ID,
                    DESCRIPCION: this.data.DESCRIPCION,
                    HTMLTEXT: this.data.HTMLTEXT,
                }
            }
        }
    },
    mounted() {
        this.editorVisible = true;
    },

}

</script>