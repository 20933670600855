<template>
  <div class="center">Redirigiendo ...</div>
</template>

<script>
import { mapGetters } from "vuex";
import { redirectByRole } from "@/router/redirections";

export default {
  name: "Redirect",
  computed: {
    ...mapGetters(["isAuthenticated", "isProfileLoaded", "getProfile"]),
  },
  mounted() {
    let role = "";
    if (this.isAuthenticated) {
      try {
        role = this.getProfile.role;
        this.$router.push({ name: redirectByRole(role) });
      } catch (error) {
        // localStorage.removeItem("user-token");
      }
    }
  },
};
</script>

<style>
</style>