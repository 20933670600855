var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"results-search"},[_vm._l((_vm.legislations),function(legislation){return _c('div',{key:legislation.id,staticClass:"documents-list-cards"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('router-link',{staticClass:"card-title",attrs:{"to":{
            name: 'DocumentsLegislationDetail',
            params: { id: legislation.id },
          }}},[_vm._v(" "+_vm._s(legislation.name)+" ")]),_c('div',{staticClass:"card-detail"},[_c('div',{staticClass:"to-detail"},[_c('div',{staticClass:"actions"},[_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"content-action",attrs:{"title":"Descargar"},on:{"click":function($event){return _vm.downloadFile(legislation.id)}}},[_c('img',{staticClass:"ml-2",attrs:{"src":require("@/assets/icons/download-paper.svg"),"alt":"Descargar"}})]),_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"content-action",attrs:{"title":"Previsualizar"},on:{"click":function($event){return _vm.openDocumentModal(legislation.id)}}},[_c('img',{staticClass:"ml-2",attrs:{"src":require("@/assets/icons/search-paper.svg"),"alt":"Previsualizar"}})]),_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"content-action",attrs:{"title":"Agregar a mis documentos"},on:{"click":function($event){return _vm.handleUserDocument(legislation)}}},[_c('img',{staticClass:"ml-2",attrs:{"src":legislation.isFavorite
                      ? _vm.favoriteBluePicture
                      : _vm.favoritePicture,"alt":"Agregar a mis documentos"}})])]),_c('router-link',{staticClass:"btn-to-detail",attrs:{"to":{
                name: 'DocumentsLegislationDetail',
                params: { id: legislation.id },
              }}},[_vm._v(" Ver Detalle "),_c('img',{staticClass:"ml-2",attrs:{"src":require("@/assets/icons/arrow-right-dark-blue.svg")}})])],1)])],1),_c('b-modal',{ref:`modal-${legislation.id}`,refInFor:true,attrs:{"centered":"","modal-class":"b-jurisdiction","hide-footer":true,"hide-header":true,"ok-only":"","no-stacking":""}},[_c('document-modal',{attrs:{"documentFile":_vm.documentFile,"title":legislation.title,"documentId":legislation.id,"documentType":0,"userId":_vm.getProfile.id,"isFavorite":legislation.isFavorite,"reload":_vm.reload},on:{"closeDocumentModal":function($event){return _vm.closeDocumentModal(legislation.id)}}})],1)],1)}),(_vm.legislations.length > 0)?_c('div',{staticClass:"d-flex"},[_c('b-pagination',{staticClass:"w-100 my-0",attrs:{"size":"lg","total-rows":_vm.getFilterList.total,"per-page":_vm.getFilterList.perPage,"first-text":"Primero","prev-text":"Anterior","next-text":"Siguiente","last-text":"Ultimo","align":"fill"},model:{value:(_vm.currentPagination),callback:function ($$v) {_vm.currentPagination=$$v},expression:"currentPagination"}})],1):_vm._e(),(_vm.legislations.length <= 0)?_c('div',[_c('h4',{staticClass:"text-center"},[_vm._v("No se encontraron resultados")])]):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }