<template>
    <div class="pt-1">
        <multiselect v-model="value" tag-placeholder="Agrega una nueva opción" selectedLabel="seleccionado"
            placeholder="Busca o agrega una nueva opción" selectLabel="Presione enter o haga click para seleccionar"
            deselectLabel="Presione enter o haga click para deselecionar" label="" track-by="" :options="options"
            :multiple="true" :taggable="true" @tag="addTag">
            <span slot="noOptions">
                Presione enter para agregar una nueva opción.
            </span>
        </multiselect>
    </div>
</template>

<script>
import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
    props: {
        selectedValue: {
            type: Array,
            required: true,
            default: [],
        },
    },
    data() {
        return {
            value: [],
            options: []
        }
    },
    watch: {
        value(newValue) {
            this.$emit("onChangeOption", newValue);
            this.options = newValue;
        },
        selectedValue(newValue) {
            if (newValue !== this.options && newValue[0] !== "") {
                this.options = newValue;
                this.value = newValue;
            }
        },
    },
    methods: {
        addTag(newTag) {
            const newTags = newTag.split(',');
            newTags.map((tag) => {
                if (!this.value.includes(tag)) {
                    this.value.push(tag);
                }
            })
        }
    }
};
</script>

<style lang="scss">
.multiselect__tag {
    min-height: 40px;
    padding-top: 10px !important;
    border: 1px solid #343a40;
    background: #343a40 !important;
    opacity: 0.7;

    &:hover {
        background: #343a40 !important;
        opacity: 0.8;
    }

    .multiselect__tag-icon {
        padding-top: 5px !important;
        background: #343a40 !important;
        opacity: 0.7;

        &::after {
            color: #fff;
        }

        &:hover {
            background: #343a40 !important;
            opacity: 0.8;
        }
    }
}
</style>

