<template>
  <div class="main-terms">
    <div class="container">
      <div class="content-terms mt-5">
        <h1 class="title">Términos y Condiciones</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Terms",
};
</script>

<style lang="scss" scoped>
$color-light-blue: #0673f2;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #DDE3EB;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;
$color-gray-main: #486169;

.main-terms {
  min-height: 100vh;
  display: flex;
  background: $color-white;
  padding-top: 50px;
  .content-terms {
    max-width: 800px;
    margin: auto;
    .title {
      text-align: center;
      font-family: Inter-Bold;
      line-height: 39px;
      font-size: 40px;
      color: $color-dark;
      max-width: 680px;
      margin: 0 auto;
      margin-bottom: 24px;
    }
    .terms-sub-title {
      color: $color-light-blue;
      font-size: 15px;
      font-family: Inter-SemiBold;
      margin-top: 32px;
    }
    .terms-text {
      color: $color-dark;
      font-size: 15px;
      font-family: Inter-Regular;
    }
  }
}
</style>