<template>
  <div>
    <div v-if="data.length > 0">
      <div class="d-flex justify-content-center align-items-center md-mt-3 mt-5">
        <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage"
          @change="handleSearch" size="sm"></b-pagination>
      </div>
      <p class="text-left mt-3 color-blue font-weight-bold">
        Se está mostrando {{ table.perPage }} registros, de {{ (table.currentPage - 1) * table.perPage + 1 }} - {{
          table.currentPage * table.perPage }} de {{ table.totalRows }} registros en total.
      </p>
      <div class="card mt-3" v-for="(item, index) in resultados" :key="index">
        <div class="card-header bg-blue text-left">{{ item.TITULO }}</div>
        <div class="card-body">
          <div class="row p-1">
            <div class="col-12 col-md-4 p-2">
              <p style="font-size: 13px; margin-bottom: 5px;" class="font-weight-bold text-left">Pretensión/Delito:</p>
              <div>
                <span v-for="(delito, index) in item.DELITO" style="font-size: 11px;" :key="index"
                  class="d-block text-left">{{ delito.DESCP }}
                </span>
              </div>
            </div>
            <div class="col-12 col-md-8 p-2">
              <p style="font-size: 13px; margin-bottom: 5px;" class="font-weight-bold text-left">Tema:
              </p>
              <div>
                <span style="font-size: 11px;" :key="index" class="d-block text-left"
                  v-html="texto_mostrado(item.TEMA)">
                </span>
              </div>
            </div>
            <div class="col-12 col-md-4 p-2">
              <p style="font-size: 13px; margin-bottom: 5px;" class="font-weight-bold text-left">Órgano jurisdiccional:
              </p>
              <div>
                <span v-for="(organo, index) in item.OJURISDICCIONAL" style="font-size: 11px;" :key="index"
                  class="d-block text-left">{{ organo.DESCP }}
                </span>
              </div>
            </div>
            <div class="col-12 col-md-8 p-2">
              <p style="font-size: 13px; margin-bottom: 5px;" class="font-weight-bold text-left">SubTema:
              </p>
              <div>
                <span style="font-size: 11px;" :key="index" class="d-block text-left"
                  v-html="texto_mostrado(item.SUBTEMA)">
                </span>
              </div>
            </div>
            <div class="col-12 col-md-4 p-2">
              <p style="font-size: 13px; margin-bottom: 5px;" class="font-weight-bold text-left">Fecha Resolución:</p>
              <div>
                <span style="font-size: 11px;" :key="index" class="d-block text-left">{{ item.FRESOLUTION }}
                </span>
              </div>
            </div>
            <div class="col-12 col-md-4 p-2">
              <p style="font-size: 13px; margin-bottom: 5px;" class="font-weight-bold text-left">Palabras clave:
              </p>
              <div>
                <span style="font-size: 11px;" :key="index" class="d-block text-left">{{ item.KEYWORDS }}
                </span>
              </div>
            </div>
            <div class="col-12 col-md-8 p-2">
              <p style="font-size: 13px; margin-bottom: 5px;" class="font-weight-bold text-left">Síntesis:
              </p>
              <div>
                <span style="font-size: 11px;" :key="index" class="d-block text-left"
                  v-html="texto_mostrado(item.SHORTSUMMARY)">
                </span>
              </div>
            </div>
            <div class="col-12 p-2 container-buttons">
              <div style="display: flex; justify-content: start;gap:1rem">
                <button class="btn btn-danger" 
                  title="Previsualizar"
                  @click="print(item.ENTRIEFILE)"
                  >
                   <b-icon icon="file-earmark-pdf" />
                </button>

                <button class="btn btn-primary"
                  @click="download(item)"
                  title="Descargar Documento"
                  >
                   <b-icon icon="download" class="mr-1" />
                   <b-icon icon="file-earmark-pdf" />
                </button>

                <button v-if="item.TYPE == 'jurisprudences'"
                  class="btn btn-primary" @click="createPDF(item)"
                  title="Descargar Resumen Ejecutivo">
                
                  <b-icon icon="download" class="mr-1" />
                  <b-icon icon="pencil-square" />

                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="sin_resultados border rounded-lg">
      <img src="@/assets/icons/no-results.png" alt="No se encontraron resultados" 
      width="50" height="50"
      class="w-1/4 mx-auto" />
      <p class="text-center text-gray-500 font-bold text-2xl mt-1">No se encontraron resultados</p>
      <span class="text-center text-gray-500 font-bold text-lg">Intenta con otra búsqueda</span>
    </div>

    <ModalMostrarPDF :openModal="openModal" :toggleModal="toggleModal" :pdfUrl="pdfUrl" />

    <div v-if="isLoadingDownload" class="spinner-overlay">
      <b-spinner label="Cargando..." variant="primary"></b-spinner>
    </div>

  </div>

</template>

<script>
import adminEntriesProxy from "../../../../proxies/AdminEntriesProxy.js";
import UserProxy from "../../../../proxies/UserProxy.js";
import ModalMostrarPDF from "./ModalMostrarPDF.vue";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import recursos from "../../../../components/admin/entries/recursos";

export default {
  name: "SearchGeneral",
  components: {
    ModalMostrarPDF
  },
  data() {
    return {
      table: {
        currentPage: 0,
        perPage: 10,
        totalRows: 0
      },
      data: [],
      isLoadingDownload: false,
      pdfUrl: '',
      openModal: false,
    }
  },
  props: {
    resultados: {
      type: Array,
      required: true
    },
    filtroGlobal: {
      type: Object,
      required: true
    },
    setBusqueda: {
      type: Function,
      required: true
    },
    isBusqueda: {
      type: Boolean,
      required: true
    },
    role: {
      type: Object,
      required: true
    }
  },
  methods: {
    texto_mostrado(texto) {
      if (texto === null) return '';
      let texto_sin_br = texto.replace(/<br>/g, '');
      texto_sin_br = texto_sin_br.replace(/<strong>/g, '');
      texto_sin_br = texto_sin_br.replace(/&[a-z]+;/g, '');
      texto_sin_br = texto_sin_br.replace(/<p><\/p>/g, '');
      return texto_sin_br.replace(/<p>/g, '');
    },
    handleSearch(page) {
      this.setBusqueda({
        ...this.filtroGlobal,
        INIT: ((page - 1) <= 0 ? 0 : (page - 1)) * this.table.perPage,
        ROWS: this.table.perPage
      });
    },
    async download(item) {
      let path = item?.ENTRIEFILE || "";
      this.isLoadingDownload = true;
      await adminEntriesProxy.downloadFile({
        PATH: path
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          const extension = path.split('.').pop();
          link.href = url;
          link.setAttribute('download', (item?.TITULO || "") + "-" + " ENTRADA PRINCIPAL" + '.' + extension);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$bvToast.toast(`Ocurrió un error al descargar el archivo`, {
            title: "Ocurrió un error al descargar el archivo",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => this.isLoadingDownload = false);
    },
    async createPDF(entrie, tipo) {
      this.isLoadingDownload = true;
      let data = await this.obtenerDatos(entrie.ID);
      try {
        let margin = [40, 10, 40, 10];
        let totalPages = 0;
        let fontSize = 11;
        let documentoPDF = {

          header: (currentPage, pageCount) => {
            return {
              style: 'headerStyle',
              columns: [
                { width: '*', text: '', alignment: 'center', margin: [40, 40, 40, 40] },
                {
                  width: 'auto',
                  stack: [
                    {
                      image: recursos.nuevoLogoJuris,
                      width: 60,
                      link: 'http://web-juris-search-caro.s3-website-us-east-1.amazonaws.com/',
                      alignment: 'center',
                      margin: [0, 20, 0, 0]
                    },
                  ],
                },
                { width: '*', text: '', alignment: 'center', margin: [40, 40, 40, 40] },
              ],
            }
          },


          background: [
            {
              image: recursos.toIMG,
              width: 620,
              height: 600,
              absolutePosition: { x: 5, y: 150 },
              alignment: 'center',
              opacity: 0.5,
            }
          ],

          content: [
            {
              text: `${data.TITLE}`,
              style: 'header',
              alignment: 'left',
              margin: [40, -20, 40, 10],
              bold: true,
              FontFace: 'Calibri',
            },
            {
              columns: [
                {
                  ul: [
                    `Tipo de Recurso:`,
                    `Delitos:`,
                    `Vinculante:`,
                  ],
                  margin: [margin[0], 0, 0, 0],
                  fontSize,
                  lineHeight: 1.5,
                  width: '35%',
                  FontFace: 'Calibri',
                },
                {
                  ul: [
                    `${data.RECURSO}`,
                    `${data.DELITO}`,
                    `${data.ISBINDING}`,
                  ],
                  margin: [0, 5, margin[0] + 20, 0],
                  fontSize,
                  lineHeight: 1.5,
                  width: '65%',
                  FontFace: 'Calibri',
                },
              ]
            },

            {
              style: 'tableExample',
              table: {
                dontBreakRows: false,
                widths: ['35%', '65%'],
                body: [
                  [
                    {
                      text: 'CONTENIDO',
                      bold: true,
                      colSpan: 2,
                      fontSize,
                      alignment: 'center',
                      margin: [20, 15, 20, 15]
                    },
                    {},
                  ],
                  [
                    {
                      text: 'TEMA',
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    this.renderContent(data.TEMA, fontSize, [10, 15, 10, 15]),
                  ],
                  [
                    {
                      text: 'SUBTEMA',
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    this.renderContent(data.SUBTEMA, fontSize, [10, 15, 10, 15]),

                  ],
                  [
                    {
                      text: 'PALABRAS CLAVES',
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    {
                      text: data.KEYWORDS,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                  ],
                  [
                    {
                      text: 'SÍNTESIS DE LOS FUNDAMENTOS JURÍDICOS RELEVANTES',
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    this.renderContent(data.SHORTSUMMARY, fontSize, [10, 15, 10, 15]),
                  ],
                  [
                    {
                      text: 'FUNDAMENTOS JURÍDICOS RELEVANTES',
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                      fillColor: '#fff2cc',
                    },

                    {
                      ...this.renderContent(data.RESUMEN, fontSize, [10, 15, 10, 15]),
                      fillColor: '#fff2cc',
                      italics: true,

                    }
                  ],
                  [
                    {
                      text: 'IDENTIFICACIÓN',
                      bold: true,
                      fontSize,
                      colSpan: 2,
                      alignment: 'center',
                      margin: [10, 15, 10, 15],
                    },
                    {},
                  ],
                  [
                    {
                      text: 'ÁMBITO',
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    {
                      text: data.AMBIT,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                  ],
                  [
                    {
                      text: 'FECHA DE RESOLUCIÓN',
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    {
                      text: data.FRESOLUTION,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                  ],
                  [
                    {
                      text: 'ÓRGANO JURISDICCIONAL',
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    {
                      text: data.OJURISDICCIONAL,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                  ],
                  [
                    {
                      text: 'MAGISTRADOS',
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    {
                      text: data.MAGISTRATES,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                  ],
                  [
                    {
                      text: ['VOTO DEL DESIDENTE\n', {
                        text: 'Voto que discrepa del fallo final adoptado.', fontSize: fontSize - 2, bold: false, italics: true
                      }],
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    {
                      text: data?.VDESIDENTE || '-',
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                  ],
                  [
                    {
                      text: ['VOTO CONCURRENTE\n', {
                        text: 'Voto que disiente de la argumentación jurídica, pero no del fallo final adoptado.',
                        fontSize: fontSize - 1, bold: false,
                        italics: true
                      }],
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    {
                      text: data?.CVOTE || '-',
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                  ],
                ],
              },
            }

          ],
          styles: {
            FontFace: 'Calibri',
            headerStyle: {
              fontSize: 18,
              bold: true,
              margin: [0, 0, 0, 5],
            },
            tableExample: {
              margin: [margin[0], 10, margin[2], 10],
              FontFace: 'Calibri',
            },
            footer: {
              FontFace: 'Calibri',
              fontSize: 10,
              margin: [40, 50, 40, 10],
            },
          },
          footer: function (currentPage, pageCount) {
            if (currentPage > totalPages) {
              totalPages = currentPage;
            }
            return {
              style: 'footer',
              columns: [
                { width: '*', text: ``, alignment: 'left', color: 'transparent' },
                { width: 'auto', text: 'www.', alignment: 'center', color: 'gray' },
                { width: 'auto', text: 'ccfirma', alignment: 'center', color: '#e81eb2', link: 'https://ccfirma.com/' },
                { width: 'auto', text: '.com', alignment: 'center', color: 'gray' },
                { width: '*', text: `Página ${currentPage} de ${pageCount}`, alignment: 'right' }
              ],
            };
          },

          pageMargins: [40, 100, 40, 80],
        }

        pdfMake.vfs = await pdfFonts.pdfMake.vfs;

        await pdfMake.createPdf(documentoPDF).download((`${data.TITLE} - RESUMEN EJECUTIVO`).toUpperCase() + '.pdf');

      } catch (error) {
        // console.log(error)
      } finally {
        this.isLoadingDownload = false;
      }

    },
    async print(path) {
      this.isLoadingDownload = true;
      await adminEntriesProxy.downloadFile({
        PATH: path
      })
        .then(async (response) => {
          const url = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
          this.pdfUrl = url;
          this.openModal = true;

        })
        .catch((error) => {
          this.$bvToast.toast(`Ocurrió un error al descargar el archivo`, {
            title: "Ocurrió un error al descargar el archivo",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => this.isLoadingDownload = false);
    },
    async favoriteAdd(IDENTRIE) {
      this.isLoadingDownload = true;
      await UserProxy.addFavorite(
        IDENTRIE
      )
        .then((response) => {
          const toastMessage = response.MESSAGE;

          this.$bvToast.toast(toastMessage, {
            title: response.STATUS ? "Exito" : "Error",
            variant: response.STATUS ? "primary" : "danger",
            solid: true,
          });
        })
        .catch((error) => {
          this.$bvToast.toast(`Ocurrió un error al agregar a favoritos`, {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => this.isLoadingDownload = false);
    },
    async obtenerDatos(id) {
      let data = {}
      await adminEntriesProxy.getPrint({
        ID: id
      })
        .then((response) => {
          let ambit = JSON.parse(response.AMBIT)?.[0]?.LABEL || '';
          ambit = ambit ? ambit.replace(/\s*,/g, ',') : '';
          let magistrados = JSON.parse(response.MAGISTRADOS)?.[0]?.LABEL || '';
          magistrados = magistrados ? magistrados.replace(/\s*,/g, ',') : '';
          let delitos = JSON.parse(response.DELITO)?.[0]?.LABEL || '';
          delitos = delitos ? delitos.replace(/\s*,/g, ',') : '';
          let ojurisdiccional = JSON.parse(response.OJURISDICCIONAL)?.[0]?.LABEL || '';
          ojurisdiccional = ojurisdiccional ? ojurisdiccional.replace(/\s*,/g, ',') : '';
          let recursos = JSON.parse(response.RECURSO)?.[0]?.LABEL || '';
          recursos = recursos ? recursos.replace(/\s*,/g, ',') : '';
          let materias = JSON.parse(response.MATERIA)?.[0]?.LABEL || '';
          materias = materias ? materias.replace(/\s*,/g, ',') : '';

          data = {
            ID: response.ID,
            TITLE: response.TITLE,
            ISBINDING: response.ISBINDING === "1" ? "SI" : "NO",
            AMBIT: ambit,
            FRESOLUTION: response.FRESOLUTION ? new Date(response.FRESOLUTION).toLocaleDateString("es-PE", {
              year: "numeric",
              month: "long",
              day: "numeric",
            }) : '',
            OJURISDICCIONAL: ojurisdiccional,
            MAGISTRATES: magistrados,
            VDESIDENTE: response.VDESIDENTE,
            CVOTE: response.CVOTE,
            ENTRIEFILE: response.ENTRIEFILE,
            ENTRIEFILERESUMEN: response.ENTRIEFILERESUMEN,
            KEYWORDS: response.KEYWORDS?.replace(/\s*,/g, ', '),
            TEMA: response.TEMA,
            SUBTEMA: response.SUBTEMA,
            SHORTSUMMARY: response.SHORTSUMMARY,
            RESUMEN: response.RESUMEN,
            NENTRIEFILERESUMEN: null,
            NENTRIEFILE: null,
            DELITO: delitos,
            RECURSO: recursos,
            MATERIA: materias,
          }

        })
        .catch((error) => {
          data = {}
          this.$bvToast.toast(`Ocurrió un error al obtener la entrada`, {
            title: "Ocurrió un error al obtener la entrada",
            variant: "danger",
            solid: true,
          });
        });

      return data;
    },
    renderContent(content, fontSize, margin) {
      let decodedContent = this.decodeHtmlEntities(content);

      if (Array.isArray(decodedContent)) {
        return {
          ul: decodedContent,
          fontSize,
          alignment: 'justify',
          margin
        };
      }

      return {
        text: decodedContent,
        fontSize,
        alignment: 'justify',
        margin
      };
    },
    decodeHtmlEntities(text) {
      if (text === null) return '';
      text = text.replace(/&[a-z]+;/g, '');
      try {

        if (text.includes('<ul>')) {
          let t = text.split('<li>').map((item) => {
            item = item.replace(/<\/?[^>]+(>|$)/g, '');
            return item;
          }).filter((item) => item.trim() !== '');

          return t;
        }

        return text.replace(/<[^>]*>?/gm, '');
      } catch (error) {
        return text.replace(/<[^>]*>?/gm, '');
      }

    },
    toggleModal() {
      this.openModal = !this.openModal;
    },
  },
  watch: {
    resultados() {
      this.data = this.resultados;
      this.table.totalRows = this.resultados?.[0]?.TOTALROWS;
    },
    isBusqueda() {
      if (!this.isBusqueda) {
        this.table = {
          currentPage: 1,
          perPage: 10,
          totalRows: 0
        }
      }
    }
  },
  async mounted() {
    window.onbeforeprint = () => false;
    window.onafterprint = () => window.close();
  }

}
</script>

<style>
/* // ajustar botones cuando este en celular */
.container-buttons {
  display: flex;
  justify-content: start;
  gap: 1rem;

  button {
    padding: 0.5rem 1rem;
    font-size: 0.8rem;

    @media (max-width: 768px) {
      padding: 0.5rem 0.5rem;
      font-size: 0.5rem;
    }
  }
}


.sin_resultados {
  width: 80%;
  margin: 50px auto 0 auto;
  padding: 20px;
  border-radius: 0.375rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  color: #718096;
  font-size: 1.5rem;
  border:none;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  background-color: #f5f8ffff;
}


.sin_resultados p {
  margin: 0px;
}

.sin_resultados span {
  font-size: 1rem!important;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
}

@media (max-width: 768px) {
  .sin_resultados {
    width: 90%!important;
    margin: 20px auto 0 auto!important;
  }

  .sin_resultados p {
    font-size: 1.2rem!important;
  }

  .sin_resultados span {
    font-size: 1rem!important;
  }
}
</style>