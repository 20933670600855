<template>
  <div>
    <router-view></router-view>
    <user-footer />
  </div>
</template>

<script>

import UserFooter from "../user/UserFooter.vue";
export default {
  components: { UserFooter },
  name: "AuthRoot",
  mounted() {
    // localStorage.clear();
  },
};

</script>

<style>
</style>