<template>
    <b-modal v-model="activeUserModal" centered :hide-footer="true" :hide-header="true">
        <div class="delete-user-card">
            <div v-if="!isUserActive">
                <h1 class="title">¿Activar usuario?</h1>
                <ul>
                    <li>El usuario podrá volver a iniciar sesión</li>
                    <li>
                        El usuario encontrará su sección "Mis documentos" tal como la dejó
                    </li>
                </ul>
                <p>¿Continuar activación?</p>
                <p></p>
                <div class="d-flex">
                    <button class="btn btn-white" @click="closeChangeStatusModal">
                        No, cancelar
                    </button>
                    <button class="btn btn-blue" @click="changeStatus">
                        Si, activar usuario
                    </button>
                </div>
            </div>
            <div v-else>
                <h1 class="title">¿Suspender usuario?</h1>
                <ul>
                    <li>El usuario no podrá iniciar sesión</li>
                    <li>
                        El usuario no perderá su sección "Mis documentos" tal como la dejó
                    </li>
                    <li>Solamente un administrador puede volver a activarlo</li>
                </ul>
                <p>¿Continuar activación?</p>
                <p></p>
                <div class="d-flex">
                    <button class="btn btn-white" @click="closeChangeStatusModal">
                        No, cancelar
                    </button>
                    <button class="btn btn-blue" @click="changeStatus()">
                        Si, suspender usuario
                    </button>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>

export default {
    props: {
        activeUserModal: {
            type: Boolean,
            default: false,
        },
        isUserActive: {
            type: Boolean,
            default: false,
        },
        closeChangeStatusModal: {
            type: Function,
            required: true,
        },
        changeStatus: {
            type: Function,
            required: true,
        },
    },
};

</script>

<style lang="scss" scoped>
$color-light-blue: #0673f2;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #DDE3EB;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;
$color-weight-gray: #486169;
$color-red: #eb5757;
.pt-100 {
  padding-top: 61px;
}
.main-back-navbar {
  padding: 10px 0;
  border-bottom: 1px solid $color-light-gray;
  background: $color-white;
  .back-text {
    color: $color-weight-gray;
    font-family: Inter-Regular;
    cursor: pointer;

    :hover {
      text-decoration: none;
    }
  }
  .btn-blue {
    background: $color-light-blue;
    border-radius: 50px !important;
    font-family: Inter-Regular;
    font-weight: 500;
    font-size: 15px;
    color: white;
    min-width: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
  }
  .btn-red {
    background: $color-white;
    border: 1px solid $color-red;
    border-radius: 50px !important;
    font-family: Inter-Regular;
    font-weight: 500;
    font-size: 15px;
    color: $color-red;
    min-width: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    margin-right: 20px;
  }
}
.main-edit-user {
  padding-top: 30px;
  padding-bottom: 50px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background: $color-white-gray;
  .content-card-create {
    background: $color-white;
    padding: 20px;
    // max-width: 500px;
    border-radius: 5px;
    height: 100%;
    border: 1px solid $color-light-gray;
    h6 {
      color: $color-light-blue;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-family: Inter-Bold;
    }
    .form-control {
      font-size: 15px;
      height: 52px;
      color: $color-dark;
      font-family: Inter-Regular;
      margin: auto;
    }
    .form-group {
      .label-control {
        font-family: Inter-SemiBold;
        .dropdown-menu {
          label {
            margin: auto !important;
          }
        }
      }
    }
    .btn-blue {
      background: $color-light-blue;
      border-radius: 50px !important;
      color: white;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 52px;
    }
  }
  .content-card-state {
    background: $color-white;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid $color-light-gray;
    h6 {
      color: $color-light-blue;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-family: Inter-Bold;
    }
    .switch-title {
      font-family: Inter-Bold;
      font-size: 14px;
    }
    .switch-text {
      font-size: 12px;
      font-weight: 500;
    }
  }
}
.delete-user-card {
  .title {
    font-size: 24px;
    text-align: center;
    font-family: Inter-Regular;
    font-weight: 700;
    color: $color-dark;
    margin-bottom: 30px;
  }
  .sub-title {
    font-size: 15px;
    text-align: center;
    font-family: Inter-Regular;
    font-weight: 400;
    color: $color-dark;
    line-height: 135%;
    margin-bottom: 30px;
  }
  .btn-white {
    background: $color-white;
    border: 1px solid $color-light-gray;
    border-radius: 50px !important;
    font-family: Inter-Regular;
    font-weight: 500;
    font-size: 14px;
    color: $color-dark;
    min-width: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    margin-right: 20px;
  }
  .btn-red {
    background: $color-white;
    border: 1px solid $color-red;
    border-radius: 50px !important;
    font-family: Inter-Regular;
    font-weight: 500;
    font-size: 14px;
    color: $color-red;
    min-width: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    margin-right: 20px;
  }
  .btn-blue {
    background: $color-light-blue;
    border-radius: 50px !important;
    font-family: Inter-Regular;
    font-weight: 500;
    font-size: 14px;
    color: white;
    min-width: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
  }
}
</style>