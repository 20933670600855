<template>
  <div class="content-filter">
    <users-table :role="role" :userType="userType" :buttonText="buttonText" />
  </div>
</template>

<script>
import UsersTable from "@/components/admin/users/components/UsersTable.vue";
export default {
  name: "Administrators",
  props: {
    userType: {
      type: Number,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    role: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    UsersTable,
  },
};
</script>