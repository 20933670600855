<template>
    <div class="main-background fade-in">
        <div class="content-list container">
            <div class="main-tab mb-4">
                <div class="content-tab">
                    <a class="content-tab-icon" @click="selected = 'filtros'"
                        :class="{ active: selected === 'filtros' }">
                        <a>Filtros</a>
                    </a>
                    <a class="content-tab-icon" :class="{ active: selected === 'nivel1' }">
                        <a>Nivel 2</a>
                    </a>
                    <a class="content-tab-icon" :class="{ active: selected === 'nivel2' }">
                        <a>Nivel 3</a>
                    </a>
                    <a class="content-tab-icon" :class="{ active: selected === 'nivel3' }">
                        <a>Nivel 4</a>
                    </a>
                    <a class="content-tab-icon" :class="{ active: selected === 'nivel4' }">
                        <a>Nivel 5</a>
                    </a>
                    <a class="content-tab-icon" :class="{ active: selected === 'nivel5' }">
                        <a>Nivel 6</a>
                    </a>
                    <a class="content-tab-icon" @click="selected = 'magistrados'"
                        :class="{ active: selected === 'magistrados' }">
                        <a>Magistrados</a>
                    </a>
                </div>
            </div>

            <FiltrosParent v-if="selected === 'filtros'" :data="data" :role="role" :setData="setData"
                :regresar="regresar" />
            <Nivel1 v-if="selected === 'nivel1'" :data="data" :role="role" :setData="setData" :regresar="regresar" />
            <Nivel2 v-if="selected === 'nivel2'" :data="data" :role="role" :setData="setData" :regresar="regresar" />
            <Nivel3 v-if="selected === 'nivel3'" :data="data" :role="role" :setData="setData" :regresar="regresar" />
            <Nivel4 v-if="selected === 'nivel4'" :data="data" :role="role" :setData="setData" :regresar="regresar" />
            <Nivel5 v-if="selected === 'nivel5'" :data="data" :role="role" :setData="setData" :regresar="regresar" />
            <Magistrados v-if="selected === 'magistrados'" :role="role" />
        </div>
    </div>
</template>

<script>
import FiltrosParent from './FiltrosParent.vue';
import Nivel1 from './Nivel1.vue';
import Nivel2 from './Nivel2.vue';
import Nivel3 from './Nivel3.vue';
import Nivel4 from './Nivel4.vue';
import Nivel5 from './Nivel5.vue';
import Magistrados from './Magistrados.vue';
import { provide } from 'vue';

export default {
    name: "IndexFiltros",
    props: {
        role: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        FiltrosParent,
        Nivel1,
        Nivel2,
        Nivel3,
        Nivel4,
        Nivel5,
        Magistrados
    },
    data() {
        return {
            selected: "filtros",
            data: {
                NIVEL1: {},
                NIVEL2: {},
                NIVEL3: {},
                NIVEL4: {},
            }
        };
    },
    setup() {
        provide('objectValidator', {
            getValidationState(field, fieldTouched, validators, model) {
                if (!fieldTouched[field]) return null;
                return this.getValidationMessage(field, validators, model) ? false : true;
            },
            getValidationMessage(field, validators, model) {
                if (validators[field].REQUIRED && !model[field]) {
                    return "Este campo es requerido";
                }

                if (validators[field].MAXLENGTH && model[field] && model[field].length > validators[field].MAXLENGTH) {
                    return `Este campo no debe exceder los ${validators[field].MAXLENGTH} caracteres`;
                }

                if (validators[field].REGEX && model[field] && !validators[field].REGEX.test(model[field])) {
                    return "El formato no es válido";
                }

                if (validators[field].MINLENGTH && model[field] && model[field].length < validators[field].MINLENGTH) {
                    return `Este campo debe tener al menos ${validators[field].MINLENGTH} caracteres`;
                }

                return null;
            },
            checkFormValidity(validators, model) {
                let isValid = true;
                for (const field in validators) {
                    if (this.getValidationMessage(field, validators, model)) {
                        isValid = false;
                    }
                }
                return isValid;
            },
            clearErrors(validators, model, fieldTouched) {
                for (const field in validators) {
                    model[field] = null;
                }

                Object.keys(fieldTouched).forEach(key => {
                    fieldTouched[key] = false;
                });
            },
            validateOnInput(field, fieldTouched) {
                fieldTouched[field] = true;
            },
            onForceInput(model, fieldTouched) {
                const fields = Object.keys(model);
                fields.forEach((field) => {
                    fieldTouched[field] = true;
                });
            }
        });
    },
    methods: {
        setData(data, key, selected) {
            this.data = {
                ...this.data,
                [key]: data
            }

            this.selected = selected;
        },
        regresar(data, selected) {
            if (selected === "filtros") this.data.NIVEL1 = {};
            if (["filtros", "nivel1"].includes(selected)) this.data.NIVEL2 = {};
            if (["filtros", "nivel1", "nivel2"].includes(selected)) this.data.NIVEL3 = {};
            if (["filtros", "nivel1", "nivel2", "nivel3"].includes(selected)) this.data.NIVEL4 = {};

            this.selected = selected;
        }
    },
    watch: {
        selected() {
            if (this.selected === "magistrados") {
                this.data = {
                    NIVEL1: {},
                    NIVEL2: {},
                    NIVEL3: {},
                    NIVEL4: {},
                }
            }

        }
    },
};
</script>

<style lang="scss" scoped>
$color-light-blue: #0673f2;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #DDE3EB;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;

.main-background {
    background: $color-white;
    padding-top: 100px;
    padding-bottom: 50px;
    min-height: 100vh;

    .content-list {
        margin-top: 10px;
        background: $color-white;
        border-radius: 5px;
        padding: 20px;
        border-radius: 5px;
        padding: 24px;
        box-shadow: 0px 9px 36px rgba(0, 0, 0, 0.14),
            0px 2.71324px 10.8529px rgba(0, 0, 0, 0.0134174),
            0px 0.407592px 1.63037px rgba(0, 0, 0, 0.0106725);
        border-radius: 8px;

        .main-tab {
            background: $color-white;
            display: flex;
            justify-content: center;
            margin-top: 20px;
            margin-bottom: 20px;
            border-radius: 50px;

            .content-tab {
                width: 100%;
                display: flex;

                .content-tab-icon {
                    padding: 10px 5px;
                    width: 100%;
                    text-align: center;
                    border: 1px solid rgb(223, 223, 223);
                    border-radius: 50px;

                    a {
                        color: black;
                    }
                }

                :hover {
                    background: $color-light-blue;
                    cursor: pointer;
                    border-radius: 50px;
                    text-decoration: none;
                }

                .active {
                    background: white;
                    border: 1px solid $color-light-blue;
                    border-radius: 50px;
                    font-weight: bold;

                    :hover {
                        background: white;
                    }

                    a {
                        color: $color-light-blue;
                    }
                }

                :hover:not(.active) {
                    a {
                        color: white;
                    }
                }
            }

            @media (max-width: 768px) {
                .content-tab {
                    flex-direction: column;
                    gap: 10px;
                }
            }
        }


    }
}


.fade-in {
    animation: fadeIn 0.5s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
</style>
