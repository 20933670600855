<template>
    <b-modal id="modal-filtro-crear" v-model="show" title="Crear Filtro" size="lg" ok-title="Crear"
        cancel-title="Cancelar" @ok="create" :ok-disabled="isAction">
        <b-form ref="form" @submit.stop.prevent="create">
            <div class="row">
                <b-form-group disabled class="col-12 col-md-6 disabled">
                    <label class="label-control">Descripción</label>
                    <b-form-input v-model="model.NIVEL"></b-form-input>
                </b-form-group>

                <b-form-group class="col-12 col-md-6" :state="getValidationState('TIPO')">
                    <label class="label-control">Tipo </label><span class="text-danger">*</span>
                    <b-form-select v-model="model.TIPO" :disabled="!IND" :state="getValidationState('TIPO')"
                        :options="options" @input="validateOnInput('TIPO')"></b-form-select>
                    <b-form-invalid-feedback :state="getValidationState('TIPO')">
                        {{ getValidationMessage('TIPO') }}
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group class="col-12" :state="getValidationState('LABEL')">
                    <label class="label-control">Etiqueta </label><span class="text-danger">*</span>
                    <b-form-input v-model="model.LABEL" :state="getValidationState('LABEL')"
                        @input="validateOnInput('LABEL')"></b-form-input>
                    <b-form-invalid-feedback :state="getValidationState('LABEL')">
                        {{ getValidationMessage('LABEL') }}
                    </b-form-invalid-feedback>
                </b-form-group>

            </div>
        </b-form>
    </b-modal>

</template>

<script>
import FilterProxy from '../../../../proxies/FilterProxy';

export default {
    name: "ModalAgregarFiltro",
    props: {
        openModal: {
            type: Boolean,
            default: false
        },
        toggleModal: {
            type: Function,
            required: true
        },
        search: {
            type: Function,
            required: true
        },
        objectValidator: {
            type: Object,
            required: true
        },
        NIVEL: {
            type: String,
            required: true
        },
        TIPO: {
            type: Number,
            default: 0
        },
        IDPARENT: {
            type: Number,
            default: null
        },
        IND: {
            type: Boolean,
            default: false
        }
    },
    components: {

    },
    data() {
        return {
            options: [
                { value: 0, text: "-- Seleccione" },
                { value: 1, text: "JURISPRUCENCIA" },
                { value: 2, text: "LEGISLACIÓN" },
            ],
            show: false,
            model: {
                NIVEL: null,
                TIPO: 0,
                IDPARENT: null,
                LABEL: null,
                ID: null,
            },
            validators: {
                TIPO: {
                    REQUIRED: true
                },
                LABEL: {
                    REQUIRED: true
                },
            },
            fieldTouched: {},
            isAction: false
        }
    },
    methods: {
        getValidationState(field) {
            return this.objectValidator.getValidationState(field, this.fieldTouched, this.validators, this.model);
        },
        getValidationMessage(field) {
            return this.objectValidator.getValidationMessage(field, this.validators, this.model);
        },
        checkFormValidity() {
            return this.objectValidator.checkFormValidity(this.validators, this.model);
        },
        clearErrors() {
            this.objectValidator.clearErrors(this.validators, this.model, this.fieldTouched);
        },
        validateOnInput(field) {
            this.objectValidator.validateOnInput(field, this.fieldTouched);
        },
        onForceInput() {
            const fields = Object.keys(this.model);
            fields.forEach((field) => {
                this.fieldTouched = {
                    ...this.fieldTouched,
                    [field]: true,
                };
            });
        },
        async create(bvModalEvent) {

            if (this.model.IDPARENT === null && !this.IND) {
                this.$bvToast.toast(`Debe seleccionar un filtro superior`, {
                    title: "Error",
                    variant: "danger",
                    solid: true,
                });
                return;
            }

            bvModalEvent.preventDefault();
            this.onForceInput();
            if (!this.checkFormValidity()) return;
            this.$bvToast.hide();
            this.isAction = true;
            await FilterProxy.create({
                ...this.model,
                IDPARENT: this.model.IDPARENT || null,
                NIVEL: this.model.NIVEL.split(" ")[1]
            })
                .then((response) => {
                    const toastMessage = response.MESSAGE;
                    this.$bvToast.toast(toastMessage, {
                        title: response.STATUS ? "Éxito" : "Error",
                        variant: response.STATUS ? "primary" : "danger",
                        solid: true,
                    });

                    if (response.STATUS) {
                        this.show = false;
                        this.search();
                    }
                })
                .catch((error) => {
                    this.$bvToast.toast(`Ocurrió un error al crear la pregunta frecuente`, {
                        title: "Error",
                        variant: "danger",
                        solid: true,
                    });
                })
                .finally(() => {
                    this.isAction = false;
                });
        }
    },
    watch: {
        openModal() {
            this.show = this.openModal
        },
        show(val) {
            if (!val) {
                this.toggleModal();
                this.clearErrors();
                this.isAction = false;
            } else {
                this.model.TIPO = this.TIPO;
                this.model.NIVEL = this.NIVEL;
                this.model.IDPARENT = this.IDPARENT;
            }
        },
        fieldTouched() {

        }
    },
    mounted() {

    }
}

</script>