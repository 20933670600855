<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {};
  },
  // created() {
  //   this.$route.dispatch("autoLogin");
  // },
};
</script>
<style>
@charset "UTF-8";

@font-face {
  font-family: Inter-Black;
  src: url("./assets/fonts/Inter-Black.ttf");
}

@font-face {
  font-family: Inter-Bold;
  src: url("./assets/fonts/Inter-Bold.ttf");
}

@font-face {
  font-family: Inter-ExtraBold;
  src: url("./assets/fonts/Inter-ExtraBold.ttf");
}

@font-face {
  font-family: Inter-ExtraLight;
  src: url("./assets/fonts/Inter-ExtraLight.ttf");
}

@font-face {
  font-family: Inter-Light;
  src: url("./assets/fonts/Inter-Light.ttf");
}

@font-face {
  font-family: Inter-Medium;
  src: url("./assets/fonts/Inter-Medium.ttf");
}

@font-face {
  font-family: Inter-Regular;
  src: url("./assets/fonts/Inter-Regular.ttf");
}

@font-face {
  font-family: Inter-SemiBold;
  src: url("./assets/fonts/Inter-SemiBold.ttf");
}

@font-face {
  font-family: Inter-Thin;
  src: url("./assets/fonts/Inter-Thin.ttf");
}

html {
  scroll-behavior: smooth;
}

.table-container {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 10px;
}

.modal-footer button{
  color: white!important;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
}

.responsive-table th,
.responsive-table td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}

.responsive-table thead {
  background-color: #f2f2f2;
}

.responsive-table th {
  white-space: nowrap;
}




/* // * TABLE */
.choose::-webkit-file-upload-button {
  color: white;
  display: inline-block;
  background: #007bff;
  border: none;
  padding: 8px 15px;
  font-weight: 700;
  border-radius: 3px;
  white-space: nowrap;
  cursor: pointer;
  font-size: 10pt;
}

.choose::file-selector-button {
  color: white;
  display: inline-block;
  background: #007bff;
  border: none;
  padding: 8px 15px;
  font-weight: 700;
  border-radius: 3px;
  white-space: nowrap;
  cursor: pointer;
  font-size: 10pt;
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  /* color de fondo con transparencia */
  z-index: 1000;
  /* asegúrate de que el spinner esté por encima de todo */
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  /* color del spinner */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fade-in {
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.container-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f2f2f2;
  border-bottom: 1px solid #e0e0e0;

  margin: auto 80px;

  @media (max-width: 768px) {
    margin: auto 10px;
  }
}

.contenedor-buttons{
  display: flex;
  gap: 10px;
}

.contenedor-buttons .btn-blue-primary {
  background: #0673f2!important;
  color: white!important;
}

.contenedor-buttons .btn-blue-secondary {
  background: rgb(58, 146, 79)!important;
  color: white!important;
}

.vdatetime-input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

td button svg {
  color: #fff;
}

.btn-primary, .btn-dark{
  color: white!important;
}

</style>
