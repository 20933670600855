<template>
    <b-modal id="modal-noticia-crear" v-model="show" title="Editar Noticia" size="lg" ok-title="Guardar"
        cancel-title="Cancelar" @ok="edit"
        :ok-disabled="isAction"
        >
        <b-form ref="form" @submit.stop.prevent="edit">
            <div class="row">
                <b-form-group class="col-12" :state="getValidationState('TITULO')">
                    <label class="label-control">Titulo</label>
                    <b-form-input v-model="model.TITULO" :state="getValidationState('TITULO')"
                        @input="validateOnInput('TITULO')"></b-form-input>
                    <b-form-invalid-feedback :state="getValidationState('TITULO')">
                        {{ getValidationMessage('TITULO') }}
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group class="col-12">
                    <label for="file"
                        class="rs-form-control-label block mb-2 text-sm font-normal text-gray-500 dark:text-gray-500">
                        Imagen <span class="text-danger">*</span>
                    </label>
                    <div class="relative">
                        <label for="file_input" class="text-sm choose"></label>
                        <input value="model.IMAGENE" accept="image/png, image/jpeg, image/jpg, image/svg+xml, image/webp"
                            class="w-100 text-sm choose text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                            type="file" id="file_input" name="file_input"
                            @change="model.IMAGENE = $event.target.files[0]" />
                        <a style="cursor: pointer;" @click="descargarAtob"
                            class="text-blue-500 text-sm cursor-pointer flex items-center">
                            <small>Ver Imagen
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-cloud-download" width="16" height="16"
                                    viewBox="0 0 24 24" stroke-width="2" stroke="#2c3e50" fill="none"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M19 18a3.5 3.5 0 0 0 0 -7h-1a5 4.5 0 0 0 -11 -2a4.6 4.4 0 0 0 -2.1 8.4" />
                                    <path d="M12 13l0 9" />
                                    <path d="M9 19l3 3l3 -3" />
                                </svg>
                            </small>
                        </a>
                    </div>
                </b-form-group>

                <b-form-group class="col-12" :state="getValidationState('DESCRIPCION')">
                    <label class="label-control">Descripción</label>
                    <b-form-textarea v-model="model.DESCRIPCION" :state="getValidationState('DESCRIPCION')"
                        @input="validateOnInput('DESCRIPCION')"></b-form-textarea>
                    <b-form-invalid-feedback :state="getValidationState('DESCRIPCION')">
                        {{ getValidationMessage('DESCRIPCION') }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </div>
        </b-form>
    </b-modal>

</template>

<script>
import MantenimientoProxy from "../../../../proxies/MantenimientoProxy";

export default {
    name: "ModalEditarNoticia",
    props: {
        openModalEditar: {
            type: Boolean,
            default: false
        },
        toggleModalEditar: {
            type: Function,
            required: true
        },
        search: {
            type: Function,
            required: true
        },
        data: {
            type: Object,
            required: true
        },
        objectValidator: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            show: false,
            model: {
                ID: null,
                TITULO: null,
                DESCRIPCION: null,
                IMAGEN: null,
                IMAGENE: null,
            },
            validators: {
                TITULO: {
                    REQUIRED: true
                },
                DESCRIPCION: {
                    REQUIRED: true
                },
            },
            fieldTouched: {},
            isAction: false
        }
    },
    methods: {
        getValidationState(field) {
            return this.objectValidator.getValidationState(field, this.fieldTouched, this.validators, this.model);
        },
        getValidationMessage(field) {
            return this.objectValidator.getValidationMessage(field, this.validators, this.model);
        },
        checkFormValidity() {
            return this.objectValidator.checkFormValidity(this.validators, this.model);
        },
        clearErrors() {
            this.objectValidator.clearErrors(this.validators, this.model, this.fieldTouched);
        },
        validateOnInput(field) {
            this.objectValidator.validateOnInput(field, this.fieldTouched);
        },
        onForceInput() {
            const fields = Object.keys(this.model);
            fields.forEach((field) => {
                this.fieldTouched = {
                    ...this.fieldTouched,
                    [field]: true,
                };
            });
        },
        async descargarAtob() {
            await MantenimientoProxy.getImage({
                ...this.data,
                KEY: this.data.IMAGEN
            })
                .then((response) => {
                    const extension = this.data.IMAGEN.split('.').pop() || 'png';
                    const url = window.URL.createObjectURL(new Blob([response]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `${this.data.TITULO}.${extension}`);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    this.$bvToast.toast(`Ocurrió un error al obtener la información`, {
                        title: "Error",
                        variant: "danger",
                        solid: true,
                    });
                })
        },
        async edit(bvModalEvent) {
            bvModalEvent.preventDefault();
            this.onForceInput();
            if (!this.checkFormValidity()) return;

            this.$bvToast.hide();
            let formData = new FormData();
            formData.append("ID", this.model.ID);
            formData.append("TITULO", this.model.TITULO);
            formData.append("DESCRIPCION", this.model.DESCRIPCION);
            formData.append("IMAGEN", this.model.IMAGEN);
            formData.append("files", this.model.IMAGENE);

            this.isAction = true;
            await MantenimientoProxy.edit(formData)
                .then((response) => {
                    const toastMessage = response.MESSAGE;
                    this.$bvToast.toast(toastMessage, {
                        title: response.STATUS ? "Éxito" : "Error",
                        variant: response.STATUS ? "primary" : "danger",
                        solid: true,
                    });

                    if (response.STATUS) {
                        this.show = false;
                        this.search();
                    }
                })
                .catch((error) => {
                    this.$bvToast.toast(`Ocurrió un error al crear el magistrado`, {
                        title: "Error",
                        variant: "danger",
                        solid: true,
                    });
                })
                .finally(() => {
                    this.isAction = false;
                });
        }
    },
    watch: {
        openModalEditar() {
            this.show = this.openModalEditar
        },
        show(val) {
            if (!val) {
                this.toggleModalEditar();
                this.clearErrors();
                this.isAction = false;
            } else {
                this.model = {
                    ID: this.data.ID,
                    TITULO: this.data.TITULO,
                    DESCRIPCION: this.data.DESCRIPCION,
                    IMAGEN: this.data.IMAGEN,
                    IMAGENE: null,
                }
            }
        }
    }

}

</script>