<template>
  <div class="fade-in">
    <div class="content-title">
      <h1 class="title">Encuentra documentos con información precisa</h1>
      <p class="subtitle">
        Puedes buscar por nombres de casos, número de documentos, etc. Además no
        te olvides de usar filtros avanzados para resultados más precisos
      </p>
    </div>

    <div class="row mx-4 my-4">
      <div  :class="{ 'col-3': true, 'fade-in': showFilter, 'fade-out': !showFilter }" v-show="showFilter">
        <filter-user :selects="selects" :setCancelar="setCancelar" :typeFilter="typeFilter" :filter="filter"
          :setBusqueda="setBusqueda" />
      </div>

      <div :class="showFilter ? 'col-9' : 'col-12'" v-if="routerActive === 'SearchJurisdiction'">
        <div class="search-jurisdiction">
          <div class="row">
            <div class="col-12 col-md-8">
              <b-form-input style="background-color: #fbfbfb;" v-model="filter.GLOBAL"
                placeholder="Busca por nombre,código o palabras relacionadas"></b-form-input>
            </div>
            <div class="col-12 col-md-4">
              <b-form-select v-model="filter.TYPE">
                <option value="jurisprudences">Jurisprudencia</option>
                <option value="legislations">Legislación</option>
              </b-form-select>
            </div>
          </div>
          <div class="d-flex justify-content-start  mt-3 space-x-4" style="gap: 15px">
            <button class="rounded-full btn btn-secondary text-white" @click="showFilterUpdate">
              {{
                !showFilter ? "Mostrar filtros" : "Ocultar filtros"
              }}
              <img src="@/assets/icons/filter-blue.svg" class="ml-2" />
            </button>

            <button class="rounded-full btn btn-primary" @click="setBusqueda()">
              Buscar
              <img src="@/assets/icons/search-white.svg" class="ml-2" />
            </button>
          </div>

        </div>

        <SearchGeneral :setBusqueda="setBusqueda" :resultados="data" :role="role"
          :isBusqueda="isBusqueda" :filtroGlobal="filter" />

      </div>

    </div>

    <div v-if="isLoading" class="spinner-overlay">
      <b-spinner label="Cargando..." variant="primary"></b-spinner>
    </div>
  </div>
</template>

<script>
import SearchJurisdiction from './jurisdiction/SearchJuristiction.vue';
import MagistradoProxy from "../../../proxies/Magistrados.Proxy.js";
import filterProxy from "../../../proxies/FilterProxy.js";
import AdminEntriesProxy from "../../../proxies/AdminEntriesProxy.js";
import SearchGeneral from './general/SearchGeneral.vue';
import FilterUser from "../../../components/subscriber/filter/Filter.vue";

export default {
  name: "SearchAdmin",
  props: {
    typeFilter: {
      require: true,
      default: "jurisprudence",
    },
    role: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    SearchJurisdiction,
    SearchGeneral,
    FilterUser
  },
  data() {
    return {
      filtroGlobal: {},
      data: [],
      isLoading: false,
      isBusqueda: false,
      routerActive: 'SearchJurisdiction',
      selects: {
        "MAGISTRATES": [],
        "DELITOS": [],
        "ÁMBITO": [],
        "ÓRGANO JURISDICCIONAL": [],
        "RECURSOS": [],
        "MATERIAS": [],
        "NORMA": [],
        "JURISPRUDENCIA VINCULANTE": [],
        "JURISDICCIONES": [],
        "TIPO DE NORMA": [],
        "ÓRGANO EMISOR": [],
      },
      filter: {
        GLOBAL: null,
        FRESOLUTION1: [],
        DELITO: null,
        NMRCN: null,
        TPONRMA: null,
        OEMISOR: null,
        RECURSO: null,
        OJURISDICCIONAL: null,
        MAGISTRATES: null,
        JVINCULANTE: null,
        FRESOLUTION2: [],
        MATERIA: null,
        JURISDICCION: null,
        TITLE: null,
        TYPE: "jurisprudences",
        CRITERIO: null,
        CURRENTPAGE: 0,
        PERPAGE: 10,
        INDICADOR: 2
      },

      showFilter: true,
    };
  },
  methods: {
    async setBusqueda(filtro = {}) {
      filtro = { ...this.filter, ...filtro };
      this.isLoading = true;
      this.isBusqueda = true;
      await AdminEntriesProxy.search({
        ...filtro,
        FRESOLUTION: filtro.FRESOLUTION1 ? filtro.FRESOLUTION1.join(",") : null,
        DELITO: filtro.DELITO ? filtro.DELITO.join(",") : null,
        RECURSO: filtro.RECURSO ? filtro.RECURSO.join(",") : null,
        OJURISDICCIONAL: filtro.OJURISDICCIONAL ? filtro.OJURISDICCIONAL.join(",") : null,
        MAGISTRATES: filtro.MAGISTRATES ? filtro.MAGISTRATES.join(",") : null,
        JVINCULANTE: filtro.JVINCULANTE ? filtro.JVINCULANTE.join(",") : null,
        MATERIA: filtro.MATERIA ? filtro.MATERIA.join(",") : null,
        JURISDICCION: filtro.JURISDICCION ? filtro.JURISDICCION.join(",") : null,
        ROWS: filtro?.ROWS || 10,
        INIT: filtro?.INIT || 0,
        NMRCN: filtro.NMRCN,
        TYPE: filtro.TYPE,
        TPONRMA: filtro.TPONRMA ? filtro.TPONRMA.join(",") : null,
        OEMISOR: filtro.OEMISOR ? filtro.OEMISOR.join(",") : null,
      })
        .then((response) => {
          this.data = response?.map((item) => {
            return {
              ...item,
              DELITO: JSON.parse(item.DELITO),
              OJURISDICCIONAL: JSON.parse(item.OJURISDICCIONAL),
              FRESOLUTION: item.FRESOLUTION ? item.FRESOLUTION.split("T")[0] : null,
              TEMA: ![null, undefined, ""].includes(item?.TEMA) ? item.TEMA : null,
              SUBTEMA: ![null, undefined, ""].includes(item?.SUBTEMA) ? item.SUBTEMA : null,
              SHORTSUMMARY: ![null, undefined, ""].includes(item?.SHORTSUMMARY) ? this.setPalabras(item.SHORTSUMMARY.replace(/<[^>]*>?/gm, ''), 18) : null,
            };
          });
        })
        .catch((error) => {
          this.$bvToast.toast(`Ocurrió un error al realizar la búsqueda`, {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });

    },
    async showFilterUpdate() {
      this.showFilter = !this.showFilter;
    },
    setPalabras(palabra, cantidad = 15) {
      if (!palabra) return "";
      return palabra.split(" ").slice(0, cantidad).join(" ") + "..." || "";
    },
    setCancelar() {
      this.isLoading = true;
      this.isBusqueda = false;
      this.data = [];
      this.isLoading = false;
    },
    async filtersAll() {
      this.isLoading = true;
      Promise.all([
        MagistradoProxy.list({ ROWS: 1000, INIT: 0, DESC: null })
          .then((response) => response)
          .catch((error) => []),
        filterProxy.list({ NIVEL: 5, }, null)
          .then((response) => response)
          .catch((error) => []),
      ])
        .then(async ([magistrados, filters]) => {
          await this.magistrados(magistrados);
          await this.filtrosNiveles(filters);
        })
        .catch((error) => {
          this.$bvToast.toast(`Ocurrió un error al obtener los filtros`, {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async magistrados(magistrados) {
      this.selects["MAGISTRATES"] = await magistrados.map((item) => {
        return {
          id: item.ID,
          label: item.APELLIDOS + " " + item.NOMBRES,
        };
      });
    },
    async filtrosNiveles(data) {
      await data.forEach((item) => {
        let NIVEL_2 = JSON.parse(item.NIVEL_2)
        this.selects[`${item.LABEL.toUpperCase()}`] = NIVEL_2.map((item) => {
          return {
            id: item.VALUE,
            label: item.LABEL,
            children: item.NIVEL_3.map((item2) => {
              return {
                id: item2.VALUE,
                label: item2.LABEL,
                children: item2.NIVEL_4.map((item3) => {
                  return {
                    id: item3.VALUE,
                    label: item3.LABEL,
                    children: item3.NIVEL_5.map((item4) => {
                      return {
                        id: item4.VALUE,
                        label: item4.LABEL,
                        children: item4.NIVEL_6.map((item5) => {
                          return {
                            id: item5.VALUE,
                            label: item5.LABEL,
                          };
                        }),
                      };
                    }),
                  };
                }),
              };
            }),
          };
        });
      });
    }
  },
  computed: {

  },
  watch: {
    routerActive() {
      this.setCancelar();
    },
    "filter.TYPE": {
      handler: function (val) {
        this.filter = {
          GLOBAL: this.filter.GLOBAL,
          FRESOLUTION1: [],
          DELITO: null,
          NMRCN: null,
          TPONRMA: null,
          OEMISOR: null,
          RECURSO: null,
          OJURISDICCIONAL: null,
          MAGISTRATES: null,
          JVINCULANTE: null,
          FRESOLUTION2: [],
          MATERIA: null,
          JURISDICCION: null,
          TITLE: null,
          TYPE: this.filter.TYPE,
          CRITERIO: null,
          CURRENTPAGE: this.filter.CURRENTPAGE,
          PERPAGE: this.filter.PERPAGE,
          INDICADOR: 2
        };
      },
      deep: false,
    },
  },
  mounted() {
    this.filtersAll();
  },
};
</script>

<style lang="scss" scoped>
$color-light-blue: #1864FF;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #fff !important;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;
$color-gray-main: #486169;

.content-title {
  padding-top: 150px;
  ;

  .title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
    font-family: Inter-SemiBold;
    line-height: 39px;
    font-size: 32px;
    color: $color-dark;
  }

  .subtitle {
    font-size: 15px;
    margin-bottom: 40px;
    font-family: Inter-Regular;
    color: $color-dark;
    font-family: Inter-Regular;
    max-width: 600px;
    margin: auto;
    text-align: center;
  }

  @media (max-width: 768px) {
    .content-title {
      padding: 150px 10px;
    }

    .title {
      font-size: 24px;
      padding: 0 5px;
    }

    .subtitle {
      font-size: 13px;
      padding: 0 5px;
    }
  }
}

.container-resultados {
  margin: 50px 50px;
  height: auto;

  @media (max-width: 768px) {
    margin: 30px 10px;
  }
}

.content-card-general {

  .search-card {
    padding: 24px;
    box-shadow: 0px 9px 36px rgba(0, 0, 0, 0.14),
      0px 2.71324px 10.8529px rgba(0, 0, 0, 0.0134174),
      0px 0.407592px 1.63037px rgba(0, 0, 0, 0.0106725);
    border-radius: 8px;
  }

  .main-tab {
    background: $color-white-gray;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 50px;

    .content-tab {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 0px;

      .content-tab-icon {
        padding: 10px 50px;
        width: 100%;
        text-align: center;
        border: 1px solid rgb(223, 223, 223);
        border-radius: 50px;

        a {
          color: black;
        }
      }

      :hover {
        background: $color-light-blue;
        cursor: pointer;
        border-radius: 50px;
        text-decoration: none;
      }

      .active {
        background: white;
        border: 1px solid $color-light-blue;
        border-radius: 50px;
        font-weight: bold;

        :hover {
          background: white;
        }

        a {
          color: $color-light-blue;
        }
      }

      :hover:not(.active) {
        a {
          color: white;
        }
      }
    }

    @media (max-width: 1568px) {
      .content-tab {
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}

.fade-in {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-out {
  animation: fadeOut 1s;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
</style>