<template>
  <div class="search-jurisdiction">
    <div class="row">
      <div class="col-12 col-md-8">
        <b-form-input style="background-color: #fbfbfb;" v-model="FORM.TITLE"
          placeholder="Busca por nombre,código o palabras relacionadas"></b-form-input>
      </div>
      <div class="col-12 col-md-4">
        <b-form-select v-model="FORM.TYPE">
        <option value="jurisprudences">Jurisprudencia</option>
        <option value="legislations">Legislación</option>
        </b-form-select>
      </div>
    </div>
    <div class="d-flex justify-content-start  mt-3 space-x-4"
      style="gap: 15px"
    >
      <button class="rounded-full btn btn-secondary text-white" @click="showFilterUpdate">
        {{
          !showFilter ? "Mostrar filtros" : "Ocultar filtros"
        }}
        <img src="@/assets/icons/filter-blue.svg" class="ml-2" />
      </button>

      <button class="rounded-full btn btn-primary" @click="setBusqueda({
        GLOBAL: FORM.TITLE,
        INDICADOR: 2,
        TYPE: FORM.TYPE,
        ...filter
      })">
        Buscar
        <img src="@/assets/icons/search-white.svg" class="ml-2" />
      </button>
    </div>

  </div>
</template>

<script>
import FilterUser from "../../../../components/subscriber/filter/Filter.vue";

export default {
  name: "SearchJuristiction",
  components: { FilterUser },
  props: {
    setBusqueda: {
      type: Function,
      require: true,
    },
    selects: {
      type: Object,
      require: true,
    },
    isBusqueda: {
      type: Boolean,
      require: true,
    },
    showFilterUpdate: {
      type: Function,
      require: true,
    },
    showFilter: {
      type: Boolean,
      require: true,
    },
    filter: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      FORM: {
        TITLE: "",
        TYPE: "jurisprudences",
      },
    };
  },
  methods: {
  },
};
</script>


<style lang="scss" scoped>
$color-light-blue: #0673f2;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #dde3eb;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;
$color-gray-main: #486169;

.search-jurisdiction {

  .form-control, .custom-select{
    font-size: 15px;
    height: 50px;
    color: $color-dark;
    font-family: Inter-Regular;
    margin: auto;
  }


  .content-filter {
    display: flex;
    margin-top: 20px;

    .btn-white {
      width: 50%;
      background: $color-white;
      color: $color-dark;
      font-size: 15px;
      border-radius: 36px;
      height: 44px;
      padding: 10px 16px;
      font-family: Inter-Regular;
      display: flex;
      align-items: center;
      border: 1px solid $color-light-gray;
      justify-content: center;
      margin-right: 5px;
    }

    .btn-blue {
      width: 50%;
      background: $color-light-blue;
      color: $color-white !important;
      font-size: 15px;
      border-radius: 36px;
      height: 44px;
      padding: 10px 16px;
      font-family: Inter-Regular;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 5px;
    }

    .btn-dark {
      width: 50%;
      background: $color-dark;
      color: $color-white !important;
      font-size: 15px;
      border-radius: 36px;
      height: 44px;
      padding: 10px 16px;
      font-family: Inter-Regular;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 5px;
    }

    @media (max-width: 768px) {
      .btn-white {
        font-size: 10px;
        height: 40px;
        padding: 10px 16px;
      }

      .btn-blue {
        font-size: 10px;
        height: 40px;
        padding: 10px 16px;
      }

      .btn-dark {
        font-size: 10px;
        height: 40px;
        padding: 10px 16px;
      }
    }
  }

  .show-filter {
    margin-top: 20px;

    .card-show-filter {
      border: 1px solid $color-light-gray;
      border-radius: 12px;
      padding: 24px;
      margin-bottom: 20px;

      .content-filter-input {
        text-align: left;

        .label-login {
          margin-bottom: 2px;
          font-family: Inter-SemiBold;
          text-align: left;
        }

        .form-control {
          font-size: 15px;
          height: 52px;
          color: $color-dark;
          font-family: Inter-Regular;
        }
      }
    }

    .btn-blue {
      width: 100%;
      background: $color-light-blue;
      color: $color-white !important;
      font-size: 15px;
      border-radius: 36px;
      height: 44px;
      padding: 10px 16px;
      font-family: Inter-Regular;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.fade-filter {
  opacity: 0;
  animation: fade 0.5s forwards;
}

.show-filter {
  opacity: 1;
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
</style>