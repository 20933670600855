const getParentDeep = (arr, targetId) => arr.find(({ id }) => id === targetId)
    ?? arr.flatMap(({ children }) => getParentDeep(children, targetId))
        .filter(e => e)
        .at(0);

// formulario
const getValidationState = (fieldTouched, field) => {
    if (!fieldTouched[field]) return null;
    return getValidationMessage(field) ? false : true;
}

const getValidationMessage = (referencia, modelo, field) => {
    if (referencia[field].REQUIRED && !modelo[field]) {
        return "Este campo es requerido";
    }

    if (referencia[field].MAXLENGTH && modelo[field] && modelo[field].length > referencia[field].MAXLENGTH) {
        return `Este campo no debe exceder los ${referencia[field].MAXLENGTH} caracteres`;
    }

    if (referencia[field].REGEX && modelo[field] && !referencia[field].REGEX.test(modelo[field])) {
        return "El formato no es válido";
    }

    if (referencia[field].MINLENGTH && modelo[field] && modelo[field].length < referencia[field].MINLENGTH) {
        return `Este campo debe tener al menos ${referencia[field].MINLENGTH} caracteres`;
    }

    return null;
}

const checkFormValidity = (validators) => {
    let isValid = true;
    for (const field in validators) {
        if (getValidationMessage(field)) {
            isValid = false;
        }
    }
    return isValid;
}

const validateOnInput = (fieldTouched, field) => {
    fieldTouched = {
        ...fieldTouched,
        [field]: true,
    };
}

const onForceInput = (model, fieldTouched) => {
    const fields = Object.keys(model);
    fields.forEach((field) => {
        fieldTouched = {
            ...fieldTouched,
            [field]: true,
        };
    });
}

export default { getParentDeep, getValidationState, getValidationMessage, checkFormValidity, validateOnInput, onForceInput };