<template>
    <div class="app-container">
        <div class="form-container">
            <h2 class="text-center">Bienvenido a JURIS SEARCH</h2>
            <b-form class="mx-auto container-autouser" ref="form" @submit.stop.prevent="register">
                <div class="row">
                    <b-form-group disabled class="col-12" :state="getValidationState('NOMBRES')">
                        <label class="label-control">Nombres </label><span class="text-danger">&nbsp;*</span>
                        <b-form-input v-model="model.NOMBRES" :state="getValidationState('NOMBRES')"
                            @input="validateOnInput('NOMBRES')"></b-form-input>
                        <b-form-invalid-feedback :state="getValidationState('NOMBRES')">
                            {{ getValidationMessage('NOMBRES') }}
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group disabled class="col-12" :state="getValidationState('CORREO')">
                        <label class="label-control">Correo Electrónico </label><span class="text-danger">&nbsp;*</span>
                        <b-form-input v-model="model.CORREO" :state="getValidationState('CORREO')"
                            @input="validateOnInput('CORREO')"></b-form-input>
                        <b-form-invalid-feedback :state="getValidationState('CORREO')">
                            {{ getValidationMessage('CORREO') }}
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group class="col-12" :state="getValidationState('PASSWORD')">
                        <label class="label-control">Contraseña </label><span class="text-danger">&nbsp;*</span>
                        <b-form-input type="password" v-model="model.PASSWORD" :state="getValidationState('PASSWORD')"
                            @input="validateOnInput('PASSWORD')"></b-form-input>
                        <b-form-invalid-feedback :state="getValidationState('PASSWORD')">
                            {{ getValidationMessage('PASSWORD') }}
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group class="col-12" :state="getValidationState('PASSWORD_CONFIRM')">
                        <label class="label-control">Confirmar Contraseña </label><span
                            class="text-danger">&nbsp;*</span>
                        <b-form-input type="password" v-model="model.PASSWORD_CONFIRM"
                            :state="getValidationState('PASSWORD_CONFIRM')"
                            @input="validateOnInput('PASSWORD_CONFIRM')"></b-form-input>
                        <b-form-invalid-feedback :state="getValidationState('PASSWORD_CONFIRM')">
                            {{ getValidationMessage('PASSWORD_CONFIRM') }}
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <div class="col-12">
                        <b-button type="submit" variant="primary"
                            :disabled="!checkFormValidity()">Registrarse</b-button>
                    </div>
                </div>
            </b-form>
        </div>
    </div>
</template>

<style lang="scss" scoped>
$color-light-blue: #0673f2;
$color-dark: #00171f;
$color-white: #ffffff;
$color-light-gray: #dfdfdf;

.app-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}

.form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.container-autouser {
    width: 50%;

    @media (max-width: 768px) {
        width: 90%;
    }
}
</style>


<script>
import newUserProxy from "../../proxies/NewUserProxy";
import decode from "jwt-decode";
import confetti from 'canvas-confetti';

export default {
    name: "autoUser",
    data() {
        return {
            isLoading: false,
            model: {
                NOMBRES: null,
                CORREO: null,
                NOMBRES: null,
                APELLIDOP: null,
                APELLIDOM: null,
                TELEFONO: null,
                FNACIMIENTO: null,
                PROFESION: null,
                CARGO: null,
                DIRECCION: null,
                PASSWORD: null,
                PASSWORD_CONFIRM: null,
            },
            fieldTouched: {},
            validators: {
                NOMBRES: {
                    REQUIRED: true
                },
                CORREO: {
                    REQUIRED: true,
                    REGEX: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
                },
                PASSWORD: {
                    REQUIRED: true,
                    MINLENGTH: 8
                },
                PASSWORD_CONFIRM: {
                    REQUIRED: true,
                    MINLENGTH: 8
                }
            },
            token: null,
        };
    },
    methods: {
        getValidationState(field) {
            if (!this.fieldTouched[field]) return null;
            return this.getValidationMessage(field) ? false : true;

        },
        getValidationMessage(field) {
            if (this.validators[field].REQUIRED && !this.model[field]) {
                return "Este campo es requerido";
            }

            if (this.validators[field].MAXLENGTH && this.model[field] && this.model[field].length > this.validators[field].MAXLENGTH) {
                return `Este campo no debe exceder los ${this.validators[field].MAXLENGTH} caracteres`;
            }

            if (this.validators[field].REGEX && this.model[field] && !this.validators[field].REGEX.test(this.model[field])) {
                return "El campo no cumple con el formato requerido";
            }

            if (this.validators[field].MINLENGTH && this.model[field] && this.model[field].length < this.validators[field].MINLENGTH) {
                return `Este campo debe tener al menos ${this.validators[field].MINLENGTH} caracteres`;
            }

            return null;
        },
        checkFormValidity() {
            let isValid = true;
            for (const field in this.validators) {
                if (this.getValidationMessage(field)) {
                    isValid = false;
                }
            }
            return isValid;
        },
        clearErrors() {
            for (const field in this.validators) {
                this.model[field] = null;
            }

            this.fieldTouched = {};
        },
        validateOnInput(field) {
            this.fieldTouched = {
                ...this.fieldTouched,
                [field]: true,
            };
        },
        onForceInput() {
            const fields = Object.keys(this.model);
            fields.forEach((field) => {
                this.fieldTouched = {
                    ...this.fieldTouched,
                    [field]: true,
                };
            });
        },
        async register() {
            if (!this.checkFormValidity()) {
                this.onForceInput();
                return;
            }

            if (this.model.PASSWORD !== this.model.PASSWORD_CONFIRM) {
                this.$bvToast.hide();
                this.$bvToast.toast("Las contraseñas no coinciden", {
                    title: "Error",
                    variant: "danger",
                    solid: true,
                });
                return;
            }

            const response = {
                ...this.model,
                APATERNO: this.model.APELLIDOP,
                AMATERNO: this.model.APELLIDOM,
                EMAIL: this.model.CORREO,
                TOKEN: this.token,
            };

            this.$bvToast.hide();
            this.isAction = true;
            await newUserProxy.registrar(response)
                .then((response) => {
                    if (response.STATUS) {
                        this.$bvToast.toast(response.MESSAGE, {
                            title: "Éxito",
                            variant: "primary",
                            solid: true,
                        });

                        confetti({
                            particleCount: 100,
                            spread: 70,
                            origin: { y: 0.6 }
                        });

                        setTimeout(() => {
                            confetti({
                                particleCount: 100,
                                spread: 70,
                                origin: { y: 0.6 }
                            });

                            this.$router.push({ name: "Login" });
                        }, 500);
                    
                        return
                    }

                    this.$bvToast.toast(response.MESSAGE, {
                        title: "Error",
                        variant: "danger",
                        solid: true,
                    });
                })
                .catch((error) => {
                    this.$bvToast.toast("Ocurrió un error al intentar registrarse", {
                        title: "Error",
                        variant: "danger",
                        solid: true,
                    });
                })
        },
    },
    async created() {
        const token = this.$route?.params?.token;
        if (token) {
            const response = await newUserProxy.validar(token);
            if (response) {
                let decodeToken = await decode(token);
                this.token = token;
                this.model = { ...decodeToken }
                return;
            }
        }

        this.$router.push({ name: "Login" });
    },
};
</script>
