<template>
    <div class="recovery-user">
      <h2 class="title">Recupero de contraseña</h2>
  
      <div v-if="isError" class="alert alert-danger" role="alert">
        {{ error.MESSAGE }}
      </div>
  
      <b-form @submit="handleOk" @reset="clearErrors" v-if="isValid" class="row">
        <b-form-group class="col-12" :state="getValidationState('EMAIL')">
          <label class="label-control">Fecha de nacimiento</label><span class="text-danger">&nbsp;*</span>
          <b-form-input disabled placeholder="" v-model="model.EMAIL" :state="getValidationState('EMAIL')" @input="validateOnInput('EMAIL')" class="custom"></b-form-input>
          <b-form-invalid-feedback :state="getValidationState('EMAIL')">
            {{ getValidationMessage('EMAIL') }}
          </b-form-invalid-feedback>
        </b-form-group>
  
        <b-form-group class="col-12" :state="getValidationState('PASSWORD')">
          <label class="label-control">Contraseña</label><span class="text-danger">&nbsp;*</span>
          <b-form-input placeholder="" v-model="model.PASSWORD" type="password" :state="getValidationState('PASSWORD')" @input="validateOnInput('PASSWORD')" class="custom"></b-form-input>
          <b-form-invalid-feedback :state="getValidationState('PASSWORD')">
            {{ getValidationMessage('PASSWORD') }}
          </b-form-invalid-feedback>
        </b-form-group>
  
        <b-form-group class="col-12" :state="getValidationState('CONFIRM_PASSWORD')">
          <label class="label-control">Confirmar Contraseña</label><span class="text-danger">&nbsp;*</span>
          <b-form-input placeholder="" v-model="model.CONFIRM_PASSWORD" type="password" :state="getValidationState('CONFIRM_PASSWORD')" @input="validateOnInput('CONFIRM_PASSWORD')" class="custom"></b-form-input>
          <b-form-invalid-feedback :state="getValidationState('CONFIRM_PASSWORD')">
            {{ getValidationMessage('CONFIRM_PASSWORD') }}
          </b-form-invalid-feedback>
        </b-form-group>
        
        <div class="col-12 d-flex justify-content-center">
            <b-button type="submit" variant="primary" class="mr-2" :disabled="isLoading">
              <b-spinner small v-if="isLoading"></b-spinner>
              Guardar
            </b-button>
            <b-button 
            @click="$router.push({ name: 'Login' })"
            variant="danger" :disabled="isLoading">Cancelar</b-button>
        </div>
      </b-form>
  
      <div v-if="isLoad" class="spinner-overlay">
        <b-spinner label="Cargando..." variant="primary"></b-spinner>
      </div>
    </div>
  </template>
  
  <script>
  import NewUserProxy from "../../proxies/NewUserProxy";
  
  export default {
    name: "IsRecover",
    data() {
      return {
        isLoad: false,
        isValid: false,
        isError: false,
        isLoading: false,
        error: {
          STATUS: false,
          MESSAGE: "",
        },
        model: {
          EMAIL: null,
          PASSWORD: null,
          CONFIRM_PASSWORD: null,
          TOKEN: this.$route.params.token,
        },
        validators: {
          EMAIL: {
            REQUIRED: true,
            MAXLENGTH: 100,
          },
          PASSWORD: {
            REQUIRED: true,
            MINLENGTH: 8,
          },
          CONFIRM_PASSWORD: {
            REQUIRED: true,
            MINLENGTH: 8,
          },
        },
        fieldTouched: {},
      };
    },
    methods: {
      async validateToken() {
        this.isLoad = true;
        const token = this.$route.params.token;
        await NewUserProxy.validar_recovery(token)
          .then((response) => {
            if (!response?.STATUS) {
              this.isError = true;
              this.error = {
                STATUS: true,
                MESSAGE: response?.MESSAGE || "Error al validar token",
              };
              return;
            }
  
            this.isValid = true;
            this.model.EMAIL = response.DATA.EMAIL;
          })
          .catch((error) => {
            this.isError = true;
            this.error = {
              STATUS: true,
              MESSAGE: error?.response?.data?.MESSAGE || "Error al validar token",
            };
          })
          .finally(() => {
            this.isLoad = false;
          });
      },
      async handleOk(bvModalEvent) {
        bvModalEvent.preventDefault();
        this.onForceInput();
        if (!this.checkFormValidity()) return;
  
        if (this.model.PASSWORD !== this.model.CONFIRM_PASSWORD) {
          this.$bvToast.toast("Las contraseñas no coinciden", {
            title: "Error",
            variant: "danger",
            solid: true,
          });
          return;
        }
  
        this.isLoading = true;
        this.$bvToast.hide();
        await NewUserProxy.recoveryUser(this.model)
          .then((response) => {
            const toastMessage = response.MESSAGE;
  
            this.$bvToast.toast(toastMessage, {
              title: response.STATUS ? "Exito" : "Error",
              variant: response.STATUS ? "primary" : "danger",
              solid: true,
            });
  
            if (response.STATUS) {
              setTimeout(() => {
                this.$router.push({ name: "Login" });
              }, 2000);
            }
          })
          .catch((error) => {
            this.$bvToast.toast(`Ocurrió un error al registrar la solicitud`, {
              title: "Error",
              variant: "danger",
              solid: true,
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
      getValidationState(field) {
        if (!this.fieldTouched[field]) return null;
        return this.getValidationMessage(field) ? false : true;
      },
      getValidationMessage(field) {
        if (this.validators[field].REQUIRED && !this.model[field]) {
          return "Este campo es requerido";
        }
  
        if (this.validators[field].MAXLENGTH && this.model[field] && this.model[field].length > this.validators[field].MAXLENGTH) {
          return `Este campo no debe exceder los ${this.validators[field].MAXLENGTH} caracteres`;
        }
  
        if (this.validators[field].REGEX && this.model[field] && !this.validators[field].REGEX.test(this.model[field])) {
          return "El formato no es válido";
        }
  
        if (this.validators[field].MINLENGTH && this.model[field] && this.model[field].length < this.validators[field].MINLENGTH) {
          return `Este campo debe tener al menos ${this.validators[field].MINLENGTH} caracteres`;
        }
  
        return null;
      },
      checkFormValidity() {
        let isValid = true;
        for (const field in this.validators) {
          if (this.getValidationMessage(field)) {
            isValid = false;
          }
        }
        return isValid;
      },
      clearErrors() {
        for (const field in this.validators) {
          this.model[field] = null;
        }
  
        this.fieldTouched = {};
      },
      validateOnInput(field) {
        this.fieldTouched = {
          ...this.fieldTouched,
          [field]: true,
        };
      },
      onForceInput() {
        const fields = Object.keys(this.model);
        fields.forEach((field) => {
          this.fieldTouched = {
            ...this.fieldTouched,
            [field]: true,
          };
        });
      },
    },
    mounted() {
      this.validateToken();
    },
  };
  </script>
  
  <style>
  .recovery-user {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .title {
    margin-bottom: 20px;
  }
  
  
  .spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
  }
  </style>
  