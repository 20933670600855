<template>
  <div>
    <div class="main-login">
      <div class="container">
        <div class="card-login">
          <div class="content-img">
            <img src="@/assets/logo/logo.png" alt="juris-logo" />
          </div>
          <h1 class="login-title">Crear contraseña</h1>
          <b-form @submit.prevent="handleSubmit">
            <b-form-group  label-for="password">
              <label class="label-recover">Nueva contraseña</label>
              <b-form-input
                v-model="model.password"
                type="password"
                :state="!validation.hasError('model.password')"
                aria-describedby="input-password"
                required
              ></b-form-input>
              <b-form-invalid-feedback id="input-password">
                {{validation.firstError('model.password')}}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label-for="confirmPassword">
               <label class="label-recover">Repetir contraseña</label>
              <b-form-input
                v-model="model.confirmPassword"
                type="password"
                :state="!validation.hasError('model.confirmPassword')"
                aria-describedby="input-confirmPassword"
                required
              ></b-form-input>
              <b-form-invalid-feedback id="input-confirmPassword">
                {{validation.firstError('model.confirmPassword')}}
              </b-form-invalid-feedback>
            </b-form-group>

            <button class="btn btn-block" type="submit">
              Restablecer
            </button>
            <div class="forgot-pass">
              <router-link :to="{ name: 'Login' }"
                >Regresar al inicio</router-link
              >
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import LoginProxy from "@/proxies/LoginProxy.js";
import SimpleVueValidation from 'simple-vue-validator';
const Validator = SimpleVueValidation.Validator;
const regexPassword = /^[0-9]*$/;

export default {
  name: "RecoverPassword",

  data() {
    return {     
      model: {
        token: this.$route.params.token,
        password: null,
        confirmPassword: null,
      },
    };
  },
  validators:{
    "model.password"(value){
      return Validator.value(value).regex(regexPassword,'Solo números').minLength(6,"Mínimo 6 caracteres").required("Es requerido");
    },
    'model.confirmPassword, model.password': function (confirmPassword, password) {
        if (this.validation.isTouched('model.confirmPassword')) {
          return Validator.value(confirmPassword).required("Es requerido").match(password,"Las contraseñas no son idénticas");
        }
      }
  },
  methods: {
    async handleSubmit(){
      const validate = await this.$validate();
      if(validate){
      }
    },
    async login() {
      try {
        const data = await LoginProxy.login(this.model);
        this.$store.dispatch("saveToken", data.token);
      } catch (e) {
        console.error(e);
      } finally {
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$color-light-blue: #0673f2;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #DDE3EB;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;

.main-login {
   background: $color-white-gray;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    .card-login {
      max-width: 328px;
      width: 100%;
      background: $color-white;
      border: 1px solid $color-light-gray;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 16px;
      color: $color-dark;
      height: 100%;
      padding: 16px;
      .content-img {
         padding: 0 10px;
        margin-bottom: 30px;
        text-align: center;
        .login-img {
          height: 140px;
          width: 100%;
        }
      }
      .login-title {
        font-size: 15px;
        margin-bottom: 8px;
        text-align: center;
        color: $color-dark;
        font-family: Inter-Bold;
      }
       .form-group {
        margin-bottom: 30px;
        .label-recover {
          margin-bottom: 2px;
          font-family: Inter-SemiBold;
          font-size: 15px;
        }
        .form-control {
          font-size: 15px;
          height: 52px;
          color: $color-dark;
          font-family: Inter-Regular;
        }
      }
      button {
        background: $color-light-blue;
        color: $color-white;
        font-size: 15px;
        border-radius: 36px;
        height: 44px;
        padding: 8px 0;
        font-family: Inter-Medium;
      }
      .btn-blue {
        background: $color-white;
        color: $color-dark;
        font-size: 15px;
        border-radius: 36px;
        height: 44px;
        padding: 8px 0;
        font-family: Inter-Medium;
        border: 1px solid $color-light-gray;
        padding: 8px 0;
      }
      .forgot-pass {
      background: $color-white;
        margin-top: 15px;
        font-size: 14px;
        text-align: center;
        border-radius: 36px;
        height: 44px;
        width: 100%;
        border: 1px solid $color-light-gray;
        padding: 8px 0;
        font-family: Inter-Medium;
        a {
          color: $color-dark;
          text-decoration: none;
          font-size: 15px;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .main-login {
    .container {
      .card-login {
        max-width: 100%;
      }
    }
  }
}
</style>
