<template>
  <div class="main-background fade-in">
    <div class="content-list container">
      <div class="main-tab mb-4">
        <div class="content-tab">
          <router-link class="content-tab-icon" :class="{ active: selected === 'SubscribersIndex' }"
            :to="{ name: 'SubscribersIndex' }">
            <router-link :to="{ name: 'SubscribersIndex' }">Subscriptores</router-link>
          </router-link>
          <router-link class="content-tab-icon" :class="{ active: selected === 'Digitizers' }"
            :to="{ name: 'Digitizers' }">
            <router-link :to="{ name: 'Digitizers' }">Digitadores</router-link>
          </router-link>
          <router-link class="content-tab-icon" :class="{ active: selected === 'Administrators' }"
            :to="{ name: 'Administrators' }">
            <router-link :to="{ name: 'Administrators' }">Administradores</router-link>
          </router-link>
        </div>
      </div>
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "Users",
  computed: {
    selected() {
      return this.$route.name;
    },
  },
};
</script>
<style lang="scss" scoped>
$color-light-blue: #0673f2;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #DDE3EB;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;

.main-background {
  background: $color-white;
  padding-top: 100px;
  padding-bottom: 50px;
  min-height: 100vh;

  .content-list {
    margin-top: 10px;
    background: $color-white;
    border-radius: 5px;
    padding: 20px;
    border-radius: 5px;
    padding: 24px;
    box-shadow: 0px 9px 36px rgba(0, 0, 0, 0.14),
      0px 2.71324px 10.8529px rgba(0, 0, 0, 0.0134174),
      0px 0.407592px 1.63037px rgba(0, 0, 0, 0.0106725);
    border-radius: 8px;

    .main-tab {
      background: $color-white;
      display: flex;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 20px;
      border-radius: 50px;

      .content-tab {
        width: 100%;
        display: flex;

        .content-tab-icon {
          padding: 10px 50px;
          width: 100%;
          text-align: center;
          border: 1px solid rgb(223, 223, 223);
          border-radius: 50px;

          a {
            color: black;
          }
        }

        :hover {
          background: $color-light-blue;
          cursor: pointer;
          border-radius: 50px;
          text-decoration: none;
        }

        .active {
          background: white;
          border: 1px solid $color-light-blue;
          border-radius: 50px;
          font-weight: bold;

          :hover {
            background: white;
          }

          a {
            color: $color-light-blue;
          }
        }

        :hover:not(.active) {
          a {
            color: white;
          }
        }
      }

      @media (max-width: 768px) {
        .content-tab {
          flex-direction: column;
          gap: 10px;
        }
      }
    }


  }
}

.fade-in {
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>