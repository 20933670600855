<template>
  <div>
    <span class="label-2">{{ labels }}</span>
    <div class="custom-select-2" :tabindex="tabindex" @blur="open = false">
      <div class="selected" :class="{ open: open }" @click="open = !open">
        {{ selected }}
      </div>
      <div class="items" :class="{ selectHide: !open }">
        <div
          v-for="(option, i) of options"
          :key="i"
          @click="
            selected = option;
            open = false;
            $emit('input', option);
          "
        >
          <div class="inside-select">{{ option }} <span> > </span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    labels: {
      type: String,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selected: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0]
        : null,
      open: false,
    };
  },
//   mounted() {
//     this.$emit("input", this.selected);
//   },
};
</script>

<style lang="scss" scoped>
$color-light-blue: #0673f2;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #DDE3EB;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;
$color-gray-main: #486169;
.custom-select-2 {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 47px;
  line-height: 47px;
  margin-bottom: 20px;
}

.custom-select-2 .selected {
  background-color: $color-white;
  border-radius: 6px;
  border: 1px solid $color-light-gray;
  color: $color-dark;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select-2 .selected.open {
  border: 1px solid $color-light-gray;
  border-radius: 6px 6px 0px 0px;
}

.custom-select-2 .selected:after {
  position: absolute;
  content: "";
  top: 22px;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: $color-dark transparent transparent transparent;
}

.custom-select-2 .items {
  color: $color-dark;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  border-right: 1px solid $color-light-gray;
  border-left: 1px solid $color-light-gray;
  border-bottom: 1px solid $color-light-gray;
  position: absolute;
  background-color: $color-white;
  left: 0;
  right: 0;
  z-index: 1;
}

.custom-select-2 .items div {
  color: $color-dark;
  cursor: pointer;
  user-select: none;
}

.custom-select-2 .items div:hover {
  background-color: $color-white-gray;
}

.selectHide {
  display: none;
}

.inside-select {
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
}

.label-2{
    font-family: Inter-Regular;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 4px;
}
</style>
