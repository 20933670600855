<template>
    <b-modal id="modal-directorio-crear" v-model="show" title="Crear Directorio" size="lg" ok-title="Crear"
        cancel-title="Cancelar" @ok="create" :ok-disabled="isAction">
        <b-form ref="form" @submit.stop.prevent="create">
            <div class="row">
                <b-form-group class="col-12" :state="getValidationState('LABEL', fieldTouched, validators, model)">
                    <label class="label-control">Directorio <span class="text-danger">*</span></label>
                    <b-form-input v-model="model.LABEL"
                        :state="getValidationState('LABEL', fieldTouched, validators, model)"
                        @input="validateOnInput('LABEL', fieldTouched)"
                        @blur="validateOnInput('LABEL', fieldTouched)"></b-form-input>
                    <b-form-invalid-feedback :state="getValidationState('LABEL', fieldTouched, validators, model)">
                        {{ getValidationMessage('LABEL', validators, model) }}
                    </b-form-invalid-feedback>
                </b-form-group>

            </div>
        </b-form>
    </b-modal>

</template>

<script>
import UserProxy from '../../../proxies/UserProxy';

export default {
    name: "ModalAgregarDirectorio",
    props: {
        openModal: {
            type: Boolean,
            default: false
        },
        toggleModal: {
            type: Function,
            required: true
        },
        search: {
            type: Function,
            required: true
        },

    },
    data() {
        return {
            show: false,
            model: {
                LABEL: null,
            },
            validators: {
                LABEL: {
                    REQUIRED: true
                },
            },
            fieldTouched: {},
            isAction: false
        }
    },
    methods: {
        getValidationState(field, fieldTouched, validators, model) {
            if (!fieldTouched[field]) return null;
            return this.getValidationMessage(field, validators, model) ? false : true;
        },
        getValidationMessage(field, validators, model) {
            if (validators[field].REQUIRED && !model[field]) {
                return "Este campo es requerido";
            }

            if (validators[field].MAXLENGTH && model[field] && model[field].length > validators[field].MAXLENGTH) {
                return `Este campo no debe exceder los ${validators[field].MAXLENGTH} caracteres`;
            }

            if (validators[field].REGEX && model[field] && !validators[field].REGEX.test(model[field])) {
                return "El formato no es válido";
            }

            if (validators[field].MINLENGTH && model[field] && model[field].length < validators[field].MINLENGTH) {
                return `Este campo debe tener al menos ${validators[field].MINLENGTH} caracteres`;
            }

            return null;
        },
        checkFormValidity(validators, model) {
            let isValid = true;
            for (const field in validators) {
                if (this.getValidationMessage(field, validators, model)) {
                    isValid = false;
                }
            }
            return isValid;
        },
        clearErrors(validators, model, fieldTouched) {
            for (const field in validators) {
                model[field] = null;
            }

            Object.keys(fieldTouched).forEach(key => {
                fieldTouched[key] = false;
            });
        },
        validateOnInput(field, fieldTouched) {
            fieldTouched[field] = true;
        },
        onForceInput(model, fieldTouched) {
            const fields = Object.keys(model);
            fields.forEach((field) => {
                fieldTouched[field] = true;
            });
        },
        async create(bvModalEvent) {

            bvModalEvent.preventDefault();
            this.onForceInput(this.model, this.fieldTouched);
            if (!this.checkFormValidity(this.validators, this.model)) return;

            
            this.$bvToast.hide();
            this.isAction = true;
            await UserProxy.createDirectory({
                DSCRPCN: this.model.LABEL,
            })
                .then((response) => {
                    const toastMessage = response.MESSAGE;
                    this.$bvToast.toast(toastMessage, {
                        title: response.STATUS ? "Éxito" : "Error",
                        variant: response.STATUS ? "primary" : "danger",
                        solid: true,
                    });

                    if (response.STATUS) {
                        this.show = false;
                        this.search();
                    }
                })
                .catch((error) => {
                    this.$bvToast.toast(`Ocurrió un error al crear la pregunta frecuente`, {
                        title: "Error",
                        variant: "danger",
                        solid: true,
                    });
                })
                .finally(() => {
                    this.isAction = false;
                });
        }
    },
    watch: {
        openModal() {
            this.show = this.openModal
        },
        show(val) {
            if (!val) {
                this.toggleModal();
                this.clearErrors(this.validators, this.model, this.fieldTouched);
                this.isAction = false;
            }
        },
        fieldTouched() {

        }
    },
    mounted() {

    }
}

</script>