<template>
  <div>
    <div class="main-navbar">
      <b-navbar toggleable="lg" type="light" variant="white" class="container-menu">

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <div>
          <router-link :to="{ name: 'About-admin' }">
            <img src="@/assets/logo/logo.svg" width="100" alt="logo" class="mr-3" /></router-link>
        </div>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <div class="content-link">
              <router-link class="nav-link" :to="{ name: 'EntriesJurisdiction' }">
                <img src="@/assets/navbar/entries.svg" />
                <span>Entradas</span>
              </router-link>
            </div>
            <div class="content-link">
              <router-link class="nav-link" :to="{ name: 'IndexFiltros' }">
                <img src="@/assets/navbar/filter.svg" />
                <span>Filtros </span>
              </router-link>
            </div>
            <div class="content-link">
              <router-link class="nav-link" :to="{ name: 'SubscribersIndex' }"><img src="@/assets/navbar/user.svg" />
                <span> Usuarios</span>
              </router-link>
            </div>
            <div class="content-link">
              <router-link class="nav-link" :to="{ name: 'SearchAdmin' }"><img src="@/assets/navbar/search.svg"
                  class="mr-2" />
                <span :to="{ name: 'SearchAdmin' }">
                  Busqueda</span></router-link>
            </div>
            <div class="content-link">
              <router-link class="nav-link" :to="{ name: 'inicio' }"><img src="@/assets/navbar/search.svg"
                  class="mr-2" />
                <span :to="{ name: 'inicio' }">
                  Mantenimiento</span></router-link>
            </div>
          </b-navbar-nav>

          <b-navbar-nav class="ml-auto">
            <!-- <div class="content-link">
              <router-link class="nav-link" :to="{ name: 'Settings' }">
                <img src="@/assets/navbar/engine.svg" /> Ajustes
              </router-link>
            </div> -->
            <div class="content-link" @click="logoutModal = true">
              <div class="nav-link">
                <img src="@/assets/navbar/logout.svg" /> Cerrar Sesión
              </div>
            </div>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <b-modal v-model="logoutModal" centered :hide-footer="true" :hide-header="true">
      <logout-modal @logout="logout" @closeModal="closeModal" />
    </b-modal>

    <router-view></router-view>
    <admin-footer />

  </div>
</template>

<script>
import AdminFooter from "@/views/layouts/admin/AdminFooter.vue";
import { AUTH_LOGOUT } from "@/store/actions/auth";
import LogoutModal from "@/components/LogoutModal.vue";
export default {
  name: "AdminRoot",
  components: { AdminFooter, LogoutModal },
  data() {
    return {
      userRole: null,
      logoutModal: false,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$router.push({ name: "Login" });
      });
    },
    closeModal() {
      this.logoutModal = false;
    },
  },
};
</script>
<style lang="scss" scoped>
$color-light-gray: #dfdfdf;
$color-light-blue: #0673f2;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #dde3eb;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;

.main-navbar {
  border-bottom: 1px solid $color-light-gray;
  position: fixed;
  width: 100%;
  background: $color-white;
  z-index: 99;

  .navbar {
    .navbar-collapse {
      ul {
        .content-link {
          margin-right: 10px;

          :hover {
            background: $color-white-gray;
            text-decoration: none;
            border-radius: 30px;
          }

          .nav-link {
            display: flex;
            align-items: center;
            padding: 10px 15px;
            margin-right: 10px;
            font-family: Inter-Regular;
            font-weight: 500;
            color: $color-dark;
            cursor: pointer;

            img {
              border-radius: 0px !important;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
</style>