import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store/store.js';
import '@/providers/AxiosProvider';
import moment from 'moment';
import 'moment/locale/es';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es'; 

Vue.component('date-picker', DatePicker);

// You need a specific loader for CSS files
import SimpleVueValidation from 'simple-vue-validator';
Vue.use(SimpleVueValidation);


Vue.use(require('vue-moment'), {
  moment
});

import VueTreeList from 'vue-tree-list';

Vue.use(VueTreeList);

Vue.config.productionTip = false

import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(VueLoading);

import vSelectMenu from 'v-selectmenu';
Vue.use(vSelectMenu, { language: "es" });

import Multiselect from 'vue-multiselect';
Vue.component('multiselect', Multiselect);

import CrispChat from '@dansmaculotte/vue-crisp-chat';

Vue.use(CrispChat, {
  // websiteId: 'b697673d-93bc-466d-8237-dbba69a4a9d7',
  websiteId: 'f43d4d3b-6dcb-4407-9e83-5f5c3d57632a',
  disabled: false,
  hideOnLoad: true
});

import Treeselect from '@riophae/vue-treeselect'
Vue.component('Treeselect', Treeselect)
// NUEVO
import VueCarousel from 'vue-carousel'; 
Vue.use(VueCarousel);

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
Vue.use(VueViewer)

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css' // Ensure you are using css-loader

Vue.use(Vuetify)

export const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
