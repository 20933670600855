<template>
  <div>

    <div class="table-container mt-3">
      <b-col v-if="!auditoria" sm="5" md="2" class="px-0">
        <b-form-group label="Filas" label-for="per-page-select" label-cols-sm="2" label-cols-md="2" label-cols-lg="2"
          label-size="sm" class="mb-0">
          <b-form-select id="per-page-select" v-model="grid.perPage" @change="onSearch" :options="grid.pageOptions"
            size="sm">
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-table :class="[!auditoria ? 'mt-3' : 'mt-0', 'user-table responsive-table']" head-variant="light" hover
        :items="data" :fields="fields" :busy="loading" responsive show-empty>

        <template v-slot:cell(CDESTDO)="data">
          <div class="d-flex justify-content-center text-center align-items-center h-100">
            <b-badge style="margin: 5px!important;text-align:center!important"
              :variant="data.item.CDESTDO === 'A' ? 'success' : 'danger'">
              {{ data.item.CDESTDO === 'A' ? 'A' : 'I' }}
            </b-badge>
          </div>
        </template>

        <template v-slot:empty>
          <div class="text-center">
            <p class="mt-3">
              <b-icon icon="info-circle-fill" variant="info" class="mr-2"></b-icon>
              No se encontraron registros
            </p>
          </div>
        </template>

        <template v-slot:cell(ACCIONES)="data">
          <div class="d-flex justify-content-center" style="gap: 10px;">
            <b-button v-if="actionsSubmit.view" size="sm" variant="info" @click="actionsSubmit.view(data.item)">
              <b-icon icon="eye-fill"></b-icon>
            </b-button>
            <b-button v-if="actionsSubmit.edit" size="sm" variant="warning" @click="actionsSubmit.edit(data.item)">
              <b-icon icon="pencil-fill"></b-icon>
            </b-button>
            <b-button v-if="actionsSubmit.delete && role.IDR == 0" size="sm" variant="danger"
              @click="actionsSubmit.delete(data.item)">
              <b-icon icon="trash-fill"></b-icon>
            </b-button>
          </div>
        </template>

        <template v-slot:table-busy>
          <div class="text-center">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </template>
      </b-table>
    </div>
    <b-pagination v-if="!auditoria" v-model="grid.currentPage" :total-rows="grid.totalRows" :per-page="grid.perPage"
      @change="handlePage" size="sm"></b-pagination>
  </div>
</template>

<script>

export default {
  name: "dataTable",
  props: {
    search: {
      type: Function,
      required: true,
    },
    fieldsComponent: {
      type: Array,
      required: true,
    },
    actions: {
      type: Object,
      required: true,
    },
    rowData: {
      type: Array,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    role: {
      type: Object,
      default: () => ({}),
    },
    auditoria: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fields: [],
      actionsSubmit: {},
      data: [],
      grid: {
        items: [],
        currentPage: 1,
        perPage: 10,
        totalRows: 10,
        isLoading: true,
        pageOptions: [5, 10, 15, 50],
      },
      filter: {
        name: null,
      },
    };
  },
  components: {

  },
  computed: {
    rows() {
      return this.grid.items.length;
    },
  },
  created() {
    this.actionsSubmit = this.actions;

    if (!this.auditoria) {
      this.fields = [...this.fieldsComponent.filter((field) => field.key !== "ACCIONES"),
      {
        key: "FCRCN", label: "Fecha de creación",
        formatter: (value) => {
          return new Date(value).toLocaleDateString("es-PE", {
            year: "numeric",
            month: "long",
            day: "numeric",
          });
        },
      },
      {
        key: "CDESTDO", label: "Estado", class: "text-center",
      },
      this.fieldsComponent.find((field) => field.key === "ACCIONES"),
      ];
    }else{
      this.fields = this.fieldsComponent;
    }



    this.data = this.rowData;

  },
  methods: {
    handlePage(page) {
      this.grid.currentPage = page;
      this.onSearch();
    },
    async onSearch() {
      const init = (this.grid.currentPage - 1) * this.grid.perPage;
      const rows = this.grid.perPage;

      await this.search({
        perPage: rows,
        init: init,
      })
    },
  },
  watch: {
    rowData() {
      this.data = this.rowData;
      this.grid.totalRows = this.rowData?.[0]?.TOTALROWS || 0;
    },
  },
  mounted() {
    this.onSearch();
  },

};
</script>

<style lang="scss" scoped>
$color-light-blue: #0673f2;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #DDE3EB;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;

.content-filter {
  .form-control {
    border-radius: 5px;
    height: 38px;
  }

  .btn-blue {
    background: $color-light-blue;
    border-radius: 50px !important;
    color: white;
    max-width: 180px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>