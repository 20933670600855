<template>
  <div class="pt-100 fade-in">
    <div class="main-edit-user mt-5">
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-12">
            <div>
              <div class="content-card-create">
                <h6>Datos de la persona</h6>
                <b-form ref="form" @submit.stop.prevent="editProfile" autocomplete="off">
                  <div class="row">
                    <b-form-group class="col-12" :state="getValidationState('NOMBRES')">
                      <label class="label-control">Nombres completos <span class="text-danger">*</span></label>
                      <b-form-input v-model="model.NOMBRES" placeholder="" :state="getValidationState('NOMBRES')"
                        @input="validateOnInput('NOMBRES')"></b-form-input>
                      <b-form-invalid-feedback :state="getValidationState('NOMBRES')">
                        {{ getValidationMessage('NOMBRES') }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group class="col-md-6 col-12" :state="getValidationState('APATERNO')">
                      <label class="label-control">Apellido paterno <span class="text-danger">*</span></label>
                      <b-form-input v-model="model.APATERNO" placeholder="" :state="getValidationState('APATERNO')"
                        @input="validateOnInput('APATERNO')"></b-form-input>
                      <b-form-invalid-feedback :state="getValidationState('APATERNO')">
                        {{ getValidationMessage('APATERNO') }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group class="col-md-6 col-12" :state="getValidationState('AMATERNO')">
                      <label class="label-control">Apellido materno <span class="text-danger">*</span></label>
                      <b-form-input v-model="model.AMATERNO" placeholder="" :state="getValidationState('AMATERNO')"
                        @input="validateOnInput('AMATERNO')"></b-form-input>
                      <b-form-invalid-feedback :state="getValidationState('AMATERNO')">
                        {{ getValidationMessage('AMATERNO') }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group class="col-12" :state="getValidationState('EMAIL')">
                      <label class="label-control">Correo electrónico <span class="text-danger">*</span></label>
                      <b-form-input v-model="model.EMAIL" placeholder="Escribe tu email"
                        :state="getValidationState('EMAIL')" @input="validateOnInput('EMAIL')"></b-form-input>
                      <b-form-invalid-feedback :state="getValidationState('EMAIL')">
                        {{ getValidationMessage('EMAIL') }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group class="col-md-6 col-12">
                      <label class="label-control">Teléfono <span class="text-danger">*</span></label>
                      <b-form-input v-model="model.TELEFONO" placeholder="Escribe tu teléfono"
                        :state="getValidationState('TELEFONO')" @input="validateOnInput('TELEFONO')"></b-form-input>
                      <b-form-invalid-feedback :state="getValidationState('TELEFONO')">
                        {{ getValidationMessage('TELEFONO') }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group class="col-12 col-md-6" :state="getValidationState('PASSWORD')">
                      <label class="label-control">Contraseña <span class="text-danger">*</span></label>
                      <b-form-input v-model="model.PASSWORD" placeholder="Escribe tu contraseña" type="password"
                        :state="getValidationState('PASSWORD')" @input="validateOnInput('PASSWORD')"></b-form-input>
                      <b-form-invalid-feedback :state="getValidationState('PASSWORD')">
                        {{ getValidationMessage('PASSWORD') }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group class="col-12 col-md-6" :state="getValidationState('FNACIMIENTO')">
                      <label class="label-control">Fecha de nacimiento</label>
                      <b-form-datepicker :date-disabled-fn="dateDisabled" placeholder="" v-model="model.FNACIMIENTO"
                        :state="getValidationState('FNACIMIENTO')" @input="validateOnInput('FNACIMIENTO')" locale="es"
                        class="custom">
                      </b-form-datepicker>
                      <b-form-invalid-feedback :state="getValidationState('FNACIMIENTO')">
                        {{ getValidationMessage('FNACIMIENTO') }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group class="col-12 col-md-6">
                      <label class="label-control">Dirección</label>
                      <b-form-input v-model="model.DIRECCION" placeholder="Escribe tu dirección"></b-form-input>
                    </b-form-group>

                    <b-form-group class="col-12 col-md-6">
                      <label class="label-control">Profesión</label>
                      <b-form-input v-model="model.PROFESION" placeholder="Escribe tu profesión"></b-form-input>
                    </b-form-group>

                    <b-form-group class="col-12 col-md-6">
                      <label class="label-control">Cargo</label>
                      <b-form-input v-model="model.CARGO" placeholder="Escribe tu cargo"></b-form-input>
                    </b-form-group>

                    <div class="container pt-2">
                      <div class="d-flex justify-content-between align-items-center">
                        <a class="d-flex justify-content-between back-text" style="cursor: pointer; color: #486169;"
                          @click="$router.go(-1)">
                          <img src="@/assets/icons/back-arrow.svg" class="mr-2" />
                          Regresar
                        </a>
                        <div class="d-flex">
                          <button class="btn btn-blue" @click="editProfile" :disabled="isLoading">
                            <b-spinner style="margin-right: 10px;" label="Spinning" small v-if="isLoading"></b-spinner>
                            <span>Guardar cambios</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
          </div>

          <div class="row col-12 col-md-4" style="height: fit-content;">
            <div class="col-12 second-details">
              <div class="content-card-state">
                <h6>Datos del sistema</h6>
                <!-- TODO: IMPROVE SWITCH CHANGE -->
                <div class="mb-2">
                  <span class="switch-title">Estado: </span>
                  <span class="switch-text" v-if="model.CDESTDO">
                    <b-badge style="padding: 5px 10px;" :variant="model.CDESTDO == 'A' ? 'success' : 'danger'">
                      {{ model.CDESTDO == 'A' ? 'Activo' : 'Suspendido' }}
                    </b-badge>
                  </span>
                </div>
                <div>
                  <span class="switch-title">Activo desde</span>
                  <div>
                    {{ model.FCRCN | moment("DD/MM/YYYY") }}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 mt-5 second-details">
              <div class="content-card-state">
                <h6>Datos del Plan</h6>
                <!-- TODO: IMPROVE SWITCH CHANGE -->
                <div class="mb-2">
                  <span class="switch-title">Plan: </span>
                  <span class="switch-text" >
                      {{ model.DATOS?.DESCRIPCION}}
                  </span>
                </div>
                <div class="mb-2">
                  <span class="switch-title">Estado: </span>
                  <span class="switch-text" v-if="model.DATOS?.CESTDO">
                    <b-badge style="padding: 5px 10px;" :variant="model.DATOS?.CESTDO == 'A' ? 'success' : 'danger'">
                      {{ model.DATOS?.CESTDO == 'A' ? 'Activo' : 'Cancelado' }}
                    </b-badge>
                  </span>
                </div>
                <div>
                  <span class="switch-title">Fecha de inicio</span>
                  <div>
                    {{ model.DATOS?.FINICIO | moment("DD/MM/YYYY") }}
                  </div>
                </div>
                <div>
                  <span class="switch-title">Fecha de fin</span>
                  <div>
                    {{ model.DATOS?.FFIN | moment("DD/MM/YYYY") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isLoading" class="spinner-overlay">
      <b-spinner label="Cargando..." variant="primary"></b-spinner>
    </div>
  </div>
</template>

<script>
import UserProxy from "../../../proxies/UserProxy";
import { mapGetters } from "vuex";

export default {
  name: "UserProfile",
  data() {
    return {
      isLoading: false,
      model: {
        APATERNO: null,
        AMATERNO: null,
        NOMBRES: null,
        EMAIL: null,
        TELEFONO: null,
        FNACIMIENTO: null,
        CDESTDO: null,
        FCRCN: null,
        PASSWORD: null,
        PROFESION: null,
        CARGO: null,
        DIRECCION: null,
        DATOS: null,
      },
      validators: {
        APATERNO: {
          REQUIRED: true
        },
        AMATERNO: {
          REQUIRED: true
        },
        NOMBRES: {
          REQUIRED: true
        },
        EMAIL: {
          REQUIRED: true,
          REGEX: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
        },
        TELEFONO: {
          REQUIRED: true,
          MAXLENGTH: 9
        },
        PASSWORD: {
          REQUIRED: true,
          MINLENGTH: 8
        },
        FNACIMIENTO: {
          REQUIRED: true
        },
      },
      fieldTouched: {},
    };
  },
  computed: {
    ...mapGetters(["getProfile"]),
  },
  methods: {
    async editProfile() {
      this.onForceInput();
      if (!this.checkFormValidity()) return;

      this.isLoading = true;
      this.$bvToast.hide();
      await UserProxy.editarFoce(this.model)
        .then((response) => {
          const toastMessage = response.MESSAGE;

          this.$bvToast.toast(toastMessage, {
            title: response.STATUS ? "Exito" : "Error",
            variant: response.STATUS ? "primary" : "danger",
            solid: true,
          });

          if (response.STATUS) {
            this.get
          }
        })
        .catch((error) => {
          this.$bvToast.toast(`Ocurrió un error al editar sus datos`, {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async getUser() {
      this.isLoading = true;
      await UserProxy.getUsuario()
        .then((response) => {
          this.model = {
            APATERNO: response.APATERNO,
            AMATERNO: response.AMATERNO,
            NOMBRES: response.NOMBRES,
            EMAIL: response.EMAIL,
            TELEFONO: response.TELEFONO,
            FNACIMIENTO: response.FNACIMIENTO,
            CDESTDO: response.CDESTDO,
            PASSWORD: response.PASSWORD,
            FCRCN: response.FCRCN?.split("T")[0],
            PROFESION: response.PROFESION,
            CARGO: response.CARGO,
            DIRECCION: response.DIRECCION,
            DATOS: JSON.parse(response.DATOS)?.[0] || {},
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    dateDisabled(ymd, date) {
      const today = new Date();
      const minDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
      return date > minDate;
    },
    // * VALIDACIONES
    getValidationState(field) {
      if (!this.fieldTouched[field]) return null;
      return this.getValidationMessage(field) ? false : true;
    },
    getValidationMessage(field) {
      if (this.validators[field].REQUIRED && !this.model[field]) {
        return "Este campo es requerido";
      }

      if (this.validators[field].MAXLENGTH && this.model[field] && this.model[field].length > this.validators[field].MAXLENGTH) {
        return `Este campo no debe exceder los ${this.validators[field].MAXLENGTH} caracteres`;
      }

      if (this.validators[field].REGEX && this.model[field] && !this.validators[field].REGEX.test(this.model[field])) {
        return "El formato no es válido";
      }

      if (this.validators[field].MINLENGTH && this.model[field] && this.model[field].length < this.validators[field].MINLENGTH) {
        return `Este campo debe tener al menos ${this.validators[field].MINLENGTH} caracteres`;
      }

      return null;
    },
    checkFormValidity() {
      let isValid = true;
      for (const field in this.validators) {
        if (this.getValidationMessage(field)) {
          isValid = false;
        }
      }
      return isValid;
    },
    clearErrors() {
      for (const field in this.validators) {
        this.model[field] = null;
      }

      this.fieldTouched = {};
    },
    validateOnInput(field) {
      this.fieldTouched = {
        ...this.fieldTouched,
        [field]: true,
      };
    },
    onForceInput() {
      const fields = Object.keys(this.model);
      fields.forEach((field) => {
        this.fieldTouched = {
          ...this.fieldTouched,
          [field]: true,
        };
      });
    },
  },
  async mounted() {
    await this.getUser();
  },
};
</script>

<style lang="scss" scoped>
$color-light-blue: #1864FF;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #ffffff;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;
$color-weight-gray: #486169;
$color-red: #eb5757;

.pt-100 {
  padding-top: 61px;
}

.main-back-navbar {
  padding: 10px 0;
  border-bottom: 1px solid $color-light-gray;
  background: $color-white;

  .back-text {
    color: $color-weight-gray;
    font-family: Inter-Regular;
    cursor: pointer;

    :hover {
      text-decoration: none;
    }
  }

  .btn-blue {
    background: $color-light-blue;
    border-radius: 50px !important;
    font-family: Inter-Regular;
    font-weight: 500;
    font-size: 15px;
    color: white;
    min-width: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
  }

  .btn-red {
    background: $color-white;
    border: 1px solid $color-red;
    border-radius: 50px !important;
    font-family: Inter-Regular;
    font-weight: 500;
    font-size: 15px;
    color: $color-red;
    min-width: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    margin-right: 20px;
  }
}

.main-edit-user {
  padding-top: 30px;
  padding-bottom: 50px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background: $color-white-gray;

  .content-card-create {
    background: $color-white;
    padding: 20px;
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    height: 100%;
    border: 1px solid $color-light-gray;

    h6 {
      color: $color-light-blue;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-family: Inter-Bold;
    }

    .form-control {
      font-size: 15px;
      height: 52px;
      color: $color-dark;
      font-family: Inter-Regular;
      margin: auto;
    }

    .form-group {
      .label-control {
        font-family: Inter-SemiBold;

        .dropdown-menu {
          label {
            margin: auto !important;
          }
        }
      }
    }

    .btn-blue {
      background: $color-light-blue;
      border-radius: 50px !important;
      color: white;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 52px;
    }
  }

  .content-card-state {
    background: $color-white;
    padding: 20px;
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: 1px solid $color-light-gray;

    h6 {
      color: $color-light-blue;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-family: Inter-Bold;
    }

    .switch-title {
      font-family: Inter-Bold;
      font-size: 14px;
    }

    .switch-text {
      font-size: 12px;
      font-weight: 500;
    }
  }
}

.delete-user-card {
  .title {
    font-size: 24px;
    text-align: center;
    font-family: Inter-Regular;
    font-weight: 700;
    color: $color-dark;
    margin-bottom: 30px;
  }

  .sub-title {
    font-size: 15px;
    text-align: center;
    font-family: Inter-Regular;
    font-weight: 400;
    color: $color-dark;
    line-height: 135%;
    margin-bottom: 30px;
  }

  .btn-white {
    background: $color-white;
    border: 1px solid $color-light-gray;
    border-radius: 50px !important;
    font-family: Inter-Regular;
    font-weight: 500;
    font-size: 14px;
    color: $color-dark;
    min-width: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    margin-right: 20px;
  }

  .btn-red {
    background: $color-white;
    border: 1px solid $color-red;
    border-radius: 50px !important;
    font-family: Inter-Regular;
    font-weight: 500;
    font-size: 14px;
    color: $color-red;
    min-width: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    margin-right: 20px;
  }

  .btn-blue {
    background: $color-light-blue;
    border-radius: 50px !important;
    font-family: Inter-Regular;
    font-weight: 500;
    font-size: 14px;
    color: white;
    min-width: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
  }
}

.fade-in {
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.second-details {
  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
  }
}
</style>