<template>
  <div class="main-questions fade-in">
    <div class="container mt-5">
      <div class="content-questions">
        <h1 class="title">Preguntas Frecuentes</h1>
        <p class="subtitle">
          A continuación podrás leer respuestas a dudas comunes que podrían
          ayudarte a mejorar tu experiencia en Juris Search. Si estas respuestas
          no resuleven tus dudas, por favor dirígete al sitio de Ayuda
        </p>
        <div class="content-cards">
          <div class="card-question" v-for="pregunta in preguntas" :key="pregunta.ID">
            <div 
            v-b-toggle="'collapse-' + pregunta.ID"
            class="content-title-question">
              {{ pregunta.DESCRIPCION }}
              <img src="@/assets/icons/plus-blue.svg" alt="plus-blue" />
            </div>
            <b-collapse :id="'collapse-' + pregunta.ID" 
            class="mt-2">
              <div class="card-question-content">
                <div v-html="pregunta.HTMLTEXT"></div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>

    <div v-if="loading" class="spinner-overlay">
      <b-spinner label="Cargando..." variant="primary"></b-spinner>
    </div>
  </div>
</template>

<script>
import LoginProxy from "../../proxies/LoginProxy";

export default {
  name: "Questions",
  data() {
    return {
      preguntas: [],
      loading: false,
    };
  },
  methods: {
    async preguntasAll() {
      this.loading = true;
      LoginProxy.listpreguntas({
        INIT: 0,
        ROWS: 100000,
        DESC: null,
        CESTDO: 'A'
      })
        .then((response) => {
          this.preguntas = response || [];
        })
        .catch((error) => {
          this.$bvToast.toast("Error", {
            title: "Error al cargar las preguntas",
            variant: "warning",
            solid: true,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.preguntasAll();
   },
};
</script>

<style lang="scss" scoped>
$color-light-blue: #0673f2;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #DDE3EB;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;
$color-gray-main: #486169;

.main-questions {
  min-height: 100vh;
  display: flex;
  background: $color-white;
  padding: 100px 0;

  .content-questions {
    max-width: 680px;
    margin: auto;
    text-align: center;

    .title {
      text-align: center;
      font-weight: bold;
      margin-bottom: 20px;
      font-family: Inter-SemiBold;
      line-height: 39px;
      font-size: 32px;
      color: $color-dark;
    }

    .subtitle {
      font-size: 15px;
      margin-bottom: 40px;
      font-family: Inter-Regular;
      color: $color-dark;
      font-family: Inter-Regular;
    }

    .content-cards {
      background: white;
      border-radius: 8px;
      border: 1px solid $color-light-gray;

      .card-question {
        padding: 24px;
        text-align: left;
        border-bottom: 1px solid $color-light-gray;

        .content-title-question {
          display: flex !important;
          align-items: center;
          justify-content: space-between;
          font-family: Inter-Bold;
          color: $color-dark;

          img {
            margin-left: 25px;
          }
        }

        .card-question-content {
          color: $color-dark;
          margin-top: 20px;
          font-family: Inter-Regular;
        }
      }

      :last-child {
        border-bottom: 0px !important;
      }
    }
  }
}
</style>