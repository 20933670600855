<template>
  <b-modal id="modal-prevent-user" ref="modal" v-model="localShowModal" :title="'Crear ' +
    (userType == '2' ? 'Subscriptor' : userType == '1' ? 'Digitador' : userType == '0' ? 'Administrador' : '')"
    size="lg" ok-title="Crear" cancel-title="Cancelar" no-close-on-backdrop @ok="handleOk" :ok-disabled="isLoading"
    :cancel-disabled="isLoading">
    <div class="content-card-create">
      <b-form ref="form" @submit.stop.prevent="create">
        <div class="row">

          <b-form-group class="col-md-6 col-12" :state="getValidationState('APATERNO')">
            <label class="label-control">Apellido Paterno</label><span class="text-danger"> *</span>
            <b-form-input v-model="model.APATERNO" :state="getValidationState('APATERNO')"
              @input="validateOnInput('APATERNO')"></b-form-input>
            <b-form-invalid-feedback :state="getValidationState('APATERNO')">
              {{ getValidationMessage('APATERNO') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group class="col-md-6 col-12" :state="getValidationState('AMATERNO')">
            <label class="label-control">Apellido Materno</label><span class="text-danger"> *</span>
            <b-form-input v-model="model.AMATERNO" :state="getValidationState('AMATERNO')"
              @input="validateOnInput('AMATERNO')"></b-form-input>
            <b-form-invalid-feedback :state="getValidationState('AMATERNO')">
              {{ getValidationMessage('AMATERNO') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group class="col-12" :state="getValidationState('NOMBRES')">
            <label class="label-control">Nombres</label><span class="text-danger"> *</span>
            <b-form-input v-model="model.NOMBRES" :state="getValidationState('NOMBRES')"
              @input="validateOnInput('NOMBRES')">
            </b-form-input>
            <b-form-invalid-feedback :state="getValidationState('NOMBRES')">
              {{ getValidationMessage('NOMBRES') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group class="col-12" :state="getValidationState('EMAIL')">
            <label class="label-control">Correo electrónico</label><span class="text-danger"> *</span>
            <b-form-input v-model="model.EMAIL" :state="getValidationState('EMAIL')"
              @input="validateOnInput('EMAIL')"></b-form-input>
            <b-form-invalid-feedback :state="getValidationState('EMAIL')">
              {{ getValidationMessage('EMAIL') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group class="col-md-6 col-12" :state="getValidationState('TELEFONO')">
            <label class="label-control">Teléfono</label><span class="text-danger"> *</span>
            <b-form-input v-model="model.TELEFONO" type="number" :state="getValidationState('TELEFONO')"
              @input="validateOnInput('TELEFONO')"></b-form-input>
            <b-form-invalid-feedback :state="getValidationState('TELEFONO')">
              {{ getValidationMessage('TELEFONO') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group class="col-md-6 col-12" :state="getValidationState('FNACIMIENTO')">
            <label class="label-control">Fecha de nacimiento</label><span class="text-danger"> *</span>
            <!-- <b-form-datepicker  placeholder="" v-model="model.FNACIMIENTO"
              :state="getValidationState('FNACIMIENTO')" @input="validateOnInput('FNACIMIENTO')" locale="es"
              class="custom"
              year-picker
              >
            </b-form-datepicker> -->
            <date-picker v-model="model.FNACIMIENTO" valueType="format" 
                :disabledDate="time => time.getTime() > Date.now()"
                ></date-picker>
            <b-form-invalid-feedback :state="getValidationState('FNACIMIENTO')">
              {{ getValidationMessage('FNACIMIENTO') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <!-- // agregar select -->
          <b-form-group class="col-md-6 col-12" v-if="userType == '2'">
            <label class="label-control">Elige un plan</label>
            <b-form-select v-model="model.IDPLAN">
              <b-form-select-option v-for="plan in planes" :key="plan.ID" :value="plan.ID" :disabled="plan.CDESTDO == 'I'">
                {{ plan.DESCRIPCION }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
           
        </div>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
import userProxy from "@/proxies/UserProxy.js";

export default {
  name: "SuscribersCreate",
  props: {
    userType: {
      type: String,
      required: true,
    },
    'get-users': {
      type: Function,
      required: true,
    },
    planes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      localShowModal: this.showModal,
      model: {
        APATERNO: null,
        AMATERNO: null,
        NOMBRES: null,
        EMAIL: null,
        TELEFONO: null,
        FNACIMIENTO: null,
        IDPLAN: null,
      },
      validators: {
        APATERNO: {
          REQUIRED: true
        },
        AMATERNO: {
          REQUIRED: true
        },
        NOMBRES: {
          REQUIRED: true
        },
        EMAIL: {
          REQUIRED: true,
          REGEX: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
        },
        TELEFONO: {
          REQUIRED: true,
          MAXLENGTH: 9,
          MINLENGTH: 9
        },
        FNACIMIENTO: {
          REQUIRED: true
        },
      },
      fieldTouched: {},
    };
  },
  methods: {
    dateDisabled(ymd, date) {
      const today = new Date();
      const minDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
      return date > minDate;
    },
    getValidationState(field) {
      if (!this.fieldTouched[field]) return null;
      return this.getValidationMessage(field) ? false : true;
    },
    getValidationMessage(field) {
      if (this.validators[field].REQUIRED && !this.model[field]) {
        return "Este campo es requerido";
      }

      if (this.validators[field].MAXLENGTH && this.model[field] && this.model[field].length > this.validators[field].MAXLENGTH) {
        return `Este campo no debe exceder los ${this.validators[field].MAXLENGTH} caracteres`;
      }

      if (this.validators[field].REGEX && this.model[field] && !this.validators[field].REGEX.test(this.model[field])) {
        return "El formato no es válido";
      }

      if (this.validators[field].MINLENGTH && this.model[field] && this.model[field].length < this.validators[field].MINLENGTH) {
        return `Este campo debe tener al menos ${this.validators[field].MINLENGTH} caracteres`;
      }

      return null;
    },
    checkFormValidity() {
      let isValid = true;
      for (const field in this.validators) {
        if (this.getValidationMessage(field)) {
          isValid = false;
        }
      }
      return isValid;
    },
    clearErrors() {
      for (const field in this.validators) {
        this.model[field] = null;
      }

      this.fieldTouched = {};
    },
    validateOnInput(field) {
      this.fieldTouched = {
        ...this.fieldTouched,
        [field]: true,
      };
    },
    onForceInput() {
      const fields = Object.keys(this.model);
      fields.forEach((field) => {
        this.fieldTouched = {
          ...this.fieldTouched,
          [field]: true,
        };
      });
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.create();
    },
    async create() {
      this.onForceInput();
      if (!this.checkFormValidity()) return;

      this.isLoading = true;
      this.model.IDROLE = this.userType;

      this.$bvToast.hide();
      await userProxy.create(this.model)
        .then((response) => {
          const toastMessage = response.MESSAGE;

          this.$bvToast.toast(toastMessage, {
            title: response.STATUS ? "Exito" : "Error",
            variant: response.STATUS ? "primary" : "danger",
            solid: true,
          });

          if (response.STATUS) {
            this.$emit("closeModal");
            this.localShowModal = false;
            this.getUsers();
          }
        })
        .catch((error) => {
          this.$bvToast.toast(`Ocurrió un error al crear el usuario`, {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });


    },
  },
  watch: {
    localShowModal(value) {
      if (value) this.clearErrors();
    },
  },
};
</script>


<style lang="scss" scoped>
$color-light-blue: #0673f2;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #DDE3EB;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;
$color-weight-gray: #486169;

.pt-100 {
  padding-top: 61px;
}

.main-back-navbar {
  padding: 10px 0;
  border-bottom: 1px solid $color-light-gray;

  .back-text {
    color: $color-weight-gray;
    font-family: Inter-Regular;
    cursor: pointer;

    :hover {
      text-decoration: none;
    }
  }

  .btn-blue {
    background: $color-light-blue;
    border-radius: 50px !important;
    color: white;
    min-width: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    font-family: Inter-Medium;
  }
}

.main-create-user {
  padding-top: 30px;
  padding-bottom: 50px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background: $color-white-gray;

  .content-card-create {
    background: $color-white;
    padding: 20px;
    max-width: 500px;
    border-radius: 5px;
    height: 100%;
    border: 1px solid $color-light-gray;

    h6 {
      color: $color-light-blue;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-family: Inter-Bold;
    }

    .form-control {
      font-size: 15px;
      height: 52px;
      color: $color-dark;
      font-family: Inter-Regular;
      margin: auto;
    }

    .form-group {
      .label-control {
        font-family: Inter-SemiBold;

        .dropdown-menu {
          label {
            margin: auto !important;
          }
        }
      }
    }

    .btn-blue {
      background: $color-light-blue;
      border-radius: 50px !important;
      color: white;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 52px;
    }
  }
}
</style>