<template>
  <div class="card-entries">
    <h1>¿Qué tipo de entrada deseas crear?</h1>
    <div class="row">
      <div class="col-md-6">
        <router-link :to="{ name: 'CommonEntries' }">
          <div class="option">
            <span>Caso Común</span>
          </div>
        </router-link>
      </div>
      <div class="col-md-6">
        <router-link :to="{ name: 'EmblematicEntrie' }">
          <div class="option">
            <span>Caso Emblemático</span>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EntriesModal",
  data() {
    return {
      isEntriesModalActive: false,
    };
  },
};
</script>

<style lang="scss" scoped>
$color-light-blue: #0673f2;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #f7f9fa;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;


.card-entries {
  padding: 20px;

  h1 {
    font-size: 20px;
    font-family: Inter-Regular;
    font-weight: 600;
    color: $color-light-blue;
    text-align: center;
    margin-bottom: 30px;
  }

  .row {
    .option {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $color-light-gray;
      padding: 10px;
      text-align: center;
      border-radius: 8px;
      cursor: pointer;
      min-height: 80px;

      span {
        font-size: 12px;
        font-family: Inter-Regular;
        font-weight: 400;
        color: $color-dark;
        text-decoration: none;

        &:hover {
          text-decoration: none;
        }
      }

    }
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }
}
</style>