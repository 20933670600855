<template>
  <div class="pt-100 fade-in">
    <div class="main-common-entrie">
      <div class="container p-4">
        <h3 class="text-center mb-2">Agregar Entrada</h3>
        
        <div class="row">
          <div class="col-12 col-md-6">
            <b-form-group :state="getValidationState('TITLE')">
              <label for="TITLE" class="label-login">Título de entrada <span class="text-danger">*</span></label>
              <b-form-input v-model="modelo.TITLE" @input="validateOnInput('TITLE')">
              </b-form-input>
              <b-form-invalid-feedback :state="getValidationState('TITLE')">
                {{ getValidationMessage('TITLE') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group >
              <label for="RTITLE" class="label-login">Título Descriptivo </label>
              <b-form-input v-model="modelo.RTITLE">
              </b-form-input>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group>
              <label class="">Vinculatoriedad</label>
              <b-form-checkbox name="check-button" switch size="lg" v-model="modelo.ISBINDING">
                <span class="switch-text"> No vinculante</span>
              </b-form-checkbox>
            </b-form-group>
          </div>
        </div>

        <h6 class="col-12 p-0">IDENTIFICACIÓN</h6>
        <hr>

        <div class="row">
          <div class="col-12 col-md-6">
            <b-form-group :state="getValidationState('AMBIT')">
              <label for="AMBIT" class="label-ambito">Ámbito <span class="text-danger">*</span></label>
              <treeselect :multiple="true" :options="selects.ambitos" placeholder="Seleccione una opción"
                v-model="modelo.AMBIT" no-children-text="No hay opciones disponibles"
                no-results-text="No hay resultados" clear-on-select :max-height="200"
                :limitText="count => `Solo puede seleccionar ${count} opción`"
                :class="{ 'border-danger': getValidationState('AMBIT') === false }">
              </treeselect>

              <b-form-invalid-feedback :state="getValidationState('AMBIT')">
                {{ getValidationMessage('AMBIT') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group>
              <label class="label-login">Fecha de resolución <span class="text-danger">*</span></label>
              <!-- <b-form-datepicker :date-disabled-fn="dateDisabled" @input="validateOnInput('FRESOLUTION')" placeholder=""
                locale="es" class="custom" v-model="modelo.FRESOLUTION"></b-form-datepicker> -->
                <date-picker v-model="modelo.FRESOLUTION" valueType="format" 
                :disabledDate="time => time.getTime() > Date.now()"
                ></date-picker>
                <b-form-invalid-feedback :state="getValidationState('FRESOLUTION')">
                  {{ getValidationMessage('FRESOLUTION') }}
                </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group :state="getValidationState('DELITO')">
              <label for="jurisdictional_organ" class="label-login">Delito <span class="text-danger">*</span></label>
              <treeselect :multiple="true" :options="selects.delitos" placeholder="Seleccione una opción"
                v-model="modelo.DELITO" no-children-text="No hay opciones disponibles"
                no-results-text="No hay resultados" clear-on-select>
              </treeselect>
              <b-form-invalid-feedback :state="getValidationState('DELITO')">
                {{ getValidationMessage('DELITO') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group :state="getValidationState('RECURSO')">
              <label for="jurisdictional_organ" class="label-login">Recurso
                <span class="text-danger"> *</span>
              </label>
              <treeselect :multiple="true" :options="selects.recursos" placeholder="Seleccione una opción"
                v-model="modelo.RECURSO" no-children-text="No hay opciones disponibles"
                no-results-text="No hay resultados" clear-on-select>
              </treeselect>

              <b-form-invalid-feedback :state="getValidationState('RECURSO')">
                {{ getValidationMessage('RECURSO') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group>
              <label for="jurisdictional_organ" class="label-login">Materia</label>
              <treeselect :multiple="true" :options="selects.materias" placeholder="Seleccione una opción"
                v-model="modelo.MATERIA" no-children-text="No hay opciones disponibles"
                no-results-text="No hay resultados" clear-on-select>
              </treeselect>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group :state="getValidationState('OJURISDICCIONAL')">
              <label for="jurisdictional_organ" class="label-login">Órgano jurisdiccional <span
                  class="text-danger">*</span></label>
              <treeselect :multiple="true" :options="selects.jurisdiccionales" placeholder="Seleccione una opción"
                v-model="modelo.OJURISDICCIONAL" no-children-text="No hay opciones disponibles"
                no-results-text="No hay resultados" clear-on-select>
              </treeselect>

              <b-form-invalid-feedback :state="getValidationState('OJURISDICCIONAL')">
                {{ getValidationMessage('OJURISDICCIONAL') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group :state="getValidationState('MAGISTRATES')">
              <label class="label-login" for="tags-basic">Magistrados <span class="text-danger">*</span></label>
              <treeselect :multiple="true" :options="selects.magistrados" placeholder="Seleccione una opción"
                v-model="modelo.MAGISTRATES" no-children-text="No hay opciones disponibles"
                no-results-text="No hay resultados" clear-on-select>
              </treeselect>
              <b-form-invalid-feedback :state="getValidationState('MAGISTRATES')">
                {{ getValidationMessage('MAGISTRATES') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group :state="getValidationState('VDESIDENTE')">
              <div class="d-flex flex-column">
                <label for="dissenting_vote" class="label-login">Voto Disidente
                </label>
                <small class="font-weight" style="font-size: 12px; color: #4f4f4f; font-weight: 400;">(discrepa del
                  fallo final adoptado.)</small>
              </div>
              <SelectTags :selectedValue="modelo.VDESIDENTE" @onChangeOption="onChangeVDESIDENTE"
                key="VDESIDENTE-tags" />
              <b-form-invalid-feedback :state="getValidationState('VDESIDENTE')">
                {{ getValidationMessage('VDESIDENTE') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group :state="getValidationState('CVOTE')">
              <div class="d-flex flex-column">
                <label for="concurring_vote" class="label-login">Voto Concurrente
                </label>
                <small class="font-weight" style="font-size: 12px; color: #4f4f4f; font-weight: 400;">(disiente de la
                  argumentación jurídica, pero no del fallo final
                  adoptado.)</small>
              </div>
              <SelectTags :selectedValue="modelo.CVOTE" @onChangeOption="onChangeCVOTE" key="CVOTE-tags" />
              <b-form-invalid-feedback :state="getValidationState('CVOTE')">
                {{ getValidationMessage('CVOTE') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>

        <h6 class="col-12 p-0">DOCUMENTOS</h6>
        <hr>

        <div class="row">
          <div class="col-12 col-md-6">
            <b-form-group :state="getValidationState('ENTRIEFILE')">
              <label for="file"
                class="rs-form-control-label block mb-2 text-sm font-normal text-gray-500 dark:text-gray-500">Documento
                Principal <span class="text-danger">*</span>
              </label>
              <div class="relative">
                <label for="file_input" class="text-sm choose"></label>
                <input value="modelo.ENTRIEFILE"
                  class="w-100 text-sm choose text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  type="file" id="file_input" accept=".pdf" name="file_input"
                  @change="modelo.ENTRIEFILE = $event.target.files[0]" />
                <b-form-invalid-feedback :state="getValidationState('ENTRIEFILE')">
                  {{ getValidationMessage('ENTRIEFILE') }}
                </b-form-invalid-feedback>
              </div>
            </b-form-group>
          </div>

          <!-- <div class="col-12 col-md-6">
            <b-form-group :state="getValidationState('ENTRIEFILERESUMEN')">
              <label for="file"
                class="rs-form-control-label block mb-2 text-sm font-normal text-gray-500 dark:text-gray-500">Resumen
                Ejecutivo <span class="text-danger">*</span>
              </label>
              <div class="relative">
                <label for="file_input" class="text-sm choose"></label>
                <input value="modelo.ENTRIEFILERESUMEN"
                  class="w-100 text-sm choose text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  type="file" id="file_input" accept=".pdf" name="file_input"
                  @change="modelo.ENTRIEFILERESUMEN = $event.target.files[0]" />
                <b-form-invalid-feedback :state="getValidationState('ENTRIEFILERESUMEN')">
                  {{ getValidationMessage('ENTRIEFILERESUMEN') }}
                </b-form-invalid-feedback>
              </div>
            </b-form-group>
          </div> -->
        </div>

        <h6 class="col-12 p-0">CONTENIDO</h6>
        <hr>

        <div class="row">
          <div class="col-12">
            <b-form-group :state="getValidationState('KEYWORDS')">
              <label class="label-login" for="KEYWORDS">Palabras clave <span class="text-danger">*</span></label>
              <SelectTags :selectedValue="modelo.KEYWORDS" @onChangeOption="onChangeKeywords" key="keywords-tags" />
              <b-form-invalid-feedback :state="getValidationState('KEYWORDS')">
                {{ getValidationMessage('KEYWORDS') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-12">
            <b-form-group :state="getValidationState('TEMA')">
              <label for="theme" class="label-login">Tema <span class="text-danger">*</span></label>
              <vue-editor v-model="modelo.TEMA" class="mb-0 pb-0"
              ></vue-editor>
              <b-form-invalid-feedback  class="mt-0" :state="getValidationState('TEMA')">
                {{ getValidationMessage('TEMA') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-12">
            <b-form-group :state="getValidationState('SUBTEMA')">
              <label for="sub_theme" class="label-login">Subtema <span class="text-danger">*</span></label>
              <vue-editor v-model="modelo.SUBTEMA" class="mb-0 pb-0"
              ></vue-editor>
              <b-form-invalid-feedback :state="getValidationState('SUBTEMA')">
                {{ getValidationMessage('SUBTEMA') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-12">
            <b-form-group :state="getValidationState('SHORTSUMMARY')">
              <label for="short_summary" class="label-login">
                Síntesis de los fundamentos jurídicos relevantes
                <span class="text-danger">*</span>
              </label>
              <vue-editor v-model="modelo.SHORTSUMMARY" class="mb-0 pb-0"></vue-editor>
              <b-form-invalid-feedback :state="getValidationState('SHORTSUMMARY')">
                {{ getValidationMessage('SHORTSUMMARY') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-12">
            <b-form-group :state="getValidationState('RESUMEN')">
              <label for="resumen" class="label-login">
                Fundamentos jurídicos relevantes <span class="text-danger">*</span>
              </label>
              <vue-editor v-model="modelo.RESUMEN" class="mb-0 pb-0"></vue-editor>
              <b-form-invalid-feedback :state="getValidationState('RESUMEN')">
                {{ getValidationMessage('RESUMEN') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>

        <div class="row justify-content-center"
          style="border-top: 1px solid #dfdfdf; padding-top: 20px; margin-top: 20px;gap: 20px;">
          <b-button variant="outline-primary" @click="$router.go(-1)">
            Cancelar
          </b-button>
          <b-button variant="primary" @click="handleOk" :disabled="isLoading">
            <span>Publicar</span>
          </b-button>
          <b-button variant="danger" class="text-white" @click="localStorageSave">
            <span>Guardar</span>
          </b-button>
          <b-button variant="success" class="text-white" @click="UpdateLocaleStorage" :disabled="isLoading">
            <span>Actualizar</span>
          </b-button>
        </div>
      </div>
    </div>

    <div v-if="isLoading" class="spinner-overlay">
      <b-spinner label="Cargando..." variant="primary"></b-spinner>
    </div>
  </div>
</template>


<script>
import adminEntriesProxy from "../../../../proxies/AdminEntriesProxy.js";
import MagistradoProxy from "../../../../proxies/Magistrados.Proxy";
import DynamicAsyncSelect from "@/components/select/DynamicAsyncSelect.vue";
import SelectTags from "@/components/select/SelectTags.vue";
import Editor from "@tinymce/tinymce-vue";
import SelectGlobal from "@/components/select/SelectGlobal.vue";
import filterProxy from "../../../../proxies/FilterProxy.js";
import Treeselect from '@riophae/vue-treeselect'
import { VueEditor } from "vue2-editor";

export default {
  name: "CommonEntrie",
  components: { DynamicAsyncSelect, SelectTags, Editor, SelectGlobal, VueEditor },
  data() {
    return {
      isLoading: false,
      typeEntrie: "common",
      entrieFile: null,
      entrieFileResumen: null,
      entrieFilters: [],
      menu: [],
      model: {
        title: null,
        type: 0,
        identification: {
          ambit: null,
          theme: null,
          sub_theme: null,
          record: "",
          resolution_date: null,
          jurisdictional_organ: null,
          magistrates: [],
          dissenting_vote: null,
          concurring_vote: null,
        },
        content: {
          short_summary: "",
          resumen: null,
          keywords: [],
        },
        organization: {
          binding: 0,
          filters: [],
        },
      },
      modelo: {
        TITLE: null,
        RTITLE: null,
        ISBINDING: false,
        AMBIT: [],
        FRESOLUTION: null,
        OJURISDICCIONAL: [],
        MAGISTRATES: [],
        VDESIDENTE: [],
        CVOTE: [],
        ENTRIEFILE: null,
        ENTRIEFILERESUMEN: null,
        KEYWORDS: [],
        TEMA: null,
        SUBTEMA: null,
        SHORTSUMMARY: null,
        RESUMEN: null,
        RECURSO: [],
        DELITO: [],
        MATERIA: [],
      },
      validators: {
        TITLE: {
          REQUIRED: true
        },
        AMBIT: {
          REQUIRED: true,
          REGEX: /[^[]/
        },
        FRESOLUTION: {
          REQUIRED: true
        },
        OJURISDICCIONAL: {
          REQUIRED: true,
          REGEX: /[^[]/
        },
        MAGISTRATES: {
          REQUIRED: true,
          REGEX: /[^[]/
        },
        VDESIDENTE: {
          REQUIRED: true
        },
        CVOTE: {
          REQUIRED: true
        },
        ENTRIEFILE: {
          REQUIRED: true
        },
        // ENTRIEFILERESUMEN: {
        //   REQUIRED: true
        // },
        KEYWORDS: {
          REQUIRED: true,
          REGEX: /[^[]/
        },
        TEMA: {
          REQUIRED: true
        },
        SUBTEMA: {
          REQUIRED: true
        },
        SHORTSUMMARY: {
          REQUIRED: true
        },
        RESUMEN: {
          REQUIRED: true
        },
        RECURSO: {
          REQUIRED: true,
          REGEX: /[^[]/
        },
        DELITO: {
          REQUIRED: true,
          REGEX: /[^[]/
        },
      },
      fieldTouched: {},
      selects: {
        magistrados: [],
        ambitos: [],
        jurisdiccionales: [],
        delitos: [],
        recursos: [],
        materias: []
      },
    };
  },
  watch: {
    "model.organization.binding"(newValue, oldValue) {
      if (newValue) {
        return this.model.organization.binding = 1;
      } else {
        return this.model.organization.binding = 0;
      }
    }
  },
  methods: {
    localStorageSave() {
      localStorage.setItem("commonEntrie", JSON.stringify(this.modelo));
    },
    UpdateLocaleStorage() {
      let data = JSON.parse(localStorage.getItem("commonEntrie"));
      this.modelo = data;
    },
    async getFilters() {
      await filterProxy.list({ NIVEL: 5 }, "1")
        .then((res) => {
          if (res && res.length > 0) {
            let ambitos = this.configFilter(res, "ÁMBITO");
            let jurisdiccionales = this.configFilter(res, "ÓRGANO JURISDICCIONAL");
            let delitos = this.configFilter(res, "DELITOS");
            let recursos = this.configFilter(res, "TIPO DE RECURSO");
            let materias = this.configFilter(res, "MATERIA");

            this.selects.ambitos = ambitos?.NIVEL_2?.map((item) => {
              return {
                id: item.VALUE,
                label: item.LABEL,
              };
            });

            this.selects.jurisdiccionales = jurisdiccionales?.NIVEL_2?.map((item) => {
              return {
                id: item.VALUE,
                label: item.LABEL,
                children: item.NIVEL_3.map((item2) => {
                  return {
                    id: item2.VALUE,
                    label: item2.LABEL,
                    children: item2.NIVEL_4.map((item3) => {
                      return {
                        id: item3.VALUE,
                        label: item3.LABEL,
                        children: item3.NIVEL_5.map((item4) => {
                          return {
                            id: item4.VALUE,
                            label: item4.LABEL,
                            children: item4.NIVEL_6.map((item5) => {
                              return {
                                id: item5.VALUE,
                                label: item5.LABEL,
                              };
                            }),
                          };
                        }),
                      };
                    }),
                  };
                }),
              };
            });

            this.selects.delitos = delitos?.NIVEL_2?.map((item) => {
              return {
                id: item.VALUE,
                label: item.LABEL,
                children: item.NIVEL_3.map((item2) => {
                  return {
                    id: item2.VALUE,
                    label: item2.LABEL,
                    children: item2.NIVEL_4.map((item3) => {
                      return {
                        id: item3.VALUE,
                        label: item3.LABEL,
                        children: item3.NIVEL_5.map((item4) => {
                          return {
                            id: item4.VALUE,
                            label: item4.LABEL,
                            children: item4.NIVEL_6.map((item5) => {
                              return {
                                id: item5.VALUE,
                                label: item5.LABEL,
                              };
                            }),
                          };
                        }),
                      };
                    }),
                  };
                }),
              };
            });

            this.selects.recursos = recursos?.NIVEL_2?.map((item) => {
              return {
                id: item.VALUE,
                label: item.LABEL,
                children: item.NIVEL_3.map((item2) => {
                  return {
                    id: item2.VALUE,
                    label: item2.LABEL,
                    children: item2.NIVEL_4.map((item3) => {
                      return {
                        id: item3.VALUE,
                        label: item3.LABEL,
                        children: item3.NIVEL_5.map((item4) => {
                          return {
                            id: item4.VALUE,
                            label: item4.LABEL,
                            children: item4.NIVEL_6.map((item5) => {
                              return {
                                id: item5.VALUE,
                                label: item5.LABEL,
                              };
                            }),
                          };
                        }),
                      };
                    }),
                  };
                }),
              };
            });

            this.selects.materias = materias?.NIVEL_2?.map((item) => {
              return {
                id: item.VALUE,
                label: item.LABEL,
                children: item.NIVEL_3.map((item2) => {
                  return {
                    id: item2.VALUE,
                    label: item2.LABEL,
                    children: item2.NIVEL_4.map((item3) => {
                      return {
                        id: item3.VALUE,
                        label: item3.LABEL,
                        children: item3.NIVEL_5.map((item4) => {
                          return {
                            id: item4.VALUE,
                            label: item4.LABEL,
                            children: item4.NIVEL_6.map((item5) => {
                              return {
                                id: item5.VALUE,
                                label: item5.LABEL,
                              };
                            }),
                          };
                        }),
                      };
                    }),
                  };
                }),
              };
            });
          }
        })
        .catch((err) => {
          this.$bvToast.toast(`Ocurrió un error al obtener los filtros`, {
            title: "Ocurrió un error al obtener los filtros",
            variant: "danger",
            solid: true,
          });
        })
    },
    configFilter(data, label) {
      try {
        return data.filter((item) => item.LABEL.toUpperCase() === label.toUpperCase())
          .map((item) => {
            return {
              ...item,
              NIVEL_2: JSON.parse(item.NIVEL_2)
            };
          })?.[0] || {};
      } catch (error) {
        return {};
      }
    },
    dateDisabled(ymd, date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today;
    },
    async onChangeKeywords(newKeywords) {
      this.modelo.KEYWORDS = newKeywords;
    },
    async onChangeVDESIDENTE(newVDESIDENTE) {
      this.modelo.VDESIDENTE = newVDESIDENTE;
    },
    async onChangeCVOTE(newCVOTE) {
      this.modelo.CVOTE = newCVOTE;
    },
    async getAllFilters() {
      await MagistradoProxy.list({ ROWS: 1000, INIT: 0, DESC: null })
        .then((response) => {
          this.selects.magistrados = response.map((item) => {
            return {
              id: item.ID,
              label: (item.APELLIDOS + " " + item.NOMBRES).replace(/null/g, ""),
            };
          });
        })
        .catch((error) => {
          this.selects.magistrados = [];
        });

      // filters
      await this.getFilters();
    },
    /// METODOS PARA VALIDACION
    getValidationState(field) {
      if (!this.fieldTouched[field]) return null;
      return this.getValidationMessage(field) ? false : true;

    },
    getValidationMessage(field) {
      if (this.validators[field].REQUIRED && !this.modelo[field]) {
        return "Este campo es requerido";
      }

      if (this.validators[field].MAXLENGTH && this.modelo[field] && this.modelo[field].length > this.validators[field].MAXLENGTH) {
        return `Este campo no debe exceder los ${this.validators[field].MAXLENGTH} caracteres`;
      }

      if (this.validators[field].REGEX && this.modelo[field] && !this.validators[field].REGEX.test(this.modelo[field])) {
        return "El campo no cumple con el formato requerido";
      }

      if (this.validators[field].MINLENGTH && this.modelo[field] && this.modelo[field].length < this.validators[field].MINLENGTH) {
        return `Este campo debe tener al menos ${this.validators[field].MINLENGTH} caracteres`;
      }

      return null;
    },
    checkFormValidity() {
      let isValid = true;
      for (const field in this.validators) {
        if (this.getValidationMessage(field)) {
          isValid = false;
        }
      }
      return isValid;
    },
    clearErrors() {
      for (const field in this.validators) {
        this.modelo[field] = null;
      }

      this.fieldTouched = {};
    },
    validateOnInput(field) {
      this.fieldTouched = {
        ...this.fieldTouched,
        [field]: true,
      };
    },
    onForceInput() {
      const fields = Object.keys(this.modelo);
      fields.forEach((field) => {
        this.fieldTouched = {
          ...this.fieldTouched,
          [field]: true,
        };
      });
    },
    handleOk(bvModalEvent) {
      // bvModalEvent.preventDefault();
      this.create();
    },
    async create() {
      this.onForceInput();
      if (!this.checkFormValidity()) return;

      this.isLoading = true;
      const formData = new FormData();
      formData.append("files", this.modelo.ENTRIEFILE);
      formData.append("files", this.modelo.ENTRIEFILERESUMEN);
      formData.append("TITLE", this.modelo.TITLE);
      formData.append("RTITLE", this.modelo.RTITLE);
      formData.append("ISBINDING", this.modelo.ISBINDING);
      formData.append("AMBIT", this.modelo.AMBIT.join(","));
      formData.append("FRESOLUTION", this.modelo.FRESOLUTION);
      formData.append("OJURISDICCIONAL", this.modelo.OJURISDICCIONAL);
      formData.append("MAGISTRATES", this.modelo.MAGISTRATES.join(","));
      formData.append("VDESIDENTE", this.modelo.VDESIDENTE);
      formData.append("CVOTE", this.modelo.CVOTE);
      formData.append("KEYWORDS", this.modelo.KEYWORDS.join(","));
      formData.append("TEMA", this.modelo.TEMA);
      formData.append("SUBTEMA", this.modelo.SUBTEMA);
      formData.append("SHORTSUMMARY", this.modelo.SHORTSUMMARY);
      formData.append("RESUMEN", this.modelo.RESUMEN);
      formData.append("TYPE", "jurisprudences");
      formData.append("TIPO", this.typeEntrie);
      formData.append("DELITO", this.modelo.DELITO.join(",") || "");
      formData.append("RECURSO", this.modelo.RECURSO.join(",") || "");
      formData.append("MATERIA", this.modelo.MATERIA.join(",") || "");

      await adminEntriesProxy.create(formData)
        .then(response => {
          const toastMessage = response.STATUS ? "Entrada creada con éxito" : response.MESSAGE;

          this.$bvToast.toast(toastMessage, {
            title: response.STATUS ? "Success" : "Error",
            variant: response.STATUS ? "primary" : "danger",
            solid: true,
          });

          if (response.STATUS) {
            localStorage.removeItem("commonEntrie");
            setTimeout(() => {
              this.$router.push({ name: "EntriesJurisdiction" });
            }, 1500);
          }

        })
        .catch(err => {
          console.log(err);
          this.$bvToast.toast(`Ocurrió un error al agregar la entrada`, {
            title: "Ocurrió un error al agregar la entrada",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.isLoading = false;
        })
    }
  },
  watch: {
    // 'modelo.AMBIT'(newValue, oldValue) {
    //   if (newValue.length > 1) {
    //     this.modelo.AMBIT = [newValue[newValue.length - 1]];
    //   }
    // }
  },
  async mounted() {
    await this.getAllFilters();
  },

};
</script>

<style lang="scss">
// this can cover all elements in all components
@media (max-width: 575.98px) {
  .v-dropdown-container {
    width: 100% !important;

    .sm-parent {
      display: flex;
      flex-direction: row;
      margin: 0px !important;
      padding: 0px !important;
      width: 100% !important;
    }
  }
}
</style>

<style lang="scss" scoped>
$color-light-blue: #1864FF;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #DDE3EB;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;
$color-weight-gray: #486169;

.convertir_file {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: #374151;
}

.pt-100 {
  padding-top: 61px;
}

.main-back-navbar {
  padding: 10px 0;
  border-bottom: 1px solid $color-light-gray;

  .back-text {
    color: $color-weight-gray;
    font-family: Inter-Regular;
    cursor: pointer;

    :hover {
      text-decoration: none;
    }
  }

  .btn-blue {
    background: $color-light-blue;
    border-radius: 50px !important;
    color: white;
    min-width: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    font-family: Inter-Medium;
  }
}

.main-common-entrie {
  padding-top: 50px;
  padding-bottom: 50px;
  background: $color-white;
  min-height: 100vh;

  h6 {
    font-family: Inter-bold;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: $color-light-blue;
  }

  .container {
    background-color: $color-white;
    border-radius: 8px;
    box-shadow: 0px 9px 36px rgba(0, 0, 0, 0.14),
      0px 2.71324px 10.8529px rgba(0, 0, 0, 0.0134174),
      0px 0.407592px 1.63037px rgba(0, 0, 0, 0.0106725);



    .row {
      .card {
        padding: 12px;
        margin-bottom: 20px;

        .title-blue {
          color: $color-light-blue;
          font-size: 16px;
          font-weight: 600;
          font-family: Inter-Regular;
          margin-bottom: 30px;
          text-transform: uppercase;
        }

        .switch-title {
          font-family: Inter-Bold;
          font-size: 15px;
        }

        .switch-text {
          font-size: 12px;
          font-weight: 500;
        }

        .form-group {
          margin-bottom: 20px;


          input {
            font-size: 15px;
            height: 38px;
            color: $color-dark;
            font-family: Inter-Regular;
          }

          select {
            font-size: 15px;
            height: 38px;
            color: $color-dark;
            font-family: Inter-Regular;
          }
        }
      }
    }
  }
}

.fade-in {
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>