<template>
  <div>
    <div class="main-navbar">
      <b-navbar toggleable="lg" type="light" variant="white" class="container">

        <b-navbar-toggle v-if="isLogged" target="nav-collapse"></b-navbar-toggle>
        <div>
          <router-link :to="{ name: isLogged ? 'About' : 'Login'}">
            <img src="@/assets/logo/logo.svg" width="100" alt="logo" class="mr-3" /></router-link>
        </div>

        <b-collapse v-if="isLogged" id="nav-collapse" is-nav>
          <b-navbar-nav>
            <div class="content-link">
              <router-link class="nav-link" :to="{ name: 'Search' }">
                <img src="@/assets/navbar/search.svg" class="mr-2" />
                <span :to="{ name: 'Search' }"> Busqueda</span>
              </router-link>
            </div>
            <div class="content-link">
              <router-link class="nav-link" :to="{ name: 'Documents' }">
                <img src="@/assets/navbar/document.svg" class="mr-2" />
                <span :to="{ name: 'Documents' }">
                  Mis documentos</span>
              </router-link>
            </div>
            <div class="content-link" v-if="!isLogged">
              <router-link class="nav-link" :to="{ name: 'Help' }">
                <img src="@/assets/navbar/document.svg" class="mr-2" />
                <span :to="{ name: 'Help' }">
                  Contáctanos
                </span>
              </router-link>
            </div>
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <div class="content-link" v-if="!isLogged">
              <div class="nav-link" @click="registerModal = true">
                <img src="@/assets/navbar/document.svg" class="mr-2" />
                Quiero crear una cuenta
              </div>
            </div>

            <!-- <div v-if="isLogged">
              <b-dropdown text="Menu" class="btn btn-transparent" toggle-class="text-decoration-none" no-caret>
                <b-dropdown-item @click="profile">
                  <img class="mr-2" src="@/assets/navbar/user.svg" alt="Mi perfil" />
                  <span>Mi perfil</span>
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="logout">
                  <img class="mr-2" src="@/assets/icons/logout.svg" alt="Cerrar Sesión" />
                  <span>Cerrar Sesión</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div v-else>
              <router-link :to="{ name: 'Login' }" class="btn btn-white">Iniciar Sesión
              </router-link>
            </div> -->

            <div class="content-link" v-if="isLogged">
              <div class="nav-link" @click="profile">
                <img src="@/assets/navbar/user.svg" class="mr-2" />
                Mi perfil
              </div>
            </div>

            <div class="content-link" v-if="isLogged">
              <div class="nav-link" @click="logout">
                <img src="@/assets/navbar/logout.svg" /> Cerrar Sesión
              </div>
            </div>

            <div class="content-link" v-if="!isLogged">
              <router-link class="nav-link" :to="{ name: 'Login' }">
                <img src="@/assets/navbar/document.svg" class="mr-2" />
                Iniciar Sesión
              </router-link>
            </div>


          </b-navbar-nav>
        </b-collapse>

      </b-navbar>
    </div>
    <b-modal v-model="logoutModal" centered :hide-footer="true" :hide-header="true">
      <logout-modal @logout="logout" @closeModal="closeModal" />
    </b-modal>

    <router-view></router-view>
    <UserFooter />

  </div>
</template>

<script>
import UserFooter from "./UserFooter.vue";
import Register from "@/views/auth/Register.vue";
import { AUTH_LOGOUT } from "@/store/actions/auth";

export default {
    name: "UserRoot",
    components: { UserFooter, Register },
    data() {
        const isLogged = this.$store.getters.isAuthenticated;

        return {
            isLogged,
            registerModal: false,
            closeModal: false,
            logoutModal: false,

        };
    },
    methods: {
        profile() {
            if (this.$route.name !== 'Profile') {
                this.$router.push({ name: 'Profile' });
            }

        },
        logout() {
            this.$store.dispatch(AUTH_LOGOUT).then(() => {
                this.$router.push({ name: "Login" });
            });
        },
        closeModalRegister() {
            this.registerModal = false;
        },
    },
};
</script>
<style lang="scss" scoped>
$color-light-gray: #dfdfdf;
$color-light-blue: #0673f2;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #dde3eb;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;

.main-navbar {
  border-bottom: 1px solid $color-light-gray;
  position: fixed;
  width: 100%;
  background: $color-white;
  z-index: 99;

  .navbar {
    .navbar-collapse {
      ul {
        .content-link {
          margin-right: 10px;

          :hover {
            background: $color-white-gray;
            text-decoration: none;
            border-radius: 30px;
          }

          .nav-link {
            display: flex;
            align-items: center;
            padding: 10px 15px;
            margin-right: 10px;
            font-family: Inter-Regular;
            font-weight: 500;
            color: $color-dark;
            cursor: pointer;

            img {
              border-radius: 0px !important;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
</style>