<template>
    <div class="content-filter">
        <div class="content-filter">
            <b-form class="d-flex flex-column flex-md-row align-items-end justify-content-between"
                @submit.prevent="search">
                <b-form-input placeholder="Buscar por descripción" v-model="filter.name"
                    class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
                <div class="contenedor-buttons">
                    <button type="button" class="btn btn-blue-primary" @click="search">Buscar</button>
                    <button type="button" class="btn btn-blue-secondary" @click="openModal = true">Nuevo</button>
                </div>
            </b-form>
        </div>
        <dataTable :role="role" :loading="pag.loading" :search="search" :fieldsComponent="fields" :actions="actions"
            :rowData="rowData" />

        
        <Auditoria 
        v-if="role.IDR == 0"
        url="NOTICIAS" />

        <ModalEliminar :action="onDeleted" :openDelete="openDelete" :closeHandler="closeHandler"
            :message="'¿Está seguro de eliminar este registro, esta acción no se puede deshacer?'"
            :buttonOk="'Si, eliminar'" />
        <ModalAgregarNoticia :openModal="openModal" :toggleModal="toggleModal" :search="search"
            :objectValidator="objectValidator" />
        <ModalEditarNoticia :openModalEditar="openModalEditar" :toggleModalEditar="toggleModalEditar" :search="search"
            :data="dataEditar" :objectValidator="objectValidator" />
    </div>
</template>

<script>
import { inject } from 'vue'
import DataTable from "../../../components/admin/DataTable.vue";
import MantenimientoProxy from '../../../proxies/MantenimientoProxy';
import ModalEliminar from "../../../components/Modal/ModalEliminar.vue";
import ModalAgregarNoticia from "./Modales/ModalAgregarNoticia.vue";
import ModalEditarNoticia from "./Modales/ModalEditarNoticia.vue";
import Auditoria from "../../../components/admin/Auditoria.vue";

export default {
    name: "Noticias",
    props: {
        role: {
            type: Object,
            default: () => ({})
        },
    },
    components: {
        DataTable,
        ModalEliminar,
        ModalAgregarNoticia,
        ModalEditarNoticia,
        Auditoria
    },
    data() {
        return {
            selected: "Noticias",
            filter: {
                name: null,
            },
            pag: {
                loading: false,
                perPage: 10,
                init: 0,
            },
            fields: [
                { key: "RN", label: "" },
                { key: "TITULO", label: "Título", width: "30%" },
                { key: "DESCRIPCION", label: "Descripción", width: "30%" },
                { key: "ACCIONES", label: "Acciones", class: "text-center" },
            ],
            actions:
            {
                edit: (data) => this.onEdited(data),
                delete: (data) => this.onDeleted(data),
            },
            rowData: [],
            // AGREGAR
            openModal: false,
            // ELIMINAR
            openDelete: false,
            dataDelete: {},
            // EDITAR
            openModalEditar: false,
            dataEditar: {}
        };
    },
    setup() {
        const objectValidator = inject('objectValidator');

        return {
            objectValidator,
        }
    },
    methods: {
        async search(pag = {}) {
            if (Object.keys(pag).length === 0) {
                pag = this.pag
            }
            this.pag = { ...this.pag, ...pag }
            this.pag.loading = true;
            await MantenimientoProxy.list({
                ROWS: pag?.perPage || 10,
                INIT: pag?.init || 0,
                DESC: this.filter.name,
            })
                .then((response) => {
                    this.rowData = response;
                })
                .catch((error) => {
                    this.rowData = [];
                })
                .finally(() => {
                    this.pag.loading = false;
                });
        },
        toggleModal() {
            this.openModal = !this.openModal;
        },
        // ELIMINAR
        async onDeleted(data = {}, isDelete = false) {
            if (!isDelete) {
                this.dataDelete = data;
                this.openDelete = true;
            } else {
                this.openDelete = false;
                this.$bvToast.hide();
                await MantenimientoProxy.delete(this.dataDelete.ID)
                    .then((response) => {
                        this.$bvToast.toast(response.MESSAGE, {
                            title: response.STATUS ? "Exito" : "Error",
                            variant: response.STATUS ? "primary" : "danger",
                            solid: true,
                        });

                        if (response.STATUS) this.search(this.pag);
                    })
                    .catch((err) => {
                        this.$bvToast.toast(`Ocurrió un error al eliminar el registro`, {
                            title: "ERROR",
                            variant: "danger",
                            solid: true,
                        });
                    })
                    .finally(() => this.isLoading = false);
            }
        },
        closeHandler() {
            this.openDelete = false;
        },
        // EDITAR
        async onEdited(data) {
            this.dataEditar = data;
            this.openModalEditar = true;
        },
        toggleModalEditar() {
            this.openModalEditar = !this.openModalEditar;
        },
    },
};

</script>
