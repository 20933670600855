<template>
  <div class="pt-100">
    <div class="main-back-navbar">
      <div class="container">
        <div class="d-flex justify-content-between align-items-center">
          <a class="d-flex justify-content-between back-text" @click="$router.go(-1)">
            <img src="@/assets/icons/back-arrow.svg" class="mr-2" />
            Regresar
          </a>
          <div class="d-flex">
            <button class="btn btn-red" @click="deleteEntrieModal = true">
              <span>Eliminar <img src="@/assets/icons/trash-red.svg" class="ml-2" /></span>
            </button>
            <button class="btn btn-blue" @click="editEntrie" :disabled="isLoading">
              <b-spinner label="Spinning" small v-if="isLoading"></b-spinner>
              <span v-else>Publicar entrada</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="main-ejecutive-entrie">
      <div class="container">
        <div class="row">
          <div class="col-md-7">
            <div class="card">
              <b-form-group>
                <label for="title" class="label-login">Título de entrada</label>
                <b-form-input id="title" type="text" v-model="model.title" required></b-form-input>
              </b-form-group>
              <b-form-group>
                <label for="subtitle" class="label-login">Subtitulo</label>
                <b-form-input id="subtitle" type="text" v-model="model.sub_title" required></b-form-input>
              </b-form-group>
            </div>

            <div class="card">
              <h1 class="title-blue">Contenido</h1>
              <b-form-group>
                <label for="body" class="label-login">Cuerpo</label>
                <b-form-textarea id="body" type="text" rows="8" v-model="model.body" required></b-form-textarea>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-5">
            <div class="card">
              <b-form-group>
                <label for="document" class="label-login">Documento</label>
                <b-form-file v-model="entrieFile" placeholder="Selecciona o arrastrar aqui"
                  drop-placeholder="Drop file here..."></b-form-file>
              </b-form-group>
              <a v-if="this.linkFile" :href="this.linkFile" target="_blank">Ver Documento </a>
            </div>
            <div class="card">
              <h1 class="title-blue">ORGANIZACIÓN</h1>
              <v-selectmenu :data="menu" type="regular" key-field="id" class="pb-4" width="420" embed="true"
                :title="false" align="center">
                <template #row="{ row }">
                  <span v-html="row.content" @click="addNewFilter(row)"></span>
                </template>
              </v-selectmenu>
              <h1 class="title-blue">Filtros seleccionados</h1>
              <b-list-group class="shadow-sm">
                <b-list-group-item v-for="filter in entrieFilters" :key="filter">
                  <div class="d-flex flex-row justify-content-between">
                    <span>{{ filter.title }}</span>

                    <img src="@/assets/icons/close-gray.svg" class="pl-2 pb-1 pe-auto" @click="removeFilter(filter)"
                      role="button" />
                  </div>

                </b-list-group-item>
              </b-list-group>
              <div class="pt-5">
                <span class="switch-title">Vinculatoriedad</span>
                <b-form-checkbox name="check-button" switch size="lg" v-model="model.filters">
                  <span class="switch-text"> No vinculante</span>
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <entries-delete-modal @closeDeleteEntrieModal="closeDeleteEntrieModal" :IdEntrie="IdEntrie"
      :deleteEntrieModal="deleteEntrieModal" :typeEntrie="typeEntrie" />
  </div>
</template>

<script>
import adminEntriesProxy from "@/proxies/AdminEntriesProxy.js";
import filterProxy from "@/proxies/FilterProxy.js";
import { getParentDeep } from "@/common/Helper.js";
import EntriesDeleteModal from "@/components/admin/entries/EntriesDeleteModal.vue";

export default {
  name: "ExecutiveEntrieEdit",
  components: { EntriesDeleteModal },
  data() {
    return {
      isLoading: false,
      typeEntrie: "executive",
      IdEntrie: this.$route.params.id,
      entrieFile: null,
      linkFile: null,
      entrieFilters: [],
      menu: [],
      model: {
      },
      options: [
        { value: null, text: "Please select an option" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Selected Option" },
        { value: "d", text: "This one is disabled", disabled: true },
      ],
      deleteEntrieModal: false,
    };
  },
  methods: {
    closeDeleteEntrieModal() {
      this.deleteEntrieModal = false;
    },
    async addNewFilter(data) {
      if (!data.children) {
        let newEntrieFilters = this.entrieFilters;
        let isAbleToAdd = newEntrieFilters.find((filter) => filter.id === data.id);

        if (!isAbleToAdd) {
          newEntrieFilters.push({ ...data, ...{ title: data.content } });
        }
      }
    },
    async removeFilter(data) {
      let newEntrieFilters = [...this.entrieFilters];
      const index = newEntrieFilters.indexOf(data);
      if (index > -1) {
        newEntrieFilters.splice(index, 1);
      }

      this.entrieFilters = newEntrieFilters;
    },
    async getFile() {
      try {
        this.isLoading = true;
        if (this.model.document_url) {

          this.linkFile = await adminEntriesProxy.getEntrieFile(this.IdEntrie);

          this.$bvToast.toast(`Documento traido con éxito`, {
            title: "Éxito",
            variant: "primary",
            solid: true,
          });
        }
      } catch (e) {
        console.error(e);

        this.$bvToast.toast(`No se pudo cargar el documento`, {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
        if (this.linkFile === null) {
          this.getFile();
        }
      }
    },
    onFileChange() {
      this.entrieFile = this.$refs.file.files[0];
    },
    async updateFile() {
      try {
        this.isLoading = true;

        const { data } = await adminEntriesProxy.uploadEntrieFile(
          this.IdEntrie, this.entrieFile
        );
        this.$bvToast.toast(`Imagen editada con éxito`, {
          title: "Éxito",
          variant: "primary",
          solid: true,
        });

      } catch (e) {
        console.error(e);
        this.$bvToast.toast(`No se pudo agregar la imagen`, {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async getById() {
      try {
        this.isLoading = true;

        const { jurisprudence } = await adminEntriesProxy.getById(
          this.IdEntrie
        );

        this.model = jurisprudence;
        this.entrieFilters = jurisprudence.filters;

      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async editEntrie() {
      try {
        this.isLoading = true;

        //order body to send 
        const prevModel = {
          title: this.model.title,
          sub_title: this.model.sub_title,
          type: this.model.type,
          content: {
            body: this.model.body
          },
          organization: {
            binding: this.model.binding,
            filters: this.entrieFilters.map((filter) => filter.id),
          }
        }

        //final model to send
        const finalModel = {
          id: this.IdEntrie,
          jurisprudence: prevModel
        }
        await adminEntriesProxy.edit(this.typeEntrie, finalModel);

        if (this.entrieFile) {
          await this.updateFile();
        }

        await this.initialData();

        this.$bvToast.toast(`Entrada editada con éxito`, {
          title: "Éxito",
          variant: "primary",
          solid: true,
        });
      } catch (e) {
        console.error(e);
        this.$bvToast.toast(`No se pudo editar la entrada`, {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async getAllFilters() {
      const { filters } = await filterProxy.list(0);
      const result = filters
        .sort(({ parent: a }, { parent: b }) => a - b)
        .reduce((acc, { id, title, parent }) => {
          const obj = { id, content: title, children: [] };
          const parentObj = getParentDeep(acc, parent);
          if (parentObj) parentObj.children.push(obj)
          else acc.push(obj);
          return acc;
        }, []);

      this.menu = result;
    },
    async initialData() {
      await this.getById();
      await this.getFile();
      await this.getAllFilters();
    }
  },
  async mounted() {
    await this.initialData();
  },
};
</script>

<style lang="scss" scoped>
$color-light-blue: #0673f2;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #DDE3EB;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;
$color-weight-gray: #486169;
$color-red: #eb5757;

.pt-100 {
  padding-top: 61px;
}

.main-back-navbar {
  padding: 10px 0;
  border-bottom: 1px solid $color-light-gray;

  .back-text {
    color: $color-weight-gray;
    font-family: Inter-Regular;
    cursor: pointer;

    :hover {
      text-decoration: none;
    }
  }

  .btn-blue {
    background: $color-light-blue;
    border-radius: 50px !important;
    color: white;
    min-width: 155px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    font-family: Inter-Medium;
  }

  .btn-red {
    background: $color-white;
    border: 1px solid $color-red;
    border-radius: 50px !important;
    font-family: Inter-Regular;
    font-weight: 500;
    font-size: 15px;
    color: $color-red;
    min-width: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    margin-right: 20px;
  }
}

.main-ejecutive-entrie {
  padding-top: 50px;
  background: $color-white-gray;

  .container {
    .row {
      .card {
        padding: 12px;
        margin-bottom: 20px;

        .title-blue {
          color: $color-light-blue;
          font-size: 16px;
          font-weight: 600;
          font-family: Inter-Regular;
          margin-bottom: 30px;
          text-transform: uppercase;
        }

        .switch-title {
          font-family: Inter-Bold;
          font-size: 15px;
        }

        .switch-text {
          font-size: 12px;
          font-weight: 500;
        }

        .form-group {
          margin-bottom: 20px;

          .label-login {
            margin-bottom: 2px;
            font-family: Inter-SemiBold;
          }

          input {
            font-size: 15px;
            height: 52px;
            color: $color-dark;
            font-family: Inter-Regular;
          }

          select {
            font-size: 15px;
            height: 52px;
            color: $color-dark;
            font-family: Inter-Regular;
          }
        }

        .custom-file-label[data-browse] {
          background: red !important;

          &::after {
            display: none;
          }
        }

        input[type="file"] {
          display: none;
        }

        .custom-file-upload {
          background: $color-light-blue;
          border-radius: 50px !important;
          color: white;
          min-width: 150px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 44px;
          font-family: Inter-Medium;
        }

        .btn-blue {
          background: $color-light-blue;
          border-radius: 50px !important;
          color: white;
          min-width: 150px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 44px;
          font-family: Inter-Medium;
        }
      }
    }
  }
}
</style>