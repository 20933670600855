<template>
    <div class="main-settings fade-in">
        <div class="container">
            <div class="setting-card mb-3" v-if="backup !== null">
                <h1 class="title-blue">RESPALDO DEL SISTEMA</h1>
                <p class="sub-title">Último respaldo automático</p>
                <div class="content-info">
                    <img class="mr-2" src="@/assets/icons/date-blue.svg" alt="Fecha del ultimo back up" />
                    <span>{{ backup.created_at | moment("DD MMM YYYY") }}</span>
                </div>
                <div class="content-info">
                    <img class="mr-2" src="@/assets/icons/clock-blue.svg" alt="Hora del ultimo back up" />
                    <span>{{ backup.created_at | moment("HH:mm A") }}</span>
                </div>
                <button class="btn btn-blue" @click="createBackUp">Generar respaldo manual</button>
            </div>
            <div class="setting-card mt-5">
                <h1 class="title-blue">CAMBIAR CONTRASEÑA</h1>
                <p class="sub-title-pass">Al elegir esta opción serás desconectado automáticamente
                    para continuar con el proceso mediante el mensaje que te enviaremos al correo
                    electrónico asociado a la cuenta.
                </p>
                <button class="btn btn-blue" @click="changePassword">Cambiar contraseña</button>
            </div>
        </div>
    </div>
</template>

<script>
import securityProxy from "@/proxies/SecurityProxy.js";
import { AUTH_LOGOUT } from "@/store/actions/auth";
import LoginProxy from "@/proxies/LoginProxy.js";
import { mapGetters } from "vuex";

export default {
    name: "SettingsAdmin",
    data() {
        return {
            backup: {}
        }
    },
    computed: {
        ...mapGetters(["getProfile"]),
    },
    methods: {
        async createBackUp() {
            let loader = this.$loading.show({
                canCancel: false,
                color: "#007bff",
            });
            try {
                await securityProxy.generateBackUp();
            } catch (error) {
                console.log("ERROR on creating last backup");
                console.log(error);
            } finally {
                this.getLastBackUp();
                loader.hide();
            }
        },
        async getLastBackUp() {
            let loader = this.$loading.show({
                canCancel: false,
                color: "#007bff",
            });
            try {
                const {
                    backup
                } = await securityProxy.getLastBackUp();
                this.backup = backup;
            } catch (error) {
                console.log("ERROR on getting last backup");
                console.log(error);
            } finally {
                loader.hide();
            }
        },
        async changePassword() {
            let loader = this.$loading.show({
                canCancel: false,
                color: "#007bff",
            });
            try {
                const { email } = this.getProfile;
                await LoginProxy.recover({
                    email,
                }).then(() => {
                    this.logout();
                })
            } catch (error) {
                console.log("ERROR on change password");
                console.log(error);
            } finally {
                loader.hide();
            }
        },
        logout() {
            this.$store.dispatch(AUTH_LOGOUT).then(() => {
                this.$router.push({ name: "AdminLogin" });
            });
        },
    },
    mounted() {
        this.getLastBackUp();
    },
}
</script>

<style lang="scss" scoped>
$color-light-blue: #1864FF;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #fff !important;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;
$color-gray-main: #486169;

.container{
    margin-top: 20px;
}

.main-settings {
    background: $color-white-gray;
    min-height: calc(100vh - 52px);
    padding-top: 90px;
    padding-bottom: 100px;
    //position: fixed;
    width: 100%;

    .setting-card {
        max-width: 500px;
        margin: auto;
        padding: 15px;
        box-shadow: 0px 9px 36px rgba(0, 0, 0, 0.14),
            0px 2.71324px 10.8529px rgba(0, 0, 0, 0.0134174),
            0px 0.407592px 1.63037px rgba(0, 0, 0, 0.0106725);
        border-radius: 8px;

        .title-blue {
            font-size: 16px;
            color: $color-light-blue;
            margin-bottom: 30px;
            font-family: Inter-Regular;
            font-weight: 600;
        }

        .sub-title {
            font-size: 15px;
            color: $color-dark;
            text-align: center;
            font-family: Inter-Regular;
            font-weight: 600;
            margin-bottom: 15px
        }

        .sub-title-pass {
            font-size: 15px;
            color: $color-dark;
            font-family: Inter-Regular;
            font-weight: 400;
            margin-bottom: 15px
        }

        .content-info {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 15px;

            span {
                color: $color-dark;
                font-family: Inter-Regular;
                font-size: 15px;
                font-weight: 400;
            }
        }

        .btn-blue {
            background: $color-light-blue;
            padding: 12px;
            width: 100%;
            border-radius: 36px;
            color: $color-white;
        }
    }
}

.fade-in {
    animation: fadeIn 0.5s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
</style>