<template>
  <div class="main-results">
    <div class="container">
      <div class="content-card">
        <div class="search-card">
          <div class="main-tab">
            <div class="content-tab">
              <router-link class="content-tab-icon" :class="{ active: selected === 'ResultsJuristiction' }"
                :to="{ name: 'ResultsJuristiction' }">
                <router-link :to="{ name: 'ResultsJuristiction' }">Jurisprudencia</router-link>
              </router-link>
              <router-link class="content-tab-icon" :class="{ active: selected === 'ResultsLegislation' }"
                :to="{ name: 'ResultsLegislation' }">
                <router-link :to="{ name: 'ResultsLegislation' }">Legislación</router-link>
              </router-link>
            </div>
          </div>
          <div class="main-buttons pb-2">
            <b-form class="d-flex" @submit.prevent="searchDocuments">
              <b-form-input v-model="title" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
            </b-form>
          </div>
          <div class="main-buttons">
            <b-form class="d-flex align-items-center" @submit.prevent="searchDocuments">
              <div class="order">
                <label class="order__label">Orden</label>
                <b-form-checkbox v-model="order" name="check-button" switch></b-form-checkbox>
                <p v-if="order">Actuales</p>
                <p v-else>Antiguos</p>
              </div>
              <div class="limit">
                <label class="order__label">Cantidad de resultados</label>
                <multiselect v-model="perPage" :options="perPageOptions" :searchable="false" :close-on-select="true"
                  :show-labels="false" placeholder="Cantidad de resultados"></multiselect>
              </div>

              <!--button class="btn btn-white ml-4" @click="isFiltersOpen = !isFiltersOpen">
                Filtros avanzados
                <img src="@/assets/icons/filter-blue.svg" class="ml-2" />
              </button-->
              <button class="btn btn-blue">
                Buscar documentos<img src="@/assets/icons/search-white.svg" class="ml-2" />
              </button>

            </b-form>
          </div>
          <div class="main-filters" v-show="isFiltersOpen">
            Los filtros seleccionados
          </div>
        </div>
      </div>
    </div>

    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import filterProxy from "@/proxies/FilterProxy.js";
export default {
  name: "ResultsSubscriber",
  data() {
    return {
      isFiltersOpen: false,
      order: true,
      title: "",
      perPage: 5,
      perPageOptions: [1, 3, 5, 10, 15, 20, 25, 30, 35, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100],
    };
  },
  watch: {
    title(newValue) {
      this.onTitleChange(newValue);
    },
    order(newValue) {
      this.onOrderChange(newValue);
    },
    perPage(newValue) {
      this.onResultsQtyChange(newValue);
    },
    async selected() {
      const resetFilterProps = { title: "null", filters: [], year: null, range: [], currentPage: 1, perPage: 5, order: "DESC" };
      this.title = "";
      this.order = true;
      this.perPage = 5;
      await this.$store.dispatch("setFilterList", resetFilterProps);
    }
  },
  methods: {
    async onTitleChange(value) {
      const filterVuex = this.$store.state.filter;
      const { filter } = filterVuex;
      const filters = { ...filter, ...{ title: value, currentPage: 1 } };
      await this.$store.dispatch("setFilterList", filters);
    },
    async onOrderChange(value) {
      const filterVuex = this.$store.state.filter;
      const { filter } = filterVuex;
      const newOrder = value ? "DESC" : "ASC";
      const filters = { ...filter, ...{ order: newOrder, currentPage: 1 } };
      await this.$store.dispatch("setFilterList", filters);
    },
    async onResultsQtyChange(value) {
      const filterVuex = this.$store.state.filter;
      const { filter } = filterVuex;
      const filters = { ...filter, ...{ perPage: value, currentPage: 1 } };
      await this.$store.dispatch("setFilterList", filters);
    },
    async searchDocuments() {
      let loader = this.$loading.show({
        canCancel: false,
        color: "#007bff",
      });
      const filterVuex = this.$store.state.filter;
      const { filter } = filterVuex;
      let data = [];
      let dispatcher = "";
      if (this.selected === "ResultsJuristiction") {
        dispatcher = "setJurisprudenceSearchResult";
        const { jurisprudences } = await filterProxy.jurisprudenceSearch(filter);
        data = jurisprudences
      }
      if (this.selected === "ResultsLegislation") {
        dispatcher = "setLegislationSearchResult";
        const { legislations } = await filterProxy.legislationSearch(filter);
        data = legislations
      }
      if (this.selected === "ResultsGeneral") {
        const { jurisprudences } = await filterProxy.jurisprudenceSearch(filter);
        await this.$store.dispatch("setJurisprudenceSearchResult", jurisprudences);

        const { legislations } = await filterProxy.legislationSearch(filter);
        await this.$store.dispatch("setLegislationSearchResult", legislations);
      }
      if (this.selected !== "ResultsGeneral") {
        await this.$store.dispatch(dispatcher, data);
      }
      loader.hide();
      if (this.$route.name !== this.selected) {
        this.$router.push({ name: this.selected });
      }
    },
  },
  computed: {
    selected() {
      return this.$route.name;
    },
  },
  mounted() {
    const filterVuex = this.$store.state.filter;
    const { filter } = filterVuex;
    this.title = filter.title;
    this.perPage = filter.perPage;
    this.order = filter.order === "DESC";
  },
};
</script>

<style lang="scss" scoped>
$color-light-blue: #0673f2;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #dde3eb;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;
$color-gray-main: #486169;

.main-results {
  background: $color-white-gray;
  min-height: calc(100vh - 52px);
  padding-top: 90px;
  padding-bottom: 100px;
  //position: fixed;
  width: 100%;

  .content-card {
    background: $color-white;
    margin-bottom: 20px;

    .search-card {
      padding: 12px;
      box-shadow: 0px 9px 36px rgba(0, 0, 0, 0.14),
        0px 2.71324px 10.8529px rgba(0, 0, 0, 0.0134174),
        0px 0.407592px 1.63037px rgba(0, 0, 0, 0.0106725);
      border-radius: 8px;
    }

    .main-tab {
      background: $color-white-gray;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      border-radius: 50px;

      .content-tab {
        width: 100%;
        display: flex;

        .content-tab-icon {
          padding: 10px 50px;
          width: 100%;
          text-align: center;

          a {
            color: black;
          }
        }

        :hover {
          background: lightblue;
          cursor: pointer;
          border-radius: 50px;
          text-decoration: none;
        }

        .active {
          background: white;
          border: 1px solid $color-light-blue;
          border-radius: 50px;
          font-weight: bold;

          :hover {
            background: white;
          }

          a {
            color: $color-light-blue;
          }
        }
      }
    }

    .main-buttons {
      .form-control {
        height: 44px;
      }

      .order,
      .limit {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 100px;
        height: 100px;
      }

      .btn-white {
        max-width: 250px;
        width: 100%;
        background: $color-white;
        color: $color-dark;
        font-size: 15px;
        border-radius: 36px !important;
        height: 44px;
        padding: 10px 16px;
        font-family: Inter-Regular;
        display: flex;
        align-items: center;
        border: 1px solid $color-light-gray;
        justify-content: center;
        margin-right: 5px;
      }

      .btn-blue {
        max-width: 250px;
        width: 100%;
        background: $color-light-blue;
        color: $color-white !important;
        font-size: 15px;
        border-radius: 36px;
        height: 44px;
        padding: 10px 16px;
        font-family: Inter-Regular;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
      }
    }
  }
}
</style>