<template>
  <div>
    <entries-table :role="role"  :entrieType="entrieType" />
  </div>
</template>

<script>
import EntriesTable from "@/components/admin/entries/EntriesTable.vue";

export default {
  name: "Legislation",
  props: {
    "entrieType": {
      type: String,
      default: "jurisdiction"
    },
    "role": {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  components: {
    EntriesTable
  },
};
</script>

<style></style>