<template>
  <div class="fade-in">
    <div class="content-title">
      <h1 class="title">Documentos de mi interés</h1>
      <p class="subtitle">
        Encuentra los documentos que te interesan y organízalos en tus
        directorios para tenerlos siempre a la mano.
      </p>
    </div>
    <div class="row my-4 mx-4">
      <div class="col-12 col-md-3">
        <div class="row">
          <div class="col-12">
            <b-form-input style="background-color: #fbfbfb;" v-model="filtro.TITLE"
              placeholder="Busca por nombre real o alternativo"></b-form-input>
          </div>

          <div class="col-12 col-md-6">
            <b-form-select v-model="filtro.CARPETA" style="background-color: #fbfbfb;">
              <option value="E">Entradas</option>
              <option value="C">Carpetas</option>
            </b-form-select>
          </div>

          <div class="col-12 col-md-6">
            <b-form-select v-model="model.TYPE" style="background-color: #fbfbfb;">
              <option value="jurisprudences">Jurisprudencia</option>
              <option value="legislations">Legislación</option>
            </b-form-select>
          </div>
        </div>

        <div class="d-flex justify-content-end  mt-3 " style="gap: 15px">
          <button class="rounded-full btn btn-primary" @click="filtro.CARPETA == 'C' ? search() : searchFavorites()">
            Buscar Documentos
            <img src="@/assets/icons/search-white.svg" class="ml-2" />
          </button>
        </div>
      </div>

      <div class="col-12 col-md-9">
        <div v-if="data.length > 0 && filtro.CARPETA == 'E'">
          <div class="d-flex justify-content-center align-items-center md-mt-3 mt-5">
            <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage"
              @change="searchFavorites" size="sm"></b-pagination>
          </div>
          <p class="text-left mt-3 color-blue font-weight-bold">
            Se está mostrando {{ table.perPage }} registros, de {{ (table.currentPage - 1) * table.perPage + 1 }} - {{
              table.currentPage * table.perPage }} de {{ table.totalRows }} registros en total.
          </p>
          <div class="card mt-3" v-for="(item, index) in data" :key="index">
            <div class="card-header bg-blue text-left">{{ item.TITULO }}</div>
            <div class="card-body">
              <div class="row p-1">
                <div class="col-12 col-md-4 p-2">
                  <p style="font-size: 13px; margin-bottom: 5px;" class="font-weight-bold text-left">Pretensión/Delito:
                  </p>
                  <div>
                    <span v-for="(delito, index) in item.DELITO" style="font-size: 11px;" :key="index"
                      class="d-block text-left">{{ delito.DESCP }}
                    </span>
                  </div>
                </div>
                <div class="col-12 col-md-4 p-2">
                  <p style="font-size: 13px; margin-bottom: 5px;" class="font-weight-bold text-left">Fecha Resolución:
                  </p>
                  <div>
                    <span style="font-size: 11px;" :key="index" class="d-block text-left">{{ item.FRESOLUTION }}
                    </span>
                  </div>
                </div>
                <div class="col-12 col-md-4 p-2">
                  <p style="font-size: 13px; margin-bottom: 5px;" class="font-weight-bold text-left">Órgano
                    jurisdiccional:
                  </p>
                  <div>
                    <span v-for="(organo, index) in item.OJURISDICCIONAL" style="font-size: 11px;" :key="index"
                      class="d-block text-left">{{ organo.DESCP }}
                    </span>
                  </div>
                </div>
                <div class="col-12 p-2">
                  <p style="font-size: 13px; margin-bottom: 5px;" class="font-weight-bold text-left">Tema:
                  </p>
                  <div>
                    <span style="font-size: 11px;" :key="index" class="d-block text-left">{{ texto_mostrado(item.TEMA)
                      }}
                    </span>
                  </div>
                </div>
                <div class="col-12 p-2">
                  <p style="font-size: 13px; margin-bottom: 5px;" class="font-weight-bold text-left">Palabras clave:
                  </p>
                  <div>
                    <span style="font-size: 11px;" :key="index" class="d-block text-left">{{ item.KEYWORDS }}
                    </span>
                  </div>
                </div>
                <div class="col-12 p-2">
                  <p style="font-size: 13px; margin-bottom: 5px;" class="font-weight-bold text-left">Título alternativo:
                  </p>

                  <div class="col-12 p-0">
                    <div class="input-group">
                      <div class="form-floating w-75">
                        <input type="text" class="form-control" id="TITLEALT"
                          placeholder="Escribe el título alternativo" :value="item.TITLEALT"
                          @input="item.TITLEALT = $event.target.value" />
                      </div>
                      <span class="input-group-text" style="background-color: #fbfbfb;cursor: pointer;"
                        @click="saveTitleEntrie(item.ID, item.IDFAV)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-floppy" viewBox="0 0 16 16">
                          <path d="M11 2H9v3h2z" />
                          <path
                            d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z" />
                        </svg>
                      </span>
                    </div>
                  </div>

                </div>
                <!-- // un select de directorios -->
                <div class="col-12 p-2">
                  <p style="font-size: 13px; margin-bottom: 5px;" class="font-weight-bold text-left">Directorio:
                  </p>
                  <div class="col-12 p-0">
                    <div class="input-group">
                      <div class="form-floating w-75">
                        <b-form-select v-model="item.IDDIRECTORIO" @change="handleChange($event, item)"
                          style="background-color: #fbfbfb;">
                          <option v-for="directorio in directorios" :value="directorio.ID" :key="directorio.ID">
                            {{ directorio.DSCRPCN }}
                          </option>
                        </b-form-select>
                      </div>
                      <span class="input-group-text" style="background-color: #fbfbfb;cursor: pointer;"
                        @click="saveDirectorio(item)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-floppy" viewBox="0 0 16 16">
                          <path d="M11 2H9v3h2z" />
                          <path
                            d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z" />
                        </svg>
                      </span>
                    </div>
                  </div>

                </div>
                <div class="col-12 p-2 container-buttons">
                  <div style="display: flex; justify-content: start;gap:1rem">
                    <button title="Previsualizar" class="btn btn-danger" @click="print(item.ENTRIEFILE)">
                      <b-icon icon="file-earmark-pdf" />

                    </button>

                    <button title="Descargar Documento" class="btn btn-primary" @click="download(item)">
                      <b-icon icon="download" class="mr-1" />
                      <b-icon icon="file-earmark-pdf" />

                    </button>

                    <div v-if="item.TYPE == 'jurisprudences'">
                      <button title="Descargar Resumen Ejecutivo" class="btn btn-danger text-white"
                        @click="createPDF(item)">
                        <b-icon icon="download" class="mr-1" />
                        <b-icon icon="pencil-square" />
                      </button>
                    </div>

                    <div>
                      <button title="Eliminar de mis favoritos" class="btn btn-danger text-white"
                        @click="deleteFavorite(item.ID, true)">
                        <b-icon icon="trash" class="mr-1" />
                      </button>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <v-container style="margin: 0px 0!important; padding: 0!important;max-width: 100%;"
          v-if="filtro.CARPETA == 'C'">
          <v-card>
            <v-card-title style="display: flex; justify-content: space-between;">
              <div>
                <span>Tu Directorio</span>
              </div>
              <div>
                <b-button @click="openModal = true" style="color: white;" variant="success">
                  <b-icon icon="plus" variant="white" />
                  Agregar
                </b-button>
              </div>
            </v-card-title>

            <v-layout  v-if="data.length > 0 && filtro.CARPETA == 'C'" class="row p-2" style="padding-bottom: 0!important;">
              <v-flex class="col-12 col-md-3">
                <v-treeview v-model="selected" @update:active="getEntries" activatable :open="open" :items="data"
                  item-key="name" open-on-click>
                  <template v-slot:prepend="{ item, open }">
                    <b-icon :icon="item?.isDirectory ? 'folder' : 'check'"
                      :style="{ color: '#d1b106', fontSize: '2.5rem' }" />
                  </template>
                  <template v-slot:label="{ item }">
                    <div class="tree-item-label tree-item-label-expanded">
                      {{ item?.name || '' }}
                    </div>
                  </template>
                </v-treeview>
              </v-flex>


              <v-flex class="col-12 col-md-9 p-0">
                <v-scroll-y-transition mode="out-in">
                  <div class="card mt-3" v-if="selected" :key="selected.ID">
                    <div class="card-header bg-blue text-left">{{ selected.TITULO }}</div>
                    <div class="card-body">
                      <div class="row p-1">
                        <div class="col-12">
                          <p style="font-size: 13px; margin-bottom: 5px;" class="font-weight-bold text-left">
                            Pretensión/Delito:
                          </p>
                          <div>
                            <span v-for="(delito, index) in selected.DELITO" style="font-size: 11px;" :key="index"
                              class="d-block text-left">{{ selected.DESCP }}
                            </span>
                          </div>
                        </div>
                        <div class="col-12">
                          <p style="font-size: 13px; margin-bottom: 5px;" class="font-weight-bold text-left">Fecha
                            Resolución:
                          </p>
                          <div>
                            <span style="font-size: 11px;" class="d-block text-left">{{ selected.FRESOLUTION }}
                            </span>
                          </div>
                        </div>
                        <div class="col-12">
                          <p style="font-size: 13px; margin-bottom: 5px;" class="font-weight-bold text-left">Órgano
                            jurisdiccional:
                          </p>
                          <div>
                            <span v-for="(organo, index) in selected.OJURISDICCIONAL" style="font-size: 11px;"
                              :key="index" class="d-block text-left">{{ organo.DESCP }}
                            </span>
                          </div>
                        </div>
                        <div class="col-12">
                          <p style="font-size: 13px; margin-bottom: 5px;" class="font-weight-bold text-left">Tema:
                          </p>
                          <div>
                            <span style="font-size: 11px;" class="d-block text-left">{{
                              texto_mostrado(selected.TEMA)
                            }}
                            </span>
                          </div>
                        </div>
                        <div class="col-12">
                          <p style="font-size: 13px; margin-bottom: 5px;" class="font-weight-bold text-left">Palabras
                            clave:
                          </p>
                          <div>
                            <span style="font-size: 11px;" class="d-block text-left">{{ selected.KEYWORDS }}
                            </span>
                          </div>
                        </div>
                        <div class="col-12">
                          <p style="font-size: 13px; margin-bottom: 5px;" class="font-weight-bold text-left">Título
                            alternativo:
                          </p>
                          <div>
                            <span style="font-size: 11px;" class="d-block text-left">{{ selected.TITLEALT }}</span>
                          </div>
                        </div>
                        <div class="col-12 p-2 container-buttons">
                          <div style="display: flex; justify-content: start;gap:1rem">
                            <button title="Previsualizar" class="btn btn-danger" @click="print(selected.ENTRIEFILE)">
                              <b-icon icon="file-earmark-pdf" />
                            </button>

                            <button title="Descargar Documento" class="btn btn-primary" @click="download(selected)">
                              <b-icon icon="download" class="mr-1" />
                              <b-icon icon="file-earmark-pdf" />

                            </button>

                            <div v-if="selected.TYPE == 'jurisprudences'">
                              <button title="Descargar Resumen Ejecutivo" class="btn btn-danger text-white"
                                @click="createPDF(selected)">
                                <b-icon icon="download" class="mr-1" />
                                <b-icon icon="pencil-square" />
                              </button>
                            </div>

                            <div>
                              <button title="Eliminar de mis favoritos" class="btn btn-danger text-white"
                                @click="deleteFavorite(selected.ID, true)">
                                <b-icon icon="trash" class="mr-1" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-scroll-y-transition>
              </v-flex>
            </v-layout>
          </v-card>
        </v-container>

        <div v-if="data.length === 0" class="sin_resultados border rounded-lg">
          <img src="@/assets/icons/no-results.png" alt="No se encontraron resultados" width="50" height="50"
            class="w-1/4 mx-auto" />
          <p class="text-center text-gray-500 font-bold text-2xl mt-1">No se encontraron resultados</p>
          <span class="text-center text-gray-500 font-bold text-lg">Intenta con otra búsqueda</span>
        </div>
      </div>

    </div>

    <div v-if="isLoading" class="spinner-overlay">
      <b-spinner label="Cargando..." variant="primary"></b-spinner>
    </div>

    <ModalAgregarDirectorio :openModal="openModal" :toggleModal="toggleModal" :search="search" />
  </div>
</template>

<script>
import AdminEntriesProxy from '../../../proxies/AdminEntriesProxy';
import he from 'he';
import UserProxy from '../../../proxies/UserProxy';
import ModalAgregarDirectorio from './ModalAgregarDirectorio';
import recursos from "../../../components/admin/entries/recursos";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

export default {
  name: "Documents",
  components: {
    ModalAgregarDirectorio
  },
  data() {
    return {
      openModal: false,
      isLoading: false,
      routerActive: "DocumentsJuristiction",
      table: {
        currentPage: 1,
        perPage: 10,
        totalRows: 0
      },
      data: [],
      active: [],
      items: [],
      selected: null,
      directorios: [],
      filtro: {
        TITLE: "",
        TYPE: "jurisprudences",
        CARPETA: "C"
      },
      model: {
        GLOBAL: "",
        INDICATOR: 1,
        TYPE: "jurisprudences",
      },
    };
  },
  methods: {
    toggleModal() {
      this.openModal = !this.openModal;
    },
    async search() {
      this.isLoading = true;
      this.selected = null;
      await UserProxy.listDirectory({
        TYPE: this.model.TYPE,
        DSCRPCN: this.filtro.TITLE,
      })
        .then((response) => {
          this.data = response?.map((item) => {
            let childrens = JSON.parse(item.ENTRADAS) || [];
            return {
              id: item.id,
              name: item.DSCRPCN + (childrens.length > 0 ? ` (${childrens.length})` : " (0)"),
              isDirectory: true,
              children: childrens.map((entrie) => {
                return {
                  id: entrie.ID,
                  name: entrie.TITULO,
                }
              })
            };
          });

        })
        .catch((error) => {
          this.$bvToast.toast(`Ocurrió un error al obtener los documentos`, {
            title: "Error",
            variant: "danger",
            solid: true,
          });
          this.data = [];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // listDirectory() {
    //   this.isLoading = true;
    //   UserProxy.listDirectory({
    //     TYPE: this.filtro.TYPE,
    //     DSCRPCN: this.filtro.TITLE,
    //   })
    //     .then((response) => {
    //       this.directorios = response;
    //     })
    //     .catch((error) => {
    //       this.$bvToast.toast(`Ocurrió un error al obtener los documentos`, {
    //         title: "Error",
    //         variant: "danger",
    //         solid: true,
    //       });
    //       this.data = [];
    //     })
    //     .finally(() => {
    //       this.isLoading = false;
    //     });
    // },
    async searchFavorites(id = null) {
      this.isLoading = true;
      await AdminEntriesProxy.searchFavorites({
        GLOBAL: this.filtro.TITLE,
        TYPE: this.model.TYPE,
        ROWS: this.filtro?.ROWS || 10,
        INIT: this.filtro?.INIT || 0
      })
        .then((response) => {
          this.data = response?.map((item) => {
            return {
              ...item,
              IDDIRECTORIO: item.IDDIRECTORIO,
              DELITO: JSON.parse(item.DELITO),
              OJURISDICCIONAL: JSON.parse(item.OJURISDICCIONAL),
              FRESOLUTION: item.FRESOLUTION ? item.FRESOLUTION.split("T")[0] : null,
              TEMA: item.TEMA ? item.TEMA.replace(/<[^>]*>?/gm, '') : ''
            };
          });

          this.table.totalRows = response?.[0]?.TOTALROWS || 0;
          this.table.perPage = response?.length || 0;
        })
        .catch((error) => {
          console.log(error)
          this.$bvToast.toast(`Ocurrió un error al obtener los documentos`, {
            title: "Error",
            variant: "danger",
            solid: true,
          });
          this.data = [];
        })
        .finally(() => {
          this.isLoading = false;
        });

      await this.getDirectorios();

    },
    async getDirectorios() {
      await UserProxy.listDirectoryAll()
        .then((response) => {
          this.directorios = response ? response : [];
        })
        .catch((error) => {
          this.$bvToast.toast(`Ocurrió un error al obtener los documentos`, {
            title: "Error",
            variant: "danger",
            solid: true,
          });
          this.directorios = [];
        })
        .finally(() => {
          this.directorios.unshift({ ID: 0, DSCRPCN: "Sin directorio" });
          this.isLoading = false;
        });
    },
    async saveDirectorio(item) {
      this.isLoading = true;
      await UserProxy.saveAddDirectory({
        IDDIRECTORIO: item.IDDIRECTORIO,
        IDENTRIE: item.ID
      })
        .then((response) => {
          const toastMessage = response.MESSAGE;

          this.$bvToast.toast(toastMessage, {
            title: response.STATUS ? "Exito" : "Error",
            variant: response.STATUS ? "primary" : "danger",
            solid: true,
          });

          this.searchFavorites();
        })
        .catch((error) => {
          this.$bvToast.toast(`Ocurrió un error al guardar el directorio`, {
            title: "Error",
            variant: "danger",
            solid: true,
          });
          this.isLoading = false;
        })
    },
    handleChange(value, item) {
      item.IDDIRECTORIO = value;
    },
    async getEntries(sel) {
      if (!sel) return this.selected = null;
      sel = sel?.[0] || null;
      let id = null;

      for (let i = 0; i < this.data.length; i++) {
        const item = this.data[i];
        if (item.children) {
          for (let j = 0; j < item.children.length; j++) {
            const child = item.children[j];
            if (child.name === sel) {
              id = child.id;
              break;
            }
          }
        }
      }

      if (!id) return this.selected = null;
      await AdminEntriesProxy.searchFavoritesEntrie({
        GLOBAL: null,
        TYPE: null,
        ROWS: 1,
        INIT: 0,
        ID: id
      })
        .then((response) => {
          if (response) {
            this.selected = {
              ...response[0],
              DELITO: JSON.parse(response[0].DELITO),
              OJURISDICCIONAL: JSON.parse(response[0].OJURISDICCIONAL),
              FRESOLUTION: response[0].FRESOLUTION ? response[0].FRESOLUTION.split("T")[0] : null,
              TEMA: response?.[0].TEMA ? response[0].TEMA.replace(/<[^>]*>?/gm, '') : ''
            };
          } else {
            this.selected = null;
          }

        })
        .catch((error) => {
          this.$bvToast.toast(`Ocurrió un error al obtener los documentos`, {
            title: "Error",
            variant: "danger",
            solid: true,
          });
          this.data = [];
        })
        .finally(() => {
          this.isLoading = false;
        });


    },
    async saveTitleEntrie(ID, IDFAV) {
      this.isLoading = true;
      await AdminEntriesProxy.saveTitleEntrie({
        ID: IDFAV,
        TITLEALT: this.data.find(item => item.ID === ID).TITLEALT
      })
        .then((response) => {
          const toastMessage = response.MESSAGE;

          this.$bvToast.toast(toastMessage, {
            title: response.STATUS ? "Exito" : "Error",
            variant: response.STATUS ? "primary" : "danger",
            solid: true,
          });

          this.searchFavorites();
        })
        .catch((error) => {
          this.$bvToast.toast(`Ocurrió un error al guardar el título alternativo`, {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => this.isLoading = false);
    },
    texto_mostrado(texto) {
      return he.decode(texto);
    },
    async download(item) {
      this.isLoading = true;
      let path = item.ENTRIEFILE;
      await AdminEntriesProxy.downloadFile({
        PATH: path
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          const extension = path.split('.').pop();
          link.href = url;
          link.setAttribute('download', (item?.TITULO || "") + "-" + " ENTRADA PRINCIPAL" + '.' + extension);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$bvToast.toast(`Ocurrió un error al descargar el archivo`, {
            title: "Ocurrió un error al descargar el archivo",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => this.isLoading = false);
    },
    async print(path) {
      this.isLoading = true;
      await AdminEntriesProxy.downloadFile({
        PATH: path
      })
        .then(async (response) => {
          const url = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = url;
          document.body.appendChild(iframe);
          iframe.onload = () => {
            iframe.contentWindow.print();
          };

        })
        .catch((error) => {
          this.$bvToast.toast(`Ocurrió un error al descargar el archivo`, {
            title: "Ocurrió un error al descargar el archivo",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => this.isLoading = false);
    },
    async deleteFavorite(IDENTRIE, bandera = false) {
      this.isLoading = true;
      await UserProxy.addFavorite(
        IDENTRIE
      )
        .then((response) => {
          const toastMessage = response.MESSAGE;

          this.$bvToast.toast(toastMessage, {
            title: response.STATUS ? "Exito" : "Error",
            variant: response.STATUS ? "primary" : "danger",
            solid: true,
          });

          if (bandera) this.search();
          else this.searchFavorites();
        })
        .catch((error) => {
          this.$bvToast.toast(`Ocurrió un error al agregar a favoritos`, {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => this.isLoading = false);
    },
    async createPDF(entrie, tipo) {
      this.isLoading = true;
      let data = await this.obtenerDatos(entrie.ID);
      try {
        let margin = [40, 10, 40, 10];
        let totalPages = 0;
        let fontSize = 11;
        let documentoPDF = {

          header: (currentPage, pageCount) => {
            return {
              style: 'headerStyle',
              columns: [
                { width: '*', text: '', alignment: 'center', margin: [40, 40, 40, 40] },
                {
                  width: 'auto',
                  stack: [
                    {
                      image: recursos.nuevoLogoJuris,
                      width: 60,
                      link: 'http://web-juris-search-caro.s3-website-us-east-1.amazonaws.com/',
                      alignment: 'center',
                      margin: [0, 20, 0, 0]
                    },
                  ],
                },
                { width: '*', text: '', alignment: 'center', margin: [40, 40, 40, 40] },
              ],
            }
          },

          background: [
            {
              image: recursos.toIMG,
              width: 620,
              height: 600,
              absolutePosition: { x: 5, y: 150 },
              alignment: 'center',
              opacity: 0.5,
            }
          ],

          content: [
            {
              text: `${data.TITLE}`,
              style: 'header',
              alignment: 'left',
              margin: [40, -20, 40, 10],
              bold: true,
              FontFace: 'Calibri',
            },
            {
              columns: [
                {
                  ul: [
                    `Tipo de Recurso:`,
                    `Delitos:`,
                    `Vinculante:`,
                  ],
                  margin: [margin[0], 0, 0, 0],
                  fontSize,
                  lineHeight: 1.5,
                  width: '35%',
                  FontFace: 'Calibri',
                },
                {
                  ul: [
                    `${data.RECURSO}`,
                    `${data.DELITO}`,
                    `${data.ISBINDING}`,
                  ],
                  margin: [0, 5, margin[0] + 20, 0],
                  fontSize,
                  lineHeight: 1.5,
                  width: '65%',
                  FontFace: 'Calibri',
                },
              ]
            },

            {
              style: 'tableExample',
              table: {
                dontBreakRows: false,
                widths: ['35%', '65%'],
                body: [
                  [
                    {
                      text: 'CONTENIDO',
                      bold: true,
                      colSpan: 2,
                      fontSize,
                      alignment: 'center',
                      margin: [20, 15, 20, 15]
                    },
                    {},
                  ],
                  [
                    {
                      text: 'TEMA',
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    this.renderContent(data.TEMA, fontSize, [10, 15, 10, 15]),
                  ],
                  [
                    {
                      text: 'SUBTEMA',
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    this.renderContent(data.SUBTEMA, fontSize, [10, 15, 10, 15]),

                  ],
                  [
                    {
                      text: 'PALABRAS CLAVES',
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    {
                      text: data.KEYWORDS,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                  ],
                  [
                    {
                      text: 'SÍNTESIS DE LOS FUNDAMENTOS JURÍDICOS RELEVANTES',
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    this.renderContent(data.SHORTSUMMARY, fontSize, [10, 15, 10, 15]),
                  ],
                  [
                    {
                      text: 'FUNDAMENTOS JURÍDICOS RELEVANTES',
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                      fillColor: '#fff2cc',
                    },

                    {
                      ...this.renderContent(data.RESUMEN, fontSize, [10, 15, 10, 15]),
                      fillColor: '#fff2cc',
                      italics: true,

                    }
                  ],
                  [
                    {
                      text: 'IDENTIFICACIÓN',
                      bold: true,
                      fontSize,
                      colSpan: 2,
                      alignment: 'center',
                      margin: [10, 15, 10, 15],
                    },
                    {},
                  ],
                  [
                    {
                      text: 'ÁMBITO',
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    {
                      text: data.AMBIT,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                  ],
                  [
                    {
                      text: 'FECHA DE RESOLUCIÓN',
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    {
                      text: data.FRESOLUTION,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                  ],
                  [
                    {
                      text: 'ÓRGANO JURISDICCIONAL',
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    {
                      text: data.OJURISDICCIONAL,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                  ],
                  [
                    {
                      text: 'MAGISTRADOS',
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    {
                      text: data.MAGISTRATES,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                  ],
                  [
                    {
                      text: ['VOTO DEL DESIDENTE\n', {
                        text: 'Voto que discrepa del fallo final adoptado.', fontSize: fontSize - 2, bold: false, italics: true
                      }],
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    {
                      text: data?.VDESIDENTE || '-',
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                  ],
                  [
                    {
                      text: ['VOTO CONCURRENTE\n', {
                        text: 'Voto que disiente de la argumentación jurídica, pero no del fallo final adoptado.',
                        fontSize: fontSize - 1, bold: false,
                        italics: true
                      }],
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    {
                      text: data?.CVOTE || '-',
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                  ],
                ],
              },
            }

          ],
          styles: {
            FontFace: 'Calibri',
            headerStyle: {
              fontSize: 18,
              bold: true,
              margin: [0, 0, 0, 5],
            },
            tableExample: {
              margin: [margin[0], 10, margin[2], 10],
              FontFace: 'Calibri',
            },
            footer: {
              FontFace: 'Calibri',
              fontSize: 10,
              margin: [40, 50, 40, 10],
            },
          },
          footer: function (currentPage, pageCount) {
            if (currentPage > totalPages) {
              totalPages = currentPage;
            }
            return {
              style: 'footer',
              columns: [
                { width: '*', text: ``, alignment: 'left', color: 'transparent' },
                { width: 'auto', text: 'www.', alignment: 'center', color: 'gray' },
                { width: 'auto', text: 'ccfirma', alignment: 'center', color: '#e81eb2', link: 'https://ccfirma.com/' },
                { width: 'auto', text: '.com', alignment: 'center', color: 'gray' },
                { width: '*', text: `Página ${currentPage} de ${pageCount}`, alignment: 'right' }
              ],
            };
          },

          pageMargins: [40, 100, 40, 80],
        }

        pdfMake.vfs = await pdfFonts.pdfMake.vfs;

        await pdfMake.createPdf(documentoPDF).download((`${data.TITLE} - RESUMEN EJECUTIVO`).toUpperCase() + '.pdf');

      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false;
      }
    },
    async obtenerDatos(id) {
      let data = {}
      await AdminEntriesProxy.getPrint({
        ID: id
      })
        .then((response) => {
          let ambit = JSON.parse(response.AMBIT)?.[0]?.LABEL || '';
          ambit = ambit ? ambit.replace(/\s*,/g, ',') : '';
          let magistrados = JSON.parse(response.MAGISTRADOS)?.[0]?.LABEL || '';
          magistrados = magistrados ? magistrados.replace(/\s*,/g, ',') : '';
          let delitos = JSON.parse(response.DELITO)?.[0]?.LABEL || '';
          delitos = delitos ? delitos.replace(/\s*,/g, ',') : '';
          let ojurisdiccional = JSON.parse(response.OJURISDICCIONAL)?.[0]?.LABEL || '';
          ojurisdiccional = ojurisdiccional ? ojurisdiccional.replace(/\s*,/g, ',') : '';
          let recursos = JSON.parse(response.RECURSO)?.[0]?.LABEL || '';
          recursos = recursos ? recursos.replace(/\s*,/g, ',') : '';
          let materias = JSON.parse(response.MATERIA)?.[0]?.LABEL || '';
          materias = materias ? materias.replace(/\s*,/g, ',') : '';

          data = {
            ID: response.ID,
            TITLE: response.TITLE,
            ISBINDING: response.ISBINDING === "1" ? "SI" : "NO",
            AMBIT: ambit,
            FRESOLUTION: response.FRESOLUTION ? new Date(response.FRESOLUTION).toLocaleDateString("es-PE", {
              year: "numeric",
              month: "long",
              day: "numeric",
            }) : '',
            OJURISDICCIONAL: ojurisdiccional,
            MAGISTRATES: magistrados,
            VDESIDENTE: response.VDESIDENTE,
            CVOTE: response.CVOTE,
            ENTRIEFILE: response.ENTRIEFILE,
            ENTRIEFILERESUMEN: response.ENTRIEFILERESUMEN,
            KEYWORDS: response.KEYWORDS?.replace(/\s*,/g, ', '),
            TEMA: response.TEMA,
            SUBTEMA: response.SUBTEMA,
            SHORTSUMMARY: response.SHORTSUMMARY,
            RESUMEN: response.RESUMEN,
            NENTRIEFILERESUMEN: null,
            NENTRIEFILE: null,
            DELITO: delitos,
            RECURSO: recursos,
            MATERIA: materias,
          }

        })
        .catch((error) => {
          data = {}
          this.$bvToast.toast(`Ocurrió un error al obtener la entrada`, {
            title: "Ocurrió un error al obtener la entrada",
            variant: "danger",
            solid: true,
          });
        });

      return data;
    },
    renderContent(content, fontSize, margin) {
      let decodedContent = this.decodeHtmlEntities(content);

      if (Array.isArray(decodedContent)) {
        return {
          ul: decodedContent,
          fontSize,
          alignment: 'justify',
          margin
        };
      }

      return {
        text: decodedContent,
        fontSize,
        alignment: 'justify',
        margin
      };
    },
    decodeHtmlEntities(text) {
      if (text === null) return '';
      text = text.replace(/&[a-z]+;/g, '');
      try {

        if (text.includes('<ul>')) {
          let t = text.split('<li>').map((item) => {
            item = item.replace(/<\/?[^>]+(>|$)/g, '');
            return item;
          }).filter((item) => item.trim() !== '');

          return t;
        }

        return text.replace(/<[^>]*>?/gm, '');
      } catch (error) {
        return text.replace(/<[^>]*>?/gm, '');
      }

    },
  },
  watch: {
    "filtro.CARPETA": function (val) {
      this.data = [];
    },
  },
  mounted() {
    this.filtro.CARPETA = "E";
    this.model.TYPE = "jurisprudences";
  },
};
</script>

<style lang="scss" scoped>
$color-light-blue: #1864FF;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #fff !important;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;
$color-gray-main: #486169;

.tree-item-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.tree-item-label-expanded {
  white-space: normal;
}

.content-title {
  padding-top: 150px;
  ;

  .title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
    font-family: Inter-SemiBold;
    line-height: 39px;
    font-size: 32px;
    color: $color-dark;
  }

  .subtitle {
    font-size: 15px;
    margin-bottom: 40px;
    font-family: Inter-Regular;
    color: $color-dark;
    font-family: Inter-Regular;
    max-width: 600px;
    margin: auto;
    text-align: center;
  }

  @media (max-width: 768px) {
    .content-title {
      padding: 150px 10px;
    }

    .title {
      font-size: 24px;
      padding: 0 5px;
    }

    .subtitle {
      font-size: 13px;
      padding: 0 5px;
    }
  }
}

.container-resultados {
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  margin: 10px auto 20px auto;

  @media (max-width: 768px) {
    margin: 30px 10px;
  }
}

.content-card-general {

  .search-card {
    padding: 24px;
    box-shadow: 0px 9px 36px rgba(0, 0, 0, 0.14),
      0px 2.71324px 10.8529px rgba(0, 0, 0, 0.0134174),
      0px 0.407592px 1.63037px rgba(0, 0, 0, 0.0106725);
    border-radius: 8px;
  }

  .main-tab {
    background: $color-white-gray;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 50px;

    .content-tab {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 0px;

      .content-tab-icon {
        padding: 10px 50px;
        width: 100%;
        text-align: center;
        border: 1px solid rgb(223, 223, 223);
        border-radius: 50px;

        a {
          color: black;
        }
      }

      :hover {
        background: $color-light-blue;
        cursor: pointer;
        border-radius: 50px;
        text-decoration: none;
      }

      .active {
        background: white;
        border: 1px solid $color-light-blue;
        border-radius: 50px;
        font-weight: bold;

        :hover {
          background: white;
        }

        a {
          color: $color-light-blue;
        }
      }

      :hover:not(.active) {
        a {
          color: white;
        }
      }
    }

    @media (max-width: 768px) {
      .content-tab {
        flex-direction: column;
        gap: 10px;
      }
    }

  }
}


.fade-in {
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.content-filter {
  display: flex;
  margin-top: 20px;
  display: flex;
  align-items: end;
  justify-content: end;

  .btn-blue {
    background: $color-light-blue;
    color: $color-white !important;
    font-size: 15px;
    border-radius: 36px;
    height: 44px;
    padding: 10px 16px;
    font-family: Inter-Regular;
    margin-left: 5px;
  }



  @media (max-width: 768px) {

    .btn-blue {
      font-size: 10px;
      height: 40px;
      padding: 10px 16px;
    }

    justify-content: center;
  }
}

.content-input {
  .form-control {
    font-size: 15px;
    height: 68px;
    color: $color-dark;
    font-family: Inter-Regular;
    margin: auto;
    background: $color-white-gray;
  }
}

.sin_resultados {
  width: 80%;
  margin: 50px auto 0 auto;
  padding: 20px;
  border-radius: 0.375rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  color: #718096;
  font-size: 1.5rem;
  border: none;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  background-color: #f5f8ffff;
}


.sin_resultados p {
  margin: 0px;
}

.sin_resultados span {
  font-size: 1rem !important;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
}

@media (max-width: 768px) {
  .sin_resultados {
    width: 90% !important;
    margin: 20px auto 0 auto !important;
  }

  .sin_resultados p {
    font-size: 1.2rem !important;
  }

  .sin_resultados span {
    font-size: 1rem !important;
  }
}
</style>