<template>
  <div>
    <div class="main-login">
      <div class="row d-flex display-reverse justify-content-center grid-container">
        <!-- Carrusel -->
        <div v-if="!isLoadingRegister
        " class="col-12 col-md-8 px-0 grid-item-left">
          <carousel style="margin: auto 0;" :autoplay="true" :per-page="1" :autoplay-timeout="4000" :loop="true"
            :pagination-visibility="false" :mouse-drag="false" @page-change="updateBackgroundImage">
            <slide v-for="(seccion, index) in secciones" :key="index">
              <div class="carousel">
                <img :src="seccion.IMAGEN2" alt="login" class="background-image" />

                <div class="content-text">
                  <div class="title">
                    <h1>{{ seccion.TITULO }}</h1>
                  </div>
                  <div class="sub-title">
                    <p>{{ seccion.DESCRIPCION }}</p>
                  </div>
                  <div class="content-buttons">
                    <button class="btn button-white" @click="registerModal = true">
                      Quiero crear una cuenta
                      <img class="ml-2" src="@/assets/icons/plus-blue.svg" alt="plus-blue" />
                    </button>
                    <button class="btn button-transparent">
                      <router-link :to="{ name: 'About' }">Saber más del servicio</router-link>
                    </button>
                  </div>
                </div>
              </div>
            </slide>

            <template v-slot:pagination="{ pages }">
              <div class="VueCarousel-pagination"></div>
            </template>
          </carousel>
        </div>

        <div v-if="isLoadingRegister || secciones.length == 0" class="col-12 col-md-8 px-0 grid-item-left">
          <div class="carousel">
            <img src="../../assets/auth/banner-login.jpg" alt="login" class="background-image" />
            <div class="content-text">
              <div class="title">
                <h1>¡Bienvenido a Juris!</h1>
              </div>
              <div class="sub-title">
                <p>La plataforma que te permite gestionar tus procesos legales de manera fácil y rápida.</p>
              </div>
              <div class="content-buttons">
                <button class="btn button-white" @click="registerModal = true">
                  Quiero crear una cuenta
                  <img class="ml-2" src="@/assets/icons/plus-blue.svg" alt="plus-blue" />
                </button>
                <button class="btn button-transparent">
                  <router-link :to="{ name: 'About' }">Saber más del servicio</router-link>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="container form-container col-12 col-md-4">
          <div class="h-auto w-75 px-4 py-5"
            style="background-color: #fff; border-radius: 10px; box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);">
            <div class="content-img d-flex justify-content-center">
              <img src="@/assets/logo/logo.png" alt="juris-logo" width="60%" height="150" />
            </div>
            <b-form @submit.prevent="login">
              <b-form-group>
                <label for="email" class="label-login">Correo electrónico</label>
                <b-form-input id="email" v-model="model.EMAIL" placeholder="Escribe aquí tu correo" type="email"
                  required autocomplete="username"></b-form-input>
              </b-form-group>
              <b-form-group>
                <label for="password" class="label-login">Contraseña</label>
                <b-form-input id="password" v-model="model.PASSWORD" placeholder="Escribe aquí tu contraseña"
                  type="password" required autocomplete="current-password"></b-form-input>
              </b-form-group>
              <button class="btn-ingresar btn-block" type="submit" :disabled="isLoading">
                <b-spinner label="Spinning" small v-if="isLoading"></b-spinner>
                <span v-else>Ingresar al sistema</span>
              </button>
              <div>
                <button class="btn bnt-block forgot-pass" @click="toggleModalRecover">Olvidé mi
                  contraseña</button>
              </div>
            </b-form>
          </div>
        </div>
      </div>

      <register :openModal="registerModal" :toggleModal="toggleModal" :search="() => { }" />
      <recover :openModal="recoverModal" :toggleModal="toggleModalRecover" />
    </div>

    <div v-if="isLoadingRegister" class="spinner-overlay">
      <b-spinner label="Cargando..." variant="primary"></b-spinner>
    </div>
  </div>
</template>

<script>
import Register from "./Register.vue";
import Recover from "./Recover.vue";
import LoginProxy from "../../proxies/LoginProxy";
import { AUTH_REQUEST } from "@/store/actions/auth";


export default {
  name: "Login",
  components: { Register, Recover },
  data() {
    return {
      registerModal: false,
      isLoadingRegister: true,
      recoverModal: false,
      isLoadingRecover: false,
      isLoading: false,
      model: {
        EMAIL: null,
        PASSWORD: null,
        IND: null,
        BANDERA: false
      },
      secciones: [],
      currentSlideIndex: 0,
    };
  },
  methods: {
    async login() {
      let loader = this.$loading.show({
        canCancel: false,
        color: "#007bff",
      });

      this.$store
        .dispatch(AUTH_REQUEST, this.model)
        .then((response) => {
          this.$router.push({ name: "Redirect" });
        })
        .catch((err) => {
          if (err?.OPTION == 1) {
            this.$bvModal.msgBoxConfirm("¿Desea cerrar la sesión activa?", {
              title: "Sesión activa",
              size: "sm",
              buttonSize: "sm",
              okVariant: "danger",
              okTitle: "Sí",
              cancelTitle: "No",
              footerClass: "p-2",
              hideHeaderClose: false,
              centered: true,
            })
              .then((value) => {
                if(value) {
                  this.model.BANDERA = true;
                  this.login();
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }

          this.$bvToast.toast(err?.MESSAGE || "Error", {
            title: "Error al iniciar sesión",
            variant: "warning",
            solid: true,
          });
        })
        .finally(() => loader.hide());
    },
    toggleModalRecover(e = null) {
      if (e) {
        e.preventDefault();
      }
      this.recoverModal = !this.recoverModal;
    },
    closeRecoverModal() {
      this.recoverModal = false;
    },
    toggleModal() {
      this.registerModal = !this.registerModal;
    },
    closeModalRegister() {
      this.registerModal = false;
    },
    updateBackgroundImage(newSlideIndex) {
      this.currentSlideIndex = newSlideIndex;
    },
  },
  async mounted() {
    let url = new URL(window.location.href);
    let sessionExpired = url.searchParams.get("sessionExpired");
    let tokenExpired = url.searchParams.get("tokenExpired");
    if (sessionExpired) {
      this.$bvToast.toast("Su sesión ha expirado", {
        title: "Sesión expirada",
        variant: "warning",
        solid: true,
      });
    }

    if (tokenExpired) {
      this.$bvToast.toast("Su token ha expirado", {
        title: "Token expirado",
        variant: "warning",
        solid: true,
      });
    }
  },
  computed: {
    gridItemLeftStyle() {
      const currentImageUrl = this.secciones[this.currentSlideIndex]?.IMAGEN2 || '';
      return {
        backgroundImage: `linear-gradient(rgba(7, 29, 51, 0.74), rgba(36, 70, 105, 0.74)), url(${currentImageUrl})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        textAlign: 'center',
        gridColumn: '1/4',
        gridRow: '1/3',
        display: 'flex',
      };
    },
  },
  created() {
    LoginProxy.list({
      INIT: 0,
      ROWS: 4,
      DESC: null,
      CESTDO: 'A'
    })
      .then(async (response) => {
        const processedResponse = await Promise.all(response.map(async noticia => {
          const type = noticia.IMAGEN.split('.').pop();

          let base64String = '';
          for (let i = 0; i < noticia.IMAGEN2.data.length; i++) {
            base64String += String.fromCharCode(noticia.IMAGEN2.data[i]);
          }
          noticia.IMAGEN2 = `data:image/${type};base64,${window.btoa(base64String)}`
          return noticia;
        }))

        this.secciones = processedResponse;

      })
      .catch((error) => {
        this.$bvToast.toast("Error", {
          title: "Error al cargar las noticias",
          variant: "warning",
          solid: true,
        });
      })
      .finally(() => {
        this.isLoadingRegister = false;
      });
  },
};
</script>

<style lang="scss" scoped>
$color-light-blue: #1864FF;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #fff;
$color-light-gray: #dfdfdf;
$color-gray: #737471;

.background-image {
  filter: brightness(0.4);
}

.btn-ingresar {
  margin-top: 3rem;
  background: $color-light-blue;
  color: $color-white;
  font-size: 15px;
  border-radius: 36px;
  height: 44px;
  padding: 8px 0;
  font-family: Inter-Medium;
  border: none;
}

.forgot-pass {
  background: #fff;
  margin-top: 15px;
  font-size: 14px;
  text-align: center;
  border-radius: 36px;
  height: 44px;
  width: 100%;
  border: 1px solid #dfdfdf;
  padding: 8px 0;
  font-family: Inter-Medium;
}

.label-login {
  font-size: 14px;
  color: $color-dark;
  font-family: Inter-Medium;
  font-weight: 700;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0;
}

@media (max-width: 768px) {
  .display-reverse {
    flex-direction: row-reverse;

    .grid-item-left {
      order: 2;
    }

  }

  .grid-item-left {
    display: none !important;
  }

  .form-container {
    justify-content: center !important;
    align-items: start !important;
    margin: 0 auto;
  }

  .btn-ingresar {
    margin-top: 0;
  }
}


.carousel {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.content-text {
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: left;
  width: 100%;
  max-width: 500px;
}

.title {
  font-size: 47px;
  color: $color-white;
  margin-bottom: 18px;
  line-height: 57px;
  font-family: Inter-Bold;
}

.sub-title {
  font-size: 18px;
  color: $color-white;
  line-height: 22px;
  margin-bottom: 60px;
  font-family: Inter-Regular;
}

.content-buttons {
  display: flex;
  font-size: 15px;

  .button-white {
    background: $color-white;
    color: $color-light-blue;
    margin-right: 10px;
    height: 44px;
    border: 1px solid $color-light-gray;
    box-sizing: border-box;
    border-radius: 36px;
    padding: 8px 16px;
    font-family: Inter-Medium;
    font-size: 15px;
    display: flex;
    align-items: center;

    img {
      object-fit: none;
      padding: 0;
      height: 20px;
      width: 20px;
    }
  }

  .button-transparent {
    background: transparent;
    color: $color-white;
    border: 1px solid $color-white;
    height: 44px;
    box-sizing: border-box;
    border-radius: 36px;
    padding: 8px 16px;
    font-family: Inter-Medium;
    font-size: 15px;
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
      color: $color-white;
    }
  }
}


@media (max-width: 768px) {
  .content-text {
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .title {
    font-size: 30px;
    line-height: 36px;
  }

  .sub-title {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 30px;
  }

  .content-buttons {
    flex-direction: column;

    .button-white {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}


.main-login {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 0;

  .grid-container {

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    height: auto;
    width: 100%;
    grid-template-columns: repeat(5, minmax(10px, 1fr));

  }

}

@media (max-width: 768px) {
  .main-login {
    height: auto;
    display: block;
    margin-bottom: 20px;
  }

  .grid-container {
    display: block;
    height: auto;
  }
}


.VueCarousel-pagination {
  display: none !important;
}
</style>
