<template>
  <b-modal v-model="deleteEntrieModal" centered :hide-footer="true" :hide-header="true">
    <div class="delete-entrie-card">
      <h1 class="title">¿Eliminar entrada?</h1>
      <p class="sub-title">
        La eliminación de una estrada es definitiva y NO se puede recuperar,
        ¿desea continuar?
      </p>
      <div class="d-flex">
        <button class="btn btn-white" @click="$emit('closeDeleteEntrieModal', false)">
          No, cancelar
        </button>
        <button class="btn btn-red" @click="deleteById">
          Si, eliminar <img src="@/assets/icons/trash-red.svg" class="ml-2" />
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import adminEntriesProxy from "@/proxies/AdminEntriesProxy.js";

export default {
  name: "EntriesDeleteModal",
  props: {
    deleteEntrieModal: {
      required: true,
    },
    IdEntrie: {
      required: true,
    },
    typeEntrie: {
      required: true,
    },
  },
  methods: {
    async deleteById() {
      try {
        this.isLoading = true;

        if (this.typeEntrie === "legislation") {
          await adminEntriesProxy.deleteLegislationById(
            this.IdEntrie
          );
        } else {
          await adminEntriesProxy.deleteJurisprudenceById(
            this.IdEntrie
          );
        }

        this.$bvToast.toast(`Entrada eliminada con éxito`, {
          title: "Éxito",
          variant: "primary",
          solid: true,
        });

        setTimeout(() => {
          if (this.typeEntrie === "legislation") {
            this.$router.push({ name: "EntriesLegislation" });
          } else {
            this.$router.push({ name: "EntriesJurisdiction" });
          }

        }, 2000);

      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$color-light-blue: #0673f2;
$color-dark: #00171f;
$color-white: #ffffff;
$color-light-gray: #dfdfdf;
$color-red: #eb5757;

.delete-entrie-card {
  .title {
    font-size: 24px;
    text-align: center;
    font-family: Inter-Regular;
    font-weight: 700;
    color: $color-dark;
    margin-bottom: 30px;
  }

  .sub-title {
    font-size: 15px;
    text-align: center;
    font-family: Inter-Regular;
    font-weight: 400;
    color: $color-dark;
    line-height: 135%;
    margin-bottom: 30px;
  }

  .btn-white {
    background: $color-white;
    border: 1px solid $color-light-gray;
    border-radius: 50px !important;
    font-family: Inter-Regular;
    font-weight: 500;
    font-size: 14px;
    color: $color-dark;
    min-width: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    margin-right: 20px;
  }

  .btn-red {
    background: $color-white;
    border: 1px solid $color-red;
    border-radius: 50px !important;
    font-family: Inter-Regular;
    font-weight: 500;
    font-size: 14px;
    color: $color-red;
    min-width: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    margin-right: 20px;
  }

  .btn-blue {
    background: $color-light-blue;
    border-radius: 50px !important;
    font-family: Inter-Regular;
    font-weight: 500;
    font-size: 14px;
    color: white;
    min-width: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
  }
}
</style>