<template>
    <b-card class="item">
        <b-card-title v-html="title" />
        <b-card-text class="pb-3">
            <strong>Tema</strong>
            <p v-html="jurisprudence.theme" />
        </b-card-text>
        <router-link class="btn-to-detail" :to="{
            name: 'DocumentsAdminJuristictionDetail',
            params: { id: jurisprudence.id },
        }">
            Ver Detalle
            <img class="ml-2" src="@/assets/icons/arrow-right-dark-blue.svg" />
        </router-link>
    </b-card>
</template>

<script>
export default {
    name: "JurisprudenceAdminRelatedItem",
    props: {
        jurisprudence: {
            required: true,
        },
    },
    computed: {
        title() {
            return this.jurisprudence.title.length > 27 ? `${this.jurisprudence.title.substr(0, 27)}...` : this.jurisprudence.title;
        },
    },
};
</script>

<style lang="scss" scoped>
$color-darker-blue: #486aa3;
$color-white-gray: #dde3eb;

.item {
    .card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-height: 320px;
        min-height: 320px;

        .card-title {
            min-height: 80px;
            max-height: 80px;
        }

        .btn-to-detail {

            padding: 10px;
            border-radius: 48px;
            position: relative;
            color: $color-darker-blue;

            &:hover {
                text-decoration: none;
                background: $color-white-gray;
                color: color-darker-blue;
                text-decoration: none;
            }
        }
    }
}
</style>