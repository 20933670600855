<template>
    <div>
        <multiselect :max-height="100" v-model="value" tag-placeholder="Agregar esta etiqueta"
            placeholder="Buscar o agregar una etiqueta" selectLabel="Presione enter o haga click para seleccionar"
            deselectLabel="Presione enter o haga click para deseleccionar" label="name" track-by="code"
            :options="options" :multiple="true" 
            :show-labels="false">
            <template #selection="{ values, search, isOpen }">
                <span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} 
                    registros seleccionados
                </span>
            </template>

            <template #noResult="{ search }">
                <span class="multiselect__option">No hay resultados para "{{ search }}"</span>
            </template>
        </multiselect>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    name: 'SelectGlobal',
    props: {
        options: {
            type: Array,
            default: () => []
        },
        group: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Multiselect
    },
    data() {
        return {
            value: [],
        }
    },
    methods: {
        addTag(newTag) {
            const tag = {
                name: newTag,
                code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
            }
            this.value.push(tag)
        }
    }
}
</script>