<template>
  <div>
    <div class="main-recover">
      <div class="container">
        <div class="card-recover">
          <router-link :to="{ name: 'Login' }">
            <div class="content-img">
              <img src="@/assets/logo/logo.png" alt="juris-logo" />
            </div>
          </router-link>

          <div>
            <h1 class="recover-title">PORTAL ADMINISTRATIVO</h1>

            <b-form @submit.prevent="login">
              <b-form-group>
                <label for="email" class="label-login">Correo electrónico</label>
                <b-form-input id="email" v-model="model.email" placeholder="Escribe aquí tu correo" type="email"
                  required autocomplete="username"></b-form-input>
              </b-form-group>
              <b-form-group>
                <label for="password" class="label-login">Contraseña</label>
                <b-form-input id="password" v-model="model.password" placeholder="Escribe aquí tu contraseña"
                  type="password" required autocomplete="current-password"></b-form-input>
              </b-form-group>

              <button class="btn btn-block" type="submit">
                <b-spinner label="Spinning" small v-if="isLoading"></b-spinner>
                <span v-else>Ingresar al sistema</span>
              </button>
              <div>
                <router-link :to="{ name: 'Recover' }" class="btn btn-block forgot-pass">Olvidé mi
                  contraseña</router-link>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AUTH_REQUEST } from "@/store/actions/auth";

export default {
  name: "Recover",
  data() {
    return {
      isLoading: false,
      isLoading: false,
      model: {
        email: null,
        password: null,
      },
    };
  },
  methods: {
    async login() {
      let loader = this.$loading.show({
        canCancel: false,
        color: "#007bff",
      });
      this.$store
        .dispatch(AUTH_REQUEST, this.model)
        .then(() => {
          this.$router.push({ name: "Redirect" });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>
<style lang="scss" scoped>
$color-light-blue: #0673f2;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #dde3eb;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;

.main-recover {
  background: $color-white-gray;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    display: flex;
    justify-content: center;
    align-items: center;

    .card-recover {
      max-width: 328px;
      width: 100%;
      background: $color-white;
      border: 1px solid $color-light-gray;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 16px;
      color: $color-dark;
      height: 100%;
      padding: 16px;

      .content-img {
        padding: 0 10px;
        margin-bottom: 30px;
        text-align: center;
      }

      .content-link {
        margin-bottom: 40px;

        a {
          color: #486169;
          text-decoration: none;
        }
      }

      .recover-title {
        font-size: 15px;
        margin-bottom: 30px;
        text-align: center;
        color: $color-dark;
        font-family: Inter-Bold;
      }

      .form-group {
        margin-bottom: 30px;

        .label-login {
          margin-bottom: 2px;
          font-family: Inter-SemiBold;
        }

        .form-control {
          font-size: 15px;
          height: 52px;
          color: $color-dark;
          font-family: Inter-Regular;
        }
      }

      button {
        background: $color-light-blue;
        color: $color-white;
        font-size: 15px;
        border-radius: 36px;
        height: 44px;
        padding: 8px 0;
        font-family: Inter-Medium;
      }

      .btn-blue {
        background: $color-white;
        color: $color-dark;
        font-size: 15px;
        border-radius: 36px;
        height: 44px;
        padding: 8px 0;
        font-family: Inter-Medium;
        border: 1px solid $color-light-gray;
        padding: 8px 0;
      }

      .forgot-pass {
        background: $color-white;
        margin-top: 15px;
        font-size: 14px;
        text-align: center;
        border-radius: 36px;
        height: 44px;
        width: 100%;
        border: 1px solid $color-light-gray;
        padding: 8px 0;
        font-family: Inter-Medium;

        a {
          color: $color-dark;
          text-decoration: none;
          font-size: 15px;
        }
      }
    }
  }
}
</style>
