<template>
    <b-modal id="modal-plan-editar" v-model="show" title="Editar Plan" size="lg" ok-title="Guardar"
        cancel-title="Cancelar" @ok="edit"
        :ok-disabled="isAction"
        >
        <b-form ref="form" @submit.stop.prevent="edit">
            <div class="row">
                <b-form-group class="col-12" :state="getValidationState('DESCRIPCION')">
                    <label class="label-control">Descripción <span class="text-danger">*</span></label>
                    <b-form-input v-model="model.DESCRIPCION" :state="getValidationState('DESCRIPCION')"
                        @input="validateOnInput('DESCRIPCION')"></b-form-input>
                    <b-form-invalid-feedback :state="getValidationState('DESCRIPCION')">
                        {{ getValidationMessage('DESCRIPCION') }}
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group class="col-12 col-md-4" :state="getValidationState('VALOR')">
                    <label class="label-control">Valor <span class="text-danger">*</span></label>
                    <b-form-input v-model="model.VALOR" :state="getValidationState('VALOR')"
                        @input="validateOnInput('VALOR')"></b-form-input>
                    <b-form-invalid-feedback :state="getValidationState('VALOR')">
                        {{ getValidationMessage('VALOR') }}
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group class="col-12 col-md-4" :state="getValidationState('TIEMPO')">
                    <label class="label-control">Tiempo <span class="text-danger">*</span></label>
                    <b-form-input v-model="model.TIEMPO" :state="getValidationState('TIEMPO')"
                        @input="validateOnInput('TIEMPO')"></b-form-input>
                    <b-form-invalid-feedback :state="getValidationState('TIEMPO')">
                        {{ getValidationMessage('TIEMPO') }}
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group class="col-12 col-md-4" :state="getValidationState('PRECIO')">
                    <label class="label-control">Precio <span class="text-danger">*</span></label>
                    <b-form-input v-model="model.PRECIO" :state="getValidationState('PRECIO')"
                        @input="validateOnInput('PRECIO')"></b-form-input>
                    <b-form-invalid-feedback :state="getValidationState('PRECIO')">
                        {{ getValidationMessage('PRECIO') }}
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group class="col-12">
                    <label class="label-control">Restricciones</label>
                    <b-form-checkbox-group v-model="model.RESTRICIONES" class="d-flex flex-column gap-5">
                        <b-form-checkbox value="1">Visualizar</b-form-checkbox>
                        <b-form-checkbox value="2">Descargar</b-form-checkbox>
                        <b-form-checkbox value="3">Agregar a favoritos</b-form-checkbox>
                    </b-form-checkbox-group>
                </b-form-group>

            </div>
        </b-form>
    </b-modal>

</template>

<script>
import MantenimientoProxy from "../../../../proxies/MantenimientoProxy";

export default {
    name: "ModalEditarPlan",
    props: {
        openModalEditar: {
            type: Boolean,
            default: false
        },
        toggleModalEditar: {
            type: Function,
            required: true
        },
        search: {
            type: Function,
            required: true
        },
        data: {
            type: Object,
            required: true
        },
        objectValidator: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            show: false,
            model: {
                ID: null,
                DESCRIPCION: null,
                VALOR: null,
                TIEMPO: null,
                RESTRICIONES: [],
                PRECIO: null
            },
            validators: {
                DESCRIPCION: {
                    REQUIRED: true,
                },
                VALOR: {
                    REQUIRED: true,
                },
                TIEMPO: {
                    REQUIRED: true,
                    REGEX: /^[0-9]*$/
                },
                PRECIO: {
                    REQUIRED: true,
                    REGEX: /^\d+(\.\d{1,2})?$/
                }
            },
            fieldTouched: {},
            isAction: false
        }
    },
    methods: {
        getValidationState(field) {
            return this.objectValidator.getValidationState(field, this.fieldTouched, this.validators, this.model);
        },
        getValidationMessage(field) {
            return this.objectValidator.getValidationMessage(field, this.validators, this.model);
        },
        checkFormValidity() {
            return this.objectValidator.checkFormValidity(this.validators, this.model);
        },
        clearErrors() {
            this.objectValidator.clearErrors(this.validators, this.model, this.fieldTouched);
        },
        validateOnInput(field) {
            this.objectValidator.validateOnInput(field, this.fieldTouched);
        },
        onForceInput() {
            const fields = Object.keys(this.model);
            fields.forEach((field) => {
                this.fieldTouched = {
                    ...this.fieldTouched,
                    [field]: true,
                };
            });
        },
        async edit(bvModalEvent) {
            bvModalEvent.preventDefault();
            this.onForceInput();

            if (!this.checkFormValidity()) return;

            this.$bvToast.hide();
            let restriccionesComas = this.model.RESTRICIONES;
            this.isAction = true;
            await MantenimientoProxy.editPlan({
                ...this.model,
                RESTRICIONES: restriccionesComas?.length > 0 ? restriccionesComas.join(",") : null
            })
                .then((response) => {
                    const toastMessage = response.MESSAGE;
                    this.$bvToast.toast(toastMessage, {
                        title: response.STATUS ? "Éxito" : "Error",
                        variant: response.STATUS ? "primary" : "danger",
                        solid: true,
                    });

                    if (response.STATUS) {
                        this.show = false;
                        this.search();
                    }
                })
                .catch((error) => {
                    this.$bvToast.toast(`Ocurrió un error al editar el plan`, {
                        title: "Error",
                        variant: "danger",
                        solid: true,
                    });
                })
                .finally(() => {
                    this.isAction = false;
                });
        }
    },
    watch: {
        openModalEditar() {
            this.show = this.openModalEditar
        },
        show(val) {
            if (!val) {
                this.toggleModalEditar();
                this.clearErrors();
                this.isAction = false;

            } else {
                this.model = {
                    ID: this.data.ID,
                    DESCRIPCION: this.data.DESCRIPCION,
                    VALOR: this.data.VALOR, 
                    TIEMPO: this.data.TIEMPO,
                    PRECIO: this.data.PRECIO,
                    RESTRICIONES: this.data.RESTRICIONES?.split(","),
                }
            }
        }
    }

}

</script>