var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"results-search"},[_vm._l((_vm.generalResults),function(generalResult){return (_vm.isJurisprudence(generalResult))?_c('div',{key:`jurisprudence-${generalResult.id}`,staticClass:"documents-list-cards"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('router-link',{staticClass:"card-title",attrs:{"to":{
            name: 'DocumentsJuristictionDetail',
            params: { id: generalResult.id },
          }}},[_vm._v(" "+_vm._s(generalResult.title)+" ")]),_c('div',{staticClass:"card-detail"},[_c('div',{staticClass:"to-detail"},[_c('div',{staticClass:"actions"},[_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"content-action",attrs:{"title":"Descargar"},on:{"click":function($event){return _vm.downloadFile(generalResult.id)}}},[_c('img',{staticClass:"ml-2",attrs:{"src":require("@/assets/icons/download-paper.svg"),"alt":"Descargar"}})]),_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"content-action",attrs:{"title":"Previsualizar"},on:{"click":function($event){return _vm.openDocumentModal(generalResult.id)}}},[_c('img',{staticClass:"ml-2",attrs:{"src":require("@/assets/icons/search-paper.svg"),"alt":"Previsualizar"}})]),_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"content-action",attrs:{"title":"Agregar a mis documentos"},on:{"click":function($event){return _vm.handleUserDocument(generalResult)}}},[_c('img',{staticClass:"ml-2",attrs:{"src":generalResult.isFavorite
                      ? _vm.favoriteBluePicture
                      : _vm.favoritePicture,"alt":"Agregar a mis documentos"}})])]),_c('router-link',{staticClass:"btn-to-detail",attrs:{"to":{
                name: 'DocumentsJuristictionDetail',
                params: { id: generalResult.id },
              }}},[_vm._v(" Ver Detalle "),_c('img',{staticClass:"ml-2",attrs:{"src":require("@/assets/icons/arrow-right-dark-blue.svg")}})])],1)])],1),_c('div',{staticClass:"d-flex card-content-detail"},[_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"card-tema"},[_vm._m(0,true),_c('p',{staticClass:"tema-content",domProps:{"innerHTML":_vm._s(generalResult.theme)}})]),_c('div',{staticClass:"card-tema pl-5"},[_vm._m(1,true),_c('p',{staticClass:"tema-content",domProps:{"innerHTML":_vm._s(generalResult.ambit)}})])]),_c('div',{staticClass:"card-sintesis"},[_vm._m(2,true),(generalResult.type === 0)?_c('p',{staticClass:"sub-title pl-4",domProps:{"innerHTML":_vm._s(generalResult.resumen)}}):_vm._e(),(generalResult.type === 1)?_c('p',{staticClass:"sub-title pl-4",domProps:{"innerHTML":_vm._s(generalResult.relevant_fundamentals)}}):_vm._e()]),_c('div',{staticClass:"card-sintesis"},[_vm._m(3,true),(generalResult.type === 0)?_c('p',{staticClass:"sub-title pl-4 pl-5"},[_vm._v("-")]):_vm._e(),(generalResult.type === 1)?_c('p',{staticClass:"sub-title pl-4",domProps:{"innerHTML":_vm._s(generalResult.resumen)}}):_vm._e()])]),_c('b-modal',{ref:`modal-jurisprudence-${generalResult.id}`,refInFor:true,attrs:{"centered":"","modal-class":"b-jurisdiction","hide-footer":true,"hide-header":true,"ok-only":"","no-stacking":""}},[_c('document-modal',{attrs:{"documentFile":_vm.documentFile,"title":generalResult.title,"documentId":generalResult.id,"documentType":0,"userId":_vm.getProfile.id,"isFavorite":generalResult.isFavorite,"reload":_vm.reload},on:{"closeDocumentModal":function($event){return _vm.closeDocumentModal(generalResult.id)}}})],1)],1):_vm._e()}),_vm._l((_vm.generalResults),function(generalResult){return (!_vm.isJurisprudence(generalResult))?_c('div',{key:`jurisprudence-${generalResult.id}`,staticClass:"documents-list-cards"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('router-link',{staticClass:"card-title",attrs:{"to":{
            name: 'DocumentsLegislationDetail',
            params: { id: generalResult.id },
          }}},[_vm._v(" "+_vm._s(generalResult.name)+" ")]),_c('div',{staticClass:"card-detail"},[_c('div',{staticClass:"to-detail"},[_c('div',{staticClass:"actions"},[_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"content-action",attrs:{"title":"Descargar"},on:{"click":function($event){return _vm.downloadFileL(generalResult.id)}}},[_c('img',{staticClass:"ml-2",attrs:{"src":require("@/assets/icons/download-paper.svg"),"alt":"Descargar"}})]),_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"content-action",attrs:{"title":"Previsualizar"},on:{"click":function($event){return _vm.openDocumentModalL(generalResult.id)}}},[_c('img',{staticClass:"ml-2",attrs:{"src":require("@/assets/icons/search-paper.svg"),"alt":"Previsualizar"}})]),_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"content-action",attrs:{"title":"Agregar a mis documentos"},on:{"click":function($event){return _vm.handleUserDocumentL(generalResult)}}},[_c('img',{staticClass:"ml-2",attrs:{"src":generalResult.isFavorite
                      ? _vm.favoriteBluePicture
                      : _vm.favoritePicture,"alt":"Agregar a mis documentos"}})])]),_c('router-link',{staticClass:"btn-to-detail",attrs:{"to":{
                name: 'DocumentsLegislationDetail',
                params: { id: generalResult.id },
              }}},[_vm._v(" Ver Detalle "),_c('img',{staticClass:"ml-2",attrs:{"src":require("@/assets/icons/arrow-right-dark-blue.svg")}})])],1)])],1),_c('b-modal',{ref:`modal-legislation-${generalResult.id}`,refInFor:true,attrs:{"centered":"","modal-class":"b-jurisdiction","hide-footer":true,"hide-header":true,"ok-only":"","no-stacking":""}},[_c('document-modal',{attrs:{"documentFile":_vm.documentFile,"title":generalResult.title,"documentId":generalResult.id,"documentType":0,"userId":_vm.getProfile.id,"isFavorite":generalResult.isFavorite,"reload":_vm.reload},on:{"closeDocumentModal":function($event){return _vm.closeDocumentModalL(generalResult.id)}}})],1)],1):_vm._e()}),(_vm.generalResults.length <= 0)?_c('div',[_c('h4',{staticClass:"text-center"},[_vm._v("No se encontraron resultados")])]):_vm._e()],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('img',{attrs:{"src":require("@/assets/icons/paper-full.svg")}}),_c('p',{staticClass:"tema-title"},[_vm._v("Tema")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('img',{attrs:{"src":require("@/assets/icons/paper-full.svg")}}),_c('p',{staticClass:"tema-title"},[_vm._v("Ambito")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('img',{attrs:{"src":require("@/assets/icons/paper-empty.svg")}}),_c('p',{staticClass:"sintesis-title"},[_vm._v("Fundamentos jurídicos relevantes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('img',{attrs:{"src":require("@/assets/icons/paper-empty.svg")}}),_c('p',{staticClass:"sintesis-title"},[_vm._v("Resumen")])])
}]

export { render, staticRenderFns }