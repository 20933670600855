<template>
    <b-modal id="modal-magistrado-edit" v-model="show" title="Editar Magistrado" size="lg" ok-title="Guardar"
        cancel-title="Cancelar" @ok="editar">
        <b-form ref="form" @submit.stop.prevent="editar">
            <div class="row">

                <b-form-group class="col-md-6 col-12">
                    <label class="label-control">Nombres</label>
                    <!-- <b-form-input v-model="model.NOMBRES" :state="getValidationState('NOMBRES')" -->
                    <b-form-input v-model="model.NOMBRES"
                        @input="validateOnInput('NOMBRES')"></b-form-input>
                    <!-- <b-form-invalid-feedback :state="getValidationState('NOMBRES')">
                        {{ getValidationMessage('NOMBRES') }}
                    </b-form-invalid-feedback> -->
                </b-form-group>

                <b-form-group class="col-md-6 col-12" :state="getValidationState('APELLIDOS')">
                    <label class="label-control">Apellidos</label>
                    <b-form-input v-model="model.APELLIDOS" :state="getValidationState('APELLIDOS')"
                        @input="validateOnInput('APELLIDOS')"></b-form-input>
                    <b-form-invalid-feedback :state="getValidationState('APELLIDOS')">
                        {{ getValidationMessage('APELLIDOS') }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </div>
        </b-form>
    </b-modal>

</template>

<script>
import MagistradoProxy from "../../../../proxies/Magistrados.Proxy";

export default {
    name: "ModalEditarMagistrado",
    props: {
        openModalEditar: {
            type: Boolean,
            default: false
        },
        toggleModalEditar: {
            type: Function,
            required: true
        },
        search: {
            type: Function,
            required: true
        },
        data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            show: false,
            model: {
                ID: null,
                APELLIDOS: null,
                NOMBRES: null,
            },
            validators: {
                APELLIDOS: {
                    REQUIRED: true
                },
            },
            fieldTouched: {},
        }
    },
    methods: {
        getValidationState(field) {
            if (!this.fieldTouched[field]) return null;
            return this.getValidationMessage(field) ? false : true;
        },
        getValidationMessage(field) {
            if (this.validators[field].REQUIRED && !this.model[field]) {
                return "Este campo es requerido";
            }

            if (this.validators[field].MAXLENGTH && this.model[field] && this.model[field].length > this.validators[field].MAXLENGTH) {
                return `Este campo no debe exceder los ${this.validators[field].MAXLENGTH} caracteres`;
            }

            if (this.validators[field].REGEX && this.model[field] && !this.validators[field].REGEX.test(this.model[field])) {
                return "El formato no es válido";
            }

            if (this.validators[field].MINLENGTH && this.model[field] && this.model[field].length < this.validators[field].MINLENGTH) {
                return `Este campo debe tener al menos ${this.validators[field].MINLENGTH} caracteres`;
            }

            return null;
        },
        checkFormValidity() {
            let isValid = true;
            for (const field in this.validators) {
                if (this.getValidationMessage(field)) {
                    isValid = false;
                }
            }
            return isValid;
        },
        clearErrors() {
            for (const field in this.validators) {
                this.model[field] = null;
            }

            this.fieldTouched = {};
        },
        validateOnInput(field) {
            this.fieldTouched = {
                ...this.fieldTouched,
                [field]: true,
            };
        },
        onForceInput() {
            const fields = Object.keys(this.model);
            fields.forEach((field) => {
                this.fieldTouched = {
                    ...this.fieldTouched,
                    [field]: true,
                };
            });
        },
        async editar(bvModalEvent) {
            bvModalEvent.preventDefault();
            this.onForceInput();
            if (!this.checkFormValidity()) return;

            this.$bvToast.hide();
            await MagistradoProxy.edit(this.model)
                .then((response) => {
                    const toastMessage = response.MESSAGE;
                    this.$bvToast.toast(toastMessage, {
                        title: response.STATUS ? "Éxito" : "Error",
                        variant: response.STATUS ? "primary" : "danger",
                        solid: true,
                    });

                    if (response.STATUS) {
                        this.show = false;
                        this.search();
                    }
                })
                .catch((error) => {
                    this.$bvToast.toast(`Ocurrió un error al editar el magistrado`, {
                        title: "Error",
                        variant: "danger",
                        solid: true,
                    });
                })
        }
    },
    watch: {
        openModalEditar() {
            this.show = this.openModalEditar
        },
        show(val) {
            if (!val) {
                this.toggleModalEditar();
                this.clearErrors();
            } else {
                this.model = {
                    ID: this.data.ID,
                    APELLIDOS: this.data.APELLIDOS,
                    NOMBRES: this.data.NOMBRES,
                }
            }
        }
    }

}

</script>