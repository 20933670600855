<template>
  <div class="pt-100 fade-in">
    <div class="main-common-entrie">
      <div class="container p-4">
        <h3 class="text-center mb-2">Agregar Entrada</h3>

        <div class="row">
          <div class="col-12 col-md-6">
            <b-form-group :state="getValidationState('TITLE')">
              <label for="TITLE" class="label-login">Nombre de la Norma<span class="text-danger">*</span></label>
              <b-form-input v-model="modelo.TITLE" @input="validateOnInput('TITLE')">
              </b-form-input>
              <b-form-invalid-feedback :state="getValidationState('TITLE')">
                {{ getValidationMessage('TITLE') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group >
              <label for="RTITLE" class="label-login">Título Descriptivo </label>
              <b-form-input v-model="modelo.RTITLE">
              </b-form-input>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group>
              <label class="">Estado</label>
              <b-form-checkbox name="check-button" switch size="lg" v-model="modelo.ISBINDING">
                <span class="switch-text"> Vigente</span>
              </b-form-checkbox>
            </b-form-group>
          </div>
        </div>

        <h6 class="col-12 p-0">CONTENIDO</h6>
        <hr>

        <div class="row">
          <div class="col-12 col-md-6">
            <b-form-group :state="getValidationState('TPONRMA')">
              <label for="TPONRMA" class="label-ambito">Tipo de Norma <span class="text-danger">*</span></label>
              <treeselect :multiple="true" :options="selects.norma" placeholder="Seleccione una opción"
                v-model="modelo.TPONRMA" no-children-text="No hay opciones disponibles"
                no-results-text="No hay resultados" clear-on-select :limit="1" :max-height="200"
                :limitText="count => `Solo puede seleccionar ${count} opción`"
                :class="{ 'border-danger': getValidationState('TPONRMA') === false }">
              </treeselect>

              <b-form-invalid-feedback :state="getValidationState('TPONRMA')">
                {{ getValidationMessage('TPONRMA') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group :state="getValidationState('OEMISOR')">
              <label for="OEMISOR" class="label-ambito">Órgano emisor <span class="text-danger">*</span></label>
              <treeselect :multiple="true" :options="selects.oemisor" placeholder="Seleccione una opción"
                v-model="modelo.OEMISOR" no-children-text="No hay opciones disponibles"
                no-results-text="No hay resultados" clear-on-select :max-height="200"
                :class="{ 'border-danger': getValidationState('OEMISOR') === false }">
              </treeselect>

              <b-form-invalid-feedback :state="getValidationState('OEMISOR')">
                {{ getValidationMessage('OEMISOR') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group :state="getValidationState('NMRCN')">
              <label for="NMRCN" class="label-login">Numeración<span class="text-danger">*</span></label>
              <b-form-input type="text" v-model="modelo.NMRCN" @input="validateOnInput('NMRCN')">
              </b-form-input>
              <b-form-invalid-feedback :state="getValidationState('NMRCN')">
                {{ getValidationMessage('NMRCN') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group>
              <label class="label-login">Fecha de resolución <span class="text-danger">*</span></label>
              <!-- <b-form-datepicker :date-disabled-fn="dateDisabled" @input="validateOnInput('FRESOLUTION')" placeholder=""
                locale="es" class="custom" v-model="modelo.FRESOLUTION"></b-form-datepicker> -->
                <date-picker v-model="modelo.FRESOLUTION" valueType="format" 
                :disabledDate="time => time.getTime() > Date.now()"
                ></date-picker>

              <b-form-invalid-feedback :state="getValidationState('FRESOLUTION')">
                {{ getValidationMessage('FRESOLUTION') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group :state="getValidationState('ENTRIEFILE')">
              <label for="file"
                class="rs-form-control-label block mb-2 text-sm font-normal text-gray-500 dark:text-gray-500">Documento
                <span class="text-danger">*</span>
              </label>
              <div class="relative">
                <label for="file_input" class="text-sm choose"></label>
                <input value="modelo.ENTRIEFILE"
                  class="w-100 text-sm choose text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  type="file" id="file_input" accept=".pdf" name="file_input"
                  @change="modelo.ENTRIEFILE = $event.target.files[0]" />
                <b-form-invalid-feedback :state="getValidationState('ENTRIEFILE')">
                  {{ getValidationMessage('ENTRIEFILE') }}
                </b-form-invalid-feedback>
              </div>
            </b-form-group>
          </div>


        </div>

        <div class="row justify-content-center"
          style="border-top: 1px solid #dfdfdf; padding-top: 20px; margin-top: 20px;gap: 20px;">
          <b-button variant="outline-primary" @click="$router.go(-1)">
            Cancelar
          </b-button>
          <b-button variant="primary" @click="create" :disabled="isLoading">
            <span>Publicar</span>
          </b-button>
          <b-button variant="danger" class="text-white" @click="localStorageSave">
            <span>Guardar</span>
          </b-button>
          <b-button variant="success" class="text-white" @click="UpdateLocaleStorage" :disabled="isLoading">
            <span>Actualizar</span>
          </b-button>
        </div>
      </div>
    </div>

    <div v-if="isLoading" class="spinner-overlay">
      <b-spinner label="Cargando..." variant="primary"></b-spinner>
    </div>
  </div>

</template>

<script>
import adminEntriesProxy from "@/proxies/AdminEntriesProxy.js";
import filterProxy from "@/proxies/FilterProxy.js";
import DynamicAsyncSelect from "@/components/select/DynamicAsyncSelect.vue";
import Treeselect from '@riophae/vue-treeselect'

export default {
  name: "ExecutiveEntrie",
  components: { DynamicAsyncSelect },
  data() {
    return {
      isLoading: false,
      typeEntrie: "executive",
      entrieFile: null,
      entrieFilters: [],
      menu: [],
      model: {
        name: null,
        identification: {
          standard_type: null,
          numeration: null,
          publication_date: null,
          status: 0
        },
        organization: {
          filters: [],
        },
      },
      modelo: {
        TITLE: null,
        RTITLE: null,
        OEMISOR: [],
        ISBINDING: false,
        TPONRMA: [],
        NMRCN: null,
        FRESOLUTION: null,
        ENTRIEFILE: null,
      },
      validators: {
        TITLE: {
          REQUIRED: true
        },
        NMRCN: {
          REQUIRED: true,
        },
        TPONRMA: {
          REQUIRED: true,
          REGEX: /[^[]/
        },
        FRESOLUTION: {
          REQUIRED: true
        },
        ENTRIEFILE: {
          REQUIRED: true
        },
        OEMISOR: {
          REQUIRED: true,
          REGEX: /[^[]/
        }
      },
      fieldTouched: {},
      selects: {
        norma: [],
        oemisor: [],
      },
    };
  },
  watch: {
    'modelo.TPONRMA'(newValue, oldValue) {
      if (newValue.length > 1) {
        this.modelo.TPONRMA = [newValue[newValue.length - 1]];
      }
    }
  },
  methods: {
    localStorageSave() {
      localStorage.setItem("legislationEntrie", JSON.stringify(this.modelo));
    },
    UpdateLocaleStorage() {
      let data = JSON.parse(localStorage.getItem("legislationEntrie"));
      this.modelo = data;
    },
    dateDisabled(ymd, date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today;
    },
    async create(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.onForceInput();
      if (!this.checkFormValidity()) return;

      this.isLoading = true;
      const formData = new FormData();
      formData.append("files", this.modelo.ENTRIEFILE);
      formData.append("TITLE", this.modelo.TITLE);
      formData.append("RTITLE", this.modelo.RTITLE);
      formData.append("ISBINDING", this.modelo.ISBINDING);
      formData.append("TPONRMA", this.modelo.TPONRMA.join(",") || this.modelo.TPONRMA);
      formData.append("NMRCN", this.modelo.NMRCN);
      formData.append("FRESOLUTION", this.modelo.FRESOLUTION);
      formData.append("TYPE", "legislations")
      formData.append("TIPO", this.typeEntrie)
      formData.append("OEMISOR", this.modelo.OEMISOR.join(",") || this.modelo.OEMISOR);

      await adminEntriesProxy.createSingle(formData)
        .then(response => {
          const toastMessage = response.STATUS ? "Entrada creada con éxito" : response.MESSAGE;

          this.$bvToast.toast(toastMessage, {
            title: response.STATUS ? "Success" : "Error",
            variant: response.STATUS ? "primary" : "danger",
            solid: true,
          });

          if (response.STATUS) {
            localStorage.removeItem("legislationEntrie");
            setTimeout(() => {
              this.$router.push({ name: "EntriesJurisdiction" });
            }, 1500);
          }

        })
        .catch(err => {
          this.$bvToast.toast(`Ocurrió un error al agregar la entrada`, {
            title: "Ocurrió un error al agregar la entrada",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.isLoading = false;
        })
    },
    /// METODOS PARA VALIDACION
    getValidationState(field) {
      if (!this.fieldTouched[field]) return null;
      return this.getValidationMessage(field) ? false : true;

    },
    getValidationMessage(field) {
      if (this.validators[field].REQUIRED && !this.modelo[field]) {
        return "Este campo es requerido";
      }

      if (this.validators[field].MAXLENGTH && this.modelo[field] && this.modelo[field].length > this.validators[field].MAXLENGTH) {
        return `Este campo no debe exceder los ${this.validators[field].MAXLENGTH} caracteres`;
      }

      if (this.validators[field].REGEX && this.modelo[field] && !this.validators[field].REGEX.test(this.modelo[field])) {
        return "El campo no cumple con el formato requerido";
      }

      if (this.validators[field].MINLENGTH && this.modelo[field] && this.modelo[field].length < this.validators[field].MINLENGTH) {
        return `Este campo debe tener al menos ${this.validators[field].MINLENGTH} caracteres`;
      }

      return null;
    },
    checkFormValidity() {
      let isValid = true;
      for (const field in this.validators) {
        if (this.getValidationMessage(field)) {
          isValid = false;
        }
      }
      return isValid;
    },
    clearErrors() {
      for (const field in this.validators) {
        this.modelo[field] = null;
      }

      this.fieldTouched = {};
    },
    validateOnInput(field) {
      this.fieldTouched = {
        ...this.fieldTouched,
        [field]: true,
      };
    },
    onForceInput() {
      const fields = Object.keys(this.modelo);
      fields.forEach((field) => {
        this.fieldTouched = {
          ...this.fieldTouched,
          [field]: true,
        };
      });
    },
    async getAllFilters() {
      await filterProxy.list({ NIVEL: 5 }, "2")
        .then((res) => {
          if (res && res.length > 0) {
            let norma = this.configFilter(res, "TIPO DE NORMA");
            this.selects.norma = norma?.NIVEL_2?.map((item) => {
              return {
                id: item.VALUE,
                label: item.LABEL,
              };
            });

            let oemisor = this.configFilter(res, "ÓRGANO EMISOR");
            this.selects.oemisor = oemisor?.NIVEL_2?.map((item) => {
              return {
                id: item.VALUE,
                label: item.LABEL,
                children: item.NIVEL_3?.map((subItem) => {
                  return {
                    id: subItem.VALUE,
                    label: subItem.LABEL,
                    children: subItem.NIVEL_4?.map((subSubItem) => {
                      return {
                        id: subSubItem.VALUE,
                        label: subSubItem.LABEL,
                        children: subSubItem.NIVEL_5?.map((subSubSubItem) => {
                          return {
                            id: subSubSubItem.VALUE,
                            label: subSubSubItem.LABEL,
                          };
                        }),
                      };
                    }),
                  };
                }),
              };
            });
          }
        })
        .catch((err) => {
          this.$bvToast.toast(`Ocurrió un error al obtener los filtros`, {
            title: "Ocurrió un error al obtener los filtros",
            variant: "danger",
            solid: true,
          });
        })
    },
    configFilter(data, label) {
      return data.filter((item) => item.LABEL.toUpperCase() === label.toUpperCase())
        .map((item) => {
          return {
            ...item,
            NIVEL_2: JSON.parse(item.NIVEL_2)
          };
        })?.[0] || {};
    },
  },
  async mounted() {
    await this.getAllFilters();
  },
};
</script>

<style lang="scss">
// this can cover all elements in all components
@media (max-width: 575.98px) {
  .v-dropdown-container {
    width: 100% !important;

    .sm-parent {
      display: flex;
      flex-direction: row;
      margin: 0px !important;
      padding: 0px !important;
      width: 100% !important;
    }
  }
}
</style>

<style lang="scss" scoped>
$color-light-blue: #1864FF;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #DDE3EB;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;
$color-weight-gray: #486169;

.convertir_file {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: #374151;
}

.pt-100 {
  padding-top: 61px;
}

.main-back-navbar {
  padding: 10px 0;
  border-bottom: 1px solid $color-light-gray;

  .back-text {
    color: $color-weight-gray;
    font-family: Inter-Regular;
    cursor: pointer;

    :hover {
      text-decoration: none;
    }
  }

  .btn-blue {
    background: $color-light-blue;
    border-radius: 50px !important;
    color: white;
    min-width: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    font-family: Inter-Medium;
  }
}

.main-common-entrie {
  padding-top: 50px;
  padding-bottom: 50px;
  background: $color-white;
  min-height: 100vh;

  h6 {
    font-family: Inter-bold;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: $color-light-blue;
  }

  .container {
    background-color: $color-white;
    border-radius: 8px;
    box-shadow: 0px 9px 36px rgba(0, 0, 0, 0.14),
      0px 2.71324px 10.8529px rgba(0, 0, 0, 0.0134174),
      0px 0.407592px 1.63037px rgba(0, 0, 0, 0.0106725);



    .row {
      .card {
        padding: 12px;
        margin-bottom: 20px;

        .title-blue {
          color: $color-light-blue;
          font-size: 16px;
          font-weight: 600;
          font-family: Inter-Regular;
          margin-bottom: 30px;
          text-transform: uppercase;
        }

        .switch-title {
          font-family: Inter-Bold;
          font-size: 15px;
        }

        .switch-text {
          font-size: 12px;
          font-weight: 500;
        }

        .form-group {
          margin-bottom: 20px;


          input {
            font-size: 15px;
            height: 38px;
            color: $color-dark;
            font-family: Inter-Regular;
          }

          select {
            font-size: 15px;
            height: 38px;
            color: $color-dark;
            font-family: Inter-Regular;
          }
        }
      }
    }
  }
}

.fade-in {
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>