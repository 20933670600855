<template>
  <div>
    <div class="content-filter">
      <b-form class="d-flex flex-column flex-md-row align-items-end justify-content-between" @submit.prevent="search">
        <b-form-input placeholder="Buscar por nombres" v-model="filter.name"
          class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
        <div class="contenedor-buttons">
          <button type="button" class="btn btn-blue-primary" @click="search">Buscar</button>
          <button type="button" class="btn btn-blue-secondary" @click="openModal">Nuevo</button>
        </div>
      </b-form>
    </div>

    <div class="table-container mt-3">
      <b-col sm="5" md="2" class="px-0">
        <b-form-group label="Filas" label-for="per-page-select" label-cols-sm="2" label-cols-md="2" label-cols-lg="2"
          label-size="sm" class="mb-0">
          <b-form-select id="per-page-select" v-model="grid.perPage" @change="search" :options="grid.pageOptions"
            size="sm">
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-table class="mt-4 user-table responsive-table" head-variant="light" hover :items="grid.items" :fields="fields"
        :busy="grid.isLoading" show-empty>
        <template v-slot:cell(APATERNO)="data">
          <span>{{ data.item.APATERNO + ' ' + data.item.AMATERNO + ', ' + data.item.NOMBRES }}</span>
        </template>
        <template v-slot:cell(FCRCN)="data">
          <span>{{
            new Date(data.item.FCRCN).toLocaleDateString("es-PE", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })
          }}</span>
        </template>
        <template v-slot:cell(CDESTDO)="data">
          <div class="d-flex justify-content-center text-center align-items-center h-100">
            <b-badge style="margin: 5px!important;" :variant="data.item.CDESTDO === 'A' ? 'success' : 'danger'">
              {{ data.item.CDESTDO === 'A' ? 'A' : 'I' }}
            </b-badge>
          </div>
        </template>
        <template v-slot:cell(ACCIONES)="data">
          <div class="d-flex justify-content-center" style="gap: 10px;">
            <b-button size="sm" variant="info" @click="viewUserDetail(data.item.ID)">
              <b-icon icon="eye-fill"></b-icon>
            </b-button>
            <b-button size="sm" variant="warning" @click="editUser(data.item)">
              <b-icon icon="pencil-fill"></b-icon>
            </b-button>
            <b-button v-if="role.IDR == 0" size="sm" variant="danger"
              @click="deleteUser(data.item.ID, data.item.CDESTDO)">
              <b-icon icon="trash-fill"></b-icon>
            </b-button>
          </div>
        </template>


        <template v-slot:empty>
          <div class="text-center">
            <p class="mt-3">
              <b-icon icon="info-circle-fill" variant="info" class="mr-2"></b-icon>
              No se encontraron registros
            </p>
          </div>
        </template>
        <template v-slot:table-busy>
          <div class="text-center">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </template>
      </b-table>
    </div>

    <b-pagination v-model="grid.currentPage" :total-rows="grid.totalRows" :per-page="grid.perPage" @change="handlePage"
      size="sm"></b-pagination>

    <Auditoria v-if="role.IDR == 0" url="USUARIOS" />

    <SubscribersCreate :userType="userType.toString()" :get-users="getUsers"
      :planes="planes"
    />
    <SubscribersEdit :userType="userType.toString()" :user="editUserData" :get-users="getUsers" :planes="planes" />
    <SubscribersSuspender :activeUserModal="activeUserModal" :isUserActive="isUserActive"
      :closeChangeStatusModal="closeChangeStatusModal" :changeStatus="changeStatus" :id="id" />
  </div>
</template>

<script>
import userProxy from "../../../../proxies/UserProxy.js";
import SubscribersCreate from "../../../../views/admin/users/create/SubscribersCreate.vue";
import SubscribersEdit from "../../../../views/admin/users/create/SubscribersEdit.vue";
import SubscribersSuspender from "../../../../views/admin/users/create/SubscribersSuspender.vue";
import Auditoria from "../../Auditoria.vue";
import MantenimientoProxy from "../../../../proxies/MantenimientoProxy.js";

export default {
  name: "UsersTable",
  props: {
    userType: {
      type: Number,
    },
    buttonText: {
      type: String,
    },
    role: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      fields: [
        {
          key: "RN",
          label: "",
        },
        {
          key: "APATERNO",
          label: "Apellidos y Nombres",
          sortable: true,
        },
        {
          key: "EMAIL",
          label: "Correo electrónico",
          sortable: true,
        },
        {
          key: "FCRCN",
          label: "Subscriptor desde",
          sortable: true,
        },
        {
          key: "CDESTDO",
          label: "Estado",
          sortable: true,
        },
        {
          key: "ACCIONES",
          label: "Acciones",
        }
      ],
      grid: {
        items: [],
        currentPage: 1,
        perPage: 10,
        totalRows: 10,
        isLoading: true,
        pageOptions: [5, 10, 15, 50],
      },
      filter: {
        name: null,
      },
      selectedFilter: null,
      showModal: false,
      editUserData: {},
      activeUserModal: false,
      isUserActive: false,
      id: null,
      planes: [],
    };
  },
  components: {
    SubscribersCreate,
    SubscribersEdit,
    SubscribersSuspender,
    Auditoria,
  },
  computed: {
    rows() {
      return this.grid.items.length;
    },
  },
  methods: {
    handlePage(page) {
      this.grid.currentPage = page;
      this.getUsers();
    },
    async getUsers() {
      const init = (this.grid.currentPage - 1) * this.grid.perPage;
      const rows = this.grid.perPage;
      this.grid.isLoading = true;

      await userProxy.list({
        ROWS: rows,
        INIT: init,
        DESC: this.selectedFilter?.name || null
      }, this.userType)
        .then((users) => {
          this.grid.items = users;
          this.grid.totalRows = users?.[0]?.TOTALROWS || 0;
        })
        .catch((e) => { })
        .finally(() => this.grid.isLoading = false);
    },
    async getPlanes() {
      await MantenimientoProxy.listPlanes({ INIT: 0, ROWS: 10000 })
        .then((response) => {
          this.planes = response;
        })
        .catch(() => { this.planes = []; });
    },
    async deleteUser(id, cestado) {
      this.isUserActive = cestado === 'A';
      this.id = id;
      this.activeUserModal = true;
    },
    async changeStatus() {
      this.activeUserModal = false;
      this.isUserActive = !this.isUserActive;
      const successMessage = this.isUserActive ? "Usuario activado con éxito" : "Usuario suspendido con éxito";
      const errorMessage = this.isUserActive ? "Ocurrió un error al activar al usuario" : "Ocurrió un error al suspender al usuario";

      await userProxy.delete(this.id)
        .then((response) => {
          if (response.STATUS) {
            this.getUsers();
            this.$bvToast.toast(successMessage, {
              title: "Éxito",
              variant: "primary",
              solid: true,
            });
          } else {
            this.$bvToast.toast(errorMessage, {
              title: "Error",
              variant: "danger",
              solid: true,
            });
          }
        })
        .catch(() => {
          this.$bvToast.toast(errorMessage, {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        });
    },
    closeChangeStatusModal() {
      this.activeUserModal = false;
    },
    async editUser(user) {
      this.editUserData = user;
      await this.$nextTick();
      this.$bvModal.show("modal-prevent-user-edit");
    },
    viewUserDetail(id) {
      this.$router.push({ name: "SubscribersEdit", params: { id } });
    },
    search() {
      this.selectedFilter = { ...this.filter };
      this.getUsers();
    },
    openModal() {
      this.$bvModal.show("modal-prevent-user");
    },
    closeModalHandler() {
      this.showModal = false;
    },
  },

  async created() {
    await this.getUsers();
    await this.getPlanes();
  },
};
</script>

<style lang="scss" scoped>
$color-light-blue: #0673f2;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #DDE3EB;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;

.content-filter {
  .form-control {
    border-radius: 5px;
    height: 38px;
  }

  .btn-blue {
    background: $color-light-blue;
    border-radius: 50px !important;
    color: white;
    max-width: 180px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>