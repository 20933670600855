<template>
    <div class="main-background fade-in">
        <div class="content-list container">
            <div class="main-tab mb-4">
                <div class="content-tab">
                    <a class="content-tab-icon" @click="selected = 'Noticias'"
                        :class="{ active: selected === 'Noticias' }">
                        <a>Noticias</a>
                    </a>
                    <a class="content-tab-icon" :class="{ active: selected === 'PreguntasFrecuentes' }"
                        @click="selected = 'PreguntasFrecuentes'">
                        <a>Preguntas</a>
                    </a>
                    <a class="content-tab-icon" :class="{ active: selected === 'Planes' }"
                        @click="selected = 'Planes'">
                        <a>Planes</a>
                    </a>
                </div>
            </div>

            <Noticias v-if="selected === 'Noticias'" :role="role" />
            <Preguntas v-if="selected === 'PreguntasFrecuentes'" :role="role" />
            <Planes v-if="selected === 'Planes'" :role="role" />

        </div>
    </div>
</template>

<script>
import Noticias from './Noticias.vue';
import Preguntas from './Preguntas.vue';
import Planes from './Planes.vue';
import { provide } from 'vue';

export default {
    name: "IndexMantenimiento",
    props: {
        role: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        Noticias,
        Preguntas,
        Planes
    },
    data() {
        return {
            selected: "Noticias"
        };
    },
    setup() {
        provide('objectValidator', {
            con: () => {
                console.log("first")
            },
            dateDisabled(ymd, date) {
                const today = new Date();
                const minDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
                return date > minDate;
            },
            getValidationState(field, fieldTouched, validators, model) {
                if (!fieldTouched[field]) return null;
                return this.getValidationMessage(field, validators, model) ? false : true;
            },
            getValidationMessage(field, validators, model) {
                if (validators[field].REQUIRED && !model[field]) {
                    return "Este campo es requerido";
                }

                if (validators[field].MAXLENGTH && model[field] && model[field].length > validators[field].MAXLENGTH) {
                    return `Este campo no debe exceder los ${validators[field].MAXLENGTH} caracteres`;
                }

                if (validators[field].REGEX && model[field] && !validators[field].REGEX.test(model[field])) {
                    return "El formato no es válido";
                }

                if (validators[field].MINLENGTH && model[field] && model[field].length < validators[field].MINLENGTH) {
                    return `Este campo debe tener al menos ${validators[field].MINLENGTH} caracteres`;
                }

                return null;
            },
            checkFormValidity(validators, model) {
                let isValid = true;
                for (const field in validators) {
                    if (this.getValidationMessage(field, validators, model)) {
                        isValid = false;
                    }
                }
                return isValid;
            },
            clearErrors(validators, model, fieldTouched) {
                for (const field in validators) {
                    model[field] = null;
                }

                Object.keys(fieldTouched).forEach(key => {
                    fieldTouched[key] = false;
                });
            },
            validateOnInput(field, fieldTouched) {
                fieldTouched[field] = true;
            },
            onForceInput(model, fieldTouched) {
                const fields = Object.keys(model);
                fields.forEach((field) => {
                    fieldTouched[field] = true;
                });
            },
        });
    },
    methods: {

    }
};
</script>

<style lang="scss" scoped>
$color-light-blue: #0673f2;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #DDE3EB;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;

.main-background {
    background: $color-white;
    padding-top: 100px;
    padding-bottom: 50px;
    min-height: 100vh;

    .content-list {
        margin-top: 10px;
        background: $color-white;
        border-radius: 5px;
        padding: 20px;
        border-radius: 5px;
        padding: 24px;
        box-shadow: 0px 9px 36px rgba(0, 0, 0, 0.14),
            0px 2.71324px 10.8529px rgba(0, 0, 0, 0.0134174),
            0px 0.407592px 1.63037px rgba(0, 0, 0, 0.0106725);
        border-radius: 8px;

        .main-tab {
            background: $color-white;
            display: flex;
            justify-content: center;
            margin-top: 20px;
            margin-bottom: 20px;
            border-radius: 50px;

            .content-tab {
                width: 100%;
                display: flex;

                .content-tab-icon {
                    padding: 10px 50px;
                    width: 100%;
                    text-align: center;
                    border: 1px solid rgb(223, 223, 223);
                    border-radius: 50px;

                    a {
                        color: black;
                    }
                }

                :hover {
                    background: $color-light-blue;
                    cursor: pointer;
                    border-radius: 50px;
                    text-decoration: none;
                }

                .active {
                    background: white;
                    border: 1px solid $color-light-blue;
                    border-radius: 50px;
                    font-weight: bold;

                    :hover {
                        background: white;
                    }

                    a {
                        color: $color-light-blue;
                    }
                }

                :hover:not(.active) {
                    a {
                        color: white;
                    }
                }
            }

            @media (max-width: 768px) {
                .content-tab {
                    flex-direction: column;
                    gap: 10px;
                }
            }
        }


    }
}


.fade-in {
    animation: fadeIn 0.5s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
</style>
