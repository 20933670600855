<template>
    <div class="pt-1">
        <multiselect v-model="value" tag-placeholder="Agrega una nueva opción" selectedLabel="seleccionado"
            placeholder="Busca o agrega una nueva opción" selectLabel="Presione enter o haga click para seleccionar"
            deselectLabel="Presione enter o haga click para deselecionar" label="title" track-by="id" :options="options"
            :multiple="false" :taggable="true" @tag="addOption">
            <template slot="singleLabel" slot-scope="{option}">
                <span class="custom__tag color-primary">
                    <span>{{ option.label }}</span>
                </span>
            </template>
            <template slot="option" slot-scope="{option}">
                <div class="option__desc d-flex flex-row">
                    <img src="@/assets/icons/trash-red.svg" @click="removeOption(option)" v-if="options.length" />
                    <span class="pl-2 option__title">{{ option.title }}</span>
                </div>
            </template>
            <span slot="noOptions">Oops! No hay mas opciones disponibles.</span>
        </multiselect>
    </div>
</template>

<script>
import "vue-multiselect/dist/vue-multiselect.min.css";
import filterProxy from "@/proxies/FilterProxy.js";

export default {
    props: {
        selectedValue: {
            type: Number,
            required: false,
            default: null,
        },
        filterType: {
            type: Number,
            required: true,
            default: null,
        }
    },
    data() {
        return {
            value: null,
            options: []
        };
    },
    watch: {
        value(newValue) {
            if (newValue !== this.selectedValue) {
                const updatedValue = newValue !== null ? newValue.id : null;
                this.$emit("onChangeOption", updatedValue);
            }
        },
        selectedValue(newValue) {
            if (newValue !== this.value) {
                const selectedItem = [...this.options].find((item) => item.id === this.selectedValue);
                this.value = selectedItem;
            }
        },
        options() {
            if (this.options.length <= 0) {
                this.value = null;
            }
            if (!this.options.find((item) => item.id === this.value.id)) {
                this.value = null;
            }
        }
    },
    methods: {
        async addOption(newOption) {
            const modelCreate = {
                filter: {
                    title: newOption,
                    type: this.filterType,
                    parent: 0,
                },
            };
            try {
                const { filter } = await filterProxy.create(modelCreate);
                await this.getAllOptions();
                this.value = filter;
            } catch (e) {
                console.error(e);
            }
        },
        async removeOption(option) {
            try {
                if (option.id === this.value.id) {
                    this.value = null;
                }
                await filterProxy.delete(option.id);
                await this.getAllOptions();
            } catch (e) {
                console.error(e);
            }
        },
        async getAllOptions() {
            // const { filters } = await filterProxy.list(this.filterType);
            if(this.filterType === 4){
                this.options = [];
            } else if(this.filterType === 5){
                this.options = [];
            } else {
                this.options = [];
            }
        }
    },
    async mounted() {
        await this.getAllOptions();
    },
};
</script>

