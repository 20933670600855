<template>
  <div class="main-policy fade-in">
    <div class="container mt-5">
      <div class="row">
        <div class="col-md-4 col-12">
          <h5 class="policy-card-title">Secciones</h5>
          <div class="policy-card">
            <div class="policy-redirect">
              <a  href="#starting" class="d-flex align-items-center justify-content-between">
                <span>Principios</span>
                <img src="@/assets/icons/arrow-card.svg" />
              </a>
            </div>
            <div class="policy-redirect">
              <a  href="#rights" class="d-flex align-items-center justify-content-between">
                <span>Derechos</span>
                <img src="@/assets/icons/arrow-card.svg" />
              </a>
            </div>
            <div class="policy-redirect">
              <a  href="#info" class="d-flex align-items-center justify-content-between">
                <span>Datos Personales Recolectados y Tratados</span>
                <img src="@/assets/icons/arrow-card.svg" />
              </a>
            </div>
            <div class="policy-redirect">
              <a href="#incharge" class="d-flex align-items-center justify-content-between">
                <span >Encargado de Tratamiento</span>
                <img src="@/assets/icons/arrow-card.svg" />
              </a>
            </div>
            <div class="policy-redirect">
              <a href="#time" class="d-flex align-items-center justify-content-between">
                <span >Tiempo de almacenamiento</span>
                <img src="@/assets/icons/arrow-card.svg" />
              </a>
            </div>
            <div class="policy-redirect">
              <a href="#purpose" class="d-flex align-items-center justify-content-between">
                <span >Finalidad</span>
                <img src="@/assets/icons/arrow-card.svg" />
              </a>
            </div>
            <div class="policy-redirect">
              <a href="#storage" class="d-flex align-items-center justify-content-between">
                <span >Almacenamiento</span>
                <img src="@/assets/icons/arrow-card.svg" />
              </a>
            </div>
            <div class="policy-redirect">
              <a href="#transfer" class="d-flex align-items-center justify-content-between">
                <span 
                  >Trasferencia a terceras partes y flujo transfronterizo</span
                >
                <img src="@/assets/icons/arrow-card.svg" />
              </a>
            </div>
            <div class="policy-redirect">
              <a href="#security" class="d-flex align-items-center justify-content-between">
                <span >Seguridad</span>
                <img src="@/assets/icons/arrow-card.svg" />
              </a>
            </div>
            <div class="policy-redirect">
              <a href="#cookies" class="d-flex align-items-center justify-content-between">
                <span >Cookies</span>
                <img src="@/assets/icons/arrow-card.svg" />
              </a>
            </div>
            <div class="policy-redirect">
              <a href="#age" class="d-flex align-items-center justify-content-between">
                <span >Menores de Edad</span>
                <img src="@/assets/icons/arrow-card.svg" />
              </a>
            </div>
            <div class="policy-redirect">
              <a  href="#link" class="d-flex align-items-center justify-content-between">
                <span>Enlaces a otros sitios web</span>
                <img src="@/assets/icons/arrow-card.svg" />
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-8 col-12">
          <div class="content-policy">
            <h1 class="title">Políticas de privacidad</h1>
            <p class="policy-text">
              De acuerdo con la Ley N° 29733, ley de protección de datos
              personales y su reglamento, a continuación, te informamos sobre la
              Política de Privacidad empleada para la recolección y tratamiento
              de sus datos personales por parte de la JURIS SEARCH PENAL &
              COMPLIANCE (en adelante “Juris Search”), con domicilio legal
              ubicado en la Avenida Víctor Andrés Belaunde Nro. 370, San Isidro.
            </p>
            <p class="policy-text">
              Al acceder a nuestro sitio web o suscribirse a nuestros servicios
              online acepta haber leído y prestado su consentimiento de forma
              informada, libre, previa, expresa e inequívoca. Asimismo, la
              política de privacidad de Juris Search puede actualizarse en
              cualquier momento en cuyo caso se le informará y se publicará la
              versión actualizada en el presente sitio web.
            </p>

            <p class="policy-sub-title" id="starting">
              Principios
            </p>
            <p class="policy-text">
             Juris Search recolecta y procesa sus datos personales respetando los principios: 

            </p>
            <p class="policy-text">                  
              <ul>
                <li>Consentimiento. Previamente a la recolección y tratamiento de los datos, siempre solicitamos el consentimiento del titular de los datos personales. </li>
                <li>Finalidad. La recopilación y tratamiento de los datos personales siguen una finalidad explícita, determinada y lícita.</li>
                <li>Proporcionalidad. La recolección y tratamiento de los datos personales es razonable y no excesiva.</li>
                <li>Calidad. Los datos personales deben de ser, en la medida de lo posible, exactos, veraces y actualizados.</li>
                <li>Seguridad. Se garantizará la seguridad de los datos personales, evitando el acceso no autorizado.</li>
                <li>Legalidad. Rechazamos la obtención fraudulenta o ilícita de los datos personales.</li>
              </ul>
            </p>
            <p class="policy-sub-title"  id="rights">
              Derechos
            </p>
            <p class="policy-text">
              Puede ejercer sus derechos de acceso, rectificación, oposición, cancelación (derechos “ARCO”) 
              sobre sus datos personales. Para su ejercicio, puede enviarnos un correo a datospersonales@jurissearch.com.
              </p>
              <p class="policy-text">
              Revisaremos su solicitud y le responderemos dentro del plazo legal (8 días hábiles
              ante el derecho de información, 20 días hábiles ante el derecho de acceso y 10 
              días hábiles para otros derechos, contándose desde el día siguiente de la
              presentación de su solicitud). En el correo debe incluir su nombre y apellidos 
              completos, copia de DNI o firma digital para acreditar que es el titular de
              los datos personales. Asimismo, debe especificar su petición y el correo o 
              domicilio donde desea que se le envié las comunicaciones.
            </p>
            <p class="policy-sub-title"  id="info">
              Datos Personales Recolectados y Tratados
            </p>
            <p class="policy-text">
              Puede ejercer sus derechos de acceso, rectificación, oposición, cancelación (derechos “ARCO”) 
              sobre sus datos personales. Para su ejercicio, puede enviarnos un correo 
              a datospersonales@jurissearch.com.
            </p>
            <p class="policy-text">
              Juris Search recolecta y trata sus datos personales a través de la información suministrada 
              en nuestro sitio web. Dichos datos pueden incluir su nombre y apellidos, correo electrónico, dirección de domicilio, teléfono, negocio u ocupación, fecha de nacimiento, dirección IP, identificador único de dispositivo (UDID), sus opiniones en encuestas y las estadísticas que genera por el uso de nuestro buscador de jurisprudencia.
            </p>
            
            <p class="policy-sub-title"  id="incharge">
              Encargado de Tratamiento
            </p>
            <p class="policy-text">
              Juris Search no terceriza el tratamiento de sus datos personales, siendo la propia empresa la encargada del tratamiento.
            </p>
            <p class="policy-sub-title"  id="time">
              Tiempo de almacenamiento
            </p>
            <p class="policy-text">
              Juris Search almacena sus datos personales hasta el cumplimiento de los fines de su recolección 
              y/o tratamiento, hasta que se cumplan los plazos exigidos por ley o en el momento en que, como 
              titular de los datos personales, solicite su eliminación.
            </p>
            <p class="policy-sub-title"  id="purpose">
              Finalidad
            </p>
            <p class="policy-text">
            Juris Search recolecta y trata sus datos personales parar el cumplimiento de los siguientes fines: 
            </p>
            <p class="policy-text">                  
              <ul>
                <li>Comercial. A fin de interactuar y perfilar a potenciales clientes o administrar la relación con clientes establecidos. </li>
                <li>Marketing. A fin de realizar investigaciones de mercado, hábitos de consumo, análisis estadístico y satisfacción de clientes mediante encuestas.</li>
                <li>Eficiencia del sitio web. A fin de hacer su uso más intuitivo y responsivo.
                Analizar el uso que los usuarios dan a nuestro sitio web.</li>
              </ul>
            </p>
            <p class="policy-sub-title"  id="storage">
              Almacenamiento
            </p>
            <p class="policy-text">
              Juris Search almacena la información de sus usuarios en nuestros propios servidores ubicados en Lima, Perú.
            </p>
            <p class="policy-sub-title"  id="time">
              Tiempo de almacenamiento
            </p>
            <p class="policy-text">
              Juris Search almacena sus datos personales hasta el cumplimiento de los fines de su recolección 
              y/o tratamiento, hasta que se cumplan los plazos exigidos por ley o en el momento en que, como 
              titular de los datos personales, solicite su eliminación.
            </p>
            <p class="policy-sub-title"  id="transfer">
              Trasferencia a terceras partes y flujo transfronterizo
            </p>
            <p class="policy-text">
              Juris Search se encuentra comprometido con su privacidad. Solo comparte información en caso sea solicitado por (i) las autoridades competentes en cumplimiento de sus funciones y (ii) bajo requerimiento judicial.  Asimismo, precisamos que su información personal no es objeto de transferencia a nivel internacional (flujo transfronterizo).
            </p>
            <p class="policy-sub-title"  id="security">
              Seguridad
            </p>
            <p class="policy-text">
              Juris Search cuenta con medidas de seguridad para garantizar la protección de sus datos personales y que estos no sean alterados, perdidos, accedidos o procesados de forma no autorizada.
            </p>
            <p class="policy-sub-title"  id="cookies">
              Cookies
            </p>
            <p class="policy-text">
              Una cookie es un archivo que se envía su ordenador, celular o tablet al navegador web para almacenar información sobre tu navegación. Nuestro sitio web usa cookies para recolectar información sobre su interacción en el sitio web a fin de mejorar su y analizar las visitas a la web. Para más información, revise nuestra‎‎‏‏‎ ‎‏.
            </p>
            <p class="policy-sub-title"  id="age">
              Menores de Edad
            </p>
            <p class="policy-text">
              El sitio web de Juris Search no está diseñado ni dirigido a menores de 18 años. En caso se recolecten los datos de menores de edad sin autorización, se adoptarán las medidas adecuadas para eliminar dichos datos tan pronto como sea posible.
            </p>
            <p class="policy-sub-title" id="link">
              Enlaces a otros sitios web
            </p>
            <p class="policy-text">
              Este sitio web puede contener enlaces a otros sitios web. Cuando usted hace click en dichos enlaces y abandona nuestra web no nos hacemos responsables de las condiciones de privacidad ni de la protección de sus datos en otros sitios.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Policy",
};
</script>

<style lang="scss" scoped>
$color-light-blue: #0673f2;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #DDE3EB;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;
$color-gray-main: #486169;

.main-policy {
  min-height: 100vh;
  display: flex;
  background: $color-white;
  padding-top: 100px;
  .policy-card-title {
    font-family: Inter-Medium;
    font-weight: 600;
    font-size: 15px;
  }
  .policy-card {
    border-radius: 5px;
    border: 1px solid $color-light-gray;
    .policy-redirect {
      padding: 12px 15px;
      border-bottom: 1px solid $color-light-gray;
      cursor: pointer;

      a {
        font-family: Inter-Medium;
        color: $color-dark;
        text-decoration: none;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }
  .content-policy {
    max-width: 800px;
    margin: auto;
    padding-left: 80px;
    .title {
      font-family: Inter-Bold;
      line-height: 39px;
      font-size: 40px;
      color: $color-dark;
      max-width: 680px;
      margin: 0 auto;
      margin-bottom: 24px;
    }
    .policy-sub-title {
      color: $color-light-blue;
      font-size: 15px;
      font-family: Inter-SemiBold;
      margin-top: 32px;
    }
    .policy-text {
      color: $color-dark;
      font-size: 15px;
      font-family: Inter-Regular;
    }

    @media (max-width: 768px) {
      margin-top: 30px;
      padding-left: 0;
      .title {
        font-size: 15px;
        margin-bottom: 0;
      }

      p{
        font-size: 12px!important;
      }
    }
  }
}
</style>