import UserRoot from "@/views/layouts/user/UserRoot.vue";
import Help from "@/views/subscriber/Help.vue";
import Documents from "@/views/subscriber/documents/DocumentsSubscriber.vue";
import Results from "@/views/subscriber/results/ResultsSubscriber.vue";
import Questions from "@/views/subscriber/Questions.vue";
import About from "@/views/subscriber/About.vue";
import Terms from "@/views/subscriber/Terms.vue";
import CookiePolicy from "@/views/subscriber/CookiePolicy.vue";
import Policy from "@/views/subscriber/Policy.vue";

import Search from "@/views/subscriber/search/SearchSubscriber.vue";

import ResultsGeneral from "@/views/subscriber/results/general/ResultsGeneral.vue";
import ResultsJuristiction from "@/views/subscriber/results/jurisdiction/ResultsJurisdiction.vue";
import ResultsLegislation from "@/views/subscriber/results/legislation/ResultsLegislation.vue";

import Profile from "@/views/subscriber/profile/EditProfile.vue";
import usuarioProxy from "../proxies/UserProxy";

const ifAuthenticated = async (to, from, next) => {
    await usuarioProxy.validate()
        .then((response) => {
            if (response) {
                to.params.role = response.DATA;
                next()
            } else {
                next('/auth/login')
            }
        })
        .catch((error) => {
            next('/auth/login')
        })

}

export default [
    {
        path: "/usuario",
        component: UserRoot,
        children: [
            {
                path: 'busqueda',
                name: 'Search',
                component: Search,
                beforeEnter: ifAuthenticated,
                props: (route) => ({
                    role: route?.params?.role || []
                }),
            },
            {
                path: 'resultados',
                name: 'Results',
                component: Results,
                children: [
                    {
                        path: "generales",
                        name: "ResultsGeneral",
                        component: ResultsGeneral,
                        props: {
                            typeFilter: 0
                        },
                        beforeEnter: ifAuthenticated,
                        props: (route) => ({
                            role: route?.params?.role || []
                        }),
                    },
                    {
                        path: "jurisprudencia",
                        name: "ResultsJuristiction",
                        component: ResultsJuristiction,
                        props: {
                            typeFilter: 0
                        },
                        beforeEnter: ifAuthenticated,
                        props: (route) => ({
                            role: route?.params?.role || []
                        }),
                    },
                    {
                        path: "legislacion",
                        name: "ResultsLegislation",
                        component: ResultsLegislation,
                        props: {
                            typeFilter: 1
                        },
                        beforeEnter: ifAuthenticated,
                        props: (route) => ({
                            role: route?.params?.role || []
                        }),
                    }
                ]
            },
            {
                path: 'documentos',
                name: 'Documents',
                component: Documents,
                beforeEnter: ifAuthenticated,
                props: (route) => ({
                    role: route?.params?.role || []
                }),
            },
            {
                path: 'perfil',
                name: 'Profile',
                component: Profile,
                beforeEnter: ifAuthenticated,
            },
            {
                path: 'ayuda',
                name: 'Help',
                component: Help
            },
            {
                path: 'preguntas',
                name: 'Questions',
                component: Questions,
            },
            {
                path: 'nosotros',
                name: 'About',
                component: About,
            },
            {
                path: 'terminos-y-condiciones',
                name: 'Terms',
                component: Terms,
            },
            {
                path: 'politicas-de-cookies',
                name: 'CookiePolicy',
                component: CookiePolicy
            },
            {
                path: 'politicas-de-privacidad',
                name: 'Policy',
                component: Policy,
            },
        ]
    }
]