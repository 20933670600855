<template>
  <div class="filter-user-global">
    <div class="form-filter-user">
      <div class="accordion-header" @click="toggleAccordion('criteriosGenerales')">
        <p>
          <span class="accordion-icon">{{ accordionStatus.criteriosGenerales ? '- ' : '+ ' }}</span>
          Criterios Generales
        </p>
      </div>
      <div v-show="accordionStatus.criteriosGenerales" class="accordion-content row">
        <div class="col-12">
          <b-form-group>
            <label class="mb-0">Año de resolución</label>
            <date-picker v-model="filter.FRESOLUTION1" valueType="format" type="year" range></date-picker>
          </b-form-group>
        </div>

        <div v-if="filter.TYPE == 'jurisprudences'" class="col-12">
          <b-form-group>
            <label class="mb-0" for="DELITO">Delito </label>
            <treeselect id="DELITO" :multiple="true" :options="selects.DELITOS" placeholder="Seleccione una opción"
              v-model="filter.DELITO" no-children-text="No hay opciones disponibles" no-results-text="No hay resultados"
              clear-on-select>
            </treeselect>
          </b-form-group>
        </div>

        <div v-if="filter.TYPE != 'jurisprudences'"  class="col-12">
          <b-form-group>
            <label for="NMRCN" class="mb-0">Numeración</label>
            <b-form-input type="text" v-model="filter.NMRCN">
            </b-form-input>
          </b-form-group>
        </div>

        <div v-if="filter.TYPE != 'jurisprudences'"  class="col-12">
          <b-form-group>
            <label class="mb-0" for="TPONRMA">Tipo de norma </label>
            <treeselect id="TPONRMA" :multiple="true" :options="selects['TIPO DE NORMA']"
              placeholder="Seleccione una opción" v-model="filter.TPONRMA"
              no-children-text="No hay opciones disponibles" no-results-text="No hay resultados" clear-on-select>
            </treeselect>
          </b-form-group>
        </div>


        <div v-if="filter.TYPE != 'jurisprudences'"  class="col-12">
          <b-form-group>
            <label class="mb-0" for="OEMISOR">Órgano emisor </label>
            <treeselect id="OEMISOR" :multiple="true" :options="selects['ÓRGANO EMISOR']"
              placeholder="Seleccione una opción" v-model="filter.OEMISOR"
              no-children-text="No hay opciones disponibles" no-results-text="No hay resultados" clear-on-select>
            </treeselect>
          </b-form-group>
        </div>

        <div v-if="filter.TYPE == 'jurisprudences'"  class="col-12">
          <b-form-group>
            <label class="mb-0" for="RECURSO">Recurso </label>
            <treeselect id="RECURSO" :multiple="true" :options="selects['TIPO DE RECURSO']"
              placeholder="Seleccione una opción" v-model="filter.RECURSO"
              no-children-text="No hay opciones disponibles" no-results-text="No hay resultados" clear-on-select>
            </treeselect>
          </b-form-group>
        </div>

        <div v-if="filter.TYPE == 'jurisprudences'"  class="col-12">
          <b-form-group>
            <label class="mb-0" for="OJURISDICCIONAL">Órgano Jurisdiccional </label>
            <treeselect id="OJURISDICCIONAL" :multiple="true" :options="selects['ÓRGANO JURISDICCIONAL']"
              placeholder="Seleccione una opción" v-model="filter.OJURISDICCIONAL"
              no-children-text="No hay opciones disponibles" no-results-text="No hay resultados" clear-on-select>
            </treeselect>
          </b-form-group>
        </div>

        <div v-if="filter.TYPE == 'jurisprudences'"  class="col-12">
          <b-form-group>
            <label class="mb-0" for="MAGISTRATES">Por Magistrado </label>
            <treeselect id="MAGISTRATES" :multiple="true" :options="selects['MAGISTRATES']"
              placeholder="Seleccione una opción" v-model="filter.MAGISTRATES"
              no-children-text="No hay opciones disponibles" no-results-text="No hay resultados" clear-on-select>
            </treeselect>
          </b-form-group>
        </div>

        <div v-if="filter.TYPE == 'jurisprudences'"  class="col-12">
          <b-form-group>
            <label class="mb-0" for="JVINCULANTE">Jurisprudencia Vinculante</label>
            <treeselect id="JVINCULANTE" :multiple="true" :options="selects['JURISPRUDENCIA VINCULANTE']"
              placeholder="Seleccione una opción" v-model="filter.JVINCULANTE"
              no-children-text="No hay opciones disponibles" no-results-text="No hay resultados" clear-on-select>
            </treeselect>
          </b-form-group>
        </div>
      </div>

      <div v-if="filter.TYPE == 'jurisprudences'" class="accordion-header mt-1" @click="toggleAccordion('ppjjYCompliance')">
        <p>
          <span class="accordion-icon">{{ accordionStatus.ppjjYCompliance ? '- ' : '+ ' }}</span>
          PPJJ y Compliance
        </p>
      </div>
      <div  v-if="filter.TYPE == 'jurisprudences'"  v-show="accordionStatus.ppjjYCompliance" class="accordion-content row">
        <div v-if="filter.TYPE == 'jurisprudences'"  class="col-12">
          <b-form-group>
            <label class="mb-0">Año de resolución</label>
            <date-picker v-model="filter.FRESOLUTION2" valueType="format" type="year" range></date-picker>
          </b-form-group>
        </div>

        <div v-if="filter.TYPE == 'jurisprudences'"  class="col-12">
          <b-form-group>
            <label class="mb-0" for="MATERIA">Materia </label>
            <treeselect id="MATERIA" :multiple="true" :options="selects['MATERIA']" placeholder="Seleccione una opción"
              v-model="filter.MATERIA" no-children-text="No hay opciones disponibles"
              no-results-text="No hay resultados" clear-on-select>
            </treeselect>
          </b-form-group>
        </div>

        <div v-if="filter.TYPE == 'jurisprudences'"  class="col-12">
          <b-form-group>
            <label class="mb-0" for="JURISDICCION">Jurisdicción</label>
            <treeselect id="JURISDICCION" :multiple="true" :options="selects['JURISDICCIÓN']"
              placeholder="Seleccione una opción" v-model="filter.JURISDICCION"
              no-children-text="No hay opciones disponibles" no-results-text="No hay resultados" clear-on-select>
            </treeselect>
          </b-form-group>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "vue2-datepicker/index.css";
import Treeselect from "@riophae/vue-treeselect";
import CustomSelect from "@/components/select/CustomSelect.vue";
import DatePicker from "vue2-datepicker";

export default {
  name: "FilterUser",
  components: { CustomSelect, Treeselect, DatePicker },
  props: {
    typeFilter: {
      required: true,
    },
    filter: {
      required: true,
    },
    setBusqueda: {
      type: Function,
      required: true,
    },
    selects: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      filtersList: [],
      delitosList: [],
      organosJurisdiccionalesList: [],
      fuentesJurisprudencialesList: [],
      materiaList: [],
      jurisdiccionList: [],
      selected: [],
      accordionStatus: {
        criteriosGenerales: true,
        ppjjYCompliance: false,
      },
    };
  },

  methods: {
    isOcultarFiltro(filtro) {
      return (Object.keys(this.filter).includes(filtro));
    },
    toggleAccordion(section) {
      this.accordionStatus[section] = !this.accordionStatus[section];
    },
  },
  watch: {
    typeFilter: {
      handler() {
      },
      immediate: true,
    },
  },
  async mounted() {
  },
};
</script>

<style lang="scss" coped>
$color-light-blue: #0673f2;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #dde3eb;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;
$color-gray-main: #486169;


.filter-user-global {
  .btn-blue {
    margin-top: 10px !important;
    width: 100% !important;
    background: $color-light-blue !important;
    color: $color-white !important;
    font-size: 15px !important;
    border-radius: 36px !important;
    height: 44px !important;
    padding: 10px 16px !important;
    font-family: Inter-Regular !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-left: 5px !important;
  }
}

.mx-datepicker {
  width: 100%;

  .mx-input-wrapper {
    .mx-input {
      height: 35px;
    }
  }
}

.nav-tabs .nav-link:hover,
.nav-link:focus {
  border-color: transparent !important;
}

.main-filter-search {}

.custom-tabs {
  .nav-tabs {
    background: #dde3eb;
    border-radius: 50px;
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    border-bottom: 0;

    .nav-item {
      width: 50%;

      .nav-link {
        font-family: Inter-Regular;
        font-weight: 400;
        text-align: center;
        color: $color-dark;

        &:focus {
          border-color: 1px solid transparent !important;
        }
      }

      .active {
        font-family: Inter-Regular;
        font-weight: 600;
        font-size: 15px;
        background: $color-white;
        border-radius: 50px;
        text-align: center;
        border: 1px solid $color-light-blue !important;
        padding: 10px;
        color: $color-light-blue;
      }
    }
  }
}

.accordion {
  margin-bottom: 10px;
}

.accordion-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    border-radius: 5px;
    border: 1px solid $color-gray-main;
    color: $color-gray;
    font-family: Inter-Regular;
    font-size: 15px;
    margin: 0;
    padding: 10px;
  }

}

.accordion-content {
  padding: 10px;
  border-top: none;
  border-radius: 0 0 5px 5px;
}

.accordion-icon {
  font-size: 10px;
}
</style>
