import AdminRoot from "@/views/layouts/admin/AdminRoot.vue";
import EntriesJurisdiction from "@/views/admin/entries/jurisdiction/Jurisdiction.vue";
import EntriesLegislation from "@/views/admin/entries/legislation/Legislation.vue";

import EntriesCreate from "@/views/admin/entries/create/EntriesCreate.vue"
import CommonEntries from "@/views/admin/entries/create/CommonEntries.vue"
import EmblematicEntries from "@/views/admin/entries/create/EmblematicEntries.vue"
import ExecutiveEntries from "@/views/admin/entries/create/ExecutiveEntries.vue"
import LegislationEntries from "@/views/admin/entries/create/LegislationEntries.vue";

import EntriesEdit from "@/views/admin/entries/edit/EntriesEdit.vue";
import CommonEntriesEdit from "@/views/admin/entries/edit/CommonEntriesEdit.vue"
import EmblematicEntriesEdit from "@/views/admin/entries/edit/EmblematicEntriesEdit.vue"
import ExecutiveEntriesEdit from "@/views/admin/entries/edit/ExecutiveEntriesEdit.vue"
import LegislationEntriesEdit from "@/views/admin/entries/edit/LegislationEntriesEdit.vue"

import SubscribersIndex from "@/views/admin/users/subscribers/SubscribersIndex.vue";
import SubscribersCreate from "@/views/admin/users/create/SubscribersCreate.vue";
import SubscribersEdit from "@/views/admin/users/edit/EditUser.vue";

import SearchAdmin from "@/views/admin/search/SearchAdmin.vue";

import DocumentsAdminJuristictionDetail from "@/views/admin/documents/jurisdiction/detail/DocumentsAdminJuristictionDetail.vue";
import DocumentsAdminLegislationDetail from "@/views/admin/documents/legislation/detail/DocumentsAdminLegislationDetail.vue";

import Digitizers from "@/views/admin/users/digitizers/Digitizers.vue";
import Administrators from "@/views/admin/users/administrators/Administrators.vue";

import IndexFiltros from "@/views/admin/filtros/Index.vue";

import Entries from "@/views/admin/entries/Entries.vue";
import Users from "@/views/admin/users/Users.vue";

import Settings from "@/views/admin/settings/Settings.vue";
import usuarioProxy from "../proxies/UserProxy";

// * Mantenimiento *//
import IndexMantenimiento from "../views/admin/mantenimiento/Index.vue";
import Help from "@/views/subscriber/Help.vue";
import Questions from "@/views/subscriber/Questions.vue";
import About from "@/views/subscriber/About.vue";
import Terms from "@/views/subscriber/Terms.vue";
import CookiePolicy from "@/views/subscriber/CookiePolicy.vue";
import Policy from "@/views/subscriber/Policy.vue";

const ifAuthenticated = async (to, from, next) => {
    await usuarioProxy.validate()
        .then((response) => {
            if (response?.STATUS) {
                to.params.role = response.DATA;
                next()
            } else {
                next('/auth/login')
            }
        })
        .catch((error) => {
            next('/auth/login')
        })
}

export default [
    {
        path: "/admin",
        component: AdminRoot,
        children: [
            {
                path: 'entradas',
                name: 'Entries',
                component: Entries,
                children: [
                    {
                        path: 'jurisprudencia',
                        name: 'EntriesJurisdiction',
                        component: EntriesJurisdiction,
                        beforeEnter: ifAuthenticated,
                        props: (route) => ({
                            entrieType: 'jurisprudences',
                            role: route.params.role
                        }),

                    },
                    {
                        path: 'legislacion',
                        name: 'EntriesLegislation',
                        component: EntriesLegislation,
                        beforeEnter: ifAuthenticated,
                        props: (route) => ({
                            entrieType: 'legislations',
                            role: route.params.role
                        }),

                    }
                ],
            },
            {
                path: 'crear/entrada',
                name: 'EntriesCreate',
                component: EntriesCreate,
                children: [
                    {
                        path: 'comun',
                        name: 'CommonEntries',
                        component: CommonEntries,
                        beforeEnter: ifAuthenticated
                    },
                    {
                        path: 'emblematico',
                        name: 'EmblematicEntrie',
                        component: EmblematicEntries,
                        beforeEnter: ifAuthenticated
                    },
                    {
                        path: 'ejecutivo',
                        name: 'ExecutiveEntries',
                        component: ExecutiveEntries,
                        beforeEnter: ifAuthenticated
                    },
                    {
                        path: 'legislacion',
                        name: 'LegislationEntries',
                        component: LegislationEntries,
                        beforeEnter: ifAuthenticated
                    }
                ]
            },
            {
                path: 'editar/entrada',
                name: 'EntriesEdit',
                component: EntriesEdit,
                children: [
                    {
                        path: 'comun/:id',
                        name: 'CommonEntriesEdit',
                        component: CommonEntriesEdit,
                        beforeEnter: ifAuthenticated
                    },
                    {
                        path: 'emblematico/:id',
                        name: 'EmblematicEntriesEdit',
                        component: EmblematicEntriesEdit,
                        beforeEnter: ifAuthenticated
                    },
                    {
                        path: 'ejecutivo/:id',
                        name: 'ExecutiveEntriesEdit',
                        component: ExecutiveEntriesEdit,
                        beforeEnter: ifAuthenticated
                    },
                    {
                        path: 'legislacion/:id',
                        name: 'LegislationEntriesEdit',
                        component: LegislationEntriesEdit,
                        beforeEnter: ifAuthenticated
                    },
                ]
            },
            {
                path: 'filtros',
                name: 'IndexFiltros',
                component: IndexFiltros,
                beforeEnter: ifAuthenticated,
                props: (route) => ({
                    role: route.params.role
                }),
            },
            {
                path: 'busqueda',
                name: 'SearchAdmin',
                component: SearchAdmin,
            },
            {
                path: "documentos/jurisprudencia/:id",
                name: "DocumentsAdminJuristictionDetail",
                component: DocumentsAdminJuristictionDetail,
                beforeEnter: ifAuthenticated,
            },
            {
                path: "documentos/legislacion/:id",
                name: "DocumentsAdminLegislationDetail",
                component: DocumentsAdminLegislationDetail,
                beforeEnter: ifAuthenticated,
            },
            {
                path: 'usuarios',
                name: 'Users',
                component: Users,
                children: [
                    {
                        path: 'subscriptores',
                        name: 'SubscribersIndex',
                        component: SubscribersIndex,
                        beforeEnter: ifAuthenticated,
                        props: (route) => ({
                            role: route.params.role,
                            buttonText: 'Subscriptor',
                            userType: 2,
                        }),
                    },
                    {
                        path: 'digitadores',
                        name: 'Digitizers',
                        component: Digitizers,
                        beforeEnter: ifAuthenticated,
                        // props: {
                        //     userType: 1,
                        //     buttonText: 'Digitador'
                        // }
                        props: (route) => ({
                            role: route.params.role,
                            buttonText: 'Digitador',
                            userType: 1,
                        }),
                    },
                    {
                        path: 'administradores',
                        name: 'Administrators',
                        component: Administrators,
                        beforeEnter: ifAuthenticated,
                        // props: {
                        //     userType: 0,
                        //     buttonText: 'Administrador'
                        // }
                        props: (route) => ({
                            role: route.params.role,
                            buttonText: 'Administrador',
                            userType: 0,
                        }),
                    },
                ]
            },
            {
                path: 'crear/:id',
                name: 'SubscribersCreate',
                component: SubscribersCreate,
                beforeEnter: ifAuthenticated,
                props: {
                    role: 1
                }
            },
            {
                path: 'editar/:id',
                name: 'SubscribersEdit',
                component: SubscribersEdit,
                beforeEnter: ifAuthenticated,
                props: {
                    role: 1
                }
            },
            {
                path: 'ajustes',
                name: 'Settings',
                component: Settings,
                beforeEnter: ifAuthenticated
            },
            {
                path: 'mantenimiento',
                name: 'inicio',
                component: IndexMantenimiento,
                beforeEnter: ifAuthenticated,
                props: (route) => ({
                    role: route.params.role
                }),
            },
            {
                path: 'ayuda',
                name: 'Help-admin',
                component: Help
            },
            {
                path: 'preguntas',
                name: 'Questions-admin',
                component: Questions,
            },
            {
                path: 'nosotros',
                name: 'About-admin',
                component: About,
            },
            {
                path: 'terminos-y-condiciones',
                name: 'Terms-admin',
                component: Terms,
            },
            {
                path: 'politicas-de-cookies',
                name: 'CookiePolicy-admin',
                component: CookiePolicy
            },
            {
                path: 'politicas-de-privacidad',
                name: 'Policy-admin',
                component: Policy,
            },
        ],

    }
]