<template>
  <div class="logout-modal">
    <h1>¿Desea cerrar sesión?</h1>
    <div class="d-flex">
      <button @click="$emit('closeModal')" class="btn btn-white">No, quedarme</button>
      <button @click="$emit('logout')" class="btn btn-blue">Si, cerrar sesión</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "LogoutModal",
};
</script>

<style lang="scss" scoped>
$color-light-blue: #0673f2;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #DDE3EB;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;
$color-gray-main: #486169;
.logout-modal {
    h1{
        font-size: 24px;
        color: $color-dark;
        font-family: Inter-Regular;
        font-weight: 700;
        margin-bottom: 30px;
        text-align: center;
    }
  .btn-white {
    max-width: 250px;
    width: 100%;
    background: $color-white;
    color: $color-dark;
    font-size: 15px;
    border-radius: 36px !important;
    height: 44px;
    padding: 10px 16px;
    font-family: Inter-Regular;
    display: flex;
    align-items: center;
    border: 1px solid $color-light-gray;
    justify-content: center;
    margin-right: 5px;
  }
  .btn-blue {
    max-width: 250px;
    width: 100%;
    background: $color-light-blue;
    color: $color-white !important;
    font-size: 15px;
    border-radius: 36px;
    height: 44px;
    padding: 10px 16px;
    font-family: Inter-Regular;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
  }
}
</style>