<template>
    <div>
        <p v-b-toggle.collapse-1 @click="getAuditoria()" class="btn-collapse">
            <span v-if="isAuditoria">-</span>
            <span v-else>+</span>
            Auditoría
        </p>
        <b-collapse id="collapse-1" class="mt-2">
            <dataTable :role="{}" :loading="loadingAuditoria" :search="() => { }" :auditoria="true" :fieldsComponent="fields"
                :actions="actions" :rowData="rowData" />
        </b-collapse>
    </div>
</template>

<script>
import AuditoriaProxy from "../../proxies/AuditoriaProxy";
import DataTable from "./DataTable.vue";

export default {
    name: "Auditoria",
    components: {
        DataTable,
    },
    props: {
        url: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isAuditoria: false,
            pag: {
                loading: false,
                perPage: 10,
                init: 0,
            },
            fields: [
                { key: "RN", label: "" },
                { key: "USUARIO", label: "Usuario", width: "30%" },
                { key: "ACCION", label: "Movimiento", width: "30%" },
                { key: "FECHA", label: "Fecha", width: "30%" },
            ],
            actions:
            {

            },
            rowData: [],
            loadingAuditoria: false,
        };
    },
    methods: {
        async getAuditoria() {
            this.isAuditoria = !this.isAuditoria;
            if (!this.isAuditoria) {
                return;
            }
            
            this.loadingAuditoria = true;
            await AuditoriaProxy.list({
                URL: this.url,
                INIT: 0,
                ROWS: 10,
                DESC: "",
            })
                .then((response) => {
                    this.rowData = response;
                })
                .catch((error) => {
                    this.$bvToast.toast(`Ocurrió un error al    obtener la auditoría: ${error}`, {
                        title: "ERROR",
                        variant: "danger",
                        solid: true,
                    });
                })
                .finally(() => {
                    this.loadingAuditoria = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
$color-light-blue: #1864FF;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #fff;
$color-light-gray: #dfdfdf;
$color-gray: #737471;

.btn-collapse {
    cursor: pointer;
    color: $color-gray;
    font-size: .8rem;
    font-weight: 500;
    display: inline-block;
    width: auto;
    padding: 0.5rem 1rem;
    background-color: transparent;
    border-radius: 0.25rem;
    border: 1px solid $color-light-gray;


    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
}
</style>