import Vue from 'vue';
import VueRouter from 'vue-router';
import store from "./../store";
import authRoutes from "@/router/auth.js";
import adminRoutes from "@/router/admin.js";
import userRoutes from "@/router/subscriber.js";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...authRoutes,
    ...adminRoutes,
    ...userRoutes
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
