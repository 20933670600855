<template>
  <div class="document-modal">
    <div class="d-flex justify-content-between mb-2">
      <span class="title">{{ title }}</span>
      <img
        @click="$emit('closeDocumentModal')"
        src="@/assets/icons/close-gray.svg"
        class="mr-2"
      />
    </div>
    <div class="content-pdf">
      <iframe
        :src="documentFile"
        style="width: 100%; height: 100%"
        frameborder="0"
      ></iframe>
    </div>
    <div class="actions">
      <a class="btn btn-white" :href="documentFile" target="_blank">
        Descargar <img src="@/assets/icons/download-gray.svg" class="mr-2" />
      </a>
      <button class="btn btn-white" @click="handleUserDocument()">
        {{
          isFavorite ? "Eliminar de mis documentos" : "Añadir a mis documentos"
        }}
        <img src="@/assets/icons/favorite.svg" class="mr-2" />
      </button>
    </div>
  </div>
</template>

<script>
import documentProxy from "@/proxies/DocumentsProxy.js";
export default {
  name: "DocumentModal",
  props: {
    documentFile: {
      required: true,
    },
    title: {
      required: true,
    },
    documentId: {
      required: true,
    },
    documentType: {
      required: true,
    },
    userId: {
      required: true,
    },
    isFavorite: {
      required: true,
    },
    reload: {
      type: Function,
      required: true,
    },
  },
  methods: {
    async handleUserDocument() {
      try {
        const documentData = {
          document: {
            document_id: this.documentId,
            document_type: this.documentType,
          },
        };
        if (this.isFavorite) {
          await documentProxy.deleteUserDocument(
            this.userId,
            this.documentId,
            this.documentType
          );
        } else {
          await documentProxy.postUserDocument(this.userId, documentData);
        }
      } catch (error) {
        console.log("ERROR on saving user document");
        console.log(error);
      } finally {
        this.$emit("closeDocumentModal");
        this.reload();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.b-modal {
  .modal-dialog {
    max-width: 800px !important;
  }
}
.document-modal {
  background: white;
  .content-pdf {
    height: 400px;
    margin-bottom: 20px;
  }
  .actions {
    display: flex;
    justify-content: center;
    .btn-white {
      margin: 0 10px;
      border: 1px solid #dfdfdf;
      font-family: Inter-Medium;
      border-radius: 4px;
      padding: 12px;
    }
  }
}
</style>