import AuthRoot from "@/views/layouts/auth/AuthRoot";
import AdminLogin from "@/views/auth/AdminLogin";
import Redirect from "@/views/auth/Redirect";
import Login from "@/views/auth/Login";
import Recover from "@/views/auth/Recover";
import RecoverPassword from "@/views/auth/RecoverPassword";
import autoUser from "../views/auth/autoUser.vue";
import IsRecover from "@/views/auth/IsRecover.vue";

const ifNotAuthenticated = (to, from, next) => {
    const token = localStorage.getItem("user-token");

    // if (!token) {
        // if (from.name !== to.name) {
            next()
    //     }
    // } else {
    //     next("/auth/redirect");
    // }
};

export default [
    {
        path: "/auth",
        name: "Auth",
        component: AuthRoot,
        children: [
            {
                path: 'admin',
                name: 'AdminLogin',
                component: AdminLogin
            },
            {
                path: 'login',
                name: 'Login',
                component: Login,
                beforeEnter: ifNotAuthenticated
            },
            {
                path: 'redirect',
                name: 'Redirect',
                component: Redirect
            },
            {
                path: 'recover',
                name: 'Recover',
                component: Recover,

            },
            {
                path: 'recover/:token',
                name: 'RecoverPassword',
                component: RecoverPassword,
            },
            {
                path: 'autoUser/:token',
                name: 'autoUser',
                component: autoUser,
            },
            {

                path: 'recovery/:token',
                name: 'IsRecover',
                component: IsRecover,
            }
        ]

    },
    {
        path: "/",
        redirect: "/auth/login"
    }
];