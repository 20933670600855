<template>
    <b-modal id="modal-noticia-crear" v-model="show" title="Crear Noticia" size="lg" ok-title="Crear"
        cancel-title="Cancelar" @ok="create"
        
        >
        <b-form ref="form" @submit.stop.prevent="create">
            <div class="row">
                <b-form-group class="col-12" :state="getValidationState('TITULO')">
                    <label class="label-control">Titulo</label>
                    <b-form-input v-model="model.TITULO" :state="getValidationState('TITULO')"
                        @input="validateOnInput('TITULO')"></b-form-input>
                    <b-form-invalid-feedback :state="getValidationState('TITULO')">
                        {{ getValidationMessage('TITULO') }}
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group class="col-12" :state="getValidationState('IMAGEN')">
                    <label for="file"
                        class="rs-form-control-label block mb-2 text-sm font-normal text-gray-500 dark:text-gray-500">
                        Imagen <span class="text-danger">*</span>
                    </label>
                    <div class="relative">
                        <label for="file_input" class="text-sm choose"></label>
                        <input value="model.IMAGEN"
                            accept="image/png, image/jpeg, image/jpg, image/svg+xml, image/webp"
                            class="w-100 text-sm choose text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                            type="file" id="file_input" name="file_input"
                            @change="model.IMAGEN = $event.target.files[0]" />
                        <b-form-invalid-feedback :state="getValidationState('IMAGEN')">
                            {{ getValidationMessage('IMAGEN') }}
                        </b-form-invalid-feedback>
                    </div>
                </b-form-group>

                <b-form-group class="col-12" :state="getValidationState('DESCRIPCION')">
                    <label class="label-control">Descripción</label>
                    <b-form-textarea v-model="model.DESCRIPCION" :state="getValidationState('DESCRIPCION')"
                        @input="validateOnInput('DESCRIPCION')"></b-form-textarea>
                    <b-form-invalid-feedback :state="getValidationState('DESCRIPCION')">
                        {{ getValidationMessage('DESCRIPCION') }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </div>
        </b-form>
    </b-modal>

</template>

<script>
import MantenimientoProxy from "../../../../proxies/MantenimientoProxy";

export default {
    name: "ModalAgregarNoticia",
    props: {
        openModal: {
            type: Boolean,
            default: false
        },
        toggleModal: {
            type: Function,
            required: true
        },
        search: {
            type: Function,
            required: true
        },
        objectValidator: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            show: false,
            model: {
                TITULO: null,
                DESCRIPCION: null,
                IMAGEN: null,
            },
            validators: {
                TITULO: {
                    REQUIRED: true
                },
                DESCRIPCION: {
                    REQUIRED: true
                },
                IMAGEN: {
                    REQUIRED: true
                }
            },
            fieldTouched: {},
            isAction: false
        }
    },
    methods: {
        getValidationState(field) {
            return this.objectValidator.getValidationState(field, this.fieldTouched, this.validators, this.model);
        },
        getValidationMessage(field) {
            return this.objectValidator.getValidationMessage(field, this.validators, this.model);
        },
        checkFormValidity() {
            return this.objectValidator.checkFormValidity(this.validators, this.model);
        },
        clearErrors() {
            this.objectValidator.clearErrors(this.validators, this.model, this.fieldTouched);
        },
        validateOnInput(field) {
            this.objectValidator.validateOnInput(field, this.fieldTouched);
        },
        onForceInput() {
            const fields = Object.keys(this.model);
            fields.forEach((field) => {
                this.fieldTouched = {
                    ...this.fieldTouched,
                    [field]: true,
                };
            });
        },
        async create(bvModalEvent) {
            bvModalEvent.preventDefault();
            this.onForceInput();
            if (!this.checkFormValidity()) return;

            this.$bvToast.hide();
            let formData = new FormData();
            formData.append("TITULO", this.model.TITULO);
            formData.append("DESCRIPCION", this.model.DESCRIPCION);
            formData.append("files", this.model.IMAGEN);

            this.isAction = true;
            await MantenimientoProxy.create(formData)
                .then((response) => {
                    const toastMessage = response.MESSAGE;
                    this.$bvToast.toast(toastMessage, {
                        title: response.STATUS ? "Éxito" : "Error",
                        variant: response.STATUS ? "primary" : "danger",
                        solid: true,
                    });

                    if (response.STATUS) {
                        this.show = false;
                        this.search();
                    }
                })
                .catch((error) => {
                    this.$bvToast.toast(`Ocurrió un error al crear la noticia`, {
                        title: "Error",
                        variant: "danger",
                        solid: true,
                    });
                })
                .finally(() => {
                    this.isAction = false;
                });
        }
    },
    watch: {
        openModal() {
            this.show = this.openModal
        },
        show(val) {
            if (!val) {
                this.toggleModal();
                this.clearErrors();
                this.isAction = false;
            }
        },
        fieldTouched() {

        }
    }

}

</script>