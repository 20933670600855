<template>
  <div>
    <div class="main-jurisdiction-detail">
      <div class="content-return">
        <div class="container">
          <a class="back-text" @click="$router.go(-1)">
            <img src="@/assets/icons/back-arrow.svg" class="mr-2" />
            Regresar a resultados
          </a>
        </div>
      </div>
      <div class="container">
        <div class="content-jurisdiction-detail">
          <div>
            <h5 class="main-title">
              {{ document.title }}
            </h5>
            <div class="row">
              <div class="col-lg-4 content-main-info">
                <h5 class="title-blue pb-4">IDENTIFICACIÓN</h5>

                <div class="row main-table">
                  <div class="col-lg-5">
                    <span class="title">Ámbito</span>
                  </div>
                  <div class="col-lg-7">
                    <span class="sub-title">{{ document.ambit }}</span>
                  </div>
                </div>
                <div class="row main-table" v-if="document.type === 1">
                  <div class="col-lg-5">
                    <span class="title">Caso</span>
                  </div>
                  <div class="col-lg-7">
                    <span class="sub-title">{{ document.case }}</span>
                  </div>
                </div>
                <div class="row main-table">
                  <div class="col-lg-5">
                    <span class="title">Fecha de Resolución</span>
                  </div>
                  <div class="col-lg-7">
                    <span class="sub-title">{{
                      document.resolution_date | moment("DD/MM/YYYY")
                    }}</span>
                  </div>
                </div>
                <div class="row main-table">
                  <div class="col-lg-5">
                    <span class="title">Órgano Jurisdiccional</span>
                  </div>
                  <div class="col-lg-7">
                    <span class="sub-title">{{ jurisdictionalOrgan }}</span>
                  </div>
                </div>
                <div class="row main-table">
                  <div class="col-lg-5">
                    <span class="title">Magistrados</span>
                  </div>
                  <div class="col-lg-7">
                    <span class="sub-title mr-2" v-for="magistrate in document.magistrates" :key="magistrate">{{
                      magistrate
                    }}</span>
                  </div>
                </div>
                <div class="row main-table">
                  <div class="col-lg-5">
                    <div class="d-flex flex-column">
                      <span class="title">Voto Disidente</span>
                      <small> Voto que discrepa del fallo final adoptado.</small>
                    </div>
                  </div>
                  <div class="col-lg-7">
                    <span class="sub-title">{{ dissentingVote }}</span>
                  </div>
                </div>
                <div class="row main-table">
                  <div class="col-lg-5">
                    <div class="d-flex flex-column">
                      <span class="title">Voto concurrente</span>
                      <small> Voto que disiente de la argumentación jurídica, pero no del fallo final adoptado.</small>
                    </div>
                  </div>
                  <div class="col-lg-7">
                    <span class="sub-title">{{ concurringVote }}</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-8 content-main-resumen">
                <h5 class="title-blue pb-4">CONTENIDO</h5>
                <div class="row main-table">
                  <div class="col-lg-5">
                    <span class="title">Tema</span>
                  </div>
                  <div class="col-lg-7">
                    <span class="sub-title" v-html="document.theme"></span>
                  </div>
                </div>
                <div class="row main-table">
                  <div class="col-lg-5">
                    <span class="title">Subtema</span>
                  </div>
                  <div class="col-lg-7">
                    <span class="sub-title" v-html="document.sub_theme"></span>
                  </div>
                </div>
                <div class="content-words">
                  <div class="row main-table">
                    <div class="col-lg-5">
                      <span class="title">Palabras clave</span>
                    </div>
                    <div class="col-lg-7">
                      <div class="keywords">
                        <span class="text text-break" v-html="keywords"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="content-sumilla">
                  <p class="title">Síntesis de los fundamentos jurídicos relevantes</p>
                  <p class="sub-title" v-html="document.short_summary" v-if="document.type === 0"></p>
                  <p class="sub-title" v-html="document.synthesis_fundamentals" v-if="document.type === 1"></p>
                </div>
                <div class="content-sumilla" :style="{ 'background-color': 'antiquewhite' }">
                  <p class="title">Fundamentos jurídicos relevantes</p>
                  <p class="sub-title" v-html="document.resumen" v-if="document.type === 0"></p>
                  <p class="sub-title" v-html="document.relevant_fundamentals" v-if="document.type === 1"></p>
                </div>
                <div class="content-sumilla" v-if="document.type === 1">
                  <p class="title">Resumen</p>
                  <p class="sub-title" v-html="document.resumen"></p>
                </div>
                <div class="content-words">
                  <div v-if="document.document_url">
                    <button class="btn btn-blue" @click="onModalAction(documentFile)">
                      Ver documento
                    </button>
                  </div>
                  <div v-if="document.document_url_resumen">
                    <button class="btn btn-blue resumen" @click="onModalAction(documentResumenFile)">
                      Ver resumen ejecutivo
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="content-jurisdiction-detail">
          <div>
            <h5 class="main-title">
              Relacionados
            </h5>
            <div class="related-items">
              <div class="col-3" v-for="(item, index) in relateds" :key="index">
                <jurisprudence-admin-related-item :jurisprudence="item" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="documentModal" centered modal-class="b-jurisdiction" :hide-footer="true" :hide-header="true"
      ok-only no-stacking>
      <document-modal @closeDocumentModal="closeDocumentModal" :documentFile="modalFile" :title="document.title" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import documentProxy from "@/proxies/DocumentsProxy.js";
import DocumentModal from "@/components/admin/modal/DocumentModal.vue";
import filterProxy from "@/proxies/FilterProxy.js";
import JurisprudenceAdminRelatedItem from "./JurisprudenceAdminRelatedItem.vue";

export default {
  name: "DocumentsJuristictionDetail",
  components: { DocumentModal, JurisprudenceAdminRelatedItem },
  data() {
    return {
      documentModal: false,
      document: {},
      relateds: [],
      modalFile: null,
      documentFile: null,
      documentResumenFile: null,
      documentId: this.$route.params.id,
      isLoading: false,
      documents: null,
      jurisdictionalOrgan: "-",
      dissentingVote: "-",
      concurringVote: "-",
      keywords: "",
    };
  },
  computed: {
    ...mapGetters(["getProfile"]),
  },
  watch: {
    "$route.params.id"(val) {
      this.documentId = val;
    },
    async documentId(val) {
      await this.reload();
    },
    documents: function (val) {
      const userDocuments = val;
      let onlyDocumentsIds = [];
      userDocuments.map((document) => {
        if (document !== null) {
            onlyDocumentsIds.push(document.id);
          }
      });
      if (userDocuments.length) {
        if (onlyDocumentsIds.includes(Number(this.documentId))) {
          this.document = { ...this.document, ...{ isFavorite: true } };
        } else {
          this.document = { ...this.document, ...{ isFavorite: false } };
        }
      }
    },
  },
  methods: {
    onModalAction(fileUrl) {
      this.documentModal = true;
      this.modalFile = fileUrl;
    },
    async getFilterById(id) {
      const { filter } = await filterProxy.get(id);

      return filter;
    },
    async getDocumentById() {
      let loader = this.$loading.show({
        canCancel: false,
        color: "#007bff",
      });
      try {
        this.isLoading = true;

        const { jurisprudence } = await documentProxy.getById(this.documentId);
        const { relateds } = await documentProxy.getRelatedsById(this.documentId);

        this.document = jurisprudence;
        this.relateds = relateds;
        this.keywords = jurisprudence.keywords.length ? jurisprudence.keywords.map((keyword) => keyword.title).join() : "";

      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
        loader.hide();
      }
    },
    closeDocumentModal() {
      this.documentModal = false;
    },
    async getUserDocuments() {
      let loader = this.$loading.show({
        canCancel: false,
        color: "#007bff",
      });
      try {
        const { id } = this.getProfile;
        const {
          user: { documents },
        } = await documentProxy.getUserDocuments(id, 0);
        this.documents = documents;
      } catch (error) {
        console.log("ERROR on gettings user document");
        console.log(error);
      } finally {
        loader.hide();
      }
    },
    async reload() {
      this.documentModal = false;
      await this.getDocumentById();
      const { jurisdictional_organ_view, dissenting_vote_view, concurring_vote_view } = this.document;
      if (jurisdictional_organ_view) {
        this.jurisdictionalOrgan = jurisdictional_organ_view.title;
      }
      if (dissenting_vote_view) {
        this.dissentingVote = dissenting_vote_view.title;
      }
      if (concurring_vote_view) {
        this.concurringVote = concurring_vote_view.title;
      }
      if (this.document.document_url) {
        this.documentFile = this.document.document_url;
      }
      if (this.document.document_url_resumen) {
        this.documentResumenFile = this.document.document_url_resumen;
      }
      await this.getUserDocuments();
    },
  },
  async mounted() {
    await this.reload();
  },
};
</script>
<style lang="scss" >
.b-jurisdiction {
  .modal-dialog {
    max-width: 800px !important;
  }
}
</style>
<style lang="scss" scoped>
$color-light-blue: #0673f2;
$color-darker-blue: #486aa3;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #dde3eb;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;
$color-weight-gray: #486169;

.content-main-resumen {
  padding-left: 30px;
  border-left: 1px solid $color-light-gray;
}

.content-main-info {
  padding-right: 30px;
}

.main-jurisdiction-detail {
  min-height: 100vh;
  width: 100%;
  padding-top: 69px;
  padding-bottom: 100px;
  background: $color-white-gray;

  .content-return {
    background: white;
    padding: 17px 0;

    .back-text {
      font-family: Inter-Medium;
      font-weight: 500;
      color: $color-weight-gray;

      &:hover {
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  .content-jurisdiction-detail {
    background: white;
    padding: 32px;
    margin-top: 24px;

    .main-title {
      font-family: Inter-Medium;
      font-weight: 700;
      color: $color-dark;
      font-size: 32px;
      line-height: 39px;
      padding-bottom: 30px;
    }

    .title-blue {
      color: $color-darker-blue;
      font-family: Inter-Medium;
      font-weight: 600;
      line-height: 19px;
      font-size: 16px;
    }

    .main-table {
      border-bottom: 1px solid $color-light-gray;
      padding: 12px 0;

      &:last-child {
        border-bottom: 0px;
        margin-top: 12px;
      }

      .title {
        font-family: Inter-Medium;
        font-weight: 600;
        color: $color-dark;
        font-size: 15px;
        line-height: 140%;
      }

      .sub-title {
        font-family: Inter-Medium;
        font-weight: 400;
        color: $color-dark;
        font-size: 15px;
        line-height: 140%;
      }
    }

    .content-sumilla {
      padding: 12px 0;
      border-bottom: 1px solid $color-light-gray;

      .title {
        font-family: Inter-Medium;
        font-weight: 600;
        color: $color-dark;
        font-size: 15px;
        line-height: 140%;
      }

      .sub-title {
        font-family: Inter-Medium;
        font-weight: 400;
        color: $color-dark;
        font-size: 15px;
        line-height: 140%;
      }
    }

    .content-words {
      padding: 12px 0;

      .title {
        font-family: Inter-Medium;
        font-weight: 600;
        color: $color-dark;
        font-size: 15px;
        line-height: 140%;
      }

      .keywords {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .text {
          padding: 0px;
          // background: $color-white-gray;
          border-radius: 40px;
          font-size: 15px;
          font-family: Inter-Medium;
          font-weight: 400;
          margin-block: 0px;
          margin-right: 0px;
        }
      }

      .btn-blue {
        margin-top: 40px;
        background: $color-darker-blue;
        width: 100%;
        color: $color-white !important;
        font-size: 15px;
        border-radius: 36px;
        height: 44px;
        padding: 10px 16px;
        font-family: Inter-Regular;
        display: flex;
        align-items: center;
        justify-content: center;

        &.resumen {
          margin-top: 20px;
        }
      }
    }
  }
}

.related-items {
  display: flex;
  flex-direction: row;
}
</style>