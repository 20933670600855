<template>
  <div class="main-help fade-in">
    <div class="container mt-5">
      <div class="content-help">
        <h1 class="title">Escríbenos si necesitas ayuda</h1>
        <p class="subtitle">
          Mándanos un mensaje llenando el siguiente formulario y nos pondremos
          en contacto contigo a través del correo de tu cuenta
        </p>
        <div class="card-help">
          <b-form ref="form" @submit.stop.prevent="handleOk">
            <div class="row">
              <b-form-group class="col-12" :state="getValidationState('NOMBRES')">
                <label class="label-control">Nombres y apellidos <span class="text-danger">*</span></label>
                <b-form-input v-model="model.NOMBRES" placeholder="Escribe tus nombres y apellidos"
                  :state="getValidationState('NOMBRES')" @input="validateOnInput('NOMBRES')"></b-form-input>
                <b-form-invalid-feedback :state="getValidationState('NOMBRES')">
                  {{ getValidationMessage('NOMBRES') }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group class="col-12" :state="getValidationState('CORREO')">
                <label class="label-control">Correo <span class="text-danger">*</span></label>
                <b-form-input v-model="model.CORREO" placeholder="Escribe tu correo"
                  :state="getValidationState('CORREO')" @input="validateOnInput('CORREO')"></b-form-input>
                <b-form-invalid-feedback :state="getValidationState('CORREO')">
                  {{ getValidationMessage('CORREO') }}
                </b-form-invalid-feedback>
              </b-form-group>


              <b-form-group class="col-12" :state="getValidationState('ASUNTO')">
                <label class="label-control">Asunto <span class="text-danger">*</span></label>
                <b-form-input v-model="model.ASUNTO" :state="getValidationState('ASUNTO')"
                  @input="validateOnInput('ASUNTO')"></b-form-input>
                <b-form-invalid-feedback :state="getValidationState('ASUNTO')">
                  {{ getValidationMessage('ASUNTO') }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group class="col-12" :state="getValidationState('MENSAJE')">
                <label class="label-control">Mensaje <span class="text-danger">*</span></label>
                <b-form-textarea v-model="model.MENSAJE" rows="5" type="textarea" placeholder="Escribe tu mensaje"
                  :state="getValidationState('MENSAJE')" @input="validateOnInput('MENSAJE')"></b-form-textarea>
                <b-form-invalid-feedback :state="getValidationState('MENSAJE')">
                  {{ getValidationMessage('MENSAJE') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>

            <div class="row row-submit" style="gap: 20px;">
              <b-button variant="primary" @click="handleOk" :disabled="isLoading">
                <span>Enviar mensaje</span>
              </b-button>
            </div>

            <div class="row card-help-footer" style="border-top: 1px solid #dfdfdf;gap: 20px;">
              <p style="padding: auto;">
                Al enviar este mensaje autoriza a la empresa a comunicarse con
                usted vía correo electrónico.
              </p>
            </div>
          </b-form>
        </div>
      </div>
    </div>

    <div v-if="isLoading" class="spinner-overlay">
      <b-spinner label="Cargando..." variant="primary"></b-spinner>
    </div>

  </div>
</template>

<script>
import SettingsProxy from "../../proxies/SettingsProxy.js";

export default {
  name: "Help",
  data() {
    return {
      isLoading: false,
      model: {
        NOMBRES: null,
        CORREO: null,
        ASUNTO: null,
        MENSAJE: null,
      },
      validators: {
        NOMBRES: {
          REQUIRED: true
        },
        CORREO: {
          REQUIRED: true,
          REGEX: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
        },
        ASUNTO: {
          REQUIRED: true
        },
        MENSAJE: {
          REQUIRED: true,
          MINLENGTH: 10
        },
      },
      fieldTouched: {},
    };
  },
  methods: {
    async handleOk(bvModalEvent) {

      bvModalEvent.preventDefault();
      this.onForceInput();
      if (!this.checkFormValidity()) return;

      this.isLoading = true;
      this.$bvToast.hide();
      await SettingsProxy.create(this.model)
        .then((response) => {
          const toastMessage = response.MESSAGE;

          this.$bvToast.toast(toastMessage, {
            title: response.STATUS ? "Exito" : "Error",
            variant: response.STATUS ? "primary" : "danger",
            solid: true,
          });

          if (response.STATUS) {
            this.clearErrors();
          }
        })
        .catch((error) => {
          this.$bvToast.toast(`Ocurrió un error al registrar la solicitud`, {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getValidationState(field) {
      if (!this.fieldTouched[field]) return null;
      return this.getValidationMessage(field) ? false : true;
    },
    getValidationMessage(field) {
      if (this.validators[field].REQUIRED && !this.model[field]) {
        return "Este campo es requerido";
      }

      if (this.validators[field].MAXLENGTH && this.model[field] && this.model[field].length > this.validators[field].MAXLENGTH) {
        return `Este campo no debe exceder los ${this.validators[field].MAXLENGTH} caracteres`;
      }

      if (this.validators[field].REGEX && this.model[field] && !this.validators[field].REGEX.test(this.model[field])) {
        return "El formato no es válido";
      }

      if (this.validators[field].MINLENGTH && this.model[field] && this.model[field].length < this.validators[field].MINLENGTH) {
        return `Este campo debe tener al menos ${this.validators[field].MINLENGTH} caracteres`;
      }

      return null;
    },
    checkFormValidity() {
      let isValid = true;
      for (const field in this.validators) {
        if (this.getValidationMessage(field)) {
          isValid = false;
        }
      }
      return isValid;
    },
    clearErrors() {
      for (const field in this.validators) {
        this.model[field] = null;
      }

      this.fieldTouched = {};
    },
    validateOnInput(field) {
      this.fieldTouched = {
        ...this.fieldTouched,
        [field]: true,
      };
    },
    onForceInput() {
      const fields = Object.keys(this.model);
      fields.forEach((field) => {
        this.fieldTouched = {
          ...this.fieldTouched,
          [field]: true,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$color-light-blue: #1864FF;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #DDE3EB;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;
$color-gray-main: #486169;

.main-help {
  min-height: 100vh;
  display: flex;
  background: $color-white;
  padding: 100px 0 20px 0;

  .content-help {
    max-width: 680px;
    margin: auto;
    text-align: center;

    .title {
      text-align: center;
      font-weight: bold;
      margin-bottom: 20px;
      font-family: Inter-SemiBold;
      line-height: 39px;
      font-size: 32px;
      color: $color-dark;
    }

    .subtitle {
      font-size: 15px;
      margin-bottom: 40px;
      font-family: Inter-Regular;
      color: $color-dark;
      font-family: Inter-Regular;
    }

    .card-help {
      background: white;
      padding: 20px 20px;
      text-align: left;
      margin: 0 60px;

      h5 {
        text-transform: uppercase;
      }

      .form-group {
        .form-control {
          font-size: 15px;

          color: $color-dark;
          font-family: Inter-Regular;
        }

        .label-control {
          margin-bottom: 2px;
          font-family: Inter-SemiBold;
        }

        input {
          height: 52px;
        }
      }

      button {
        background: $color-light-blue;
        color: $color-white;
        font-size: 15px;
        border-radius: 36px;
        height: 44px;
        padding: 12px 16px;
        margin-bottom: 10px;
        margin-top: 10px;
        font-family: Inter-Medium;

        @media (max-width: 768px) {
          width: 100%;
          margin: 0 20px;
        }
      }

      .row-submit {
        padding: 0 15px;
        justify-content: end;

        @media (max-width: 768px) {
          padding: 0 0px;
        }
      }

      .card-help-footer {
        margin-top: 10px;
        color: $color-gray-main;

        @media (max-width: 768px) {
          font-size: 12px;
          text-align: center;
        }
      }

      @media (max-width: 768px) {
        margin: 0 10px;
      }
    }
  }
}
</style>