const ADMIN_REDIRECT = 'Entries';
const SUBSCRIPTOR_REDIRECT = 'Search';

const LOGIN_REDIRECT = "Login";

export function redirectByRole(role) {
    let url = LOGIN_REDIRECT;

    if (role === 0 || role === 1) {
        url = ADMIN_REDIRECT;
    }

    if (role === 2) {
        url = SUBSCRIPTOR_REDIRECT
    }

    return url;
}